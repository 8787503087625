import * as d from "../../domain/domain";
import { retrieveAvatarDownloadBlob, selectAvatarBlob, selectUser } from "../../features/users";
import { useAppDispatch } from "../../store/redux";
import { getGradientFromName } from "./colours";
import {
    getDominantPresenceMode,
    hasLivePresence,
    isLivePresence,
    isVisibleUserPresenceMode,
    PresenceMode,
    presenceModesFromTracks,
} from "../../domain/presence";
import TimeAgo from "./TimeAgo";
import {
    PersonalAvatar,
    getScreenShareText,
    getUserOverviewName,
    userOverviewToPresenceString,
} from "../../domain/users";
import ParticipantTile, { ParticipantTileProps } from "./ParticipantTile";
import typingIcon from "../../../assets/icons/typing-icon.svg";
import speakingIcon from "../../../assets/icons/speaking-icon.svg";
import screenSharingIcon from "../../../assets/icons/sharing-icon.svg";
import { credentialsValidWithin } from "../../domain/blobs";
import { ReactEventHandler, useCallback, useMemo } from "react";
import { isValidAvatarMimeType } from "../../api/users";
import useSelectorArgs from "../../hooks/useSelectorArgs";
import { useConnectedEffect } from "../../hooks/useConnectedEffect";
import classNames from "classnames";
import { isTrackActive } from "../../domain/session";
import { selectOrg } from "../../features/squads";
import useInterestedOrgs from "../../hooks/interest/useInterestedOrgs";

type AvatarSize = "xs-small" | "x-small" | "small" | "medium" | "large";

interface AvatarContext {
    showBondPresence?: boolean;
    showSquadPresence?: boolean;
    isScreenShare?: boolean;
}

interface AvatarModifiers {
    observingBond?: boolean;
    contributed?: boolean;
    mentionedCurrentUser?: boolean;
    callParticipation?: ParticipantTileProps;
    contemporary?: boolean;
}

export interface IAvatarProps {
    userId: d.UserId;
    testId?: string;
    size?: AvatarSize;
    hidden?: boolean;
    context?: AvatarContext;
    modifiers?: AvatarModifiers;
}

const getInitials = (name: string) =>
    name.trim()
        .split(" ")
        .map(word => String.fromCodePoint(word.codePointAt(0) || 0))
        .join("")
        .slice(0, 3);

const avatarBackground = (name: string, userId: d.UserId): string => {
    const unique = d.extractUUID(userId);

    const colorstr = getGradientFromName(name + unique);
    const initials = `${getInitials(name)}`;

    return `<svg xmlns='http://www.w3.org/2000/svg' height="100%" width="100%" class='c-avatar-name'><circle cx='50%' cy='50%' r='50%' fill='${colorstr}'/><text x='50%' y='50%' dominant-baseline='central' text-anchor='middle'>${initials}</text></svg>`;
};

const getPresenceModeIcon = (mode: PresenceMode): string => {
    switch (mode) {
        case PresenceMode.Typing:
            return typingIcon;
        case PresenceMode.Speaking:
            return speakingIcon;
        case PresenceMode.ScreenSharing:
            return screenSharingIcon;
        default:
            return "";
    }
};

function AvatarImage(
    { avatar: { blobId }, altText }: { avatar: PersonalAvatar; altText?: string; },
): React.JSX.Element {
    const dispatch = useAppDispatch();

    const avatarBlob = useSelectorArgs(selectAvatarBlob, blobId);
    const credentials = avatarBlob?.credentials;

    useConnectedEffect(() => {
        if (blobId && !avatarBlob) {
            dispatch(retrieveAvatarDownloadBlob(blobId));
        }
    }, [dispatch, blobId, avatarBlob]);

    const errorHandler: ReactEventHandler<HTMLImageElement> = useCallback(e => {
        e.preventDefault();
        e.stopPropagation();

        if (!blobId) {
            return;
        }

        if (credentials && credentialsValidWithin(credentials, 0)) {
            return;
        }

        dispatch(retrieveAvatarDownloadBlob(blobId));
    }, [dispatch, blobId, credentials]);

    if (!avatarBlob || !credentials || !isValidAvatarMimeType(avatarBlob.metadata.mimeType)) {
        return <></>;
    }

    return (
        <img
            className="c-avatar__image"
            alt={altText}
            src={credentials.url}
            onError={errorHandler}
        />
    );
}

export default function Avatar(
    props: IAvatarProps,
): React.JSX.Element {
    const user = useSelectorArgs(selectUser, props.userId);
    const org = useSelectorArgs(selectOrg, user?.orgId);
    useInterestedOrgs(user?.orgId);

    const hidden = props.hidden || false;
    const size = props.size || "small";
    const showSquadPresence = props.context?.showSquadPresence || false;
    const showBondPresence = props.context?.showBondPresence || false;
    const isScreenShare = props.context?.isScreenShare || false;

    const contributed = props.modifiers?.contributed || false;
    const mentionedCurrentUser = props.modifiers?.mentionedCurrentUser || false;
    const observingBond = props.modifiers?.observingBond || false;
    const upToDate = props.modifiers?.contemporary || false;
    const callParticipation = props.modifiers?.callParticipation;
    const isVideoOn = isTrackActive(callParticipation?.videoTrack);

    const presenceModes = useMemo(() => {
        let modes = user?.activity?.presenceModes ?? [];
        if (callParticipation) {
            // For a user in a call, only use RTC stack as source of live presence info
            modes = modes.filter(mode => !isLivePresence(mode));

            modes.push(...presenceModesFromTracks(
                callParticipation.audioTrack,
                callParticipation.videoTrack,
                isScreenShare,
            ));
        }
        return modes;
    }, [user?.activity?.presenceModes, callParticipation, isScreenShare]);

    // Show "sharing" icon iff isScreenShare.
    // Otherwise, show the most dominant visibile presence mode that the user has.
    const presenceForIcon = useMemo(() => (
        isScreenShare
            ? PresenceMode.ScreenSharing
            : getDominantPresenceMode(presenceModes.filter(isVisibleUserPresenceMode))
    ), [isScreenShare, presenceModes]);

    if (hidden || !user) {
        // This empty div is used to align message content
        return <div className={`c-avatar c-avatar--${size}`}></div>;
    }

    const hasLiveParticipation = !!callParticipation || hasLivePresence(presenceModes);
    const active = !!user.activity?.active || hasLiveParticipation;
    const activeInBond = active && observingBond;

    const nickname = getUserOverviewName(user);
    const nameText = !isScreenShare ? user.name : getScreenShareText(user.name);
    const nicknameText = isScreenShare && nickname ? getScreenShareText(nickname) : nickname;
    const mentionedYouText = `${nickname} mentioned you`;
    const orgName = org ? (org.personal ? "" : org.name) : "Unknown org";
    const presenceText = userOverviewToPresenceString(user) + (orgName && ` (${orgName})`);
    const hoverText = isScreenShare ? getScreenShareText(user.name) : presenceText;
    const avatarAltText = `${user.name}'s avatar`;

    const includeNamePill = showBondPresence || showSquadPresence;
    const includeAvatar = !showBondPresence || observingBond;

    const pictureValid = !!user?.picture;
    const userPicture = pictureValid ? user?.picture : undefined;

    // Formatting CSS classes:
    const callClass = isVideoOn ? `c-avatar--video` : "";
    const callCaptionClass = isVideoOn ? `is-live` : "";
    const upToDateClass = upToDate ? `c-human__caption--left` : "";
    const squadPresenceClass = showSquadPresence && (active ? "is-active" : "is-away");

    const captionClasses = classNames(
        "c-human__caption",
        squadPresenceClass,
        showBondPresence && (activeInBond ? "is-active" : "is-following"),
        {
            "has-mentioned": mentionedCurrentUser,
            "has-contributed": contributed && !mentionedCurrentUser,
        },
        callCaptionClass,
        upToDateClass,
    );

    const cBondPresence = showBondPresence ? "bond-presence" : "";

    const mentionedYouTooltip = mentionedCurrentUser ? mentionedYouText : "";
    const recencyLabel = !active && user.activity && !user.activity.active &&
        (
            <span className="c-human__meta">
                <TimeAgo
                    from={user.activity?.inactiveSince}
                    live={true}
                />
            </span>
        );

    const namePill = (
        <figcaption
            className={captionClasses}
            {...(mentionedYouTooltip ? { "data-tooltip": mentionedYouTooltip } : {})}
        >
            {includeAvatar && presenceForIcon && (
                <div
                    className="c-icon-animated"
                    style={{
                        backgroundImage: `url("${getPresenceModeIcon(presenceForIcon)}")`,
                    }}
                >
                </div>
            )}
            {nicknameText}
            {upToDate && <span className="c-icon-caughtup"></span>}
            {showSquadPresence && recencyLabel}
        </figcaption>
    );

    const nameTooltip = (
        <div className="c-tooltip">
            <div className="c-tooltip__content">
                <span className="c-tooltip__name">{nameText}</span>
            </div>
        </div>
    );

    const figureClasses = classNames(
        `c-avatar c-avatar--${size}`,
        squadPresenceClass,
        callClass,
    ); // TODO: Is this meant to have typing/sharing too?

    const avatarHTML = includeAvatar ? avatarBackground(user.name, user.id) : "";

    // TODO: this results in a figure inside a figure below. We should put the
    // participant tile alongside the backup avatarHTML once we figure out how
    // to not do this dangerously.
    const fallbackVisual = (
        <figure
            className={figureClasses}
            data-testid={props.testId}
            dangerouslySetInnerHTML={{ __html: avatarHTML }}
        />
    );

    return (
        <div
            className={`c-human ${cBondPresence} ${callParticipation ? "has-tooltip" : ""}`}
            {...(callParticipation ? {} : { title: hoverText })}
        >
            {includeAvatar ? (
                <figure
                    className={figureClasses}
                    data-testid={props.testId}
                >
                    {!isVideoOn && (
                        userPicture ? (
                            <AvatarImage
                                avatar={userPicture}
                                altText={avatarAltText}
                            />
                        ) : fallbackVisual
                    )}
                    {callParticipation && (
                        <ParticipantTile
                            {...callParticipation}
                            videoTitle={false}
                        />
                    )}
                </figure>
            ) : fallbackVisual}
            {includeNamePill && namePill}
            {callParticipation && nameTooltip}
        </div>
    );
}
