import { SetStateAction, useCallback, useEffect, useRef } from "react";

import {
    RtcTrack_Source,
    Session,
    RemoteTrack,
    connectToSession,
    createSubscribeToAllPolicy,
    RtcRenderHint,
    setLogger as setRtcLogger,
} from "@avos-io/rtc-client";

import * as d from "../../domain/domain";
import { Participant, getDisabledTrack, Track, TrackRenderHint } from "../../domain/session";
import { selectCurrentUserId } from "../../features/auth";
import {
    CallActionStatus,
    getAccessToken,
    selectCallById,
    setCallJoinStatus,
    setJoinedCall,
    setLeftCall,
} from "../../features/calls";
import { useAppDispatch, useAppSelector } from "../../store/redux";
import log from "../../misc/log";
import useMap from "../useMap";
import { Optional } from "../../misc/types";
import { MediaInputKind, MediaInputKind_Type } from "../../domain/mediaDevices";
import { useSessionConnection } from "./useSessionConnection";
import { useSessionEventListeners } from "./useSessionEventListeners";
import { useSessionPublishers } from "./useSessionPublishers";
import useSelectorArgs from "../useSelectorArgs";
import { getEnvironmentConfig } from "../../misc/environment";

setRtcLogger(log.with({ from: "rtc" }).logger());

const joinedSessions = new Map<d.RtcSessionId, Session>();

let lastSessionId: Optional<d.RtcSessionId>;
let lastSessionStats: string[] = [];

const targetConfig = getEnvironmentConfig();

const SOCKET_URL = new URL(targetConfig.sigUrl);

const toLocalTrack = (remoteTrack: Optional<RemoteTrack>, session: Optional<Session>): Track => {
    const stream = remoteTrack?.getMediaStream();
    if (!stream || !remoteTrack) {
        return getDisabledTrack();
    }

    // give the track a function it can use to call updateRenderHints on the session
    const rtcTrack = remoteTrack?.getRtcTrack();
    const updateRenderHint = session && rtcTrack && ((hint: TrackRenderHint) => {
        try {
            session.updateRenderHints(
                new Map<string, RtcRenderHint>([[
                    rtcTrack.id,
                    new RtcRenderHint(hint),
                ]]),
            );
        }
        catch (err) {
            log.error("Failed to send render hint", err);
        }
    });

    return {
        enabled: !!stream,
        muted: remoteTrack.isMuted(),
        source: stream,
        rtcTrackId: remoteTrack.getRtcTrack().id,
        updateRenderHint,
    };
};

const toTrackSource = (inputKind: MediaInputKind_Type): Optional<RtcTrack_Source> => {
    switch (inputKind) {
        case MediaInputKind.AudioInput:
            return RtcTrack_Source.MICROPHONE;
        case MediaInputKind.VideoInput:
            return RtcTrack_Source.CAMERA;
        case MediaInputKind.Display:
            return RtcTrack_Source.SCREEN_SHARE;
    }
};

// Return a copy of a participant, with the audio track updated
const updateAudioTrack = (
    part: Participant,
    remoteTrack: Optional<RemoteTrack>,
    session: Optional<Session>,
): Participant => {
    return { ...part, audioTrack: toLocalTrack(remoteTrack, session) };
};

// Return a copy of a participant, with the video track updated
const updateVideoTrack = (
    part: Participant,
    remoteTrack: Optional<RemoteTrack>,
    session: Optional<Session>,
): Participant => {
    return { ...part, videoTrack: toLocalTrack(remoteTrack, session) };
};

// Return a copy of a participant, with the display media updated
const updateDisplayTrack = (
    part: Participant,
    remoteTrack: Optional<RemoteTrack>,
    session: Optional<Session>,
): Participant => {
    return { ...part, displayTrack: toLocalTrack(remoteTrack, session) };
};

const updatePeer = (
    track: RemoteTrack,
    session: Optional<Session>,
): SetStateAction<Participant> => {
    switch (track.getRtcTrack().source) {
        case RtcTrack_Source.MICROPHONE:
            return p => updateAudioTrack(p, track, session);
        case RtcTrack_Source.CAMERA:
            return p => updateVideoTrack(p, track, session);
        case RtcTrack_Source.SCREEN_SHARE:
            return p => updateDisplayTrack(p, track, session);
        default:
            log.warn(`Received track update for track of unknown type
                ${track.getRtcTrack().source}`);
            return p => p;
    }
};

export interface useSessionProps {
    callId?: d.CallId;
    // Called when the client leaves and cannot reconnect
    onLeave?: () => void;
}

export function useSession(props: useSessionProps) {
    const { callId, onLeave } = props;
    const sessionRef = useRef<Session>();

    const dispatch = useAppDispatch();
    const userId = useAppSelector(selectCurrentUserId);

    const call = useSelectorArgs(selectCallById, callId);
    const sessionId = call?.sessionId;

    // The participant tracks to return to the caller
    // Note by using a map we get the entries sorted by arrival time
    const {
        set: addParticipant,
        remove: removeParticipant,
        reset: resetParticipants,
        update: updateParticipant,
        values: otherParticipants,
    } = useMap<d.RtcParticipantId, Participant>();

    const updateParticipantTrack = useCallback((id: d.RtcParticipantId, track: RemoteTrack) => {
        if (!track.getMediaStream()) {
            log.warn(
                `Received track update for participant ${id} without media stream - ignoring update`,
            );
            return;
        }

        updateParticipant(id, updatePeer(track, sessionRef.current));
    }, [updateParticipant]);

    const removeParticipantTrack = useCallback((id: d.RtcParticipantId, trackId: string) => {
        const peerUpdate = (part: Participant) => {
            switch (trackId) {
                case part.audioTrack.rtcTrackId:
                    return updateAudioTrack(part, undefined, sessionRef.current); // remove audio
                case part.displayTrack.rtcTrackId:
                    return updateDisplayTrack(part, undefined, sessionRef.current); // remove display
                case part.videoTrack.rtcTrackId:
                    return updateVideoTrack(part, undefined, sessionRef.current); // remove video
                default:
                    log.warn(
                        `Received track removal request for peer ${id} for unknown track ${trackId}`,
                    );
                    return part;
            }
        };

        updateParticipant(id, peerUpdate);
    }, [updateParticipant]);

    // The metadata about the current session connection.
    const { connectionSignal, getCanConnect, sendConnectionSignal } = useSessionConnection();

    // Trigger connection useEffect to retrigger and not reconnect
    const leaveSession = useCallback(() => {
        sendConnectionSignal(false);
    }, [sendConnectionSignal]);

    const muteTrack = useCallback((isMuted: boolean, trackKind: MediaInputKind_Type) => {
        const trackSource = toTrackSource(trackKind);
        if (!trackSource) {
            return;
        }

        log.debug(`${isMuted ? "Muting" : "Unmuting"} media track`, trackKind);
        sessionRef.current?.setTrackMuteBySource(isMuted, trackSource);
    }, []);

    // Listen to session/peer events
    const onSessionJoin = useCallback(() => {
        if (callId) {
            dispatch(setJoinedCall(callId));
        }
        else {
            log.warn("Tried to join call without call ID");
        }
    }, [callId, dispatch]);
    const onSessionLeave = useCallback((canReconnect: boolean) => {
        sendConnectionSignal(canReconnect);
    }, [sendConnectionSignal]);
    const { addSessionEventListeners, removeSessionEventListeners } = useSessionEventListeners({
        sessionId,
        onSessionJoin,
        onSessionLeave,
        addParticipant,
        removeParticipant,
        updateParticipantTrack,
        removeParticipantTrack,
    });

    // Define functions to publish/unpublish media into the session for each media kind
    const getSession = useCallback(() => sessionRef.current, []);
    const { publishers, resetPublishers } = useSessionPublishers({ getSession });

    // Handle leaving session on navigating away
    const onLeaveSession = useCallback(() => {
        dispatch(setLeftCall());
        onLeave?.();
    }, [dispatch, onLeave]);
    useEffect(() => {
        return onLeaveSession;
    }, [onLeaveSession]);

    // Handle connecting to the session if we've reached the state we can connect with.
    // Fetch an access token from the backend, and connect to the session.
    // Handle leaving and cleaning up the session when exiting this useEffect.
    useEffect(() => {
        if (!getCanConnect()) {
            return;
        }
        if (!sessionId || !userId) {
            return;
        }
        dispatch(setCallJoinStatus(CallActionStatus.Pending)); // TODO: move to getAccessToken?

        // Get access token from API
        const tokenFetch = dispatch(getAccessToken({ sessionId, userId }));

        // Connect to call with access token
        let isCleaningUp = false;
        tokenFetch.unwrap().then(token => {
            // Early return if already cleaning up useEffect
            if (isCleaningUp) {
                return;
            }

            // Connect to session and mark as connected upon joining
            const session = connectToSession(
                SOCKET_URL,
                d.extractRawRtcSessionId(sessionId),
                token.accessToken,
                createSubscribeToAllPolicy(),
            );

            sessionRef.current = session;
            joinedSessions.set(sessionId, session);
            addSessionEventListeners(session);
        })
            .catch(e => {
                if (e.name === "AbortError" || isCleaningUp) {
                    return;
                }

                dispatch(setCallJoinStatus(CallActionStatus.Error));
                log.error(e);
                log.info(`Get access token exception: ${e}`);
            });

        return () => {
            isCleaningUp = true;

            // Leave session on closing
            tokenFetch.abort();

            // Call on leave callback if we will not reconnect
            if (!getCanConnect()) {
                onLeaveSession();
            }

            // Capture current session object before resetting for next `useEffect`.
            // We need a reference to the old value so we can asynchronously
            // capture RTC stats and leave.
            const currentSession = sessionRef.current;
            sessionRef.current = undefined;
            joinedSessions.delete(sessionId);

            // Remove event listeners
            removeSessionEventListeners(currentSession);

            // Reset publisher functions and participants before joining the next session
            resetPublishers();
            resetParticipants();

            // Capture RTC stats and leave session
            if (currentSession) {
                captureLastSession(sessionId, currentSession)
                    .finally(() => {
                        // Note: by not using sessionRef.current::leave we keep
                        // the session in scope until this callback is called.
                        currentSession.leave();
                        log.info("Left session", sessionId);
                    });
            }
        };
    }, [
        addSessionEventListeners,
        connectionSignal,
        dispatch,
        getCanConnect,
        onLeaveSession,
        removeSessionEventListeners,
        resetParticipants,
        resetPublishers,
        sessionId,
        userId,
    ]);

    return {
        leaveSession,
        muteTrack,
        otherParticipants,
        publishers,
    };
}

async function captureLastSession(id?: d.RtcSessionId, session?: Session) {
    if (session) {
        lastSessionId = id;
        lastSessionStats = await getFormattedDumpFromSession(session, id);
    }
    else {
        lastSessionId = undefined;
        lastSessionStats = [];
    }
}

function formatRtcStats(local: RTCStatsReport, remote: RTCStatsReport): string[] {
    const statsDump = [] as string[];
    const dump = (prefix: string) => (v: any, k: string) => {
        statsDump.push(`${prefix} ${k}: ${JSON.stringify(v)}`);
    };
    local.forEach(dump("local"));
    remote.forEach(dump("remote"));
    return statsDump;
}

async function getFormattedDumpFromSession(session: Session, id?: d.RtcSessionId) {
    const stats = session.getStats();
    try {
        const [local, remote] = await stats;
        return formatRtcStats(local, remote);
    }
    catch (e) {
        log.error("Failed to get RTC stats dump for RTC session", id, e);
        return [] as string[];
    }
}

export function getRtcStats(): Map<d.RtcSessionId, string[]> {
    const statsDump = new Map<d.RtcSessionId, string[]>();
    joinedSessions.forEach(async (session, id) => {
        const formattedDump = await getFormattedDumpFromSession(session, id);
        statsDump.set(id, formattedDump);
    });
    return statsDump;
}

export const getRecentRtcStats = () => ({ lastSessionId, lastSessionStats });

// Export these to the global namespace on the browser. We want it to be possible
// to open up the developer tools and get RTC stats.
if (typeof window !== "undefined") {
    (window as any).getRtcStats = getRtcStats;
    (window as any).getRecentRtcStats = getRecentRtcStats;
}
