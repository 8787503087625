import { useCallback, useState } from "react";
import classNames from "classnames";

import useDelayedAction from "../../hooks/useDelayedAction";

export interface SwitchState {
    text?: string;
    togglingText?: string;
    extraClassNames?: string;
    // Allow skipping the delay when receiving a click from this state.
    immediate?: boolean;
}

export type SwitchContentProps = { toggling: boolean; state: SwitchState; };

export function ButtonContentComponent({ toggling, state }: SwitchContentProps): React.JSX.Element {
    return <span>{toggling ? state.togglingText : state.text}</span>;
}

interface SwitchWithDelayProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    delay?: number;
    classDuringDelay?: string;
    switchValue: boolean;
    action: (newVal: boolean) => void | Promise<void>;
    trueState: SwitchState;
    falseState: SwitchState;
    // We can't use `PropsWithChildren` as the child needs to depend
    // on the internal state of our switch. Use a component instead.
    // Capitalised to allow specifying inline in the JSX.
    Content: (props: SwitchContentProps) => React.JSX.Element;
}

type MEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>;

export default function SwitchWithDelay(props: SwitchWithDelayProps): React.JSX.Element {
    const {
        action: inputAction,
        delay,
        switchValue,
        trueState,
        falseState,
        className,
        classDuringDelay,
        Content,
        ...passthroughProps
    } = props;

    const [shouldAdjust, setShouldAdjust] = useState<boolean | undefined>();
    const toggling = shouldAdjust !== undefined;
    // 4 possible states of props.switchValue/toggling
    // true/false: switch is "on"
    // false/false: switch is "off"
    // true/true: switch is "turning off"
    // false/true: switch is "turning on"

    const action = useCallback(() => {
        if (toggling) {
            inputAction(shouldAdjust);
            setShouldAdjust(undefined);
        }
    }, [inputAction, shouldAdjust, toggling]);

    const { trigger, triggered, reset, hold, resume } = useDelayedAction({
        action,
        delay: delay,
        fireOnUnmount: true,
    });

    const state = switchValue ? trueState : falseState;
    const otherState = switchValue ? falseState : trueState;
    const text = toggling ? state.togglingText : state.text;

    const onClick = (e: MEvent) => {
        e.stopPropagation();

        if (!toggling) {
            const newValue = !switchValue;
            if (state.immediate) {
                inputAction(newValue);
            }
            else {
                setShouldAdjust(newValue);
                trigger();
            }
        }
        else {
            setShouldAdjust(undefined);
            reset();
        }
    };

    const onMouseEnter = useCallback((e: MEvent) => {
        e?.stopPropagation();
        if (triggered) {
            hold();
        }
    }, [hold, triggered]);
    const onMouseLeave = useCallback((e: MEvent) => {
        e?.stopPropagation();
        if (triggered) {
            resume();
        }
    }, [resume, triggered]);

    const classes = classNames(
        className,
        toggling && otherState.extraClassNames,
        !toggling && state.extraClassNames,
        toggling && classDuringDelay,
    );

    return (
        <button
            {...passthroughProps}
            className={classes}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            title={text}
            tabIndex={-1}
        >
            <Content state={state} toggling={toggling} />
        </button>
    );
}
