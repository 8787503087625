import log from "../misc/log";
import { Optional } from "../misc/types";

// In this file we export several union types, e.g MediaKind_Type, with
// corresponding enum-ish consts, e.g. MediaKind,
// so that we can use the enum values directly, e.g. `MediaKind.Display`.
//
// This way we can get media for many kinds of devices in one hook
// (e.g. useMediaDevice) and we can compose these enums in different ways.

enum InputDeviceKind {
    AudioInput = "audioinput",
    VideoInput = "videoinput",
}

enum OutputDeviceKind {
    AudioOutput = "audiooutput",
}

// This mirrors the `MediaDeviceKind` union type, which is e.g. returned from
// `mediaDevices.enumerateDevices`.
// See: https://developer.mozilla.org/en-US/docs/Web/API/MediaDeviceInfo/kind
//
// It is an enum so we can use e.g. `DeviceKind.AudioInput` instead of the
// raw string "audioinput".
export type DeviceKind_Type = InputDeviceKind | OutputDeviceKind;
export const DeviceKind = { ...InputDeviceKind, ...OutputDeviceKind };
export const deviceKinds: DeviceKind_Type[] = [
    DeviceKind.AudioInput,
    DeviceKind.VideoInput,
    DeviceKind.AudioOutput,
];

// The display device media kind i.e. screen share
enum DisplayKind {
    Display = "display",
}

export type MediaKind_Type = DeviceKind_Type | DisplayKind;
export const MediaKind = { ...DeviceKind, ...DisplayKind };

export type MediaInputKind_Type = InputDeviceKind | DisplayKind;
export const MediaInputKind = { ...InputDeviceKind, ...DisplayKind };
export const inputDevices: MediaInputKind_Type[] = [
    MediaKind.AudioInput,
    MediaKind.VideoInput,
    MediaKind.Display,
];

// A helper function to safely cast a MediaKind to DeviceKindType
export function toDeviceKind(kind: MediaKind_Type): Optional<DeviceKind_Type> {
    return Object.values<string>(DeviceKind).includes(kind) ? kind as DeviceKind_Type : undefined;
}

export function getDeviceName(deviceKind: DeviceKind_Type) {
    switch (deviceKind) {
        case DeviceKind.AudioInput:
            return "Microphone";
        case DeviceKind.VideoInput:
            return "Camera";
        case DeviceKind.AudioOutput:
            return "Speaker";
    }
}

// This represents groups of user media devices
export enum DeviceGroup {
    Audio = "audio",
    Video = "video",
}

export function toDeviceGroup(deviceKind: DeviceKind_Type) {
    switch (deviceKind) {
        case DeviceKind.AudioInput:
        case DeviceKind.AudioOutput:
            return DeviceGroup.Audio;
        case DeviceKind.VideoInput:
            return DeviceGroup.Video;
    }
}

// This represents groups of media (i.e. user media devices or display media).
// This corresponds 1-to-1 with the media controls in the message composer.
export type MediaGroup_Type = DeviceGroup | DisplayKind;
export const MediaGroup = { ...DeviceGroup, ...DisplayKind };

export function toMediaGroup(kind: MediaInputKind_Type) {
    switch (kind) {
        case MediaInputKind.AudioInput:
            return MediaGroup.Audio;
        case MediaInputKind.VideoInput:
            return MediaGroup.Video;
        case MediaInputKind.Display:
            return MediaGroup.Display;
    }
}

export const mediaGroupDeviceKinds = {
    [MediaGroup.Audio]: [DeviceKind.AudioInput, DeviceKind.AudioOutput],
    [MediaGroup.Video]: [DeviceKind.VideoInput],
    [MediaGroup.Display]: [],
};

export type DeviceInfo = {
    id: string;
    kind: DeviceKind_Type;
    label: string;
};

// Check a media device exists in the enumerated device list.
// Resolves true iff the enumerateDevices call is successful and
// the device is in the enumerated list.
export async function checkMediaDeviceExists(
    kind: DeviceKind_Type,
    deviceId?: string,
): Promise<boolean> {
    return navigator.mediaDevices.enumerateDevices()
        .then(ds => ds.some(d => d.kind === kind && d.deviceId === deviceId))
        .catch(e => {
            log.error("Failed to check if media device exists:", e);
            return false;
        });
}

export type MediaPublishFunc = (stream: MediaStream) => void;
export type AsyncMediaPublishFunc = (stream: MediaStream) => Promise<void>;
export type MediaUnpublishFunc = () => void;
export type MediaPublisher = {
    publish: AsyncMediaPublishFunc;
    unpublish: MediaUnpublishFunc;
};
export type MediaPublisherMap = Record<MediaInputKind_Type, MediaPublisher>;

export interface MediaToggle {
    active: boolean;
    toggle: () => void;
}
export type MediaToggleMap = Record<MediaInputKind_Type, MediaToggle>;
