// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=js+ts"
// @generated from file devices/devices.proto (package devices, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetOrAddDeviceRequest, GetOrAddDeviceResponse, GetPersonDevicesRequest, GetPersonDevicesResponse, RegisterBrowserFocusRequest, RegisterBrowserFocusResponse, RegisterConnectionRequest, RegisterConnectionResponse, RegisterObservationRequest, RegisterObservationResponse, RegisterPresenceModeRequest, RegisterPresenceModeResponse, ReportFirebaseTokenRequest, ReportFirebaseTokenResponse, SubObserversRequest, SubObserversResponse, SubPersonalActivityRequest, SubPersonalActivityResponse } from "./devices_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service devices.DeviceService
 */
export const DeviceService = {
  typeName: "devices.DeviceService",
  methods: {
    /**
     * GetOrAddDevice gets the corresponding device ID for a user/device-tag pair.
     * If the device is not found, a new ID is created and returned.
     *
     * @generated from rpc devices.DeviceService.GetOrAddDevice
     */
    getOrAddDevice: {
      name: "GetOrAddDevice",
      I: GetOrAddDeviceRequest,
      O: GetOrAddDeviceResponse,
      kind: MethodKind.Unary,
    },
    /**
     * RegisterConnection is used to mark a frontend device as currently/not connected.
     * If the connection is not found, a new ID is created and returned.
     *
     * @generated from rpc devices.DeviceService.RegisterConnection
     */
    registerConnection: {
      name: "RegisterConnection",
      I: RegisterConnectionRequest,
      O: RegisterConnectionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * RegisterObservation is used to mark a frontend device as currently/not watching a view.
     *
     * @generated from rpc devices.DeviceService.RegisterObservation
     */
    registerObservation: {
      name: "RegisterObservation",
      I: RegisterObservationRequest,
      O: RegisterObservationResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ...
     *
     * @generated from rpc devices.DeviceService.RegisterBrowserFocus
     */
    registerBrowserFocus: {
      name: "RegisterBrowserFocus",
      I: RegisterBrowserFocusRequest,
      O: RegisterBrowserFocusResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ...
     *
     * @generated from rpc devices.DeviceService.RegisterPresenceMode
     */
    registerPresenceMode: {
      name: "RegisterPresenceMode",
      I: RegisterPresenceModeRequest,
      O: RegisterPresenceModeResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ReportFirebaseToken stores the supplied firebase token in the database against the supplied deviceId.
     *
     * @generated from rpc devices.DeviceService.ReportFirebaseToken
     */
    reportFirebaseToken: {
      name: "ReportFirebaseToken",
      I: ReportFirebaseTokenRequest,
      O: ReportFirebaseTokenResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetPersonDevices returns the devices, and their firebase tokens if present for each device associated with the supplied person ID.
     *
     * @generated from rpc devices.DeviceService.GetPersonDevices
     */
    getPersonDevices: {
      name: "GetPersonDevices",
      I: GetPersonDevicesRequest,
      O: GetPersonDevicesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * SubPersonalActivity streams the live connected/active/present status of the requested persons.
     *
     * @generated from rpc devices.DeviceService.SubPersonalActivity
     */
    subPersonalActivity: {
      name: "SubPersonalActivity",
      I: SubPersonalActivityRequest,
      O: SubPersonalActivityResponse,
      kind: MethodKind.BiDiStreaming,
    },
    /**
     * SubObservers streams the set of users who are observing a particular resource.
     *
     * @generated from rpc devices.DeviceService.SubObservers
     */
    subObservers: {
      name: "SubObservers",
      I: SubObserversRequest,
      O: SubObserversResponse,
      kind: MethodKind.ServerStreaming,
    },
  }
};

