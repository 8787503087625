import { useEffect, useState } from "react";

import * as d from "../domain/domain";
import { selectSelectedBond } from "../features/bonds";
import { selectChannelsWithUnfetchedMessages } from "../features/bondsShared";
import { catchupMessages } from "../features/channels";
import useBackoff from "../hooks/useBackoff";
import { useConnectedEffect } from "../hooks/useConnectedEffect";
import { useAppDispatch, useAppSelector } from "../store/redux";

export default function MessageFetcher(): React.JSX.Element {
    const dispatch = useAppDispatch();

    const candidateChannels = useAppSelector(selectChannelsWithUnfetchedMessages);

    const selectedBond = useAppSelector(selectSelectedBond);
    const visibleChannelId = selectedBond?.channelId;

    const [currentFetch, setCurrentFetch] = useState<d.ChannelId | null>(null);

    const { actionIsPermitted, actionBegin } = useBackoff();

    useConnectedEffect(() => {
        if (!currentFetch) {
            return;
        }

        const backoffCallback = actionBegin();

        dispatch(catchupMessages({ channelId: currentFetch }))
            .unwrap()
            .then(v => {
                const isSuccess = !("error" in v);
                backoffCallback(isSuccess);
            })
            .catch(() => backoffCallback(false))
            .finally(() => setCurrentFetch(null));
    }, [actionBegin, currentFetch, setCurrentFetch, dispatch]);

    useEffect(
        () => {
            if (currentFetch) {
                return;
            }

            if (!actionIsPermitted) {
                return;
            }

            const potential = candidateChannels.find(id => id !== visibleChannelId);
            if (potential !== undefined) {
                setCurrentFetch(potential);
            }
        },
        [
            actionBegin,
            candidateChannels,
            visibleChannelId,
            currentFetch,
            setCurrentFetch,
            actionIsPermitted,
        ],
    );

    return <></>;
}
