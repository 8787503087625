import * as chats_pb from "../../gen/proto/chats/chats_pb";
import * as bonds_pb from "../../gen/proto/bonds/bonds_pb";

import { pbUserId, pbChannelId, bigintToNumber, fromProtoChannelId } from "./util";
import * as d from "../domain/domain";
import { SequenceNumberOrDeleted } from "../domain/channels";
import bondService from "./bonds";
import { streamHandler } from "./stream";
import chatService from "./chats";

function sequenceNumberParser(res: bonds_pb.SubMsgSeqNumsResponse): SequenceNumberOrDeleted {
    switch (res.response?.sequenceNumberOrDeleted.case) {
        case "sequenceNumber":
            return {
                case: "sequenceNumber",
                value: {
                    channelId: fromProtoChannelId(
                        res.response.sequenceNumberOrDeleted.value.channelId,
                    ),
                    sequenceNumber: bigintToNumber(
                        res.response.sequenceNumberOrDeleted.value.maxSequenceNumber,
                    ),
                },
            };
        case "deletedId":
            return {
                case: "deletedId",
                value: fromProtoChannelId(res.response.sequenceNumberOrDeleted.value),
            };
        default:
            throw new Error(
                `unknown sequenceNumberOrDeleted case ${res.response?.sequenceNumberOrDeleted?.case}`,
            );
    }
}

interface StreamChannelSequenceNumbersArgs {
    userId: d.UserId;
}

export async function* streamPublishedSequenceNumbersAPI(
    args: StreamChannelSequenceNumbersArgs,
    signal: AbortSignal,
) {
    const req = new bonds_pb.SubMsgSeqNumsRequest({
        userId: pbUserId(args.userId),
    });

    const logPrefix = `streamChannelSequenceNumbers`;

    const resp = bondService.subMsgSeqNums(req, { signal });

    yield* streamHandler(resp, sequenceNumberParser, logPrefix);
}

interface MarkReadArgs {
    userId: d.UserId;
    channelId: d.ChannelId;
    sequenceNumber: number;
}

export async function markRead(args: MarkReadArgs) {
    const req = new chats_pb.SetUserReadSequenceNumberRequest({
        userId: pbUserId(args.userId),
        channelId: pbChannelId(args.channelId),
        sequenceNumber: BigInt(args.sequenceNumber),
    });

    return chatService.setUserReadSequenceNumber(req);
}
