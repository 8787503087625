import IntervalTree, { Interval } from "@flatten-js/interval-tree";
import {
    DraftChatMessage,
    genCaretHintId,
    getMessageString,
    getMsgPrefixMentions,
    getMsgPrefixWithPadding,
} from "../domain/chats";
import { ContentMention, Mention } from "../domain/mentions";
import { SquadOverview } from "../domain/squads";
import { UserOverview } from "../domain/users";
import { NumberRange, Optional } from "./types";
import { clamp } from "./utils";

export function squadNameForMention(squad: SquadOverview): string {
    return squad.name;
}

export function userNameForMention(user: UserOverview): string {
    return user.nickname ?? user.name.split(/\s+/)[0] ?? "Unknown";
}

export interface ChangeSelections {
    oldSelectionStart: number;
    oldSelectionEnd: number;

    newSelectionEnd: number;
}

export type ContentMutation<T> = (
    draft: DraftChatMessage,
    args: T,
) => Optional<DraftChatMessage>;

const spliceString = (
    oldText: Optional<string>,
    range: NumberRange,
    insertedText: string,
): string =>
    (oldText === undefined) ?
        insertedText :
        oldText.substring(0, range.start) + insertedText + oldText.substring(range.end);

// Probably the correct thing to do here is more elegant than offsetting both ends
// of the range, but our mentions will be at least 2 chars long including the trigger,
// so I'll leave it like this for now
const contentMentionToInterval = (
    contentMention: ContentMention,
): [number, number] => [contentMention.startOffset + 1, contentMention.endOffset - 1];

const intervalToContentMention = (
    interval: [number, number],
    mention: Mention,
): ContentMention => ({
    ...mention,
    startOffset: interval[0] - 1,
    endOffset: interval[1] + 1,
});

const spliceContent = (
    oldMsg: DraftChatMessage,
    oldRange: NumberRange,
    insertedText: string,
): {
    newMsg: DraftChatMessage;
    newRange: NumberRange;
} => {
    const { content: { draftMessage: oldMessage, mentions: oldMentions } } = oldMsg;

    // Build an interval tree from the existing mentions
    const mentionTree = new IntervalTree<Mention>();
    for (const mention of oldMentions ?? []) {
        mentionTree.insert(contentMentionToInterval(mention), mention);
    }

    // Delete any mentions that fall partially or wholly within the spliced section from the tree
    const toDeleteMentions = mentionTree.search(
        [oldRange.start, oldRange.end],
        (m, i) => [m, i],
    ) as [Mention, Interval][];
    for (const [mention, interval] of toDeleteMentions) {
        mentionTree.remove(interval, mention);
    }

    // Calculate the full splice range and splice the message (this could be bigger than
    // the requested range if deleted mentions extend beyond it)
    const splice = {
        start: Math.min(oldRange.start, ...toDeleteMentions.map(([, i]) => i.low - 1)),
        end: Math.max(oldRange.end, ...toDeleteMentions.map(([, i]) => i.high + 1)),
    };
    const newMessage = spliceString(
        oldMessage,
        splice,
        insertedText,
    );
    const lengthDiff = newMessage.length - oldMessage.length;

    // Create the new mentions from those left in the tree, offsetting the mentions that
    // fall to the right of the splice range
    const newMentions = mentionTree.items.map(
        (item: { key: Interval; value: Mention; }): ContentMention => {
            const key = item.key as unknown as [number, number]; // `key` is incorrectly typed by the library spec
            const mention = intervalToContentMention(key, item.value);
            if (key[0] >= splice.end) {
                mention.startOffset += lengthDiff;
                mention.endOffset += lengthDiff;
            }
            return mention;
        },
    ).replaceFrom(oldMentions);

    // Calculate the start and end offsets in the new string
    const newRange = {
        start: splice.start,
        end: splice.start + insertedText.length,
    };

    // Determine whether the prefix was removed or brought into the message
    const oldPrefixLength = oldMsg.prefix?.length ?? 0;
    const newPrefixLength = splice.start >= oldPrefixLength ? oldPrefixLength : 0;

    const newPrefixPadding = clamp(0, oldMsg.prefix?.padding ?? 0)(splice.start - oldPrefixLength);

    const newMsg: DraftChatMessage = {
        ...oldMsg,
        content: {
            ...oldMsg.content,
            draftMessage: newMessage,
            trimmedMessage: newMessage.trim(),
            mentions: newMentions,
        },
        prefix: {
            length: newPrefixLength,
            padding: newPrefixPadding,
        },
    };

    return {
        newMsg,
        newRange,
    };
};

/**
 * Applies a change from the plain text textarea to the underlying marked up value
 * guided by the textarea text selection ranges before and after the change
 */
export const applyTextToContent = (
    oldMsg: DraftChatMessage,
    args: {
        newText: string;
        selections: ChangeSelections;
    },
): DraftChatMessage => {
    const { newText, selections: { oldSelectionStart, oldSelectionEnd, newSelectionEnd } } = args;

    // Determine the text that was inserted from the old and new selections
    const insertedText = newText.slice(oldSelectionStart, newSelectionEnd);

    // Determine the range of text to replace in the old content
    const spliceStart = Math.min(oldSelectionStart, newSelectionEnd);
    let spliceEnd = oldSelectionEnd;
    if (oldSelectionStart === newSelectionEnd) {
        spliceEnd = Math.max(
            oldSelectionEnd,
            oldSelectionStart + getMessageString(oldMsg.content).length - newText.length,
        );
    }

    const { newMsg, newRange: { end: newEnd } } = spliceContent(
        oldMsg,
        { start: spliceStart, end: spliceEnd },
        insertedText,
    );

    return {
        ...newMsg,
        content: {
            ...newMsg.content,
            caretHint: { id: genCaretHintId(), location: newEnd },
        },
    };
};

/**
 * Inserts a string to the underlying marked up draft message, replacing the substring
 * with the given start and end offsets
 */
export const insertTextIntoContent = (
    oldMsg: DraftChatMessage,
    args: {
        insertedText: string;
        range: NumberRange;
    },
): DraftChatMessage => {
    const { insertedText, range } = args;

    const { newMsg, newRange: { end: newEnd } } = spliceContent(
        oldMsg,
        range,
        insertedText,
    );

    return {
        ...newMsg,
        content: {
            ...newMsg.content,
            caretHint: { id: genCaretHintId(), location: newEnd },
        },
    };
};

const insertMentionIntoContentInternal = (
    oldMsg: DraftChatMessage,
    mentionText: string,
    mention: Mention,
    oldRange: NumberRange,
    padding: string,
): DraftChatMessage => {
    const { newMsg, newRange: { start: newStart, end: newEnd } } = spliceContent(
        oldMsg,
        oldRange,
        `${mentionText}${padding}`,
    );

    // Insert the new mention maintaining the offset ordering
    const newMentions = newMsg.content.mentions ?? [];
    newMentions.push({
        ...mention,
        startOffset: newStart,
        endOffset: newStart + mentionText.length,
    });
    newMentions.sort((a, b) => a.startOffset - b.startOffset);

    return {
        ...newMsg,
        content: {
            ...newMsg.content,
            mentions: newMentions,
            caretHint: { id: genCaretHintId(), location: newEnd },
        },
    };
};

/**
 * Inserts a mention to the underlying marked up draft message, replacing the substring
 * with the given start and end offsets
 */
export const insertMentionIntoContent = (
    oldMsg: DraftChatMessage,
    args: {
        mentionText: string;
        mention: Mention;
        range: NumberRange;
    },
): DraftChatMessage => {
    const { mentionText, mention, range } = args;
    return insertMentionIntoContentInternal(oldMsg, mentionText, mention, range, " ");
};

/**
 * Remove the prefix from the content by replacing it with an empty string
 */
export const removePrefixFromContent = (
    oldMsg: DraftChatMessage,
): DraftChatMessage => {
    const prefixLengthWithPadding = (oldMsg?.prefix?.length ?? 0) +
        (oldMsg?.prefix?.padding ?? 0);

    if (prefixLengthWithPadding === 0) return oldMsg;

    return insertTextIntoContent(
        oldMsg,
        {
            insertedText: "",
            range: { start: 0, end: prefixLengthWithPadding },
        },
    );
};

/**
 * Replace or add a prefix to the message containing the given mention
 */
export const setPrefixMentionInContent = (
    oldMsg: DraftChatMessage,
    mentionText: string,
    mention: Mention,
): DraftChatMessage => {
    const strippedMsg = removePrefixFromContent(oldMsg);

    const padding = " ";

    const newMsg = insertMentionIntoContentInternal(
        strippedMsg,
        mentionText,
        mention,
        { start: 0, end: 0 },
        padding,
    );

    return {
        ...newMsg,
        prefix: {
            length: mentionText.length,
            padding: padding.length,
        },
    };
};

export const clearContent = (
    oldMsg: DraftChatMessage,
): DraftChatMessage => {
    const s = getMsgPrefixWithPadding(oldMsg);
    return {
        ...oldMsg,
        content: {
            ...oldMsg.content,
            draftMessage: s,
            trimmedMessage: s.trim(),
            mentions: getMsgPrefixMentions(oldMsg),
            caretHint: { ...oldMsg.content.caretHint, location: undefined },
        },
    };
};

export const promotePrefixInContent = (
    oldMsg: DraftChatMessage,
): DraftChatMessage => ({
    ...oldMsg,
    prefix: { length: 0, padding: 0 },
});
