import {
    selectChannelInfosForSeqNumUpdate,
    updateUserReadSequenceNumber,
} from "../features/channels";
import { useAppDispatch, useAppSelector } from "../store/redux";
import { useConnectedEffect } from "../hooks/useConnectedEffect";

export default function MessageReadUpdater(): React.JSX.Element {
    const dispatch = useAppDispatch();

    const channelInfosForUpdate = useAppSelector(selectChannelInfosForSeqNumUpdate);

    useConnectedEffect(() => {
        if (channelInfosForUpdate.length == 0) {
            return;
        }

        const [channelId, seqNum] = channelInfosForUpdate[0];

        dispatch(
            updateUserReadSequenceNumber({ channelId, seqNum }),
        );
    }, [channelInfosForUpdate, dispatch]);

    return <></>;
}
