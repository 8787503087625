import { captureException } from "@sentry/react";
import { useEffect, useState } from "react";
import { useRouteError } from "react-router-dom";
import { useNavigateBack } from "../../hooks/useNavigateBack";

export function ErrorElement(props?: { sentryEnabled?: boolean; }) {
    const error = useRouteError();
    const { navigateBack } = useNavigateBack();
    const [sentryEventId, setSentryEventId] = useState("");

    useEffect(() => {
        // You may wish to spy on `captureException` perhaps.
        const exception = captureException(error, { level: "fatal" });
        if (props?.sentryEnabled ?? true) {
            setSentryEventId(exception);
        }
    }, [props?.sentryEnabled, error]);

    useEffect(() => {
        if (sentryEventId == "") return;

        const h = setTimeout(navigateBack, 5000);
        return () => {
            clearTimeout(h);
        };
    }, [sentryEventId, navigateBack]);

    // Example code to show how to get a readable message out. Given our use of sentry,
    // and the logging to the console we get, we probably don't need this? Leaving here
    // in case we do.
    /*let errorMessage: string = "";

    if (isRouteErrorResponse(error)) {
        errorMessage = `${error.status} ${error.statusText} (${error.data})`;
    }
    else if (error instanceof Error) {
        errorMessage = `${error.message} (${error.stack})`;
    }
    else if (typeof error === "string") {
        errorMessage = error;
    }
    else {
        errorMessage = "Unknown error";
    }*/

    return (
        <div className="c-error">
            <h1 className="c-error__header">An error occurred!</h1>
            <div className="c-error__content">
                {sentryEventId == "" ? <p>Gathering error information...</p>
                    : (
                        <>
                            <p>
                                <strong>ID</strong> <code>{sentryEventId}</code>
                            </p>
                            <p>Navigating you back in 5 seconds...</p>
                        </>
                    )}
            </div>
        </div>
    );
}
