import { nanoid } from "@reduxjs/toolkit";
import type { Raw, RawRtcSessionId, RaPID, Tagged, UUID } from "./uuid";
import { validateRawPersonId, validateUUID } from "./uuid";

export { extractRawRtcSessionId, extractRawPersonId, extractUUID } from "./uuid";
export type { RaPID } from "./uuid";

// We need a way to uniquely identify messages that the cloud has no knowledge
// of, in order to correctly place them in order in a channel.
// Both MessageId and UnsentMessageId boil down to strings, but MessageId will
// always be a URN with a UUID segment, while UnsentMessageId will be a nanoid.
export type MessageId = Tagged<UUID, "message">;
// export type UnsentMessageId = Tagged<RawNanoId, "unsentMsg">;
export type UnsentMessageId = string;
export type AnyMessageId = MessageId | UnsentMessageId;

export type BlobId = Tagged<UUID, "blob">;
export type BondId = Tagged<UUID, "bond">;
export type CallId = Tagged<UUID, "call">;
export type ChannelId = Tagged<UUID, "channel">;
export type NotificationId = Tagged<UUID, "notification">;
export type OrgId = Tagged<UUID, "org">;
export type PersonId = Tagged<RaPID, "person">;
export type RtcParticipantId = Tagged<UUID, "participant">;
export type RtcSessionId = Tagged<RawRtcSessionId, "session">;
export type SquadId = Tagged<UUID, "squad">;
export type UserId = Tagged<UUID, "user">;

// We need a way to uniquely identify attachments that
// the backend has no knowledge of.
// export type LocalAttachmentId = Tagged<RawNanoId, "localAttachment">;
export type LocalAttachmentId = string;
export type UploadableAvatarId = string;

export function genLocalAttachmentId(): LocalAttachmentId {
    return nanoid();
}

export type AnyAttachmentId = BlobId | LocalAttachmentId;

// `AttachmentMessageId` ties together an attachment and the message it will
// be associated with.
export type AttachmentMessageId = { id: MessageId; } | { localId: UnsentMessageId; };
export const backendMessageId = (props: AttachmentMessageId): MessageId | undefined =>
    "id" in props ? props.id : undefined;

export type DeviceTag = string;
export type ConnectionTag = string;

export type Timestamp = number;

export type PeerId = UUID; // A peer ID is the UUID from a user ID

type ExtractUrnTag<R extends Raw, Urn extends Tagged<R, string>> = Urn extends
    `urn:beyond:${infer Tag}:${R}` ? Tag :
    { error: "no urn tag found"; };

function parseUrn<
    R extends Raw,
    IdType extends Tagged<R, string>,
>(validator: (input: string) => R, tag: ExtractUrnTag<R, IdType>) {
    return (urn: string): IdType => {
        const sections = urn?.split(":") ?? [];
        if (sections.length != 4 || sections[2] !== tag) {
            throw new Error(`Invalid '${tag}' urn: ${urn}`);
        }
        return `urn:beyond:${tag}:${validator(sections[3])}` as IdType;
    };
}

export const parseBondUrn = parseUrn<UUID, BondId>(validateUUID, "bond");
export const parseMessageUrn = parseUrn<UUID, MessageId>(validateUUID, "message");
export const parseSquadUrn = parseUrn<UUID, SquadId>(validateUUID, "squad");
export const parseUserUrn = parseUrn<UUID, UserId>(validateUUID, "user");

function fromRaw<
    R extends Raw,
    IdType extends Tagged<R, string>,
>(
    validator: (input: string) => R,
    tag: ExtractUrnTag<R, IdType>,
) {
    return (rawId: string): IdType => {
        return `urn:beyond:${tag}:${validator(rawId)}` as IdType;
    };
}

export const fromRawBlobId = fromRaw<UUID, BlobId>(validateUUID, "blob");
export const fromRawBondId = fromRaw<UUID, BondId>(validateUUID, "bond");
export const fromRawCallId = fromRaw<UUID, CallId>(validateUUID, "call");
export const fromRawMessageId = fromRaw<UUID, MessageId>(validateUUID, "message");
export const fromRawPersonId = fromRaw<RaPID, PersonId>(validateRawPersonId, "person");
export const fromRawRtcParticipantId = fromRaw<UUID, RtcParticipantId>(validateUUID, "participant");
export const fromRawUserId = fromRaw<UUID, UserId>(validateUUID, "user");

/*
 * For a possible future PR:
const unsentMessageIdConverter = fromRaw<RawNanoId, LocalAttachmentId>(
    validateRawNanoId,
    "localAttachment",
);
export const generateUnsentMessageId = (size?: number) => unsentMessageIdConverter(nanoid(size));

const localAttachmentIdConverter = fromRaw<RawNanoId, LocalAttachmentId>(
    validateRawNanoId,
    "localAttachment",
);
export const generateLocalAttachmentId = (size?: number) =>
    localAttachmentIdConverter(nanoid(size));

    */
