import { BrowserStore } from "./store";
import { AnyBrowserStoreUpdate } from "./updates";

export const storageCommit = (updates: AnyBrowserStoreUpdate[]) => {
    updates.forEach(([store, value]) => storageWrite(store, value));
};

const getStorage = (store: BrowserStore) =>
    store.storeType === "local" ? localStorage : sessionStorage;

export const storageWrite = <V>(store: BrowserStore<V>, value?: V) => {
    const storage = getStorage(store);

    if (value !== undefined) {
        const s = store.raw ? value as string : JSON.stringify(value);
        storage.setItem(store.name, s);
    }
    else {
        storage.removeItem(store.name);
    }
};

export const storageRead = <V>(store: BrowserStore<V>): V | undefined => {
    const storage = getStorage(store);

    const state = storage.getItem(store.name);
    if (!state) return undefined;
    if (store.raw) return state as V;
    return JSON.parse(state) as V;
};

export const storageRemove = <V>(store: BrowserStore<V>) => {
    const storage = getStorage(store);

    storage.removeItem(store.name);
};
