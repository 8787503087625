// @generated by protoc-gen-es v1.4.2 with parameter "target=js+ts"
// @generated from file chats/chats.proto (package chats, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { AttachmentKnowledgeId, BlobId, BlobSet, CallId, CallSet, ChannelId, CrewId, FiletypeType, MessageId, MessageType, OrgId, UserId, UserSet } from "../domain/domain_pb.js";
import { BlobProperties, CompleteBlobRequest, CompleteBlobResponse, CreateBlobResponse, ExpiringSasUrl } from "../blobs/blobs_pb.js";

/**
 * @generated from message chats.ChatMessageCore
 */
export const ChatMessageCore = proto3.makeMessageType(
  "chats.ChatMessageCore",
  () => [
    { no: 1, name: "id", kind: "message", T: MessageId },
    { no: 2, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "sender_id", kind: "message", T: UserId },
    { no: 4, name: "channel_id", kind: "message", T: ChannelId },
    { no: 5, name: "client_tx_ts", kind: "message", T: Timestamp },
    { no: 6, name: "server_rx_ts", kind: "message", T: Timestamp },
    { no: 7, name: "sequence_number", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "edited_or_deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "message_type", kind: "enum", T: proto3.getEnumType(MessageType) },
    { no: 10, name: "call_id", kind: "message", T: CallId },
  ],
);

/**
 * @generated from message chats.Attachment
 */
export const Attachment = proto3.makeMessageType(
  "chats.Attachment",
  () => [
    { no: 1, name: "id", kind: "message", T: BlobId },
    { no: 2, name: "sas_url", kind: "message", T: ExpiringSasUrl },
    { no: 3, name: "properties", kind: "message", T: BlobProperties },
  ],
);

/**
 * @generated from message chats.AttachmentKnowledge
 */
export const AttachmentKnowledge = proto3.makeMessageType(
  "chats.AttachmentKnowledge",
  () => [
    { no: 1, name: "id", kind: "message", T: AttachmentKnowledgeId },
    { no: 2, name: "blob_id", kind: "message", T: BlobId },
    { no: 3, name: "short_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "long_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "alt_text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "notification_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "created_at", kind: "message", T: Timestamp },
    { no: 7, name: "filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "filetype", kind: "enum", T: proto3.getEnumType(FiletypeType) },
  ],
);

/**
 * @generated from message chats.ChatMessage
 */
export const ChatMessage = proto3.makeMessageType(
  "chats.ChatMessage",
  () => [
    { no: 1, name: "message", kind: "message", T: ChatMessageCore },
    { no: 2, name: "attachments", kind: "message", T: Attachment, repeated: true },
  ],
);

/**
 * @generated from message chats.ChannelInfo
 */
export const ChannelInfo = proto3.makeMessageType(
  "chats.ChannelInfo",
  () => [
    { no: 1, name: "channel_id", kind: "message", T: ChannelId },
    { no: 4, name: "last_activity_at", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message chats.UserLastReadPair
 */
export const UserLastReadPair = proto3.makeMessageType(
  "chats.UserLastReadPair",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 2, name: "user_max_read_seq_num", kind: "message", T: UserMaxReadSeqNum },
  ],
);

/**
 * @generated from message chats.GetMessagesRequest
 */
export const GetMessagesRequest = proto3.makeMessageType(
  "chats.GetMessagesRequest",
  () => [
    { no: 1, name: "channel_id", kind: "message", T: ChannelId },
  ],
);

/**
 * @generated from message chats.GetMessagesResponse
 */
export const GetMessagesResponse = proto3.makeMessageType(
  "chats.GetMessagesResponse",
  () => [
    { no: 1, name: "messages", kind: "message", T: ChatMessage, repeated: true },
  ],
);

/**
 * @generated from message chats.FetchMessagesRequest
 */
export const FetchMessagesRequest = proto3.makeMessageType(
  "chats.FetchMessagesRequest",
  () => [
    { no: 1, name: "channel_id", kind: "message", T: ChannelId },
    { no: 2, name: "start_sequence_number", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "limit", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message chats.FetchMessagesResponse
 */
export const FetchMessagesResponse = proto3.makeMessageType(
  "chats.FetchMessagesResponse",
  () => [
    { no: 1, name: "messages", kind: "message", T: ChatMessage, repeated: true },
  ],
);

/**
 * @generated from message chats.GetMessagesByIdsRequest
 */
export const GetMessagesByIdsRequest = proto3.makeMessageType(
  "chats.GetMessagesByIdsRequest",
  () => [
    { no: 1, name: "message_ids", kind: "message", T: MessageId, repeated: true },
  ],
);

/**
 * @generated from message chats.GetMessagesByIdsResponse
 */
export const GetMessagesByIdsResponse = proto3.makeMessageType(
  "chats.GetMessagesByIdsResponse",
  () => [
    { no: 1, name: "messages", kind: "message", T: ChatMessage, repeated: true },
  ],
);

/**
 * @generated from message chats.GetMessagesBeforeIdRequest
 */
export const GetMessagesBeforeIdRequest = proto3.makeMessageType(
  "chats.GetMessagesBeforeIdRequest",
  () => [
    { no: 1, name: "message_id", kind: "message", T: MessageId },
    { no: 2, name: "limit", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message chats.GetMessagesBeforeIdResponse
 */
export const GetMessagesBeforeIdResponse = proto3.makeMessageType(
  "chats.GetMessagesBeforeIdResponse",
  () => [
    { no: 1, name: "messages", kind: "message", T: ChatMessage, repeated: true },
  ],
);

/**
 * @generated from message chats.GetMostRecentMessagesRequest
 */
export const GetMostRecentMessagesRequest = proto3.makeMessageType(
  "chats.GetMostRecentMessagesRequest",
  () => [
    { no: 1, name: "channel_id", kind: "message", T: ChannelId },
    { no: 2, name: "limit", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message chats.GetMostRecentMessagesResponse
 */
export const GetMostRecentMessagesResponse = proto3.makeMessageType(
  "chats.GetMostRecentMessagesResponse",
  () => [
    { no: 1, name: "messages", kind: "message", T: ChatMessage, repeated: true },
  ],
);

/**
 * @generated from message chats.GetMessageRequest
 */
export const GetMessageRequest = proto3.makeMessageType(
  "chats.GetMessageRequest",
  () => [
    { no: 1, name: "message_id", kind: "message", T: MessageId },
  ],
);

/**
 * @generated from message chats.GetMessageResponse
 */
export const GetMessageResponse = proto3.makeMessageType(
  "chats.GetMessageResponse",
  () => [
    { no: 1, name: "message", kind: "message", T: ChatMessage },
  ],
);

/**
 * @generated from message chats.GetMessagesInCallRequest
 */
export const GetMessagesInCallRequest = proto3.makeMessageType(
  "chats.GetMessagesInCallRequest",
  () => [
    { no: 1, name: "call_id", kind: "message", T: CallId },
  ],
);

/**
 * @generated from message chats.GetMessagesInCallResponse
 */
export const GetMessagesInCallResponse = proto3.makeMessageType(
  "chats.GetMessagesInCallResponse",
  () => [
    { no: 1, name: "messages", kind: "message", T: ChatMessage, repeated: true },
  ],
);

/**
 * @generated from message chats.GetMessagesBeforeCallRequest
 */
export const GetMessagesBeforeCallRequest = proto3.makeMessageType(
  "chats.GetMessagesBeforeCallRequest",
  () => [
    { no: 1, name: "call_id", kind: "message", T: CallId },
    { no: 2, name: "max_number_of_messages", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message chats.GetMessagesBeforeCallResponse
 */
export const GetMessagesBeforeCallResponse = proto3.makeMessageType(
  "chats.GetMessagesBeforeCallResponse",
  () => [
    { no: 1, name: "messages", kind: "message", T: ChatMessage, repeated: true },
  ],
);

/**
 * @generated from message chats.GetCallsFromChannelRequest
 */
export const GetCallsFromChannelRequest = proto3.makeMessageType(
  "chats.GetCallsFromChannelRequest",
  () => [
    { no: 1, name: "channel_id", kind: "message", T: ChannelId },
    { no: 2, name: "first_message_sequence_number", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "last_message_sequence_number", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message chats.GetCallsFromChannelResponse
 */
export const GetCallsFromChannelResponse = proto3.makeMessageType(
  "chats.GetCallsFromChannelResponse",
  () => [
    { no: 1, name: "call_ids", kind: "message", T: CallSet },
  ],
);

/**
 * @generated from message chats.SendMessageRequest
 */
export const SendMessageRequest = proto3.makeMessageType(
  "chats.SendMessageRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 2, name: "channel_id", kind: "message", T: ChannelId },
  ],
);

/**
 * @generated from message chats.SendMessageResponse
 */
export const SendMessageResponse = proto3.makeMessageType(
  "chats.SendMessageResponse",
  () => [
    { no: 1, name: "message_id", kind: "message", T: MessageId },
  ],
);

/**
 * @generated from message chats.ChatMessageContent
 */
export const ChatMessageContent = proto3.makeMessageType(
  "chats.ChatMessageContent",
  () => [
    { no: 1, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message chats.CallMessageContent
 */
export const CallMessageContent = proto3.makeMessageType(
  "chats.CallMessageContent",
  () => [
    { no: 1, name: "call_id", kind: "message", T: CallId },
    { no: 2, name: "participant_ids", kind: "message", T: UserId, repeated: true },
  ],
);

/**
 * @generated from message chats.SendChatMessageDetails
 */
export const SendChatMessageDetails = proto3.makeMessageType(
  "chats.SendChatMessageDetails",
  () => [
    { no: 1, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "client_tx_at", kind: "message", T: Timestamp },
    { no: 3, name: "attachment_ids", kind: "message", T: BlobId, repeated: true },
  ],
);

/**
 * @generated from message chats.SendCallStartMessageDetails
 */
export const SendCallStartMessageDetails = proto3.makeMessageType(
  "chats.SendCallStartMessageDetails",
  () => [
    { no: 1, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "started_at", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message chats.SendCallEndMessageDetails
 */
export const SendCallEndMessageDetails = proto3.makeMessageType(
  "chats.SendCallEndMessageDetails",
  () => [
    { no: 1, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ended_at", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message chats.SendChatMessageAnalytics
 */
export const SendChatMessageAnalytics = proto3.makeMessageType(
  "chats.SendChatMessageAnalytics",
  () => [
    { no: 1, name: "did_create_new_bond", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "new_bond_is_private", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message chats.SendChatMessageRequest
 */
export const SendChatMessageRequest = proto3.makeMessageType(
  "chats.SendChatMessageRequest",
  () => [
    { no: 1, name: "request", kind: "message", T: SendMessageRequest },
    { no: 2, name: "details", kind: "message", T: SendChatMessageDetails },
    { no: 3, name: "analytics", kind: "message", T: SendChatMessageAnalytics },
  ],
);

/**
 * @generated from message chats.SendChatMessageResponse
 */
export const SendChatMessageResponse = proto3.makeMessageType(
  "chats.SendChatMessageResponse",
  () => [
    { no: 1, name: "response", kind: "message", T: SendMessageResponse },
  ],
);

/**
 * @generated from message chats.SendCallMessageRequest
 */
export const SendCallMessageRequest = proto3.makeMessageType(
  "chats.SendCallMessageRequest",
  () => [
    { no: 1, name: "request", kind: "message", T: SendMessageRequest },
    { no: 3, name: "call_start", kind: "message", T: SendCallStartMessageDetails, oneof: "details" },
    { no: 4, name: "call_end", kind: "message", T: SendCallEndMessageDetails, oneof: "details" },
  ],
);

/**
 * @generated from message chats.SendCallMessageResponse
 */
export const SendCallMessageResponse = proto3.makeMessageType(
  "chats.SendCallMessageResponse",
  () => [
    { no: 1, name: "response", kind: "message", T: SendMessageResponse },
  ],
);

/**
 * @generated from message chats.SubMessageChannelRequest
 */
export const SubMessageChannelRequest = proto3.makeMessageType(
  "chats.SubMessageChannelRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 2, name: "channel_id", kind: "message", T: ChannelId },
    { no: 3, name: "start_sequence_number", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message chats.SubMessageChannelResponse
 */
export const SubMessageChannelResponse = proto3.makeMessageType(
  "chats.SubMessageChannelResponse",
  () => [
    { no: 1, name: "message", kind: "message", T: ChatMessage, oneof: "message_or_deleted" },
    { no: 2, name: "deleted_id", kind: "message", T: MessageId, oneof: "message_or_deleted" },
  ],
);

/**
 * @generated from message chats.CreateChannelRequest
 */
export const CreateChannelRequest = proto3.makeMessageType(
  "chats.CreateChannelRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 4, name: "crew_id", kind: "message", T: CrewId },
  ],
);

/**
 * @generated from message chats.CreateChannelResponse
 */
export const CreateChannelResponse = proto3.makeMessageType(
  "chats.CreateChannelResponse",
  () => [
    { no: 1, name: "new_channel_id", kind: "message", T: ChannelId },
  ],
);

/**
 * @generated from message chats.AddUsersToChannelRequest
 */
export const AddUsersToChannelRequest = proto3.makeMessageType(
  "chats.AddUsersToChannelRequest",
  () => [
    { no: 1, name: "channel_id", kind: "message", T: ChannelId },
    { no: 2, name: "users_to_add", kind: "message", T: UserSet },
  ],
);

/**
 * @generated from message chats.AddUsersToChannelResponse
 */
export const AddUsersToChannelResponse = proto3.makeMessageType(
  "chats.AddUsersToChannelResponse",
  [],
);

/**
 * @generated from message chats.RemoveUserFromChannelRequest
 */
export const RemoveUserFromChannelRequest = proto3.makeMessageType(
  "chats.RemoveUserFromChannelRequest",
  () => [
    { no: 1, name: "channel_id", kind: "message", T: ChannelId },
    { no: 2, name: "user_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message chats.RemoveUserFromChannelResponse
 */
export const RemoveUserFromChannelResponse = proto3.makeMessageType(
  "chats.RemoveUserFromChannelResponse",
  [],
);

/**
 * @generated from message chats.SetUserReadSequenceNumberRequest
 */
export const SetUserReadSequenceNumberRequest = proto3.makeMessageType(
  "chats.SetUserReadSequenceNumberRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 2, name: "channel_id", kind: "message", T: ChannelId },
    { no: 3, name: "sequence_number", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message chats.SetUserReadSequenceNumberResponse
 */
export const SetUserReadSequenceNumberResponse = proto3.makeMessageType(
  "chats.SetUserReadSequenceNumberResponse",
  [],
);

/**
 * @generated from message chats.ReadChannelSequenceNumbersRequest
 */
export const ReadChannelSequenceNumbersRequest = proto3.makeMessageType(
  "chats.ReadChannelSequenceNumbersRequest",
  () => [
    { no: 1, name: "channel_id", kind: "message", T: ChannelId },
    { no: 2, name: "user_ids", kind: "message", T: UserSet },
  ],
);

/**
 * @generated from message chats.ReadChannelSequenceNumbersResponse
 */
export const ReadChannelSequenceNumbersResponse = proto3.makeMessageType(
  "chats.ReadChannelSequenceNumbersResponse",
  () => [
    { no: 1, name: "user_max_read_seq_nums", kind: "message", T: UserLastReadPair, repeated: true },
  ],
);

/**
 * @generated from message chats.GetChannelsInfoRequest
 */
export const GetChannelsInfoRequest = proto3.makeMessageType(
  "chats.GetChannelsInfoRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message chats.GetChannelsInfoResponse
 */
export const GetChannelsInfoResponse = proto3.makeMessageType(
  "chats.GetChannelsInfoResponse",
  () => [
    { no: 1, name: "channels_info", kind: "message", T: ChannelInfo, repeated: true },
  ],
);

/**
 * @generated from message chats.SubUserMaxReadSeqNumsRequest
 */
export const SubUserMaxReadSeqNumsRequest = proto3.makeMessageType(
  "chats.SubUserMaxReadSeqNumsRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 2, name: "channel_id", kind: "message", T: ChannelId },
    { no: 3, name: "add_to_sub", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message chats.SubUserMaxReadSeqNumsResponse
 */
export const SubUserMaxReadSeqNumsResponse = proto3.makeMessageType(
  "chats.SubUserMaxReadSeqNumsResponse",
  () => [
    { no: 1, name: "sequence_number", kind: "message", T: UserMaxReadSeqNum, oneof: "sequence_number_or_deleted" },
    { no: 2, name: "deleted_id", kind: "message", T: ChannelId, oneof: "sequence_number_or_deleted" },
  ],
);

/**
 * @generated from message chats.UserMaxReadSeqNum
 */
export const UserMaxReadSeqNum = proto3.makeMessageType(
  "chats.UserMaxReadSeqNum",
  () => [
    { no: 1, name: "channel_id", kind: "message", T: ChannelId },
    { no: 2, name: "max_sequence_number", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message chats.CreateAttachmentRequest
 */
export const CreateAttachmentRequest = proto3.makeMessageType(
  "chats.CreateAttachmentRequest",
  () => [
    { no: 1, name: "properties", kind: "message", T: BlobProperties },
    { no: 2, name: "uploader_id", kind: "message", T: UserId },
    { no: 3, name: "org_id", kind: "message", T: OrgId },
  ],
);

/**
 * @generated from message chats.CreateAttachmentResponse
 */
export const CreateAttachmentResponse = proto3.makeMessageType(
  "chats.CreateAttachmentResponse",
  () => [
    { no: 1, name: "response", kind: "message", T: CreateBlobResponse },
  ],
);

/**
 * @generated from message chats.CompleteAttachmentRequest
 */
export const CompleteAttachmentRequest = proto3.makeMessageType(
  "chats.CompleteAttachmentRequest",
  () => [
    { no: 1, name: "request", kind: "message", T: CompleteBlobRequest },
  ],
);

/**
 * @generated from message chats.CompleteAttachmentResponse
 */
export const CompleteAttachmentResponse = proto3.makeMessageType(
  "chats.CompleteAttachmentResponse",
  () => [
    { no: 1, name: "response", kind: "message", T: CompleteBlobResponse },
  ],
);

/**
 * @generated from message chats.CleanStaleAttachmentsRequest
 */
export const CleanStaleAttachmentsRequest = proto3.makeMessageType(
  "chats.CleanStaleAttachmentsRequest",
  [],
);

/**
 * @generated from message chats.CleanStaleAttachmentsResponse
 */
export const CleanStaleAttachmentsResponse = proto3.makeMessageType(
  "chats.CleanStaleAttachmentsResponse",
  [],
);

/**
 * @generated from message chats.UpdateAttachmentKnowledgeRequest
 */
export const UpdateAttachmentKnowledgeRequest = proto3.makeMessageType(
  "chats.UpdateAttachmentKnowledgeRequest",
  () => [
    { no: 1, name: "blob_id", kind: "message", T: BlobId },
    { no: 2, name: "short_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "long_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "filetype", kind: "enum", T: proto3.getEnumType(FiletypeType) },
  ],
);

/**
 * @generated from message chats.UpdateAttachmentKnowledgeResponse
 */
export const UpdateAttachmentKnowledgeResponse = proto3.makeMessageType(
  "chats.UpdateAttachmentKnowledgeResponse",
  [],
);

/**
 * @generated from message chats.SetAttachmentNotificationMessageRequest
 */
export const SetAttachmentNotificationMessageRequest = proto3.makeMessageType(
  "chats.SetAttachmentNotificationMessageRequest",
  () => [
    { no: 1, name: "blob_id", kind: "message", T: BlobId },
    { no: 2, name: "notification_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "filetype", kind: "enum", T: proto3.getEnumType(FiletypeType) },
  ],
);

/**
 * @generated from message chats.SetAttachmentNotificationMessageResponse
 */
export const SetAttachmentNotificationMessageResponse = proto3.makeMessageType(
  "chats.SetAttachmentNotificationMessageResponse",
  [],
);

/**
 * @generated from message chats.SetAttachmentAltTextRequest
 */
export const SetAttachmentAltTextRequest = proto3.makeMessageType(
  "chats.SetAttachmentAltTextRequest",
  () => [
    { no: 1, name: "blob_id", kind: "message", T: BlobId },
    { no: 2, name: "alt_text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "filetype", kind: "enum", T: proto3.getEnumType(FiletypeType) },
  ],
);

/**
 * @generated from message chats.SetAttachmentAltTextResponse
 */
export const SetAttachmentAltTextResponse = proto3.makeMessageType(
  "chats.SetAttachmentAltTextResponse",
  [],
);

/**
 * @generated from message chats.GetAttachmentKnowledgesRequest
 */
export const GetAttachmentKnowledgesRequest = proto3.makeMessageType(
  "chats.GetAttachmentKnowledgesRequest",
  () => [
    { no: 1, name: "blob_set", kind: "message", T: BlobSet },
  ],
);

/**
 * @generated from message chats.GetAttachmentKnowledgesResponse
 */
export const GetAttachmentKnowledgesResponse = proto3.makeMessageType(
  "chats.GetAttachmentKnowledgesResponse",
  () => [
    { no: 1, name: "knowledges", kind: "message", T: AttachmentKnowledge, repeated: true },
  ],
);

/**
 * @generated from message chats.GetAttachmentBlobUrlRequest
 */
export const GetAttachmentBlobUrlRequest = proto3.makeMessageType(
  "chats.GetAttachmentBlobUrlRequest",
  () => [
    { no: 1, name: "attachment_id", kind: "message", T: BlobId },
    { no: 2, name: "message_id", kind: "message", T: MessageId },
  ],
);

/**
 * @generated from message chats.GetAttachmentBlobUrlResponse
 */
export const GetAttachmentBlobUrlResponse = proto3.makeMessageType(
  "chats.GetAttachmentBlobUrlResponse",
  () => [
    { no: 1, name: "attachment_id", kind: "message", T: BlobId },
    { no: 2, name: "sas_url", kind: "message", T: ExpiringSasUrl },
  ],
);

