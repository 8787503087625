import BondStreamsManager from "./BondStreamsManager";
import CallStreamManager from "./CallStreamManager";
import UserStreamManager from "./UserStreamManager";
import SquadStreamManager from "./SquadStreamManager";

function StreamManagers(): React.JSX.Element {
    return (
        <>
            <BondStreamsManager />
            <CallStreamManager />
            <SquadStreamManager />
            <UserStreamManager />
        </>
    );
}

export default StreamManagers;
