import { MessageType, OfficialMessageAndAttachments } from "../../api/chats";
import { PerChannelData } from "../../ds/ChannelMap";
import { PersistedBondCreationState } from "../../features/bondCreation";
import { Optional, TypedEntries } from "../../misc/types";
import { OfficialAttachment } from "../attachments";
import { bondCreationDraftTarget, DraftTarget, newChannelDraftTarget } from "../channels";
import {
    AnyOfficialMessage,
    AnyUnsentLocalMessage,
    CallEndedMessage,
    CallStartMessage,
    DraftChatMessage,
    genLocalMessageId,
    LocalMessageType,
    OfficialChatMessage,
    OfficialMessageType,
} from "../chats";
import * as d from "../domain";
import { AvatarBlob } from "../users";
import * as v13Types from "./v13Types";

const translateDCMC = (
    draft: v13Types.DraftChatMessageContent,
    draftTarget: DraftTarget,
    draftId?: d.UnsentMessageId,
): DraftChatMessage => {
    draftId ??= draft.coreContent.id ?? genLocalMessageId();
    return {
        type: LocalMessageType.Draft,
        localId: draftId,
        draftTarget,
        // We have to just forget about attachments in this migration.
        attachmentIds: [],
        prefix: draft.prefix,
        content: {
            draftId,
            draftMessage: draft.coreContent.message || "",
            trimmedMessage: draft.trimmedMessage,
            mentions: draft.coreContent.mentions,
            caretHint: {
                id: draft.caretHintId,
                location: draft.caretHint,
            },
        },
    };
};

type TranslateDraftChatMessageContentArg = [
    channelId: d.ChannelId,
    oldDraft: Optional<v13Types.DraftChatMessageContent>,
];
export const translateDraftChatMessageContent = (
    [channelId, oldDraft]: TranslateDraftChatMessageContentArg,
): Optional<[d.ChannelId, DraftChatMessage]> => {
    if (!oldDraft) return;
    return [channelId, translateDCMC(oldDraft, newChannelDraftTarget(channelId))];
};

const translateCallStartMessage = (
    oldMsg: v13Types.SentMessage & { messageType: MessageType.CALL_START; },
): CallStartMessage => {
    const { id, channelId, serverRxTs, sequenceNumber, callId } = oldMsg;
    return {
        type: OfficialMessageType.CallStart,
        id,
        channelId,
        serverRxTs,
        sequenceNumber,
        callId: callId ?? oldMsg.content.callId,
    };
};

const translateCallEndedMessage = (
    oldMsg: v13Types.SentMessage & { messageType: MessageType.CALL_END; },
): CallEndedMessage => {
    const { id, channelId, serverRxTs, sequenceNumber, callId } = oldMsg;
    return {
        type: OfficialMessageType.CallEnd,
        id,
        channelId,
        serverRxTs,
        sequenceNumber,
        callId: callId ?? oldMsg.content.callId,
        participantIds: oldMsg.content.participantIds || [],
    };
};

const translateAttachment = (attachment: v13Types.Attachment): OfficialAttachment => {
    const { id, fileName, fileSize, mimeType, dimensions, initialSasUrl } = attachment;
    return {
        id,
        metadata: {
            fileName,
            fileSize,
            mimeType,
            dimensions,
        },
        credentials: {
            ...initialSasUrl,
            type: "download",
            blobId: id,
        },
    };
};

const translateChatMessage = (
    oldMsg: v13Types.SentChatMessage,
): [OfficialChatMessage, OfficialAttachment[]] => {
    const {
        id,
        channelId,
        clientTxTs,
        serverRxTs,
        sequenceNumber,
        senderId,
        callId,
        attachments,
        content: { coreContent: content },
    } = oldMsg;
    return [{
        id,
        channelId,
        serverRxTs,
        sequenceNumber,
        type: OfficialMessageType.Chat,
        senderId,
        clientTxTs,
        callId,
        content,
        attachmentIds: attachments.map(a => a.id),
    }, attachments.map(translateAttachment)];
};

type TranslateSentMessageArg = [
    msgId: d.MessageId,
    oldMsg: Optional<v13Types.SentMessage>,
];
export const translateSentMessage = (
    [_id, oldMsg]: TranslateSentMessageArg,
): Optional<OfficialMessageAndAttachments> => {
    if (v13Types.isCallStartMsg(oldMsg)) return [translateCallStartMessage(oldMsg), []];
    if (v13Types.isCallEndedMsg(oldMsg)) return [translateCallEndedMessage(oldMsg), []];
    if (v13Types.isChatMsg(oldMsg)) return translateChatMessage(oldMsg);
};

type TranslateUnsentMessageArg = [
    id: d.UnsentMessageId,
    oldMsg: Optional<v13Types.UnsentChatMessage>,
];
export const translateUnsentMessage = (
    [_id, oldMsg]: TranslateUnsentMessageArg,
): Optional<[AnyUnsentLocalMessage, OfficialAttachment[]]> => {
    if (!oldMsg) return;

    const {
        localId,
        channelId,
        confirmedId,
        clientTxTs,
        content: { coreContent: content, prefix },
        attachments,
    } = oldMsg;

    return [{
        type: LocalMessageType.Unsent,
        localId,
        draftTarget: newChannelDraftTarget(channelId),
        attachmentIds: attachments.map(a => a.id),
        prefix,
        messageId: confirmedId,
        content,
        clientTxTs,
    }, attachments.map(translateAttachment)];
};

type TranslatePerChannelDataArg = [
    id: d.ChannelId,
    oldPCD: Optional<v13Types.PerChannelData>,
];
export const translatePerChannelData = (
    [id, oldPCD]: TranslatePerChannelDataArg,
): Optional<[d.ChannelId, PerChannelData]> => {
    if (!oldPCD) return;

    const unreadMessages: [number, AnyOfficialMessage][] = TypedEntries(
        oldPCD?.unreadMessagesIndex ?? {},
    )
        .flatMap(([index, msg]) => {
            const newMsg = translateSentMessage([msg.id, msg]);
            if (!newMsg) return [];
            return [[
                index,
                newMsg[0],
            ]];
        });
    const unreadMessagesIndex = Object.fromEntries(unreadMessages);
    return [
        id,
        { ...oldPCD, unreadMessagesIndex },
    ];
};

export const translatePersistedBondCreationState = (
    { draft, draftPassesBack, queryParameters }: v13Types.PersistedBondCreationState,
): PersistedBondCreationState => {
    return {
        draft: translateDCMC(draft.content, bondCreationDraftTarget, draft.localId),
        draftPassesBack,
        queryParameters,
    };
};

type TranslateAvatarBlobArg = [
    userId: d.UserId,
    oldAvatarBlob: Optional<v13Types.AvatarBlob>,
];
export const translateAvatarBlob = (
    [userId, oldAvatarBlob]: TranslateAvatarBlobArg,
): Optional<[d.UserId, AvatarBlob]> => {
    if (!oldAvatarBlob) return;

    return [userId, {
        blobId: oldAvatarBlob.blobId,
        metadata: oldAvatarBlob.properties,
        credentials: {
            blobId: oldAvatarBlob.blobId,
            type: "download",
            ...oldAvatarBlob.sasUrl,
        },
    }];
};

export type {
    DraftChatMessageContent,
    PerChannelData,
    PersistedBondCreationState,
    SentMessage,
    UnsentChatMessage,
    AvatarBlob,
} from "./v13Types";
