import { selectFirstUnsentMessages } from "../features/channels";
import { sendMessageThunk } from "../features/chats";
import { useAppDispatch, useAppSelector } from "../store/redux";
import { useConnectedEffect } from "../hooks/useConnectedEffect";
import useTimerTarget from "../hooks/useTimerTarget";
import useQueueProcessor from "../hooks/useQueueProcessor";

// Goals:
// - send all messages staged in all channels, as determined by the redux store
// - send order must be respected for each channel
// - temporary failures are retried after some interval
// - permanent failures stop further messages being sent from their respective channel
// - do not spam the server

const MessageSender = () => {
    const dispatch = useAppDispatch();

    const msgs = useAppSelector(selectFirstUnsentMessages);

    const { nowMs, setTarget: setTimerTarget } = useTimerTarget();

    const { target: targetMsg, succeeded, failed } = useQueueProcessor(msgs);

    useConnectedEffect(() => {
        if (!targetMsg) return;

        const nextAttempt = targetMsg.backoffState?.nextAttempt ?? nowMs;
        if (nowMs < nextAttempt) {
            setTimerTarget(nextAttempt);
        }
        else {
            // backoff state is externalised into the message itself, hence we
            // always say the request succeeded.
            dispatch(sendMessageThunk(targetMsg)).unwrap().then(_ => succeeded(), _ => failed());
        }
    }, [targetMsg, dispatch, setTimerTarget, nowMs, succeeded, failed]);

    return <></>;
};

export default MessageSender;
