import { separateDiscriminatedUnion } from "../misc/utils";
import * as d from "./domain";

type BaseMention = {
    notify: boolean;
};

export type SquadMention = BaseMention & {
    target: d.SquadId;
};

export type UserMention = BaseMention & {
    target: d.UserId;
};

export type Mention =
    | { case: "squad"; } & SquadMention
    | { case: "user"; } & UserMention;

export type ContentMention = Mention & {
    startOffset: number;
    endOffset: number;
};

export const isSquadMention = (mention: Mention): mention is { case: "squad"; } & SquadMention =>
    mention.case == "squad";

export const isUserMention = (mention: Mention): mention is { case: "user"; } & UserMention =>
    mention.case == "user";

export function extractSquadIdsFromMentions(mentions: Mention[]): d.SquadId[] {
    return mentions.filter(isSquadMention).map(m => m.target);
}

export function newSquadMention(squadId: d.SquadId): Mention {
    return {
        case: "squad",
        target: squadId,
        notify: false,
    };
}

export function newUserMention(userId: d.UserId): Mention {
    return {
        case: "user",
        target: userId,
        notify: true,
    };
}

export function filterMentions(ms: Mention[]): { userIds: d.UserId[]; squadIds: d.SquadId[]; } {
    const [users, squads] = separateDiscriminatedUnion<
        Mention & UserMention,
        Mention & SquadMention
    >(isUserMention, ms);

    return {
        userIds: users.map(m => m.target),
        squadIds: squads.map(m => m.target),
    };
}
