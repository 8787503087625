import { useContext, useCallback } from "react";

import { DraftTarget } from "../domain/channels";
import { selectCurrentOrgId, selectCurrentUserId } from "../features/auth";
import {
    addAttachmentsToDraft,
    clearAttachmentsFromDraft,
    selectDraft,
} from "../features/channels";
import { useAppDispatch, useAppSelector } from "../store/redux";
import log from "../misc/log";
import { FileStashContext } from "./managers/AttachmentManager";
import useSelectorArgs from "../hooks/useSelectorArgs";
import { createProposedAttachment, ProposedAttachment } from "../domain/attachments";
import { fileToBlobMetadata } from "../domain/blobs";
import { getAttachmentDimensionsNoThrow } from "../misc/attachments";
import { genLocalAttachmentId } from "../domain/domain";

interface AttachmentUploadControlsProps {
    draftTarget: DraftTarget;
}

// AttachmentUploadControls is responsible for the UI and all of the interactions with files,
// the fileStash, and the Redux store regarding uploading an attachment to a draft.
//
// It is tested via its only use: MessageComposer.
export default function AttachmentUploadControls(
    props: AttachmentUploadControlsProps,
): React.JSX.Element {
    const { draftTarget } = props;

    const dispatch = useAppDispatch();

    const draft = useSelectorArgs(selectDraft, draftTarget);
    const totalDraftAttachmentsLength = draft?.attachmentIds.length ?? 0;

    const fileStash = useContext(FileStashContext);

    const userId = useAppSelector(selectCurrentUserId);
    const orgId = useAppSelector(selectCurrentOrgId);

    const addAttachment = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
        log.info("Adding attachment(s)");

        if (!e.target.files || e.target.files.length == 0) {
            e.target.value = "";
            return;
        }

        if (!userId || !orgId) {
            const missing = [];
            userId || missing.push("user id");
            orgId || missing.push("org id");
            log.error(`Failed to create attachment: missing ${missing.join(" and ")}`);
            return;
        }

        const newAttachments: ProposedAttachment[] = [];

        const ps = Array.from(e.target.files).map(async file => {
            const localId = genLocalAttachmentId();
            fileStash.set(localId, file);
            const initiatedAt = Date.now();

            const dimensions = await getAttachmentDimensionsNoThrow(file);

            newAttachments.push(
                createProposedAttachment({
                    localId,
                    draftTarget,
                    initiatedAt,
                    metadata: { ...fileToBlobMetadata(file), dimensions },
                    ownership: { uploaderId: userId, orgId },
                }),
            );
        });

        await Promise.allSettled(ps);

        dispatch(addAttachmentsToDraft(newAttachments));
    }, [dispatch, draftTarget, fileStash, orgId, userId]);

    const clearAttachments = useCallback(() => {
        dispatch(clearAttachmentsFromDraft(draftTarget));
    }, [dispatch, draftTarget]);

    return (
        <>
            <input
                type="file"
                id="fileUpload"
                multiple={true}
                onChange={addAttachment}
                className="cp-btn cp-btn-composer cp-btn-composer--add"
                title="Attach files"
            />
            {totalDraftAttachmentsLength > 0 && (
                <button
                    onClick={clearAttachments}
                    className="c-btn c-btn--clear-file"
                    title={`Clear attachment${totalDraftAttachmentsLength > 1 ? `s` : ``}`}
                >
                    {`Clear ${totalDraftAttachmentsLength} attachment${
                        totalDraftAttachmentsLength > 1 ? `s` : ``
                    }`}
                </button>
            )}
        </>
    );
}
