import { createPromiseClient, ConnectError } from "@connectrpc/connect";

import { ChatService } from "../../gen/proto/chats/chats_connect";
import * as chats_pb from "../../gen/proto/chats/chats_pb";
import * as bonds_pb from "../../gen/proto/bonds/bonds_pb";

import {
    fromProtoTimestamp,
    pbBondId,
    pbUserId,
    pbChannelId,
    pbTimestamp,
    bigintToNumber,
    pbMessageId,
    fromProtoMessageId,
    fromProtoChannelId,
    fromProtoUserId,
    fromProtoBlobId,
    fromProtoCallId,
    pbOrgId,
    pbBlobId,
    pbMentionSet,
} from "./util";
import {
    UnsentChatMessage,
    CallMessageContentBlob,
    AnyOfficialMessage,
    OfficialMessage,
    OfficialChatMessage,
    CallStartMessage,
    CallEndedMessage,
    SanitisedChatContent,
    toErroredMsg,
    AnyUnsentLocalMessage,
    OfficialMessageType,
} from "../domain/chats";
import { ExpiringSasUrl, BlobCredentials } from "../domain/blobs";
import { translateBlobProperties, translateSasUrl } from "../api/blobs";
import { transport } from "./transport";
import * as d from "../domain/domain";
import bondService from "./bonds";
import { streamHandler } from "./stream";
import log from "../misc/log";
import { MessageType } from "./chats";
import { CredentialedAttachment, OfficialAttachment } from "../domain/attachments";
import { defaultBackoffUpdater } from "../domain/backoff";

export const service = createPromiseClient(ChatService, transport);
export default service;

function getAttachmentId(attachment: chats_pb.Attachment): d.BlobId {
    return fromProtoBlobId(attachment.id);
}

function translateAttachment(attachment: chats_pb.Attachment): OfficialAttachment {
    const props = attachment.properties;

    if (!props) {
        throw new Error(`attachment properties are undefined`);
    }

    const id = getAttachmentId(attachment);
    const url = translateSasUrl(attachment.sasUrl);

    return {
        id,
        metadata: translateBlobProperties(props),
        credentials: {
            ...url,
            type: "download",
            blobId: id,
        },
    };
}

// Re-export proto types for use in the rest of the frontend
export { MessageType } from "../../gen/proto/domain/domain_pb";

type MessageWithAttachments<T> = [T, OfficialAttachment[]];
type MessagesBundle<T> = { msgs: T[]; attachments?: OfficialAttachment[]; };

export const flattenMsgAndAttachments = <T, S extends string>(
    input: MessageWithAttachments<T>[],
    f: (t: T) => S,
): MessagesBundle<T> => ({
    msgs: input.map(([msg, _]) => msg)
        .filterLatest(f),
    attachments: input.flatMap(([_, attachments]) => attachments)
        .filterLatest(a => a.id),
});

export type OfficialMessageAndAttachments = MessageWithAttachments<AnyOfficialMessage>;
export type OfficialMessagesBundle = MessagesBundle<AnyOfficialMessage> & {
    channelId: d.ChannelId;
};

export const flattenOfficialMessageAndAttachments = (
    data: OfficialMessageAndAttachments[],
    channelId: d.ChannelId,
): OfficialMessagesBundle => ({
    channelId,
    ...flattenMsgAndAttachments(data, msg => msg.id),
});

function translateOfficialMessage(
    msg: chats_pb.ChatMessage,
): OfficialMessageAndAttachments {
    const message = msg.message;

    if (!message) {
        throw new Error(`message innards are undefined`);
    }

    const messageType = message.messageType;

    const partialMsg: OfficialMessage = {
        id: fromProtoMessageId(message.id),
        channelId: fromProtoChannelId(message.channelId),
        serverRxTs: fromProtoTimestamp(message.serverRxTs),
        sequenceNumber: bigintToNumber(message.sequenceNumber ?? BigInt(0)),
        // Give a valid value to appease tsc, overwrite later.
        type: OfficialMessageType.Chat,
    };

    switch (messageType) {
        case MessageType.CHAT: {
            const ocm: OfficialChatMessage = {
                ...partialMsg,
                type: OfficialMessageType.Chat,
                senderId: fromProtoUserId(message.senderId),
                clientTxTs: fromProtoTimestamp(message.clientTxTs),
                content: parseChatMessageContent(message.content),
                callId: message.callId && fromProtoCallId(message.callId),
                attachmentIds: msg.attachments.map(getAttachmentId),
            };
            const attachments = msg.attachments.map(translateAttachment);
            return [ocm, attachments];
        }
        case MessageType.CALL_START: {
            const { callId } = parseCallMessageContent(message.content);
            const csm: CallStartMessage = {
                ...partialMsg,
                type: OfficialMessageType.CallStart,
                callId,
            };
            return [csm, []];
        }
        case MessageType.CALL_END: {
            const { callId, participantIds } = parseCallMessageContent(message.content);
            const cem: CallEndedMessage = {
                ...partialMsg,
                type: OfficialMessageType.CallEnd,
                callId,
                participantIds,
            };
            return [cem, []];
        }
        case MessageType.UNSPECIFIED:
            throw new Error(`message type is unspecified`);
    }
}

function parseChatMessageContent(json: string): SanitisedChatContent {
    const content = JSON.parse(json);
    // TODO: add validation
    return content;
}

function parseCallMessageContent(json: string): CallMessageContentBlob {
    const raw = JSON.parse(json);
    if (!("callId" in raw)) {
        throw new Error(`callId not found in message content`);
    }

    const callId = d.fromRawCallId(raw.callId);
    const participantIds = raw.participantIds?.map(d.fromRawUserId) ?? [];
    return {
        callId,
        participantIds,
    };
}

export async function fetchMessages(
    channelId: d.ChannelId,
    from?: number,
): Promise<OfficialMessageAndAttachments[]> {
    const startSequenceNumber = from ? BigInt(from) : undefined;
    const req = new chats_pb.FetchMessagesRequest({
        channelId: pbChannelId(channelId),
        startSequenceNumber,
        limit: 64,
    });
    const resp = await service.fetchMessages(req);
    return resp.messages.map(translateOfficialMessage);
}

interface SendMessageViaBondArgs {
    msg: UnsentChatMessage;
    bondId: d.BondId;
    channelId: d.ChannelId;
    senderId: d.UserId;
    officialAttachmentIds?: d.BlobId[];
}

export async function sendMessageViaBond(
    { msg, bondId, channelId, senderId, officialAttachmentIds }: SendMessageViaBondArgs,
): Promise<[d.ChannelId, AnyUnsentLocalMessage]> {
    const { content, clientTxTs } = msg;
    const req = new bonds_pb.SendChatMessageViaBondRequest(
        {
            bondId: pbBondId(bondId),
            request: {
                request: {
                    channelId: pbChannelId(channelId),
                    userId: pbUserId(senderId),
                },
                details: new chats_pb.SendChatMessageDetails({
                    content: JSON.stringify(content),
                    clientTxAt: pbTimestamp(clientTxTs),
                    attachmentIds: officialAttachmentIds?.map(pbBlobId),
                }),
            },
            mentions: pbMentionSet(msg.content.mentions ?? []),
        },
    );

    try {
        const resp = await bondService.sendChatMessageViaBond(req);
        if (resp?.response?.response?.messageId) {
            return [channelId, {
                ...msg,
                messageId: fromProtoMessageId(resp.response.response.messageId),
                backoffState: undefined,
            }];
        }
        else {
            // This is an error, really. All successful RPC responses should contain the message id.
            // Sending again should return the id.
            // TODO make sure we deal with all variations of timing when updating the store here.
            log.info(`Sent message ${msg.localId} but no id on response`);
        }
    }
    catch (e) {
        log.error(`Error sending message ${msg.localId}: ${e}, ${JSON.stringify(e)}`);
        // TODO Which errors are permanent and should not be retried?
        // For now, any RPC failure can be retried, anything else stops any messages being sent.
        if (!(e instanceof ConnectError)) {
            return [channelId, toErroredMsg(msg)];
        }
        // else fall through to normal backoff case.
    }

    return [channelId, {
        ...msg,
        backoffState: defaultBackoffUpdater(msg.backoffState?.attempts ?? 0),
    }];
}

function messageParser(res: chats_pb.SubMessageChannelResponse) {
    switch (res.messageOrDeleted?.case) {
        case "message":
            return translateOfficialMessage(res.messageOrDeleted.value);
        case "deletedId":
            throw new Error(`TODO handle message deletedId`);
        default:
            throw new Error(`undefined messageOrDeleted`);
    }
}

interface StreamMessagesArgs {
    userId: d.UserId;
    channelId: d.ChannelId;
    startSequenceNumber: number;
}
export async function* streamMessages(args: StreamMessagesArgs, signal: AbortSignal) {
    const startSequenceNumber = args.startSequenceNumber ? BigInt(args.startSequenceNumber)
        : undefined;

    const req = new chats_pb.SubMessageChannelRequest({
        userId: pbUserId(args.userId),
        channelId: pbChannelId(args.channelId),
        startSequenceNumber,
    });

    const logPrefix = `streamMessages ${args.channelId}`;

    const resp = service.subMessageChannel(req, { signal });

    yield* streamHandler(resp, messageParser, logPrefix);
}

export async function createAttachment(
    args: Pick<CredentialedAttachment, "metadata" | "ownership">,
): Promise<BlobCredentials> {
    const req = new chats_pb.CreateAttachmentRequest({
        properties: args.metadata,
        uploaderId: pbUserId(args.ownership.uploaderId),
        orgId: pbOrgId(args.ownership.orgId),
    });

    const resp = await service.createAttachment(req);

    if (!resp.response) {
        throw new Error("Received no response in response");
    }
    if (!resp.response.blobId) {
        throw new Error("Received no blobId in response");
    }
    if (!resp.response.uploadSasUrl) {
        throw new Error("Received no sasUrl in response");
    }
    if (resp.response.uploadSasUrl.url == "") {
        throw new Error("Received no url in response");
    }

    return {
        type: "upload",
        blobId: fromProtoBlobId(resp.response.blobId),
        url: resp.response.uploadSasUrl.url,
        expiresAt: fromProtoTimestamp(resp.response.uploadSasUrl.urlExpiry),
    };
}

export interface CompleteAttachmentArgs {
    blobId: d.BlobId;
    completerId: d.UserId;
}
export async function completeAttachment(args: CompleteAttachmentArgs) {
    const req = new chats_pb.CompleteAttachmentRequest({
        request: {
            blobId: pbBlobId(args.blobId),
            completerId: pbUserId(args.completerId),
        },
    });

    await service.completeAttachment(req);
}

export interface GetAttachmentBlobUrlArgs {
    blobId: d.BlobId;
    messageId: d.MessageId;
}
export async function getAttachmentBlobUrl(
    args: GetAttachmentBlobUrlArgs,
): Promise<ExpiringSasUrl> {
    const req = new chats_pb.GetAttachmentBlobUrlRequest({
        attachmentId: pbBlobId(args.blobId),
        messageId: pbMessageId(args.messageId),
    });

    const resp = await service.getAttachmentBlobUrl(req);

    return translateSasUrl(resp.sasUrl);
}
