import { createPromiseClient } from "@connectrpc/connect";

import * as domain_pb from "../../gen/proto/domain/domain_pb";
import { NotificationService } from "../../gen/proto/notifications/notifications_connect";
import * as notifications_pb from "../../gen/proto/notifications/notifications_pb";

import * as d from "../domain/domain";
import {
    NotificationPriority,
    WebNotification,
    WebNotificationDetails,
    WebNotificationType,
} from "../domain/notifications";
import { streamHandler } from "./stream";
import transport from "./transport";
import {
    fromProtoBondId,
    fromProtoCallId,
    fromProtoMessageId,
    fromProtoNotifId,
    fromProtoUserId,
    fromProtoTimestamp,
    pbNotifId,
    pbUserId,
} from "./util";

const service = createPromiseClient(NotificationService, transport);
export default service;

function translateNotificationPriority(priority: domain_pb.NotifPriority): NotificationPriority {
    switch (priority) {
        default:
        case domain_pb.NotifPriority.UNSPECIFIED:
            return NotificationPriority.Unspecified;
        case domain_pb.NotifPriority.LOW:
            return NotificationPriority.Low;
        case domain_pb.NotifPriority.MEDIUM:
            return NotificationPriority.Medium;
        case domain_pb.NotifPriority.HIGH:
            return NotificationPriority.High;
        case domain_pb.NotifPriority.TIME_SENSITIVE:
            return NotificationPriority.TimeSensitive;
        case domain_pb.NotifPriority.URGENT:
            return NotificationPriority.Urgent;
        case domain_pb.NotifPriority.CRITICAL:
            return NotificationPriority.Critical;
    }
}

function translateNotificationDetails(
    details: notifications_pb.WebNotification["details"],
): WebNotificationDetails {
    switch (details.case) {
        case "textDetails":
            return {
                case: WebNotificationType.Text,
                value: {
                    text: details.value.text,
                },
            };
        case "chatDetails": {
            const pbDetails = details.value;
            return {
                case: WebNotificationType.ChatMessage,
                value: {
                    bond: {
                        id: fromProtoBondId(pbDetails.bondId),
                        name: pbDetails.bondName,
                    },
                    previewText: pbDetails.previewText,
                    messageId: fromProtoMessageId(pbDetails.chatMessageId),
                    senderId: fromProtoUserId(pbDetails.senderId),
                },
            };
        }
        case "callStartDetails": {
            const pbDetails = details.value;
            return {
                case: WebNotificationType.CallStart,
                value: {
                    bond: {
                        id: fromProtoBondId(pbDetails.bondId),
                        name: pbDetails.bondName,
                    },
                    callId: fromProtoCallId(pbDetails.callId),
                    initiator: {
                        id: fromProtoUserId(pbDetails.initiatorId),
                        name: pbDetails.initiatorName,
                    },
                },
            };
        }
        default:
            throw new Error(
                `Invalid notification details case: ${details.case}`,
            );
    }
}

function translateNotification(notification: notifications_pb.WebNotification): WebNotification {
    return {
        id: fromProtoNotifId(notification.id),
        userId: fromProtoUserId(notification.userId),
        eventTs: fromProtoTimestamp(notification.eventTs),
        createdTs: fromProtoTimestamp(notification.createdTs),
        priority: translateNotificationPriority(notification.priority),
        dismissed: false,
        details: translateNotificationDetails(notification.details),
    };
}

export type NotificationWrapper = {
    case: "notification";
    notification: WebNotification;
};
export type DismissedWrapper = {
    case: "dismissed";
    id: d.NotificationId;
};

export type NotificationOrDismissed = NotificationWrapper | DismissedWrapper;

function notificationParser(
    res: notifications_pb.SubWebNotificationChangesResponse,
): NotificationOrDismissed {
    switch (res.notificationOrDismissed?.case) {
        case "notification":
            return {
                case: "notification",
                notification: translateNotification(res.notificationOrDismissed.value),
            };
        case "dismissedId":
            return {
                case: "dismissed",
                id: fromProtoNotifId(res.notificationOrDismissed.value),
            };
        default:
            throw new Error(
                `Unknown notificationOrDismissed case ${res.notificationOrDismissed?.case}`,
            );
    }
}

export async function dismissNotification(userId: d.UserId, id: d.NotificationId) {
    const req = new notifications_pb.DismissNotificationRequest({
        userId: pbUserId(userId),
        notifId: pbNotifId(id),
    });

    try {
        await service.dismissNotification(req);
    }
    catch (e) {
        throw new Error(`Error dismissing notification ${id}: ${e}, ${JSON.stringify(e)}`);
    }
}

export async function ackNotification(userId: d.UserId, id: d.NotificationId) {
    const req = new notifications_pb.AckNotificationRequest({
        userId: pbUserId(userId),
        notifId: pbNotifId(id),
    });

    try {
        await service.ackNotification(req);
    }
    catch (e) {
        throw new Error(`Error acking notification ${id}: ${e}, ${JSON.stringify(e)}`);
    }
}

export async function* streamNotifications(userId: d.UserId, signal: AbortSignal) {
    const req = new notifications_pb.SubWebNotificationChangesRequest({
        userId: pbUserId(userId),
    });

    const logPrefix = `streamNotifications ${userId}`;

    const resp = service.subWebNotificationChanges(req, { signal });

    yield* streamHandler(resp, notificationParser, logPrefix);
}
