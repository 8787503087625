import { useEffect } from "react";
import { useAppSelector } from "../store/redux";
import { selectInterestedUnreadBondCount } from "../features/filterPanel";

export default function WindowTitleUpdater(): React.JSX.Element {
    const interestedUnreadBondCount = useAppSelector(selectInterestedUnreadBondCount);

    useEffect(() => {
        const title = interestedUnreadBondCount > 0 ? `Bond (${interestedUnreadBondCount})`
            : `Bond`;

        document.title = title;
    }, [interestedUnreadBondCount]);

    return <></>;
}
