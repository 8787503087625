import * as blobs_pb from "../../gen/proto/blobs/blobs_pb";
import { fromProtoTimestamp } from "../api/util";
import { BlobMetadata, ExpiringSasUrl } from "../domain/blobs";

export function translateSasUrl(sasUrl?: blobs_pb.ExpiringSasUrl): ExpiringSasUrl {
    return {
        url: sasUrl?.url || "",
        expiresAt: fromProtoTimestamp(sasUrl?.urlExpiry),
    };
}

export function translateBlobProperties(properties: blobs_pb.BlobProperties): BlobMetadata {
    return {
        fileName: properties.fileName,
        mimeType: properties.mimeType,
        fileSize: properties.fileSize,
        ...properties.dimensions && {
            dimensions: {
                width: properties.dimensions.width,
                height: properties.dimensions.height,
            },
        },
    };
}
