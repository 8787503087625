import {
    createBrowserRouter,
    createRoutesFromElements,
    Outlet,
    redirect,
    Route,
} from "react-router-dom";
import BondView from "../views/BondView";
import { ErrorElement } from "./gui/ErrorElement";
import AttachmentLightbox from "./messages/AttachmentLightbox";
import SettingsView from "../views/SettingsView";
import { LocationLogger } from "./LocationLogger";
import LoginView from "../views/LoginView";
import LogoutView from "../views/LogoutView";
import { AuthenticatedRootView } from "../views/RootView";
import log from "../misc/log";
import BondInterestedModal from "./BondInterestedModal";
import BondsCardView from "../views/BondsCardView";
import { setRouter } from "../misc/router";

const indexRedirect = async () => {
    log.info(`Redirecting index -> /bond`);
    return redirect("/bond");
};

const errorHandler = {
    errorElement: <ErrorElement />,
};

const routeTree = (
    <Route
        path="/"
        element={
            <>
                <LocationLogger />
                <Outlet />
            </>
        }
        {...errorHandler}
    >
        {/* /login,/logout routes */}
        <Route
            path="login"
            element={<LoginView />}
            {...errorHandler}
        />
        <Route
            path="logout"
            element={<LogoutView />}
            {...errorHandler}
        />

        {
            /* Index. We always redirect to /bond since that is our authenticated area
            today. */
        }
        <Route index={true} loader={indexRedirect} {...errorHandler} />

        {/* /bond routes */}
        <Route
            path="bond"
            element={<AuthenticatedRootView />}
            {...errorHandler}
        >
            <Route path=":bondId" element={<BondView />} {...errorHandler}>
                <Route path="message/:messageId" element={<Outlet />} {...errorHandler}>
                    <Route
                        path="attachment/:attachmentVersion/:attachmentIdx"
                        element={<AttachmentLightbox />}
                        {...errorHandler}
                    />
                </Route>
                <Route path="interested" element={<BondInterestedModal />} {...errorHandler} />
            </Route>
            <Route
                path="settings"
                element={
                    <>
                        <SettingsView />
                        <BondsCardView />
                    </>
                }
                {...errorHandler}
            />

            <Route index={true} element={<BondsCardView />} {...errorHandler} />
        </Route>
    </Route>
);

export function setupRoutes() {
    setRouter(createBrowserRouter(createRoutesFromElements(routeTree)));
}
