import { useCallback, useMemo, useState } from "react";
import * as d from "../domain/domain";
import { UserOverview } from "../domain/users";
import { fetchUsersForPerson, selectUserIdsKnownToCurrentUser } from "../features/squads";
import { getPersonByEmail, selectAllUsersExceptCurrent, selectUsers } from "../features/users";
import { useAppDispatch, useAppSelector } from "../store/redux";
import { AutoCompleteQuery } from "../hooks/useAutoCompleteQuery";
import useInterestedUsers from "../hooks/interest/useInterestedUsers";
import { useShallowEqualsMemo } from "../hooks/useShallowEquals";
import { userNameForMention } from "../misc/messageContent";
import { similaritySearch, similaritySort } from "../misc/similarStrings";
import { partialComponent } from "../misc/utils";
import { AutoComplete, SuggestionContentProps } from "./gui/AutoComplete";
import Avatar from "./gui/Avatar";
import SensitiveText from "./gui/SensitiveText";
import useSelectorArgs from "../hooks/useSelectorArgs";

interface KnownUserSuggestionProps {
    user: UserOverview;
}
function UserMentionSuggestionContent(
    props: KnownUserSuggestionProps & SuggestionContentProps,
): React.JSX.Element {
    const { user } = props;
    return (
        <>
            <Avatar userId={user.id} size="xs-small" />
            <div className="u-truncate-auto">
                <em>
                    <SensitiveText>{userNameForMention(user)}</SensitiveText>
                </em>
                &nbsp;&bull;&nbsp;
                <SensitiveText>{user.name}</SensitiveText>
            </div>
        </>
    );
}

interface EmailLookupSuggestionProps {
    email: string;
}
function EmailLookupSuggestionContent(
    props: EmailLookupSuggestionProps & SuggestionContentProps,
): React.JSX.Element {
    const { email } = props;

    return (
        <>
            <div className="">
                Search for&nbsp;
                <em>
                    <SensitiveText>{email}</SensitiveText>
                </em>
                &nbsp;by email.
            </div>
        </>
    );
}

export type ChooseUserFunc = (_: d.UserId) => void;

interface UserLookupAutoCompleteProps {
    mentionX: number;
    mentionY: number;
    chooseUser: ChooseUserFunc;
    query: AutoCompleteQuery;
    similarityThreshold?: number;
}

export const UserLookupAutoComplete = (props: UserLookupAutoCompleteProps): React.JSX.Element => {
    const { mentionX, mentionY, chooseUser: choose, query } = props;
    const similarityThreshold = props.similarityThreshold ?? -100000;
    const dispatch = useAppDispatch();

    const [lookedUpUserIds, setLookedUpUserIds] = useState<d.UserId[]>([]);
    const lookedUpUsers = useSelectorArgs(selectUsers, lookedUpUserIds);

    const knownUserIds = useAppSelector(selectUserIdsKnownToCurrentUser);
    const relevantUserIds = [...lookedUpUserIds, ...knownUserIds];
    useInterestedUsers(relevantUserIds);

    const users = useAppSelector(selectAllUsersExceptCurrent);

    const suggest = useCallback(
        <T extends { name: string; }>(entities: T[]) => {
            const simSearched = entities
                .filter(e => similaritySearch(query.text, e.name, similarityThreshold))
                .sort((a, b) => similaritySort(query.text, a.name, b.name));

            const allSorted = entities
                .sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

            return [...((query.text) ? simSearched : allSorted), ...lookedUpUsers];
        },
        [query.text, similarityThreshold, lookedUpUsers],
    );

    const suggestedUsers = useShallowEqualsMemo(() => suggest(users), [suggest, users]);
    const suggestionsForUsers = useShallowEqualsMemo(
        () =>
            suggestedUsers.map(user => ({
                key: user.id,
                className: "c-suggestion--human",
                Content: partialComponent<KnownUserSuggestionProps, SuggestionContentProps>(
                    UserMentionSuggestionContent,
                    { user },
                ),
                onAdd: () => choose(user.id),
            })),
        [suggestedUsers, choose],
    );

    const chooseUserByEmail = useCallback(async (targetEmail: string) => {
        const personId = await dispatch(getPersonByEmail({ email: targetEmail })).unwrap();
        if (!personId) {
            alert(`No user found with email "${targetEmail}".`);
            return;
        }

        const userIds = await dispatch(fetchUsersForPerson(personId)).unwrap();
        if (userIds.length === 0) {
            alert(`No user found with email "${targetEmail}".`);
            return;
        }

        setLookedUpUserIds(userIds.map(u => u.userId));
    }, [dispatch, setLookedUpUserIds]);

    const lookupEmail = query.text;
    const lookupEmailSuggestion = useShallowEqualsMemo(() => {
        return {
            key: lookupEmail,
            className: "c-suggestion--squad",
            Content: partialComponent<EmailLookupSuggestionProps, SuggestionContentProps>(
                EmailLookupSuggestionContent,
                { email: lookupEmail },
            ),
            onAdd: () => chooseUserByEmail(lookupEmail),
        };
    }, [chooseUserByEmail, lookupEmail]);

    const suggestionGroups = useShallowEqualsMemo(
        () => [suggestionsForUsers, [lookupEmailSuggestion]].filter(g => g.length > 0),
        [suggestionsForUsers, lookupEmailSuggestion],
    );

    const suggestionCount = useMemo(
        () => suggestionGroups.flat().length,
        [suggestionGroups],
    );

    return (
        <>
            {(suggestionCount > 0) && (
                <AutoComplete
                    anchorX={mentionX}
                    anchorY={mentionY}
                    verticalAnchor="bottom"
                    horizontalClampBehaviour="clamp"
                    suggestionGroups={suggestionGroups}
                />
            )}
        </>
    );
};
