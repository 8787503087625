import useRegisterInterest from "./useRegisterInterest";
import {
    registerMetaInterest,
    deregisterMetaInterest,
    MetaInterestCounterKey,
} from "../../features/meta";

export default function useMetaInterest(
    interested: boolean,
    ...interestKeys: MetaInterestCounterKey[]
) {
    useRegisterInterest({
        ids: interested ? interestKeys : [],
        registerInterest: registerMetaInterest,
        unregisterInterest: deregisterMetaInterest,
    });
}
