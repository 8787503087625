import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DeviceKind, MediaInputKind } from "../../domain/mediaDevices";
import VolumeMeter from "./VolumeMeter";
import MediaDeviceSelector from "../../components/gui/MediaDeviceSelector";
import { Optional } from "../../misc/types";
import { useMediaTrack } from "../../hooks/media/useMediaTrack";
import useAudioSinkRef from "../../hooks/media/useAudioSinkRef";
import useRefCallback from "../../hooks/useRefCallback";

const EXAMPLE_AUDIO_URL =
    "https://interactive-examples.mdn.mozilla.net/media/cc0-audio/t-rex-roar.mp3";

interface IAudioPreviewProps {
    className?: string;
}

export default function AudioPreview(props: IAudioPreviewProps): React.JSX.Element {
    // Capture MediaStream for volume meter
    const [micStream, setMicStream] = useState<Optional<MediaStream>>(undefined);
    const onGetMedia = useCallback(async (stream: MediaStream) => {
        setMicStream(stream);
    }, []);
    const onEndMedia = useCallback(() => {
        setMicStream(undefined);
    }, []);
    const streamCapturer = useMemo(() => ({
        publish: onGetMedia,
        unpublish: onEndMedia,
    }), [onGetMedia, onEndMedia]);

    // Play a fake sound if no device selected
    const [sampleAudioEnabled, setSampleAudioEnabled] = useState(false);
    const isSampleAudioPlaying = useRef(false);
    const enableSampleAudio = useCallback(() => {
        setSampleAudioEnabled(true);
    }, []);

    const { track: micTrack, toggleTrack } = useMediaTrack({
        endOnToggle: true,
        mediaKind: MediaInputKind.AudioInput,
        onNoDevice: enableSampleAudio,
        publisher: streamCapturer,
    });

    const micEnabled = micTrack.enabled;

    useEffect(() => {
        if (!micEnabled) {
            setSampleAudioEnabled(false);
        }
    }, [micEnabled]);

    const playSampleAudio = useCallback((node: HTMLAudioElement) => {
        if (sampleAudioEnabled) {
            node.src = EXAMPLE_AUDIO_URL;
            node.play();
            isSampleAudioPlaying.current = true;
        }
        else if (isSampleAudioPlaying.current) {
            node.pause();
            isSampleAudioPlaying.current = false;
        }
    }, [sampleAudioEnabled]);

    const audioSinkRefCb = useAudioSinkRef({ track: micTrack });

    const audioRef = useRefCallback(playSampleAudio, { parentRefCb: audioSinkRefCb });

    return (
        <div className={props.className ?? ""}>
            <VolumeMeter enabled={micEnabled} stream={micStream} />
            <div className="c-settings-controls">
                <MediaDeviceSelector kind={DeviceKind.AudioInput} />
                <audio ref={audioRef} autoPlay loop data-testid={"preview-audio"} />
                <button
                    className="c-btn c-btn--primary c-btn--settings"
                    onClick={toggleTrack}
                    data-testid="play-btn"
                >
                    {micEnabled ? "Stop" : "Play"}
                </button>
            </div>
        </div>
    );
}
