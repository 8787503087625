import { PromiseWithResolvers, promiseWithResolvers } from "../misc/promises";

interface Queue<T> {
    push: (t: T) => void;
    clear: () => void;
}

export const asyncIterableQueue = <T>(): AsyncIterableIterator<T> & Queue<T> => {
    const q: T[] = [];
    const promises: PromiseWithResolvers<IteratorResult<T>>[] = [];

    const next = async (): Promise<IteratorResult<T>> => {
        const value = q.shift();

        if (value !== undefined) {
            return { value, done: false };
        }
        else {
            const p = promiseWithResolvers<IteratorResult<T>>();
            promises.push(p);

            return p.promise;
        }
    };

    const push = (value: T) => {
        const promise = promises.shift();

        if (promise !== undefined) {
            promise.resolve({ value, done: false });
        }
        else {
            q.push(value);
        }
    };

    const clear = () => {
        if (q.length) {
            q.splice(0, q.length);
        }
        if (promises.length) {
            promises.forEach(p => {
                p.reject(new DOMException("Cleared pending items from queue.", "AbortError"));
            });
            promises.splice(0, promises.length);
        }
    };

    // Chicken and egg: `AsyncIterableIterator` must return something with
    // `[Symbol.asyncIterator]` defined, i.e. "itself".
    // This isn't possible without creating a handle (`iter`) to close the loop.
    const iter = {
        next,
        push,
        clear,
        [Symbol.asyncIterator](): AsyncIterableIterator<T> {
            return iter;
        },
    };
    return iter;
};

export default asyncIterableQueue;
