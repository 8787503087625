import { useEffect, useMemo } from "react";
import useTimerTarget from "./useTimerTarget";
import { DateTime, Duration, Interval } from "luxon";
import { getDurationStr } from "../misc/utils";

// Format the time since a start time as a stopwatch-style string
// e.g. 01:00, 1:00:00 etc.
export default function useStopwatch(startTime?: number) {
    const { now, setTarget } = useTimerTarget();

    useEffect(() => {
        setTarget(now.plus(Duration.fromMillis(1000)));
    }, [now, setTarget]);

    const durationStr = useMemo(() => {
        if (!startTime) {
            return "";
        }

        const diff = Interval.fromDateTimes(DateTime.fromMillis(startTime), now);
        return getDurationStr(diff);
    }, [now, startTime]);

    return durationStr;
}
