import { nanoid } from "@reduxjs/toolkit";
import { ExpandType, Optional } from "../misc/types";
import { BackoffState } from "./backoff";
import { bondCreationDraftTarget, DraftTarget, newChannelDraftTarget } from "./channels";
import * as d from "./domain";
import { ContentMention } from "./mentions";
import { AnyLocalAttachment, isUploadedAttachment } from "./attachments";

// Chat content

// This is stored as a string-y blob in the database. But it must
// be parsed by something on the backend in order for the message
// to be provided to an AI for the purpose of creating summaries.

export interface SanitisedChatContent {
    // Opaque, client-only identifier for each message.
    // Allows the client to tie together messages received from the
    // cloud and messages that it sent.
    // The backend provides the contract that messages with different
    // ids will be treated as distinct messages for the idempotent
    // semantics of sending a message.
    id?: d.UnsentMessageId;
    // Plaintext version of the message. Not provided if, e.g., the
    // message contains only an uploaded file but no text, or this
    // is a message deletion.
    message?: string;
    // List of non-overlapping character ranges representing mentions
    // in the text, ordered by start offset
    mentions?: ContentMention[];
}

export const genCaretHintId = () => nanoid(16);

export const genLocalMessageId = (): d.UnsentMessageId => nanoid();

export interface DraftChatContent {
    draftId: d.UnsentMessageId;
    draftMessage: string;
    trimmedMessage: string;
    mentions?: ContentMention[];
    // Hint for the message composer to update the caret position (e.g. after
    // a text modification), with a unique id to enable idempotence
    caretHint: { location?: number; id: string; };
}

function isDraftChatContent<T extends DraftChatContent | SanitisedChatContent>(
    dcc: Optional<T>,
): dcc is DraftChatContent & T {
    return !!dcc && "draftId" in dcc;
}

export function getMessageString(
    content: Optional<DraftChatContent | SanitisedChatContent>,
): string {
    if (!content) return "";
    return (isDraftChatContent(content) ? content.draftMessage : content.message) ?? "";
}

export function emptyDraftChatMessage(channelId?: d.ChannelId): DraftChatMessage {
    const localId = genLocalMessageId();
    return {
        type: LocalMessageType.Draft,
        localId,
        draftTarget: channelId ? newChannelDraftTarget(channelId) : bondCreationDraftTarget,
        attachmentIds: [],
        content: {
            draftId: localId,
            draftMessage: "",
            trimmedMessage: "",
            caretHint: { id: genCaretHintId() },
        },
        prefix: {
            length: 0,
            padding: 0,
        },
    };
}

// canoniseContent takes a SanitisedChatContent, containing only the primary
// fields, and derives the secondary fields that are used for calculating
// whether the message should be allowed to be sent.
//
// It is a slice reducer's responsibility to ensure that this is used before
// committing a message into the Redux store.
export function canoniseContent(
    { id, mentions, message }: SanitisedChatContent,
    caretHint: Optional<number>,
): DraftChatContent {
    if (!id) {
        throw new Error("DraftChatContents require a draftId, no id provided");
    }

    return {
        draftId: id,
        draftMessage: message ?? "",
        trimmedMessage: message?.trim() ?? "",
        mentions,
        caretHint: { location: caretHint, id: genCaretHintId() },
    };
}

// decanoniseContent takes a DraftChatContent and produces a
// SanitisedChatContent that is suitable for sending to the backend.
//
// It is a thunk's responsibility to ensure that this is used before sending a
// message to the backend.
function decanoniseContent(
    { draftId, mentions, draftMessage, trimmedMessage }: DraftChatContent,
): SanitisedChatContent {
    const toMove = draftMessage.length - draftMessage.trimStart().length;
    const movedMentions = mentions?.map(v => ({
        ...v,
        startOffset: v.startOffset - toMove,
        endOffset: v.endOffset - toMove,
    }));

    return {
        id: draftId,
        // `||` is correct as trimmedMessage may be an empty string
        message: trimmedMessage || undefined,
        mentions: movedMentions,
    };
}

// Stored as JSON, hence given an explicit type here.
export interface CallMessageContentBlob {
    callId: d.CallId;
    // The summary list of participants (only in call end messages)
    // TODO: marking as required - need to check this assumption as part of possible migration
    participantIds: d.UserId[];
}

export enum MessageSendableStatus {
    // The message in question may not be sent.
    Denied = -1,

    // The message in question is ready to send.
    Ready = 0,

    // The message in question has attachments that are uploading and must
    // finish doing so before the message can be sent.
    Waiting = 1,

    // The string portion of the message in question is too long to be sent.
    TooLong = 2,

    // The message has no recipients. (Look at bondCreation messages.)
    NoRecipients = 3,
}

const statusText = {
    [MessageSendableStatus.Denied]: "Denied",
    [MessageSendableStatus.Ready]: "Send",
    [MessageSendableStatus.NoRecipients]: "Your message has no recipients",
    [MessageSendableStatus.TooLong]: "Your message is longer than the allowed maximum size",
    [MessageSendableStatus.Waiting]: "Waiting for attachments to finish uploading",
};
export const messageSendableStatusText = (status: MessageSendableStatus): string =>
    statusText[status];

export function draftIsSendable(
    dcm: Optional<DraftChatMessage>,
    requireRecipients: boolean,
    attachments?: AnyLocalAttachment[],
): MessageSendableStatus {
    if (!dcm) return MessageSendableStatus.Denied;

    const { attachmentIds, content: { mentions, trimmedMessage } } = dcm;

    const mentionsCount = mentions?.length ?? 0;
    const trimmedLength = trimmedMessage.length;

    if (requireRecipients && mentionsCount === 0) return MessageSendableStatus.NoRecipients;

    if (trimmedLength > 4000) return MessageSendableStatus.TooLong;

    if (attachments?.some(a => !isUploadedAttachment(a))) return MessageSendableStatus.Waiting;

    if (trimmedLength > 0 || attachmentIds.length > 0) return MessageSendableStatus.Ready;

    return MessageSendableStatus.Denied;
}

// Official chat messages.
// These correspond to messages we have been pushed by a backend.

export enum OfficialMessageType {
    Chat,
    CallStart,
    CallEnd,
}

export interface OfficialMessage {
    id: d.MessageId;
    channelId: d.ChannelId;
    serverRxTs: d.Timestamp;
    sequenceNumber: number;
    type: OfficialMessageType;
}

export type CallStartMessage = ExpandType<
    & OfficialMessage
    & Pick<CallMessageContentBlob, "callId">
    & { type: OfficialMessageType.CallStart; }
>;

export type CallEndedMessage = ExpandType<
    & OfficialMessage
    & CallMessageContentBlob
    & { type: OfficialMessageType.CallEnd; }
>;

export type OfficialChatMessage = ExpandType<
    & OfficialMessage
    & {
        type: OfficialMessageType.Chat;
        senderId: d.UserId;
        clientTxTs: d.Timestamp;
        content: SanitisedChatContent;
        callId?: d.CallId;
        // Unused - add back later.
        // editOf?: number;
        attachmentIds: d.BlobId[];
    }
>;

export type AnyOfficialMessage = OfficialChatMessage | CallStartMessage | CallEndedMessage;

export const determineOfficialMessageContributors = (
    msg: Optional<AnyOfficialMessage>,
): d.UserId[] => {
    switch (msg?.type) {
        case OfficialMessageType.Chat:
            return [msg.senderId];
        case OfficialMessageType.CallEnd:
            return msg.participantIds ?? [];
        default:
            return [];
    }
};

// Local messages.
// The backend has no idea about these messages until we try to send them.

export enum LocalMessageType {
    Draft = "draft",
    Unsent = "unsent",
    Errored = "errored",
}

interface LocalChatMessage {
    type: LocalMessageType;
    localId: d.UnsentMessageId;
    draftTarget: DraftTarget;
    // Always store all local attachments here.
    // Selectors will get you those in the different states.
    attachmentIds: d.LocalAttachmentId[];
    // Track the length of the message prefix and any (usually whitespace)
    // padding between the prefix and the body. The prefix is brittle: it will
    // immediately become part of the message body if it is modified by the
    // user. The padding will shrink as it is modified but will not convert the
    // rest of the prefix.
    // This is kept on all versions of `LocalChatMessage`, in order that
    // message edits might be able re-use it.
    prefix?: {
        length: number;
        padding: number;
    };
    // In order to avoid a flashing UI, we want to track which call we think a
    // message has been sent during. This is ignored totally when the message
    // is actually sent.
    liveCallId?: d.CallId;
}

export type DraftChatMessage = ExpandType<
    & LocalChatMessage
    & {
        type: LocalMessageType.Draft;
        content: DraftChatContent;
    }
>;

export type UnsentChatMessage = ExpandType<
    & LocalChatMessage
    & {
        type: LocalMessageType.Unsent;
        // A place to put the official message id given to us by the backend.
        // We want to keep using our local id until we receive the message from
        // an RPC stream.
        messageId?: d.MessageId;
        content: SanitisedChatContent;
        clientTxTs: d.Timestamp;
        backoffState?: BackoffState;
        // editOf?: d.MessageId; // Unused
        // localEditOf?: d.UnsentMessageId; // Unused
    }
>;
export const isUnsentMsg = (msg: Optional<AnyLocalMessage>): msg is UnsentChatMessage =>
    msg?.type === LocalMessageType.Unsent;

export type ErroredChatMessage = ExpandType<
    & LocalChatMessage
    & {
        type: LocalMessageType.Errored;
        content: SanitisedChatContent;
        clientTxTs: d.Timestamp;
        // No backoffState - being errored means we have given up.
        // editOf?: d.MessageId; // Unused
    }
>;

export const isErroredMsg = (msg: Optional<AnyLocalMessage>): msg is ErroredChatMessage =>
    msg?.type === LocalMessageType.Errored;

export const toErroredMsg = (
    { backoffState: _, messageId: __, ...msg }: UnsentChatMessage,
): ErroredChatMessage => ({
    ...msg,
    type: LocalMessageType.Errored,
});

export type AnyUnsentLocalMessage = UnsentChatMessage | ErroredChatMessage;

export type AnyLocalMessage = DraftChatMessage | UnsentChatMessage | ErroredChatMessage;

export type AnyMessage = AnyOfficialMessage | AnyLocalMessage;

// Helper functions

export function isOfficialMessage<T extends AnyMessage>(
    msg?: T,
): msg is AnyOfficialMessage & T {
    return !!msg && "id" in msg;
}

export function isOfficialChatMessage<T extends AnyMessage>(
    msg?: T,
): msg is OfficialChatMessage & T {
    return isOfficialMessage(msg) && msg.type === OfficialMessageType.Chat;
}

export function isCallEndedMessage<T extends AnyMessage>(msg?: T): msg is CallEndedMessage & T {
    return isOfficialMessage(msg) && msg.type === OfficialMessageType.CallEnd;
}

export function isLocalMessage<T extends AnyMessage>(msg?: T): msg is LocalChatMessage & T {
    return !!msg && "localId" in msg;
}

export function isUnsentLocalMessage<T extends AnyMessage>(
    msg?: T,
): msg is AnyUnsentLocalMessage & T {
    switch (msg?.type) {
        case LocalMessageType.Unsent:
        case LocalMessageType.Errored:
            return true;
        default:
            return false;
    }
}

export function haveSameMessageType(a: AnyMessage, b: AnyMessage): boolean {
    if (isOfficialMessage(a) && isOfficialMessage(b)) return a.type === b.type;
    const aLocal = isLocalMessage(a);
    const bLocal = isLocalMessage(b);
    if (aLocal && bLocal) return true;
    return (isOfficialChatMessage(a) && bLocal) || (aLocal && isOfficialChatMessage(b));
}

export function haveSameSender(a: AnyMessage, b: AnyMessage, ourUserId?: d.UserId): boolean {
    if (isLocalMessage(a)) {
        return isLocalMessage(b) || (isOfficialChatMessage(b) && b.senderId === ourUserId);
    }
    else if (isLocalMessage(b)) {
        return isOfficialChatMessage(a) && a.senderId === ourUserId;
    }
    else {
        return isOfficialChatMessage(a) && isOfficialChatMessage(b) && a.senderId === b.senderId;
    }
}

export function getSenderId(msg: Optional<AnyMessage>, ourUserId?: d.UserId): Optional<d.UserId> {
    if (isOfficialChatMessage(msg)) return msg.senderId;
    if (isUnsentLocalMessage(msg)) return ourUserId;
}

export function getCallId(msg: Optional<AnyMessage>): Optional<d.CallId> {
    if (!msg) return;
    if (isOfficialChatMessage(msg)) return msg.callId;
    if (isLocalMessage(msg)) return msg.liveCallId;
    return msg.callId;
}

export function convertDraftToUnsentMessage(
    { content: draftContent, ...draft }: DraftChatMessage,
    now: d.Timestamp,
): UnsentChatMessage {
    return {
        ...draft,
        type: LocalMessageType.Unsent,
        content: decanoniseContent(draftContent),
        clientTxTs: now,
    };
}

export function getSenderIdForMention(
    msg: AnyOfficialMessage,
    id?: d.UserId,
): Optional<d.UserId> {
    if (!isOfficialChatMessage(msg)) return;
    if (id === undefined) return;

    const mentions = msg.content.mentions;
    if (!mentions || mentions.length === 0) return;

    return mentions.find(m => m.case === "user" && m.target === id) && msg.senderId;
}

export const getAttachmentMsgId = (msg: AnyMessage): d.AttachmentMessageId =>
    isLocalMessage(msg) ? { localId: msg.localId } : { id: msg.id };

export const getMsgSequenceNumber = (msg?: AnyMessage): Optional<number> => {
    if (isOfficialMessage(msg)) return msg.sequenceNumber;
};

export const getMsgAttachmentIds = (msg?: AnyMessage): d.BlobId[] | d.LocalAttachmentId[] => {
    const noAttachments = !isLocalMessage(msg) && !isOfficialChatMessage(msg);
    return noAttachments ? [] : msg.attachmentIds;
};

export const getMsgTs = (msg?: AnyMessage): Optional<Date> => {
    if (!msg) return;
    if (isOfficialMessage(msg)) return new Date(msg.serverRxTs);
    if (msg.type !== LocalMessageType.Draft) return new Date(msg.clientTxTs);
};

// A draft message may contain a "prefix" which is used for the squad filter mention:
//
// "@Pulse Hello, world! @User"
//  ------X===================
//  M1____               M2___
//
// -: prefix
// X: padding
// =: body
// M*: mention
//
// In this example:
//     getMsgPrefix: "@Beyond"
//     getMsgPrefixWithPadding: "@Beyond "
//     getMsgPrefixMentions: [{target: "<Pulse squad ID>", startOffset: 0, endOffset: 6, ...}]
//     getMsgBody: "Hello, world! @User"
type MaybeMessage = Optional<AnyLocalMessage>;
const msgSlice = (msg: MaybeMessage, start?: number, end?: number): string =>
    getMessageString(msg?.content)?.slice(start, end) ?? "";

export const getMsgPrefix = (msg: MaybeMessage) => msgSlice(msg, 0, msg?.prefix?.length);

export const getMsgPrefixWithPadding = (msg: MaybeMessage) =>
    msgSlice(msg, 0, msg?.prefix && (msg.prefix.length + msg.prefix.padding));

export const getMsgBody = (msg: MaybeMessage) =>
    msgSlice(msg, msg?.prefix && (msg.prefix.length + msg.prefix.padding));

export const isMsgBodyEmpty = (msg: MaybeMessage) => (getMsgBody(msg)?.trim().length ?? 0) == 0;

export const getMsgPrefixMentions = (msg: MaybeMessage) => {
    if (!msg) return;
    const { prefix, content: { mentions } } = msg;
    if (prefix) {
        return mentions?.filter(m => m.endOffset <= prefix.length);
    }
};
