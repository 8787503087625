import { MouseEventHandler } from "react";

interface PeopleCountProps {
    n: number;
    testId?: string;
    onClick?: MouseEventHandler<HTMLElement>;
    outline?: boolean;
    extraClass?: string;
}

export const PeopleCount = (props: PeopleCountProps) => {
    const outline = props.outline ? "c-btn-people--outline" : "";
    return (
        <button
            className={`c-btn c-btn-people ${outline} ${props.extraClass || ""}`}
            onClick={props.onClick || (_e => {})}
            data-testid={props.testId}
        >
            <span className="c-btn-people__icon">People</span>
            <span className="c-btn-people__count">{props.n}</span>
        </button>
    );
};

export default PeopleCount;
