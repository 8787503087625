import { Breadcrumb, SeverityLevel, addBreadcrumb } from "@sentry/react";
import { DateTime } from "luxon";
import LogArchiveWorker from "./logArchiveWorker?worker";
import { getArchivedLogLines } from "./logArchiveWorker.ts";

type logProps = Record<string, any>;
// When we actually come to produce a logline, we want our props
// to be "string"y, corresponding to a(n implicit) cast
// `logProps` -> `logLineProps`
type logLineProps = Record<string, string>;

export type LogFunc = (message?: any, ...optionalParams: any[]) => void;

type LogObject = {
    log: LogFunc;
    debug: LogFunc;
    info: LogFunc;
    warn: LogFunc;
    error: LogFunc;
    with: (props?: logProps) => WithObject;
};

enum LogLevel {
    Debug = 70,
    Info = 80,
    Warn = 90,
    Error = 100,
}

const logLevelProps: Record<LogLevel, {
    name: string;
    fmt: [string, string];
    sentry: SeverityLevel;
    console: () => typeof console.log;
}> = {
    [LogLevel.Debug]: {
        name: "DBG",
        fmt: ["color: green; font-style: italic;", "font-style: italic;"],
        sentry: "debug",
        console: () => console.debug,
    },
    [LogLevel.Info]: {
        name: "INF",
        fmt: ["", ""],
        sentry: "info",
        console: () => console.info,
    },
    [LogLevel.Warn]: {
        name: "WRN",
        fmt: ["color: orange; font-weight: bold;", "color: orange;"],
        sentry: "warning",
        console: () => console.warn,
    },
    [LogLevel.Error]: {
        name: "ERR",
        fmt: ["color: red; font-weight: bold;", "color: red;"],
        sentry: "error",
        console: () => console.error,
    },
};

interface LogLine {
    msg: string;
    level: LogLevel;
    tsUtc: number;

    args?: string[];
    props?: logLineProps;
}

interface LogOptions {
    forwardToConsole?: boolean;
    forwardToSentry?: boolean;
    directConsoleLoggingOnly?: boolean;
    ignoreRegexs?: RegExp[]; // TODO: a push/pop API for this? Would fit the test API better.
}

function stringifyArgObject(arg: object): string {
    if (arg instanceof Element || arg instanceof HTMLElement) {
        return `Element (${arg.nodeName}): ` + JSON.stringify(arg);
    }
    else if (arg instanceof Event) {
        return `Event (${arg.type}): ` + JSON.stringify(arg);
    }
    return JSON.stringify(arg);
}

function stringifyArg(arg: any): string {
    switch (typeof arg) {
        case "string":
        case "number":
        case "bigint":
        case "boolean":
        case "symbol":
            return arg.toString();
        case "undefined":
            return "undefined";
        case "object":
            return stringifyArgObject(arg);
        case "function":
            return arg.toString();
    }
}

function argsForConsoleLog(
    line: { msg: string; args?: any[]; props?: logProps; },
    stringify?: boolean,
): any[] {
    let args: any[] = [line.msg];
    const concatOptionalStringify = (newArgs: any[]) =>
        args.concat(stringify ? newArgs.map(stringifyArg) : newArgs);

    if (line.args) {
        args = concatOptionalStringify(line.args);
    }
    if (line.props && Object.entries(line.props).length > 0) {
        args = concatOptionalStringify([line.props]);
    }
    return args;
}

function tsFormat(ts: number): string {
    return DateTime.fromMillis(ts).toFormat("yyMMdd-HH:mm:ss.SSS");
}

function formatLogLines(logLines: LogLine[]) {
    return logLines.map(line =>
        `${tsFormat(line.tsUtc)} ${logLevelProps[line.level].name} ` +
        argsForConsoleLog(line, true)
    );
}

class BufferedLogger {
    buffer: LogLine[] = [];
    forwardToConsole: boolean = true;
    forwardToSentry: boolean = true;
    ignoreRegexs: RegExp[] = [];
    logArchiveWorker: Worker = new LogArchiveWorker();

    log(level: LogLevel, msg: string, args?: any[], props?: logLineProps) {
        if (this.ignoreRegexs.filter(re => re.exec(msg)).length > 0) {
            return;
        }

        if (!props) {
            props = {};
        }
        if (level == LogLevel.Error) {
            props["stack"] = new Error().stack?.toString() || "";
        }

        const line: LogLine = {
            msg: msg,
            level: level,
            tsUtc: DateTime.utc().valueOf(),
            args: args,
            props: props,
        };
        this.buffer.push(line);

        this.logArchiveWorker.postMessage({
            source: "avos-beyond",
            logLine: JSON.stringify(line),
        });

        if (this.forwardToConsole) {
            logLevelProps[level].console()(
                DateTime.fromMillis(line.tsUtc).toFormat("yyyy-MM-dd HH:mm:ss.SSS"),
                ...argsForConsoleLog(line),
            );
        }
        else if (this.forwardToSentry && level >= LogLevel.Info) {
            // If we forward to console, Sentry will already pick it up. If we don't,
            // pass logs over to Sentry -- this will be a no-op if Sentry is not
            // configured.
            const breadcrumb: Breadcrumb = {
                category: "console",
                data: {
                    arguments: args?.map(stringifyArg),
                    logger: "console",
                },
                level: logLevelProps[level].sentry,
                message: msg,
            };
            addBreadcrumb(breadcrumb);
        }
    }

    dump() {
        console.group("log dump @ " + DateTime.now().toISO());
        for (const line of this.buffer) {
            console.info(
                "%s %c%s%c %s",
                tsFormat(line.tsUtc),
                logLevelProps[line.level].fmt[0],
                logLevelProps[line.level].name,
                logLevelProps[line.level].fmt[1],
                ...argsForConsoleLog(line),
            );
        }
        console.groupEnd();
    }

    configure(opts: LogOptions) {
        if (opts.forwardToConsole !== undefined) this.forwardToConsole = opts.forwardToConsole;
        if (opts.forwardToSentry !== undefined) this.forwardToSentry = opts.forwardToSentry;
        if (opts.ignoreRegexs !== undefined) this.ignoreRegexs = opts.ignoreRegexs;
    }

    clear() {
        this.buffer = [];
    }

    getLogBufferFormatted(): string[] {
        return formatLogLines(this.buffer);
    }
}

export type logFilter = (m?: any, ...p: any[]) => boolean;

class WithObject {
    private props: logProps = {};
    private filters: logFilter[] = [];

    constructor(props?: logProps) {
        this.props = props ?? {};
    }

    prop(key: string, value: any): WithObject {
        this.props[key] = value;
        return this;
    }

    filter(pred: logFilter): WithObject {
        this.filters.push(pred);
        return this;
    }

    checkFilters(m?: any, ...p: any[]): boolean {
        return this.filters.every(f => f(m, ...p));
    }

    logger(): LogObject {
        return {
            debug: (m?: any, ...p: any[]) =>
                this.checkFilters(m, ...p) && logger.log(LogLevel.Debug, m, p, this.props),
            info: (m?: any, ...p: any[]) =>
                this.checkFilters(m, ...p) && logger.log(LogLevel.Info, m, p, this.props),
            log: (m?: any, ...p: any[]) =>
                this.checkFilters(m, ...p) && logger.log(LogLevel.Info, m, p, this.props),
            warn: (m?: any, ...p: any[]) =>
                this.checkFilters(m, ...p) && logger.log(LogLevel.Warn, m, p, this.props),
            error: (m?: any, ...p: any[]) =>
                this.checkFilters(m, ...p) && logger.log(LogLevel.Error, m, p, this.props),
            with: (props?: logProps, filters?: logFilter[]) => {
                this.merge(props, filters);
                return this;
            },
        };
    }

    private merge(props?: logProps, filters?: logFilter[]) {
        if (props) {
            Object.entries(props).forEach(([k, v]) => this.prop(k, v));
        }
        if (filters) {
            filters.forEach(f => this.filter(f));
        }
    }
}

const logger = new BufferedLogger();

const Log: LogObject = {
    debug: (m?: any, ...p: any[]) => logger.log(LogLevel.Debug, m, p),
    info: (m?: any, ...p: any[]) => logger.log(LogLevel.Info, m, p),
    log: (m?: any, ...p: any[]) => logger.log(LogLevel.Info, m, p),
    warn: (m?: any, ...p: any[]) => logger.log(LogLevel.Warn, m, p),
    error: (m?: any, ...p: any[]) => logger.log(LogLevel.Error, m, p),
    with: (props?: logProps) => {
        return new WithObject(props);
    },
};

export function dumpLogs(_opts?: Record<string, never>) {
    logger.dump();
}

async function dumpArchivedLogs(_opts?: Record<string, never>) {
    console.group("archived logs dump @ " + DateTime.now().toISO());
    const archivedLogsFormatted = await getArchivedLogsFormatted();
    archivedLogsFormatted.forEach(line => console.info(line));
    console.groupEnd();
}

export function getLogBufferFormatted(): string[] {
    return logger.getLogBufferFormatted();
}

export async function getArchivedLogsFormatted() {
    const archivedLogLines: string[] = await getArchivedLogLines();
    return formatLogLines(archivedLogLines.map(l => JSON.parse(l)));
}

const respectIgnoreRegexs = (regexs: RegExp[]) => (l: LogFunc) => (m?: any, ...p: any[]) => {
    if (regexs.filter(re => re.exec(m)).length === 0) {
        l(m, ...p);
    }
};

export function configureLogs(opts: LogOptions) {
    if (opts.directConsoleLoggingOnly === true) {
        const withIgnore = respectIgnoreRegexs(opts.ignoreRegexs ?? []);
        Log.debug = withIgnore(console.debug);
        Log.info = withIgnore(console.info);
        Log.log = withIgnore(console.info);
        Log.warn = withIgnore(console.warn);
        Log.error = withIgnore(console.error);
    }
    else if (opts.directConsoleLoggingOnly === false) {
        Log.debug = (m?: any, ...p: any[]) => logger.log(LogLevel.Debug, m, p);
        Log.info = (m?: any, ...p: any[]) => logger.log(LogLevel.Info, m, p);
        Log.log = (m?: any, ...p: any[]) => logger.log(LogLevel.Info, m, p);
        Log.warn = (m?: any, ...p: any[]) => logger.log(LogLevel.Warn, m, p);
        Log.error = (m?: any, ...p: any[]) => logger.log(LogLevel.Error, m, p);
    }

    logger.configure(opts);
}

export function clearLogBuffer() {
    logger.clear();
}

// Export these to the global namespace on the browser. We want it to be possible
// to open up the developer tools and change logging settings or dump logs.
if (typeof window !== "undefined") {
    (window as any).dumpLogs = dumpLogs;
    (window as any).dumpArchivedLogs = dumpArchivedLogs;
    (window as any).configureLogs = configureLogs;
    (window as any).logAPI = Log;
}

export default Log;
