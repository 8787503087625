import { useCallback, useMemo } from "react";
import { DraftTarget } from "../domain/channels";
import { Mention, newSquadMention, newUserMention } from "../domain/mentions";
import { SquadOverview } from "../domain/squads";
import { UserOverview } from "../domain/users";
import { selectValidSquadsForMention, selectValidUserIdsForMention } from "../features/mentions";
import { selectUsers } from "../features/users";
import { AutoCompleteQuery } from "../hooks/useAutoCompleteQuery";
import useInterestedUsers from "../hooks/interest/useInterestedUsers";
import useSelectorArgs from "../hooks/useSelectorArgs";
import { useShallowEqualsMemo } from "../hooks/useShallowEquals";
import { squadNameForMention, userNameForMention } from "../misc/messageContent";
import { similaritySearch, similaritySort } from "../misc/similarStrings";
import { NumberRange } from "../misc/types";
import { partialComponent } from "../misc/utils";
import { AutoComplete, SuggestionContentProps } from "./gui/AutoComplete";
import Avatar from "./gui/Avatar";
import SensitiveText from "./gui/SensitiveText";

interface SquadMentionSuggestionProps {
    squad: SquadOverview;
}
function SquadMentionSuggestionContent(
    props: SquadMentionSuggestionProps & SuggestionContentProps,
): React.JSX.Element {
    const { squad } = props;
    return (
        <>
            <div className="u-truncate-auto">
                <em>
                    <SensitiveText>{squad.name}</SensitiveText>
                </em>
            </div>
            <div className="c-people-count">
                <div className="c-people-count__icon"></div>
                {squad.userIds.length}
            </div>
        </>
    );
}

interface UserMentionSuggestionProps {
    user: UserOverview;
}
function UserMentionSuggestionContent(
    props: UserMentionSuggestionProps & SuggestionContentProps,
): React.JSX.Element {
    const { user } = props;
    return (
        <>
            <Avatar userId={user.id} size="xs-small" />
            <div className="u-truncate-auto">
                <em>
                    <SensitiveText>{userNameForMention(user)}</SensitiveText>
                </em>
                &nbsp;&bull;&nbsp;
                <SensitiveText>{user.name}</SensitiveText>
            </div>
        </>
    );
}

export type MentionInsertFunc = (
    mentionText: string,
    mention: Mention,
    range: NumberRange,
) => void;

interface MentionAutoCompleteProps {
    mentionX: number;
    mentionY: number;
    insert: MentionInsertFunc;
    query: AutoCompleteQuery;
    draftTarget: DraftTarget;
    similarityThreshold?: number;
}

export const MentionAutoComplete = (props: MentionAutoCompleteProps): React.JSX.Element => {
    const { mentionX, mentionY, insert, query, draftTarget } = props;
    const similarityThreshold = props.similarityThreshold ?? -100000;

    const validSquads = useSelectorArgs(selectValidSquadsForMention, draftTarget);
    const validUserIds = useSelectorArgs(selectValidUserIdsForMention, draftTarget);
    const validUsers = useSelectorArgs(selectUsers, validUserIds);

    useInterestedUsers(validUserIds);

    const insertSquadMention = useCallback((squad: SquadOverview) => {
        insert(
            query.trigger + squadNameForMention(squad),
            newSquadMention(squad.id),
            query.range,
        );
    }, [insert, query]);

    const insertUserMention = useCallback((user: UserOverview) => {
        insert(
            query.trigger + userNameForMention(user),
            newUserMention(user.id),
            query.range,
        );
    }, [insert, query]);

    const suggest = useCallback(
        <E extends { name: string; nickname?: string; }>(entities: E[]) => {
            if (!query.text) {
                return entities
                    .sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
            }

            const searchText = (e: E) => `${e.nickname ?? ""} ${e.name}`;
            return entities
                .filter(e => similaritySearch(query.text, searchText(e), similarityThreshold))
                .sort((a, b) => similaritySort(query.text, a.name, b.name));
        },
        [query.text, similarityThreshold],
    );

    const suggestedSquads = useShallowEqualsMemo(
        () => suggest(validSquads),
        [suggest, validSquads],
    );
    const suggestionsForSquads = useShallowEqualsMemo(
        () =>
            suggestedSquads.map(squad => ({
                key: squad.id,
                className: "c-suggestion--squad",
                Content: partialComponent<SquadMentionSuggestionProps, SuggestionContentProps>(
                    SquadMentionSuggestionContent,
                    { squad },
                ),
                onAdd: () => insertSquadMention(squad),
            })),
        [suggestedSquads, insertSquadMention],
    );

    const suggestedUsers = useShallowEqualsMemo(
        () => suggest(validUsers),
        [suggest, validUsers],
    );
    const suggestionsForUsers = useShallowEqualsMemo(
        () =>
            suggestedUsers.map(user => ({
                key: user.id,
                className: "c-suggestion--human",
                Content: partialComponent<UserMentionSuggestionProps, SuggestionContentProps>(
                    UserMentionSuggestionContent,
                    { user },
                ),
                onAdd: () => insertUserMention(user),
            })),
        [suggestedUsers, insertUserMention],
    );

    const suggestionGroups = useShallowEqualsMemo(
        () => [suggestionsForSquads, suggestionsForUsers].filter(g => g.length > 0),
        [suggestionsForSquads, suggestionsForUsers],
    );

    const suggestionCount = useMemo(
        () => suggestionGroups.flat().length,
        [suggestionGroups],
    );

    return (
        <>
            {(suggestionCount > 0) && (
                <AutoComplete
                    anchorX={mentionX}
                    anchorY={mentionY}
                    verticalAnchor="bottom"
                    horizontalClampBehaviour="clamp"
                    suggestionGroups={suggestionGroups}
                />
            )}
        </>
    );
};
