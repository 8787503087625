import * as Sentry from "@sentry/react";
import * as ReactDOM from "react-dom/client";
import { AuthProvider, AuthProviderProps } from "react-oidc-context";
import { Provider } from "react-redux";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

import { setTracingEnabled } from "./api/trace";
import { oidcConfig } from "./components/AuthController";
import Session from "./components/Session";
import { getHydrationData } from "./persist/persist";
import "./sass/main.scss";
import { setupStore } from "./store/setup";
import log, { configureLogs } from "./misc/log";
import StrictModeWrapper from "./misc/strictModeWrapper";
import PostHogMeta from "./components/PostHogMeta";
import { setupTypeExtensions } from "./misc/primatives";
import { FeatureFlagId } from "../gen/features/features";
import { capacitorSetup, isNativePlatform } from "./misc/capacitor";
import { getEnvironmentConfig, isDevEnv } from "./misc/environment";
import { getLocalPreferredUserId } from "./features/auth";
import { getRouter } from "./misc/router";
import { setupRoutes } from "./components/Routes";

/**
 * Attempt to find the URL that we should be navigating to, as put into the
 * `url_state` field on `SigninRedirectArgs`.
 */
const parseUrlState = (urlState: string | undefined): string | undefined => {
    if (!urlState) return;

    try {
        const { returnUrl } = JSON.parse(urlState);
        if (returnUrl) return returnUrl;
    }
    catch (err) {
        log.error("Error parsing auth-flow state", err);
        return;
    }
};

// oidc-client-ts writes to local storage by itself (as configured in the
// `oidcConfig()`)
const authProviderProps: AuthProviderProps = {
    ...oidcConfig(),
    onSigninCallback: user => {
        const url = parseUrlState(user?.url_state);
        const currentUrl = getRouter().state.location.pathname;
        getRouter().navigate(url || currentUrl, { replace: true, state: {} });

        // Handle the actual authentication via an event on the
        // `UserManager` object.
    },
};

// Disabled by default on localhost to avoid a lot of noise in Sentry, but you can hack this to `true`
// to test changes to Sentry stuff locally.
const sentryEnabled = !isDevEnv;
let sentryConfig: SentryConfig | undefined;
// The Sentry SDK annoyingly doesn't export a lot of its types, hence the dance here to try
// and type this array sanely.
type SentryReplayIntegration = ReturnType<typeof Sentry.replayIntegration>;
let sentryReplayIntegration: SentryReplayIntegration | undefined;

interface SentryConfig {
    replay?: SentryReplayOptions;
}

interface SentryReplayOptions {
    sessionSampleRate: number;
    errorSampleRate: number;
}

function initSentry(cfg?: SentryConfig) {
    if (!sentryEnabled) return;

    // Sentry is proxied in real clusters. But we can just talk to it directly in development.
    const targetConfig = getEnvironmentConfig();
    const sentryDsn =
        `https://adbef5c8f9f2024d67c415643ff732ab@${targetConfig.sentryHost}/4507746791456849`;
    const integrations: SentryReplayIntegration[] = [];

    // Replay is a horror; it can only be instantiated one time. So we need to track an instance
    // of it. The reality is, once it's been loaded once it's there forever and the only thing
    // we really change is the sampling rate.
    if (cfg?.replay) {
        if (!sentryReplayIntegration) {
            sentryReplayIntegration = Sentry.replayIntegration();
        }
        integrations.push(sentryReplayIntegration);
    }

    Sentry.init({
        dsn: sentryDsn,
        environment: window.location.hostname,
        integrations: integrations,
        replaysSessionSampleRate: cfg?.replay?.sessionSampleRate,
        replaysOnErrorSampleRate: cfg?.replay?.errorSampleRate,
    });
    sentryConfig = cfg;
    log.info(`Sentry initialised ${cfg?.replay && "with replay integration" || ""}`);
}

function reinitSentry(cfg?: SentryConfig) {
    if (!sentryEnabled) return;

    if (JSON.stringify(cfg) === JSON.stringify(sentryConfig)) {
        return;
    }

    log.info("Sentry re-init requested with change in config", cfg);

    // Asynchronously close the old client
    new Promise((reject, resolve) => {
        Sentry.getClient()?.close().then(reject, resolve);
    }).catch(err => {
        log.error("Error closing Sentry client", err);
    }).finally(() => {
        // Start a new client with replay enabled
        initSentry(cfg);
    });
}

async function main() {
    setupTypeExtensions();

    configureLogs({
        forwardToConsole: isDevEnv,
        forwardToSentry: true,
    });

    if (isNativePlatform) {
        capacitorSetup();
    }

    log.info("Frontend startup", __BEYOND_FRONTEND_VERSION__);
    log.info(`Location changed: ${window.location} (initial load)`);

    initSentry();

    setupRoutes();

    const targetConfig = getEnvironmentConfig();

    // The project hard-coded here is our "bo-nd.dev" project, which also seems to be a reasonable
    // thing to use for local development. The expectation is that once we have a production
    // environment we'll selectively pick that project for production only, so it won't contain
    // dev/test data like this will.

    // `posthog.init` is annotated to return "PostHog | void" but never returns void in reality.
    const posthogClient = posthog.init(
        "phc_Weej2JxH1WnJOw2haIvtTx5SclGgijPcPsPjLQxsoVC",
        {
            api_host: targetConfig.posthogHost,
            autocapture: false,
            enable_heatmaps: false,
        },
    )!;

    posthog.register({
        frontend_version: __BEYOND_FRONTEND_VERSION__,
    });

    posthogClient.onFeatureFlags(flags => {
        log.info("Feature flags received", flags);

        // We type these strings to enforce that we're referring to a real feature flag.
        const posthogAutocapture: FeatureFlagId = "posthog-autocapture";
        if (flags.includes(posthogAutocapture)) {
            posthogClient.set_config({
                autocapture: true,
                enable_heatmaps: true,
            });
        }

        const frontendTracing: FeatureFlagId = "frontend-tracing";
        setTracingEnabled(flags.includes(frontendTracing));

        const sentryReplay: FeatureFlagId = "sentry-replay";
        if (flags.includes(sentryReplay)) {
            let sessionSampleRate = 0.01;
            let errorSampleRate = 0.1;

            const payload = posthogClient.getFeatureFlagPayload(sentryReplay);
            if (payload && typeof payload === "object") {
                const tpayload = payload as any;
                sessionSampleRate =
                    typeof tpayload.sessionSampleRate === "number" && tpayload.sessionSampleRate ||
                    0;
                errorSampleRate =
                    typeof tpayload.errorSampleRate === "number" && tpayload.errorSampleRate || 0;
            }

            reinitSentry({ replay: { sessionSampleRate, errorSampleRate } });
        }
        else {
            reinitSentry();
        }
    });

    const userId = getLocalPreferredUserId();
    const data = await getHydrationData(userId);
    // log.info(`Hydration data`, data);

    const store = setupStore(data, { persist: true });

    const rootElem = document.getElementById("root");
    if (!rootElem) {
        return;
    }

    const root = ReactDOM.createRoot(rootElem);

    root.render(
        <StrictModeWrapper>
            <AuthProvider {...authProviderProps}>
                <Provider store={store}>
                    <PostHogProvider client={posthogClient}>
                        <PostHogMeta />
                        <Session />
                    </PostHogProvider>
                </Provider>
            </AuthProvider>
        </StrictModeWrapper>,
    );
}

main();
