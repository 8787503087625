import { BlobDimensions } from "../domain/blobs";
import { Optional } from "./types";
import { replaceAll } from "./utils";

const inlinedImages: Record<string, boolean> = {
    "image/avif": false,
    "image/bmp": true,
    "image/gif": false,
    "image/jpeg": true,
    "image/jpg": true,
    "image/png": true,
    "image/svg+xml": true,
    "image/tiff": false,
    "image/webp": true,
    "image/x-icon": true,
};

/** Determine if we should inline a file in the message view.
 * @param mimeType the MIME type of the image
 * @return whether we should attempt to inline the file
 */
export const isInlinableImage = (mimeType: string): boolean => !!inlinedImages[mimeType];

/** Attempt to get the inlinable dimensions of a file.
 *
 * @param file a `File` handle to the image on disk
 * @return a promise, resolving to either `undefined` (files we aren't
 * attempting to inline) or the image's dimensions; or rejecting with an
 * `Error` with a suitable description
 */
export const getAttachmentDimensions = (file: File): Promise<Optional<BlobDimensions>> =>
    new Promise((resolve, reject) => {
        if (isInlinableImage(file.type)) {
            const img = new Image();
            img.onload = () => {
                const dimensions = {
                    width: img.width,
                    height: img.height,
                };
                if (dimensions.width == 0 || dimensions.height == 0) {
                    reject(
                        new Error(
                            `getAttachmentDimensions: invalid image dimensions: ${dimensions}`,
                        ),
                    );
                }
                else {
                    resolve(dimensions);
                }

                URL.revokeObjectURL(img.src);
            };
            img.onerror = err => {
                reject(new Error(`getAttachmentDimensions: image onerror called: ${err}`));

                URL.revokeObjectURL(img.src);
            };

            img.src = URL.createObjectURL(file);
        }
        else {
            resolve(undefined);
        }
    });

/**  Attempt to get the inlinable dimensions of a file.
 *
 * Will not throw an exception - instead will return `undefined`.
 *
 * @param file a `File` handle to the image on disk
 * @return a promise, resolving to either the images dimensions, or `undefined`
 * if we couldn't determine them.
 */
export const getAttachmentDimensionsNoThrow = (file: File) =>
    getAttachmentDimensions(file).catch(_ => undefined);

/** Create a sanitised version of a file's name.
 *
 * To be used in the `Content-Disposition` header on the upload request, the
 * filename must be a valid URI, without quotes or commas. Do that here.
 *
 * @param filename the name of the file on disk
 * @return a suitably sanitised version of the filename
 */
export const fileNamePreparer = (filename: string): string => {
    const removedQuotes = replaceAll(filename, `"`, `_`);
    const removedCommas = replaceAll(removedQuotes, `,`, `_`);
    return encodeURIComponent(removedCommas);
};
