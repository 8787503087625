import { Capacitor } from "@capacitor/core";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { nativeTargets } from "./environment";

export const isNativePlatform = Capacitor.isNativePlatform();
const nativePlatform = Capacitor.getPlatform();
export const isNativeAndroidPlatform = nativePlatform === "android";
export const isNativeIosPlatform = nativePlatform === "ios";
// isNativeAndroidPlatform || isNativeIosPlatform <=> isNativePlatform

export const nativePlatformTargetKey = "nativePlatformTarget";
export const getNativeTarget = () => {
    if (!isNativePlatform) {
        return undefined;
    }

    return localStorage.getItem(nativePlatformTargetKey) ?? nativeTargets.bondDev;
};

export const capacitorSetup = () => {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
        const urlPrefix = "io.avos.beyond.app://app";

        // Example url: io.avos.beyond.app://app/bond
        if (!event.url.startsWith(urlPrefix)) {
            window.location.href = urlPrefix + "/bond";
        }
        else {
            const slug = event.url.replace(urlPrefix, "");
            if (slug) {
                window.location.href = window.location.origin + slug;
            }
        }
        // If no match, do nothing - let regular routing logic take over
    });
};
