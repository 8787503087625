import { DependencyList } from "react";
import { createSelectorCreator, lruMemoize } from "@reduxjs/toolkit";

import shallowEqual from "../misc/shallowEqual";
import { arraysAreEqual } from "../misc/primatives";
import type { RootState } from "../store/types";
import { UserOverview } from "../domain/users";
import { createAppSelector } from "../store/redux";
import * as d from "../domain/domain";
import { weakMapMemoize } from "./weakMapMemoize";

export const shallowArrayEqualSelectorCreator = createSelectorCreator(lruMemoize, {
    resultEqualityCheck: arraysAreEqual,
});

export const memoizeOptions = {
    // lruMemoize is a bad choice if you have more consumers than the
    // configured number of slots in the LRU. For cases with unbounded
    // consumers, don't try to guess a number of slots. Instead, use a
    // weakMapMemoize. This will use a map `input values` => `selected value`,
    // with a weak reference to the input values, giving unbounded caching
    // without causing memory leaks. It's possible to clear the cache, but
    // we don't bother here (yet).
    weakMap: {
        memoize: weakMapMemoize,
    },
    weakMapShallow: {
        memoize: weakMapMemoize,
        memoizeOptions: {
            resultEqualityCheck: shallowEqual,
        },
    },
    lruShallow: {
        memoize: lruMemoize,
        memoizeOptions: {
            resultEqualityCheck: shallowEqual,
        },
    },
};

export type AnySelector<Deps extends DependencyList = DependencyList, R = any> = (
    state: RootState,
    ...deps: Deps
) => R;

export type SelectorDeps<F> = F extends AnySelector<infer Deps, any> ? Deps
    : never;

export type SelectorPair<Deps extends DependencyList, R> = [
    first: AnySelector<Deps, R>,
    second: AnySelector<Deps, R>,
];

// Take a more general selector type and forget all non-function information about it.
// `create(App)Selector` typing isn't quite kosher, I think, so we need to forget
// some if its internal state annotation.
type FunctionType<
    S extends AnySelector<Deps, R>,
    Deps extends DependencyList,
    R = ReturnType<S>,
> = S extends AnySelector<Deps, R> ? AnySelector<Deps, R> : never;

export const createSelectorPair = <
    S extends AnySelector<Deps, R>,
    Deps extends DependencyList = SelectorDeps<S>,
    R = ReturnType<S>,
>(
    first: FunctionType<S, Deps, R>,
    second: FunctionType<S, Deps, R>,
): SelectorPair<Deps, R> => {
    return [first, second];
};

export const mapUsersToIds = (
    f: (state: RootState, ...deps: DependencyList) => Array<UserOverview>, // this does not work, and I cannot figure out why. The only way to get this to not complain is to type deps as any...
): (state: RootState, ...deps: DependencyList) => Array<d.UserId> =>
    createAppSelector([f], r => r.map(u => u.id), memoizeOptions.weakMapShallow);
