import { useCallback } from "react";
import {
    fetchCurrentPersonUserRefs,
    setLocalPreferredUserId,
    selectAlternativeLogins,
    selectCurrentUserId,
} from "../features/auth";
import { selectOrg } from "../features/squads";
import { useAppDispatch, useAppSelector } from "../store/redux";
import { useConnectedEffect } from "../hooks/useConnectedEffect";
import useSelectorArgs from "../hooks/useSelectorArgs";
import { UserDefinition } from "../domain/users";
import useInterestedOrgs from "../hooks/interest/useInterestedOrgs";
import classNames from "classnames";

export function OrgSelectionDropdown(): React.JSX.Element {
    const dispatch = useAppDispatch();

    const alternativeLoginUsers = useAppSelector(selectAlternativeLogins);

    useConnectedEffect(() => {
        if (alternativeLoginUsers.length === 0) {
            dispatch(fetchCurrentPersonUserRefs());
        }
    }, [dispatch, alternativeLoginUsers]);

    return (
        <div>
            <div className="cp-dropdown-settings__section has-links">
                <div className="cp-dropdown-title">Switch organization</div>
                {alternativeLoginUsers.map(user => <OrgEntry user={user} key={user.userId} />)}
            </div>
        </div>
    );
}

function OrgEntry({ user }: { user: UserDefinition; }): React.JSX.Element {
    const currentUserId = useAppSelector(selectCurrentUserId);

    const org = useSelectorArgs(selectOrg, user.orgId);
    useInterestedOrgs(user.orgId);

    const chooseUser = useCallback(async (userDef: UserDefinition) => {
        if (userDef.userId === currentUserId) {
            return;
        }

        setLocalPreferredUserId(userDef.userId);
        // As we are changing the preferred user ID, refresh the page to reload
        // the IndexedDB and preserve websocket connection accuracy.
        window.location.reload();
    }, [currentUserId]);

    if (!org || !currentUserId) {
        return <></>;
    }

    const orgName = org.personal ? "My Account" : org.name;
    const classes = classNames({
        "is-disabled": user.userId === currentUserId,
    });

    return (
        <a
            key={user.userId}
            className={classes}
            onClick={() => chooseUser(user)}
        >
            {orgName}
        </a>
    );
}
