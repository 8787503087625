import * as d from "../../domain/domain";
import { registerInterest, unregisterInterest } from "../../features/calls";
import useRegisterInterest from "./useRegisterInterest";

export default function useInterestedCall(callId?: d.CallId) {
    useRegisterInterest({
        ids: callId ? [callId] : [],
        registerInterest,
        unregisterInterest,
    });
}
