import log from "../misc/log";
import {
    consumeFromQueue,
    dismissNotification,
    selectNotificationQueue,
    streamNotifications,
    toDisplayNotification,
} from "../features/notifications";
import { useAppDispatch, useAppSelector } from "../store/redux";
import useStreamDispatch from "../hooks/useStreamDispatch";
import { NotificationId } from "../domain/domain";
import React, { useCallback, useEffect, useState } from "react";
import { WebNotification } from "../domain/notifications";
import useSelectorArgs from "../hooks/useSelectorArgs";
import { useNavigate } from "react-router-dom";
import { isMobileBrowser } from "../misc/mobile";
import { selectAvatarPathForNotifs } from "../features/users";

const defaultIcon = "/assets/favicon-128.png";

// We attach some extra information to notifications so we can clean them up easily
type ExtendedNotification = Notification & {
    avosId?: NotificationId;
    avosClickCallback?: (e: Event) => Promise<void>;
    avosCloseCallback?: (e: Event) => Promise<void>;
};

// We keep a lookup of notification ID to native notification so we can locate a
// notification to remove it when the server tells us it is done with.
// This is a bit awkward because in many cases we won't get any close callback
// from these, meaning we slowly leak memory here.
const natives = new Map<NotificationId, ExtendedNotification>();

function dismiss(en: ExtendedNotification) {
    log.info("Notification: dismiss", en.avosId);

    if (en.avosCloseCallback) en.removeEventListener("close", en.avosCloseCallback);
    if (en.avosClickCallback) en.removeEventListener("click", en.avosClickCallback);

    en.close();

    if (en.avosId) natives.delete(en.avosId);
}

function DisplayNotification({ notif }: { notif: WebNotification; }): React.JSX.Element {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const displayNotif = toDisplayNotification(notif);
    const avatarPath = useSelectorArgs(selectAvatarPathForNotifs, displayNotif.iconUserId);

    const onClose = useCallback(async () => {
        if (!displayNotif) return;

        log.info("Notification: close", displayNotif.id);

        dispatch(dismissNotification(displayNotif.id));

        natives.delete(displayNotif.id);
    }, [displayNotif, dispatch]);

    const onClick = useCallback(async (e: Event) => {
        const native = e.target as ExtendedNotification;
        if (!native) return;

        dismiss(native);

        dispatch(dismissNotification(displayNotif.id));

        // Focus the tab and run the notification thunk, navigating to the result
        parent.focus();
        if (!displayNotif?.thunk) return;

        const dest = await dispatch(displayNotif.thunk).unwrap();
        if (dest !== null) {
            log.info(`Notification: click ${displayNotif.id}, navigating to ${dest}`);
            navigate(dest);
        }
    }, [displayNotif, dispatch, navigate]);

    useEffect(() => {
        if (!notif.id) return;
        if (!displayNotif) return;

        // Ensure we only create the notification once. This shouldn't really fire, but
        // better safe than sorry, especially in the presence of React Strict Mode.
        if (natives.get(notif.id)) return;

        const native = new Notification(displayNotif.heading, {
            body: displayNotif.content,
            // MDN suggests this is valid:
            // https://developer.mozilla.org/en-US/docs/Web/API/Notification/Notification#timestamp
            // But typescript have removed it from their interface:
            // https://github.com/microsoft/TypeScript/pull/57027
            // @ts-expect-error Why did typescript remove this?
            timestamp: displayNotif.timestamp,
            tag: displayNotif.id,
            icon: avatarPath || defaultIcon,
            badge: defaultIcon,
            requireInteraction: displayNotif.sticky,
        }) as ExtendedNotification;
        natives.set(notif.id, native);
        log.info(
            `Notification: displayed ${displayNotif.id} ts ${new Date(
                displayNotif.timestamp,
            )} now ${natives.size} items in map`,
        );

        native.avosId = notif.id;
        native.avosClickCallback = onClick;
        native.avosCloseCallback = onClose;

        native.addEventListener("click", onClick);
        native.addEventListener("close", onClose);

        dispatch(consumeFromQueue());
    }, [notif, displayNotif, avatarPath, dispatch, onClick, onClose]);

    return <></>;
}

function DismissNotification({ id }: { id: NotificationId; }): React.JSX.Element {
    const dispatch = useAppDispatch();

    useEffect(() => {
        const native = natives.get(id);
        if (!native) return;

        dismiss(native);

        dispatch(consumeFromQueue());
    }, [id, dispatch]);

    return <></>;
}

async function checkForPermissions(): Promise<boolean> {
    if (!("Notification" in window)) return false;
    if (isMobileBrowser()) return false;

    if (Notification.permission === "default") {
        log.info("Requesting browser notification permission");
        await Notification.requestPermission();
    }

    return Notification.permission === "granted";
}

// Consumes notification change events one at a time, until the queue is drained.
function NotificationFeed(): React.JSX.Element {
    useStreamDispatch(streamNotifications, []);
    const nextNotificationChange = useAppSelector(selectNotificationQueue);

    const haveNotification = nextNotificationChange !== undefined;

    const [havePermissions, setHavePermissions] = useState<boolean | undefined>(undefined);

    // This will request permissions at the point of having the first
    // notification we want to show. We may wish to delay this until some
    // "we're going to ask for permissions now" intro dialogue.
    useEffect(() => {
        if (haveNotification && havePermissions === undefined) {
            checkForPermissions().then(setHavePermissions);
        }
    }, [havePermissions, setHavePermissions, haveNotification]);

    if (!havePermissions) return <></>;

    switch (nextNotificationChange?.type) {
        case "add":
            return <DisplayNotification notif={nextNotificationChange.notification} />;
        case "remove":
            return <DismissNotification id={nextNotificationChange.id} />;
        default:
            return <></>;
    }
}

export default NotificationFeed;
