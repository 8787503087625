import { useAppDispatch, useAppSelector } from "../store/redux";
import { observeCurrentUserBondPreviews } from "../features/bonds";

import { useConnectedEffect } from "../hooks/useConnectedEffect";
import { selectCurrentSquadIds } from "../features/squads";

export default function useObserveCurrentSquads() {
    const dispatch = useAppDispatch();

    const currentUserSquads = useAppSelector(selectCurrentSquadIds);

    useConnectedEffect(() => {
        const observeStreams = currentUserSquads.map(squadId => {
            return dispatch(observeCurrentUserBondPreviews({ squadId }));
        });
        return () => {
            observeStreams.forEach(s => s.abort());
        };
    }, [dispatch, currentUserSquads]);
}
