import { useEffect } from "react";

import { useAppDispatch } from "../../store/redux";
import { useShallowEquals } from "../useShallowEquals";
import { PayloadAction } from "@reduxjs/toolkit";

interface UseRegisterInterestProps<T> {
    ids: T[];
    registerInterest: (ids: T[]) => PayloadAction<T[]>;
    unregisterInterest: (ids: T[]) => PayloadAction<T[]>;
}

/** @function Dispatch Redux reducers to register/unregister interest in a resource,
 * based on the ids passed into the props.
 * Note we first shallow equal check the ids before registering interest in them.
 */
export default function useRegisterInterest<T>(props: UseRegisterInterestProps<T>) {
    const { ids, registerInterest, unregisterInterest } = props;

    const dispatch = useAppDispatch();

    const memoedIds = useShallowEquals(ids);

    useEffect(() => {
        if (memoedIds.length === 0) {
            return;
        }

        dispatch(registerInterest(memoedIds));

        return () => {
            dispatch(unregisterInterest(memoedIds));
        };
    }, [dispatch, memoedIds, registerInterest, unregisterInterest]);
}

export type OneNoneOrMany<T> = T[] | T | undefined;

export function oneNoneOrManyToArray<T>(items: OneNoneOrMany<T>): T[] {
    if (items === undefined) {
        return [];
    }
    if (Array.isArray(items)) {
        return items;
    }
    return [items];
}
