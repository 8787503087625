import { useIdleTimer, PresenceType } from "react-idle-timer";
import { useAppDispatch, useAppSelector } from "../store/redux";
import { selectIdleSubscribers, setIdleSince, setNotIdle } from "../features/meta";
import { useCallback } from "react";
import { DateTime, Duration } from "luxon";
import log from "../misc/log";

export default function IdleTracker(): React.JSX.Element {
    const dispatch = useAppDispatch();
    const idleTimeouts = useAppSelector(selectIdleSubscribers);
    const timeout = Math.min(
        ...Object.values(idleTimeouts).map(x => Duration.fromDurationLike(x!).valueOf()),
        15 * 60 * 1000,
    );

    const callback = useCallback((p: PresenceType) => {
        if (p.type === "idle") {
            const idleSince = DateTime.now().minus(timeout);
            log.info("User idle since", idleSince.toISO());
            dispatch(setIdleSince(idleSince.toMillis()));
        }
        else {
            log.info("User no longer idle");
            dispatch(setNotIdle());
        }
    }, [dispatch, timeout]);

    useIdleTimer({
        timeout: timeout,
        onPresenceChange: callback,
    });

    return <></>;
}
