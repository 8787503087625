import { useEffect, useState } from "react";

interface WindowSize {
    width: number;
    height: number;
}

const getWindowSize = (): WindowSize => ({
    width: window.innerWidth,
    height: window.innerHeight,
});

export const useWindowSize = (): WindowSize => {
    const [size, setSize] = useState(getWindowSize());

    useEffect(() => {
        const check = () => setSize(getWindowSize());

        window.addEventListener("resize", check);
        return () => window.removeEventListener("resize", check);
    }, []);

    return size;
};

export default useWindowSize;
