import Avatar from "./gui/Avatar";
import * as d from "../domain/domain";
import {
    selectBondById,
    selectBondContemporaries,
    selectSortedBondLiveParticipantIdsPair,
    selectSortedFollowerIdsPair,
} from "../features/bonds";
import useInterestedUsers from "../hooks/interest/useInterestedUsers";
import useFreshBondObservers from "../hooks/useFreshBondObservers";
import { useShallowEqualsMemo } from "../hooks/useShallowEquals";
import useSelectorArgs from "../hooks/useSelectorArgs";
import {
    selectSortedUserIdsWithUnreadPair,
    selectUserIdSetWithMentions,
    selectUserIdSetWithUnread,
} from "../features/channels";
import { removeDuplicates } from "../misc/primatives";
import useInterestedCall from "../hooks/interest/useInterestedCall";
import useSortedUsers from "../hooks/useSortedUsers";

interface BondCardAvatarsProps {
    bondId: d.BondId;
}

function BondCardAvatars(props: BondCardAvatarsProps): React.JSX.Element {
    const { bondId } = props;

    const overview = useSelectorArgs(selectBondById, bondId);
    const channelId = overview?.channelId;

    const contemporarySet = useSelectorArgs(selectBondContemporaries, bondId);

    const userSetWithUnread = useSelectorArgs(selectUserIdSetWithUnread, channelId);
    const userSetWithMentions = useSelectorArgs(selectUserIdSetWithMentions, channelId);
    const { ids: observerIds, idSet: observersIdSet } = useFreshBondObservers(bondId);

    const liveParticipantIds = useSortedUsers(selectSortedBondLiveParticipantIdsPair, bondId);
    const liveParticipantIdSet = useShallowEqualsMemo(() => liveParticipantIds.toSet(), [
        liveParticipantIds,
    ]);

    const liveCallId = overview?.liveCallIds[0];

    const senderIds = useSortedUsers(selectSortedUserIdsWithUnreadPair, channelId);
    const sortedFollowerIds = useSortedUsers(selectSortedFollowerIdsPair, bondId);

    const previewUsers = useShallowEqualsMemo(() =>
        removeDuplicates(
            liveParticipantIds,
            observerIds,
            senderIds,
            sortedFollowerIds,
        ), [
        liveParticipantIds,
        observerIds,
        senderIds,
        sortedFollowerIds,
    ]);

    useInterestedUsers(previewUsers);

    useInterestedCall(liveCallId);

    const extraClass = liveCallId ? "c-humans--centered" : "";

    return (
        <div className={`c-humans ${extraClass}`}>
            {previewUsers.map(cid => (
                <Avatar
                    key={cid}
                    userId={cid}
                    context={{
                        showBondPresence: true,
                    }}
                    modifiers={{
                        observingBond: observersIdSet.has(cid) || liveParticipantIdSet.has(cid),
                        contributed: userSetWithUnread.has(cid) ||
                            liveParticipantIdSet.has(cid),
                        mentionedCurrentUser: userSetWithMentions.has(cid),
                        contemporary: contemporarySet?.has(cid),
                    }}
                />
            ))}
        </div>
    );
}

export default BondCardAvatars;
