import * as d from "../../domain/domain";
import { registerSquadInterest, unregisterSquadInterest } from "../../features/squads";
import useRegisterInterest, { OneNoneOrMany, oneNoneOrManyToArray } from "./useRegisterInterest";

export default function useInterestedSquads(squadIds: OneNoneOrMany<d.SquadId>) {
    useRegisterInterest({
        ids: oneNoneOrManyToArray(squadIds),
        registerInterest: registerSquadInterest,
        unregisterInterest: unregisterSquadInterest,
    });
}
