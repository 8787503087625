import React from "react";

export const SystemMessage = (props: { message: string; }): React.JSX.Element => (
    <div className="c-bond-status">
        <div className="c-bond-status__message">
            <div className="c-logo c-logo--status">Bond</div>
            {props.message}
        </div>
    </div>
);
