import { AsyncThunkAction } from "@reduxjs/toolkit";
import { DependencyList } from "react";

import { useAppDispatch } from "../store/redux";
import { useConnectedEffect } from "./useConnectedEffect";
import type { AppDispatch, AppAsyncThunkConfig } from "../store/types";

type streamingAction = AsyncThunkAction<unknown, unknown, AppAsyncThunkConfig>;
type conditionalStreamingAction = streamingAction | undefined | false;

const streamDispatch = (
    dispatch: AppDispatch,
    action: streamingAction,
): () => void => {
    const stream = dispatch(action);
    return () => {
        stream.abort();
    };
};

const useStreamDispatch = (
    actionCreator: () => conditionalStreamingAction,
    deps: DependencyList,
) => {
    const dispatch = useAppDispatch();

    useConnectedEffect(() => {
        const action = actionCreator();
        if (!action) return;

        return streamDispatch(dispatch, action);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps, dispatch]);
};

export default useStreamDispatch;
