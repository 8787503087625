import * as d from "./domain";
import * as domain_pb from "../../gen/proto/domain/domain_pb";
import { Optional } from "../misc/types";
import { Track, isTrackActive } from "./session";

export interface UserObservation {
    userId: d.UserId;
    current: boolean;
    startedAt?: d.Timestamp;
    endedAt?: d.Timestamp;
}

export enum PresenceMode {
    Unknown = "unknown",
    Typing = "typing",
    Speaking = "speaking",
    Videoing = "video-calling",
    ScreenSharing = "screen-sharing",
}

// The presence modes that are visible in a user's Avatar name pill.
// NB: Does NOT include ScreenSharing, because that is visible in the name pill for the *display tile*.
export const visibleUserPresenceModes = [
    PresenceMode.Speaking,
    PresenceMode.Typing,
];
export const isVisibleUserPresenceMode = (mode: PresenceMode) =>
    visibleUserPresenceModes.includes(mode);

export const livePresenceModes = [
    PresenceMode.ScreenSharing,
    PresenceMode.Speaking,
    PresenceMode.Videoing,
];
export const isLivePresence = (mode: PresenceMode) => livePresenceModes.includes(mode);
export const hasLivePresence = (modes?: PresenceMode[]) => modes?.some(isLivePresence) ?? false;

export const getDominantPresenceMode = (modes: PresenceMode[]): Optional<PresenceMode> => {
    if (modes.includes(PresenceMode.ScreenSharing)) {
        return PresenceMode.ScreenSharing;
    }
    if (modes.includes(PresenceMode.Videoing)) {
        return PresenceMode.Videoing;
    }
    if (modes.includes(PresenceMode.Speaking)) {
        return PresenceMode.Speaking;
    }
    if (modes.includes(PresenceMode.Typing)) {
        return PresenceMode.Typing;
    }
};

export function translatePresenceMode(mode: domain_pb.PresenceMode): PresenceMode | undefined {
    switch (mode) {
        case domain_pb.PresenceMode.SPEAKING:
            return PresenceMode.Speaking;
        case domain_pb.PresenceMode.TYPING:
            return PresenceMode.Typing;
        case domain_pb.PresenceMode.VIDEOING:
            return PresenceMode.Videoing;
        case domain_pb.PresenceMode.SCREEN_SHARING:
            return PresenceMode.ScreenSharing;
        case domain_pb.PresenceMode.UNSPECIFIED:
            return undefined;
        default:
            return undefined;
    }
}

export function presenceModesFromTracks(
    audioTrack?: Track,
    videoTrack?: Track,
    isDisplay?: boolean,
) {
    const modes: PresenceMode[] = [];

    // TODO: voice detection (see beyond#365)
    if (isTrackActive(audioTrack)) {
        modes.push(PresenceMode.Speaking);
    }
    if (isTrackActive(videoTrack)) {
        modes.push(
            isDisplay ? PresenceMode.ScreenSharing : PresenceMode.Videoing,
        );
    }

    return modes;
}
