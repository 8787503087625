import queue from "../ds/queue";
import { isClearingAuth } from "../features/auth";
import { selectIdbUpgradeRequired } from "../features/meta";
import log from "../misc/log";
import { Optional, TypedKeys } from "../misc/types";
import { separateDiscriminatedUnion } from "../misc/utils";

import { closeConnection, idb, idbCommit, purge as idbPurge } from "./idb";
import { getPersistorMap } from "./map";
import { storageCommit, storageRead, storageRemove } from "./storage";
import type {
    AnyBrowserStoreUpdate,
    AnyIdbStoreUpdate,
    PersistenceMetadata,
    PersistenceUpdate,
} from "./types";
import { isIdbStoreUpdate } from "./updates";

import type { AppStartListening } from "../store/middleware";
import type { PersistorMap, RootState } from "../store/types";
import type { UserId } from "../domain/domain";
import { isBrowserStore, storeIsValidForVersion } from "./store";

// *** WARNING ***
// Slices should not need to import this module.
// Instead, grab the types from `./types`.

const logPersistenceUpdates = false;
type StorePersistenceData = [name: keyof RootState, update: PersistenceUpdate[]][];

const persistMetadata: PersistenceMetadata = {
    /** Updating this value allows new migration code to run, which also allows you
     * to run a migration over non-IndexedDB stored values.
     */
    currentDBVersion: 17,
    /** The oldest `currentDBVersion` (as stored as the IndexedDB version by a
     * browser) for which our migration process is not simply "drop all persisted
     * data".
     */
    oldestMigratableVersion: 15,
};

/** Listener middleware for persisting application data.
 */
export const startPersisting = (
    dbUser: Optional<UserId>,
    startListening: AppStartListening,
    dbUpgradeBlockingAction: () => void,
) => {
    const enqueue = queue(async (pd: StorePersistenceData) => {
        if (logPersistenceUpdates) {
            log.info(`Persisting`, Object.fromEntries(pd));
        }

        const updates = pd.flatMap(([_, update]) => update);

        const [idbUpdates, storageUpdates] = separateDiscriminatedUnion<
            AnyIdbStoreUpdate,
            AnyBrowserStoreUpdate
        >(
            isIdbStoreUpdate,
            updates,
        );

        storageCommit(storageUpdates);

        const conn = await idb(dbUser, persistMetadata, dbUpgradeBlockingAction);
        if (!conn) return;

        await idbCommit(conn, idbUpdates);
    });

    const pm = getPersistorMap<RootState>();
    const slices = TypedKeys(pm);

    return startListening({
        predicate: (_action, currentState: RootState, previousState: RootState) =>
            currentState != previousState,

        effect: (_action, api) => {
            const currentState = api.getState();

            if (selectIdbUpgradeRequired(currentState)) return;

            if (isClearingAuth(currentState)) return;

            const previousState = api.getOriginalState();

            const updates = slices
                .flatMap(name => {
                    const p = pm[name].persist;

                    if (!p) return [];

                    // I don't think this is possible to type sanely.
                    // There's no way to distinguish one out of a union of
                    // types, using a *runtime* value...
                    // Even redux don't bother typing updates inside the
                    // reducer combiner!
                    const data = p(previousState[name] as any, currentState[name] as any);

                    if (data.length === 0) return [];

                    return [[name, data]] as StorePersistenceData;
                });

            if (updates.length > 0) {
                enqueue(updates);
            }
        },
    });
};

/** Remove any browser stores which are not valid for the current DB version.
 */
export const removeInvalidBrowserStores = <T>(pm: PersistorMap<T>) => {
    const slices = TypedKeys(pm);

    const browserStores = slices.flatMap(name => {
        const p = pm[name].stores;
        const stores = p ? Object.values(p) : [];
        return stores.filter(s => isBrowserStore(s));
    });

    const storeIsValid = storeIsValidForVersion(persistMetadata.currentDBVersion);

    browserStores
        .filter(store => !storeIsValid(store) && !!storageRead(store))
        .forEach(store => {
            log.info(`Removing outdated browser store: ${store.name}`);
            storageRemove(store);
        });
};

/** Assemble the data with which to hydrate the entire redux store.
 */
export const getHydrationData = async <T = RootState>(
    dbUser: Optional<UserId>,
): Promise<Partial<T>> => {
    const conn = await idb(dbUser, persistMetadata);
    if (!conn) return {};

    const pm = getPersistorMap<T>();
    const slices = TypedKeys(pm);
    const promises = slices.map(name => pm[name].hydrate?.(conn));

    try {
        const awaited = await Promise.all(promises);

        const asEntries = slices.map((name, i) => [name, awaited[i]]);

        return Object.fromEntries(asEntries);
    }
    catch (e) {
        log.info(`Hydrate error`, e);
        // TODO: what do?
        // We could return whatever data we did find, but it might be inconsistent.
        return {};
    }
    finally {
        await closeConnection();
    }
};

/** Purge all persisted data.
 */
export const purgeCache = async <T>(): Promise<void> => {
    const pm = getPersistorMap<T>();

    TypedKeys(pm).forEach(name => pm[name].purge?.());

    await idbPurge();
};
