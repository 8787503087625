import React, { useState } from "react";
import { CallId } from "../../domain/domain";
import { selectCallById } from "../../features/calls";
import { selectUsers } from "../../features/users";
import useSelectorArgs from "../../hooks/useSelectorArgs";
import { getUserNamesString } from "../../misc/utils";
import { useShallowEqualsMemo } from "../../hooks/useShallowEquals";
import TimeAgo from "../gui/TimeAgo";
import { CallKnowledgePreview } from "../../domain/calls";
import { userSortByNickname } from "../../domain/users";

const sortByNickname = { sort: userSortByNickname };

export interface BondArchivedInteractionProps {
    callId: CallId;
}

export const BondArchivedInteraction = (props: BondArchivedInteractionProps): React.JSX.Element => {
    const { callId } = props;

    const call = useSelectorArgs(selectCallById, callId);
    const participantIds = useShallowEqualsMemo(() => call?.participants.map(p => p.userId) ?? [], [
        call?.participants,
    ]);
    const participants = useSelectorArgs(selectUsers, participantIds, sortByNickname);

    if (!call) {
        return <div className="c-message c-message--unknown"></div>;
    }

    const timestamp = (
        <TimeAgo from={call.startedAt} to={call.endedAt} live={false} precise={true} />
    );

    const wasOrWereLive: string = participants.length > 1 ? "were live" : "was live";
    return (
        <div className="c-message c-message--archived">
            <div className="c-message__content c-message__content--archived">
                <div className="c-message__meta">
                    <span className="c-message__author">
                        {getUserNamesString(participants)} {wasOrWereLive}
                    </span>
                    <span className="c-message__timestamp">{timestamp}</span>
                </div>
                <KnowledgeDetails knowledge={call.knowledge} />
            </div>
        </div>
    );
};

interface KnowledgeDetailsProps {
    knowledge: CallKnowledgePreview;
}

const KnowledgeDetails = (props: KnowledgeDetailsProps): React.JSX.Element => {
    const { knowledge } = props;
    if (!knowledge.title) {
        return <></>;
    }
    return (
        <div className="c-message__post c-message-archive">
            <h3 className="c-message-archive__title">
                {knowledge.title}
            </h3>
            <p className="c-message-archive__summary">
                {knowledge.summary}
            </p>
            <DetailedSummaryDropdown detailedSummary={knowledge.detailedSummary} />
        </div>
    );
};

interface DetailedSummaryDropdownProps {
    detailedSummary: string;
}

const DetailedSummaryDropdown = (props: DetailedSummaryDropdownProps): React.JSX.Element => {
    const { detailedSummary } = props;
    const [displayDetailedSummary, setDisplayDetailedSummary] = useState(false);

    const toggleDetailedSummary = (): void => {
        setDisplayDetailedSummary(!displayDetailedSummary);
    };

    if (!detailedSummary) {
        return <></>;
    }
    return (
        <div className="c-bond-summary">
            <button
                className={`c-bond-summary__header ${displayDetailedSummary ? "is-open" : ""}`}
                onClick={toggleDetailedSummary}
            >
                {displayDetailedSummary ? "Hide" : "View"} summary
            </button>
            {displayDetailedSummary && (
                <div className="c-bond-summary__content">
                    <p>{detailedSummary}</p>
                </div>
            )}
        </div>
    );
};
