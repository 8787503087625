import { useEffect } from "react";
import { useAppSelector } from "../store/redux";
import { selectBackendInfo } from "../features/meta";
import log from "../misc/log";
import useIdle from "../hooks/useIdle";
import { isNativePlatform } from "../misc/capacitor";

export default function RefreshOnNewVersion(): React.JSX.Element {
    const { version } = useAppSelector(selectBackendInfo) || {};
    const isIdle = useIdle({ minutes: 15 });

    useEffect(() => {
        if (version !== undefined) {
            log.info(
                `Backend version changed: ${version} ${
                    version === __BEYOND_FRONTEND_VERSION__ ? "" : "(differs to frontend)"
                }`,
            );
        }
    }, [version]);

    useEffect(() => {
        if (isNativePlatform) return;

        if (version === undefined) return;

        if (isIdle && version != __BEYOND_FRONTEND_VERSION__) {
            log.info(
                "Refreshing page due to idle, version change",
                version,
                __BEYOND_FRONTEND_VERSION__,
            );
            window.location.reload();
        }
    }, [isIdle, version]);

    return <></>;
}
