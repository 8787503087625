// @generated by protoc-gen-es v1.4.2 with parameter "target=js+ts"
// @generated from file clients/clients.proto (package clients, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { BondId, OrgId, RtcParticipantId, RtcSessionId, SquadId, UserId } from "../domain/domain_pb.js";
import { BondOverview } from "../bonds/bonds_pb.js";
import { OrgOverview } from "../squads/squads_pb.js";

/**
 * @generated from message clients.WebsocketAuthMessage
 */
export const WebsocketAuthMessage = proto3.makeMessageType(
  "clients.WebsocketAuthMessage",
  () => [
    { no: 6, name: "protocol_version", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 1, name: "auth_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "device_tag", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "conn_tag_prefix", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "conn_tag_num", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "user_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message clients.GetBackendVersionRequest
 */
export const GetBackendVersionRequest = proto3.makeMessageType(
  "clients.GetBackendVersionRequest",
  [],
);

/**
 * @generated from message clients.GetBackendVersionResponse
 */
export const GetBackendVersionResponse = proto3.makeMessageType(
  "clients.GetBackendVersionResponse",
  () => [
    { no: 1, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "instance", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message clients.FollowBondRequest
 */
export const FollowBondRequest = proto3.makeMessageType(
  "clients.FollowBondRequest",
  () => [
    { no: 2, name: "bond_id", kind: "message", T: BondId },
    { no: 3, name: "follow", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message clients.FollowBondResponse
 */
export const FollowBondResponse = proto3.makeMessageType(
  "clients.FollowBondResponse",
  [],
);

/**
 * @generated from message clients.ArchiveBondRequest
 */
export const ArchiveBondRequest = proto3.makeMessageType(
  "clients.ArchiveBondRequest",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "archive", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message clients.ArchiveBondResponse
 */
export const ArchiveBondResponse = proto3.makeMessageType(
  "clients.ArchiveBondResponse",
  [],
);

/**
 * @generated from message clients.InviteUserToBondRequest
 */
export const InviteUserToBondRequest = proto3.makeMessageType(
  "clients.InviteUserToBondRequest",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "invited_user_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message clients.InviteUserToBondResponse
 */
export const InviteUserToBondResponse = proto3.makeMessageType(
  "clients.InviteUserToBondResponse",
  [],
);

/**
 * @generated from message clients.GetCallAccessTokenRequest
 */
export const GetCallAccessTokenRequest = proto3.makeMessageType(
  "clients.GetCallAccessTokenRequest",
  () => [
    { no: 1, name: "session_id", kind: "message", T: RtcSessionId },
    { no: 2, name: "user_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message clients.GetCallAccessTokenResponse
 */
export const GetCallAccessTokenResponse = proto3.makeMessageType(
  "clients.GetCallAccessTokenResponse",
  () => [
    { no: 1, name: "access_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "participant_id", kind: "message", T: RtcParticipantId },
  ],
);

/**
 * @generated from message clients.ReportFirebaseTokenRequest
 */
export const ReportFirebaseTokenRequest = proto3.makeMessageType(
  "clients.ReportFirebaseTokenRequest",
  () => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message clients.ReportFirebaseTokenResponse
 */
export const ReportFirebaseTokenResponse = proto3.makeMessageType(
  "clients.ReportFirebaseTokenResponse",
  [],
);

/**
 * @generated from message clients.ReportBrowserFocusRequest
 */
export const ReportBrowserFocusRequest = proto3.makeMessageType(
  "clients.ReportBrowserFocusRequest",
  () => [
    { no: 1, name: "recorded_at", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message clients.ReportBrowserFocusResponse
 */
export const ReportBrowserFocusResponse = proto3.makeMessageType(
  "clients.ReportBrowserFocusResponse",
  [],
);

/**
 * @generated from message clients.ReportTypingActivityRequest
 */
export const ReportTypingActivityRequest = proto3.makeMessageType(
  "clients.ReportTypingActivityRequest",
  () => [
    { no: 1, name: "recorded_at", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message clients.ReportTypingActivityResponse
 */
export const ReportTypingActivityResponse = proto3.makeMessageType(
  "clients.ReportTypingActivityResponse",
  [],
);

/**
 * @generated from message clients.WhoAmIRequest
 */
export const WhoAmIRequest = proto3.makeMessageType(
  "clients.WhoAmIRequest",
  [],
);

/**
 * @generated from message clients.WhoAmIResponse
 */
export const WhoAmIResponse = proto3.makeMessageType(
  "clients.WhoAmIResponse",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 2, name: "org_id", kind: "message", T: OrgId },
  ],
);

/**
 * @generated from message clients.ObserveBondsPreviewsRequest
 */
export const ObserveBondsPreviewsRequest = proto3.makeMessageType(
  "clients.ObserveBondsPreviewsRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 2, name: "squad_id", kind: "message", T: SquadId },
  ],
);

/**
 * @generated from message clients.ObserveBondsPreviewsResponse
 */
export const ObserveBondsPreviewsResponse = proto3.makeMessageType(
  "clients.ObserveBondsPreviewsResponse",
  [],
);

/**
 * @generated from message clients.ObserveBondRequest
 */
export const ObserveBondRequest = proto3.makeMessageType(
  "clients.ObserveBondRequest",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "dont_observe", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message clients.ObserveBondResponse
 */
export const ObserveBondResponse = proto3.makeMessageType(
  "clients.ObserveBondResponse",
  () => [
    { no: 1, name: "bond_overview", kind: "message", T: BondOverview, oneof: "bond_or_deleted" },
    { no: 2, name: "deleted_id", kind: "message", T: BondId, oneof: "bond_or_deleted" },
  ],
);

/**
 * @generated from message clients.SubOrgOverviewsRequest
 */
export const SubOrgOverviewsRequest = proto3.makeMessageType(
  "clients.SubOrgOverviewsRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message clients.SubOrgOverviewsResponse
 */
export const SubOrgOverviewsResponse = proto3.makeMessageType(
  "clients.SubOrgOverviewsResponse",
  () => [
    { no: 1, name: "org_overview", kind: "message", T: OrgOverview, oneof: "org_or_deleted" },
    { no: 2, name: "deleted_id", kind: "message", T: OrgId, oneof: "org_or_deleted" },
  ],
);

