import { useCallback, useEffect } from "react";

import * as d from "../../domain/domain";
import {
    CallActionStatus,
    selectCallStartStatus,
    selectDefaultTranscriptionLanguage,
    setCallStartStatus,
    setCallStartStatusFromCurrentCallId,
    startCall,
} from "../../features/calls";
import { useAppDispatch, useAppSelector } from "../../store/redux";
import { useSessionActionBackoff } from "./useSessionActionBackoff";

const defaultCallName = "New Live Session";

interface UseSessionStartProps {
    bondId: d.BondId;
}

export function useSessionStart(props: UseSessionStartProps) {
    const { bondId } = props;
    const dispatch = useAppDispatch();

    // Start the call with backoff + retries
    const { signal: startSignal, getActionReady: getCanStart } = useSessionActionBackoff({
        selectCallStatus: selectCallStartStatus,
        setCallStatusFromCurrentCallId: setCallStartStatusFromCurrentCallId,
    });

    // Start the call when we receive the signal to do so.
    const transcriptionLanguage = useAppSelector(selectDefaultTranscriptionLanguage);
    useEffect(() => {
        if (!getCanStart()) {
            return;
        }

        const startCallAction = dispatch(
            startCall({
                name: defaultCallName,
                bondId,
                language: transcriptionLanguage,
            }),
        );

        return () => startCallAction.abort();
    }, [bondId, dispatch, getCanStart, startSignal, transcriptionLanguage]);

    // A callback for the caller to trigger the start call cycle
    const startSession = useCallback(() => {
        dispatch(setCallStartStatus(CallActionStatus.Ready));
    }, [dispatch]);

    return startSession;
}
