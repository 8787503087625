import { useEffect, useSyncExternalStore } from "react";

import { updateOnlineStatus } from "../features/connection";
import { useAppDispatch } from "../store/redux";

function subscribe(callback: () => void) {
    window.addEventListener("online", callback);
    window.addEventListener("offline", callback);
    return () => {
        window.removeEventListener("online", callback);
        window.removeEventListener("offline", callback);
    };
}

function getSnapshot() {
    return navigator.onLine;
}

export default function OnlineTracker() {
    const dispatch = useAppDispatch();

    const online = useSyncExternalStore(subscribe, getSnapshot);

    useEffect(() => {
        dispatch(updateOnlineStatus(online));
    }, [dispatch, online]);

    return <></>;
}
