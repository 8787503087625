import { DateTime, Interval } from "luxon";
import { BondNewDivider, BondTimeDivider } from "../components/messages/BondDivider";
import { CallMessageView } from "../components/messages/CallMessageView";
import { ChatMessageView } from "../components/messages/ChatMessageView";
import DebugMessageView from "../components/messages/DebugMessageView";
import {
    AnyOfficialMessage,
    AnyUnsentLocalMessage,
    getMsgSequenceNumber,
    getMsgTs,
    isCallEndedMessage,
    isOfficialChatMessage,
    isOfficialMessage,
    OfficialMessageType,
} from "../domain/chats";
import * as d from "../domain/domain";
import { AnyMessageId } from "../domain/domain";
import { selectCurrentUserId } from "../features/auth";
import { selectMessage } from "../features/chats";
import useBooleanFeatureFlag from "../hooks/useBooleanFeatureFlag";
import useSelectorArgs from "../hooks/useSelectorArgs";
import { useAppSelector } from "../store/redux";

export interface MessageViewProps {
    id: AnyMessageId;
    currentCallId?: d.CallId;
    previousId?: AnyMessageId;
    lastMessage?: boolean;
    publishedSequenceNumber?: number;
}

// straddleDay decides whether the there is more than one calendar day in the
// interval [previousMsgTs, msgTs).
//
// For example, if msgTs and previousMsgTs reflected 23:59 and the following
// 00:01 respectively, then this would return true.
//
// For more information, see the function documentation for the count function
// on Luxon's Interval class.
function straddleDay(msgTs: Date, previousMsgTs: Date): boolean {
    const dT = DateTime.fromMillis(msgTs.getTime());
    const previousDT = DateTime.fromMillis(previousMsgTs.getTime());

    const interval = Interval.fromDateTimes(previousDT, dT);
    const dayCount = interval.count("days");

    return dayCount >= 2;
}

// TODO render emoji reactions
export default function MessageView(props: MessageViewProps): React.JSX.Element {
    const { publishedSequenceNumber, currentCallId, id, previousId, lastMessage } = props;

    const currentUserId = useAppSelector(selectCurrentUserId);

    const msg = useSelectorArgs(selectMessage, id);
    const previousMsg = useSelectorArgs(selectMessage, previousId);

    const msgSequenceNumber = getMsgSequenceNumber(msg);

    const firstUnread = ((publishedSequenceNumber ?? 0) + 1) == msgSequenceNumber;
    const wasSentByUs = isOfficialChatMessage(msg) && msg.senderId === currentUserId;
    const lastMessageWasCall = lastMessage && isCallEndedMessage(msg);
    const newDivider = firstUnread && !wasSentByUs && !lastMessageWasCall;

    const msgTs = getMsgTs(msg);
    const previousMsgTs = getMsgTs(previousMsg);
    const dateDivider = !!msgTs && !!previousMsgTs && !firstUnread &&
        straddleDay(msgTs, previousMsgTs);

    return (
        <div id={id}>
            {dateDivider && <BondTimeDivider />}
            {newDivider && <BondNewDivider />}
            <MessageViewInternal currentCallId={currentCallId} msg={msg} previousId={previousId} />
        </div>
    );
}

interface MessageViewInternalProps {
    currentCallId?: d.CallId;
    msg?: AnyOfficialMessage | AnyUnsentLocalMessage;
    previousId?: AnyMessageId;
}

function MessageViewInternal(props: MessageViewInternalProps): React.JSX.Element {
    const { msg, previousId, currentCallId } = props;

    const showDebug = useBooleanFeatureFlag("debug-message-view");
    if (showDebug) {
        return <DebugMessageView msg={msg} />;
    }

    if (msg && !isOfficialMessage(msg)) {
        return (
            <ChatMessageView
                currentCallId={currentCallId}
                msg={msg}
                previousMsgId={previousId}
            />
        );
    }

    switch (msg?.type) {
        case OfficialMessageType.Chat: {
            return (
                <ChatMessageView
                    currentCallId={currentCallId}
                    msg={msg}
                    previousMsgId={previousId}
                />
            );
        }
        case OfficialMessageType.CallStart: {
            return <CallMessageView callId={msg.callId} />;
        }
        case OfficialMessageType.CallEnd: {
            return <></>;
        }
        default: {
            return <div className="c-message c-message--unknown"></div>;
        }
    }
}
