// @generated by protoc-gen-es v1.4.2 with parameter "target=js+ts"
// @generated from file identity/identity.proto (package identity, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { PersonId, PersonSet } from "../domain/domain_pb.js";

/**
 * @generated from message identity.PersonalInfo
 */
export const PersonalInfo = proto3.makeMessageType(
  "identity.PersonalInfo",
  () => [
    { no: 1, name: "person_id", kind: "message", T: PersonId },
    { no: 2, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "nickname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message identity.GetPersonalInfosRequest
 */
export const GetPersonalInfosRequest = proto3.makeMessageType(
  "identity.GetPersonalInfosRequest",
  () => [
    { no: 1, name: "person_ids", kind: "message", T: PersonSet },
  ],
);

/**
 * @generated from message identity.GetPersonalInfosResponse
 */
export const GetPersonalInfosResponse = proto3.makeMessageType(
  "identity.GetPersonalInfosResponse",
  () => [
    { no: 1, name: "personal_infos", kind: "message", T: PersonalInfo, repeated: true },
  ],
);

/**
 * @generated from message identity.CreatePersonForMockAuthRequest
 */
export const CreatePersonForMockAuthRequest = proto3.makeMessageType(
  "identity.CreatePersonForMockAuthRequest",
  () => [
    { no: 1, name: "new_person_id", kind: "message", T: PersonId },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "nickname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message identity.CreatePersonForMockAuthResponse
 */
export const CreatePersonForMockAuthResponse = proto3.makeMessageType(
  "identity.CreatePersonForMockAuthResponse",
  [],
);

/**
 * @generated from message identity.EditPersonForMockAuthRequest
 */
export const EditPersonForMockAuthRequest = proto3.makeMessageType(
  "identity.EditPersonForMockAuthRequest",
  () => [
    { no: 1, name: "person_id", kind: "message", T: PersonId },
    { no: 2, name: "new_display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message identity.EditPersonForMockAuthResponse
 */
export const EditPersonForMockAuthResponse = proto3.makeMessageType(
  "identity.EditPersonForMockAuthResponse",
  [],
);

/**
 * @generated from message identity.GetPersonByEmailRequest
 */
export const GetPersonByEmailRequest = proto3.makeMessageType(
  "identity.GetPersonByEmailRequest",
  () => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message identity.GetPersonByEmailResponse
 */
export const GetPersonByEmailResponse = proto3.makeMessageType(
  "identity.GetPersonByEmailResponse",
  () => [
    { no: 1, name: "person_id", kind: "message", T: PersonId },
  ],
);

/**
 * @generated from message identity.SubPersonalInfosRequest
 */
export const SubPersonalInfosRequest = proto3.makeMessageType(
  "identity.SubPersonalInfosRequest",
  () => [
    { no: 1, name: "add_to_sub", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "person_ids", kind: "message", T: PersonSet },
  ],
);

/**
 * @generated from message identity.SubPersonalInfosResponse
 */
export const SubPersonalInfosResponse = proto3.makeMessageType(
  "identity.SubPersonalInfosResponse",
  () => [
    { no: 1, name: "person_id", kind: "message", T: PersonId },
    { no: 2, name: "personal_info", kind: "message", T: PersonalInfo, oneof: "personal_info_or_deleted" },
    { no: 3, name: "deleted_id", kind: "message", T: PersonId, oneof: "personal_info_or_deleted" },
  ],
);

