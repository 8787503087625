import { useAppDispatch, useAppSelector } from "../../store/redux";
import { selectSquadById } from "../../features/squads";
import {
    PrivateOrSquadFilterOption,
    selectPrivateOrSquadFilter,
    setPrivateOrSquadFilterThunk,
} from "../../features/filterPanel";
import * as d from "../../domain/domain";
import { useCallback } from "react";
import SensitiveText from "../gui/SensitiveText";
import useSelectorArgs from "../../hooks/useSelectorArgs";

function SquadEntry(props: { id: d.SquadId; onClick?: () => void; }) {
    const { id, onClick: extraOnClick } = props;
    const dispatch = useAppDispatch();
    const squad = useSelectorArgs(selectSquadById, id);
    const currentCategory = useAppSelector(selectPrivateOrSquadFilter);

    const thisSquadSelected = currentCategory.by === "squad" && currentCategory.squadId === id;
    const selectedClass = thisSquadSelected ? "is-selected" : "";

    const selectThisSquad = useCallback(() => {
        extraOnClick?.();

        if (thisSquadSelected) {
            dispatch(
                setPrivateOrSquadFilterThunk({
                    by: "option",
                    option: PrivateOrSquadFilterOption.ALL,
                }),
            );
        }
        else {
            dispatch(setPrivateOrSquadFilterThunk({ by: "squad", squadId: id }));
        }
    }, [id, thisSquadSelected, dispatch, extraOnClick]);

    return squad && (
        <a
            className={`cp-dropdown-squad__item ${selectedClass}`}
            onClick={selectThisSquad}
        >
            <SensitiveText>{squad.name}</SensitiveText>
        </a>
    );
}

export default SquadEntry;
