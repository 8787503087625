import * as d from "../../domain/domain";
import { registerInterest, unregisterInterest } from "../../features/users";
import { useAppSelector } from "../../store/redux";
import { selectCurrentUserId } from "../../features/auth";
import useRegisterInterest, { OneNoneOrMany, oneNoneOrManyToArray } from "./useRegisterInterest";

export default function useInterestedUsers(userIds: OneNoneOrMany<d.UserId>) {
    const ids = oneNoneOrManyToArray(userIds);
    useRegisterInterest({ ids, registerInterest, unregisterInterest });
}

export function useSelfInterest() {
    const userId = useAppSelector(selectCurrentUserId);

    useInterestedUsers(userId);
}
