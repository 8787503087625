import { useCallback, useMemo, useState } from "react";

import Avatar from "./gui/Avatar";
import * as d from "../domain/domain";
import {
    OrderedParticipant,
    getAudio,
    getVideo,
    hasActiveVideo,
    isDisplay,
    getParticipantUserId,
    isTrackActive,
    getTileId,
    canOpenLiveView,
} from "../domain/session";
import {
    LiveActivityArgs,
    selectSortedBondParticipantIdsPair,
    selectBondContemporaries,
    selectSortedFollowerIdsPair,
    selectChannelIdByBondId,
} from "../features/bonds";
import useInterestedUsers from "../hooks/interest/useInterestedUsers";
import { ParticipantTileProps, ParticipantType } from "./gui/ParticipantTile";
import useFreshBondObservers from "../hooks/useFreshBondObservers";
import useSelectorArgs from "../hooks/useSelectorArgs";
import { useShallowEqualsMemo } from "../hooks/useShallowEquals";
import { useAppDispatch, useAppSelector } from "../store/redux";
import {
    CallActionStatus,
    JoinedCallView,
    selectGridTilesSet,
    selectCallJoinStatus,
    selectJoinedCallView,
    openLiveView,
} from "../features/calls";
import { Optional } from "../misc/types";
import {
    selectSortedUserIdsWithUnreadPair,
    selectUserIdSetWithMentions,
    selectUserIdSetWithUnread,
} from "../features/channels";
import { selectCurrentUserId } from "../features/auth";
import { separate } from "../misc/utils";
import { removeDuplicates } from "../misc/primatives";
import useSortedUsers from "../hooks/useSortedUsers";
import classNames from "classnames";
import ScrollDetector from "../components/gui/ScrollDetector";

// A fake OrderedParticipant.
// Used to treat non-live Bond Observers alongside OrderedParticipants.
type OrderedObserver = { userId: d.UserId; };
// Ordered Observer or Ordered Participant
type BondChatParticipant = OrderedObserver | OrderedParticipant;

const toOrderedObserver = (
    userId: d.UserId,
): OrderedObserver => ({ userId });

const isOrderedParticipant = (
    participant: BondChatParticipant,
): participant is OrderedParticipant => ("id" in participant);

const getTileIdWrapped = (participant: BondChatParticipant) => (
    isOrderedParticipant(participant) ? getTileId(participant) : `${participant.userId}-observer`
);

export interface BondChatParticipantsProps {
    bondId: d.BondId;
    liveParticipants?: OrderedParticipant[];
}

function BondChatParticipants(props: BondChatParticipantsProps): React.JSX.Element {
    const { bondId } = props;
    const liveParticipants = useShallowEqualsMemo(() => props.liveParticipants || [], [
        props.liveParticipants,
    ]);

    const dispatch = useAppDispatch();
    const [isScrolled, setIsScrolled] = useState(false);

    const currentUserId = useAppSelector(selectCurrentUserId);
    const callJoinStatus = useAppSelector(selectCallJoinStatus);

    const { ids: bondObserverIds, idSet: bondObserverSet } = useFreshBondObservers(bondId);
    const sortedFollowerIds = useSortedUsers(selectSortedFollowerIdsPair, bondId);
    const liveParticipantIds = useShallowEqualsMemo(
        () => new Set(liveParticipants?.map(p => p.id)),
        [liveParticipants],
    );

    const joinedCallView = useAppSelector(selectJoinedCallView);
    const isGrid = joinedCallView === JoinedCallView.Live;

    const canOpenLiveViewMemo = useMemo(
        () => canOpenLiveView(liveParticipants, currentUserId),
        [liveParticipants, currentUserId],
    );
    const openLiveViewWithFocus = useCallback((id: Optional<string>) => () => {
        dispatch(openLiveView(id));
    }, [dispatch]);
    const tileIsClickable = useCallback(
        (op: OrderedParticipant) => hasActiveVideo(op) && (isGrid || canOpenLiveViewMemo),
        [isGrid, canOpenLiveViewMemo],
    );

    const channelId = useAppSelector(selectChannelIdByBondId(bondId));
    const bondSenderIds = useSortedUsers(selectSortedUserIdsWithUnreadPair, channelId);
    const userSetWithUnread = useSelectorArgs(selectUserIdSetWithUnread, channelId);
    const userSetWithMentions = useSelectorArgs(selectUserIdSetWithMentions, channelId);

    const contemporarySet = useSelectorArgs(selectBondContemporaries, bondId);

    const gridTileSet = useAppSelector(selectGridTilesSet);
    const removeGridParticipants = useCallback(
        (bcp: BondChatParticipant) =>
            !(isOrderedParticipant(bcp) && gridTileSet.has(getTileIdWrapped(bcp))),
        [gridTileSet],
    );

    const [displayParticipants, liveUserParticipants] = useShallowEqualsMemo(
        () => separate(isDisplay, liveParticipants),
        [liveParticipants],
    );
    const displayParticipantsFiltered = useShallowEqualsMemo(
        () => isGrid ? displayParticipants.filter(removeGridParticipants) : displayParticipants,
        [displayParticipants, removeGridParticipants, isGrid],
    );

    const handleScroll = useCallback((scrolled: boolean) => {
        setIsScrolled(scrolled);
    }, []);

    // It is important that this includes all live users that we are aware of,
    // i.e. apply the removeGridParticipants filter at the end, NOT here.
    // This is so that we de-duplicate user tiles correctly.
    const liveParticipantUserIds = useShallowEqualsMemo(
        () => liveUserParticipants.map(getParticipantUserId),
        [liveUserParticipants],
    );

    // Store a map from user ID to participants so we can render their tracks after sorting them.
    // A user can join a call from multiple devices, so maintain an array for each user.
    const liveUserParticipantsMap = useShallowEqualsMemo(
        () =>
            liveUserParticipants.reduce((acc, part) => {
                const curr = acc.get(part.userId);
                acc.set(part.userId, curr ? curr.concat(part) : [part]);
                return acc;
            }, new Map<d.UserId, OrderedParticipant[]>()),
        [liveUserParticipants],
    );

    // Filter live participants with video so we can order them first
    const videoLiveUserIdSet = useShallowEqualsMemo(() => {
        return liveUserParticipants.filter(hasActiveVideo)
            .map(getParticipantUserId)
            .toSet();
    }, [liveUserParticipants]);
    const liveUserIdSet = useShallowEqualsMemo(
        () => liveParticipantUserIds.toSet(),
        [liveParticipantUserIds],
    );
    const liveUserArgs = useMemo((): LiveActivityArgs => ({
        videoUserSet: videoLiveUserIdSet,
        liveUserSet: liveUserIdSet,
        orderCurrentUserFirst: true,
    }), [liveUserIdSet, videoLiveUserIdSet]);
    const liveParticipantUserIdsSorted = useSortedUsers(
        selectSortedBondParticipantIdsPair,
        bondId,
        liveParticipantUserIds,
        liveUserArgs,
    );

    const participantUserIds = useShallowEqualsMemo(() => {
        return removeDuplicates(
            liveParticipantUserIdsSorted,
            bondObserverIds,
            bondSenderIds,
            sortedFollowerIds,
        );
    }, [
        liveParticipantUserIdsSorted,
        bondObserverIds,
        bondSenderIds,
        sortedFollowerIds,
    ]);
    useInterestedUsers(participantUserIds);

    const userParticipants = useShallowEqualsMemo(
        () =>
            participantUserIds.flatMap((userId): BondChatParticipant[] =>
                liveUserParticipantsMap.get(userId) ?? [toOrderedObserver(userId)]
            ),
        [liveUserParticipantsMap, participantUserIds],
    );
    const userParticipantsFiltered = useShallowEqualsMemo(
        () => isGrid ? userParticipants.filter(removeGridParticipants) : userParticipants,
        [isGrid, userParticipants, removeGridParticipants],
    );

    const getTileParams = useCallback(
        (op: BondChatParticipant): ParticipantTileProps => {
            const isLiveParticipant = isOrderedParticipant(op);
            return {
                participantUserId: op.userId,
                isSelf: currentUserId === op.userId,
                hideName: true,
                videoTitle: true,
                participantType: ParticipantType.User, // conditionally overwritten below

                ...isLiveParticipant && {
                    audioTrack: getAudio(op),
                    videoTrack: getVideo(op),

                    ...isDisplay(op) && { participantType: ParticipantType.Display },
                    ...tileIsClickable(op) && { onClick: openLiveViewWithFocus(getTileId(op)) },
                },
            };
        },
        [currentUserId, openLiveViewWithFocus, tileIsClickable],
    );

    const presenceClasses = classNames("c-composer-presence", {
        "c-composer-presence--live": isGrid,
        "c-composer-presence--scrolled": isScrolled && !isGrid,
        "c-composer-presence--live-scrolled": isGrid && isScrolled,
    });

    const renderAvatar = useCallback((tile: BondChatParticipant) => {
        const tileParams = getTileParams(tile);
        const userId = tile.userId;

        const isObserver = bondObserverSet.has(userId);
        const videoOn = isTrackActive(tileParams.videoTrack);
        const audioOn = isTrackActive(tileParams.audioTrack);
        const isDisplay = tileParams.participantType === ParticipantType.Display;
        const screenSharing = isDisplay && videoOn;

        // Determine liveness based on whether participants are in the RTC session.
        // Others are in the RTC session iff they are in liveParticipants.
        //
        // selfParticipant is always in liveParticipants, so use the inCallStatus
        // for the current user. Also check whether audio or video are on, in the
        // case that the user is attempting to start call by publishing.
        const isLiveParticipant = (userId === currentUserId) ?
            (callJoinStatus === CallActionStatus.Completed || audioOn || videoOn)
            : (isOrderedParticipant(tile) && liveParticipantIds.has(tile.id));

        return (
            <Avatar
                key={getTileIdWrapped(tile)}
                userId={userId}
                context={{
                    showBondPresence: true,
                    isScreenShare: screenSharing,
                }}
                modifiers={{
                    observingBond: isObserver || isLiveParticipant,
                    callParticipation: isLiveParticipant ? tileParams : undefined,
                    contributed: isLiveParticipant || userSetWithUnread.has(userId),
                    mentionedCurrentUser: userSetWithMentions.has(userId),
                    contemporary: contemporarySet?.has(userId) || false,
                }}
            />
        );
    }, [
        bondObserverSet,
        currentUserId,
        getTileParams,
        callJoinStatus,
        liveParticipantIds,
        userSetWithMentions,
        userSetWithUnread,
        contemporarySet,
    ]);

    return (
        <div className={presenceClasses}>
            <ScrollDetector
                onScroll={handleScroll}
                className="c-composer-presence__wrapper"
            >
                <div className="c-composer-presence__humans">
                    {displayParticipantsFiltered.map(renderAvatar)}
                    {userParticipantsFiltered.map(renderAvatar)}
                </div>
            </ScrollDetector>
        </div>
    );
}

export default BondChatParticipants;
