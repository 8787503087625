// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=js+ts"
// @generated from file chats/chats.proto (package chats, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AddUsersToChannelRequest, AddUsersToChannelResponse, CleanStaleAttachmentsRequest, CleanStaleAttachmentsResponse, CompleteAttachmentRequest, CompleteAttachmentResponse, CreateAttachmentRequest, CreateAttachmentResponse, CreateChannelRequest, CreateChannelResponse, FetchMessagesRequest, FetchMessagesResponse, GetAttachmentBlobUrlRequest, GetAttachmentBlobUrlResponse, GetAttachmentKnowledgesRequest, GetAttachmentKnowledgesResponse, GetCallsFromChannelRequest, GetCallsFromChannelResponse, GetChannelsInfoRequest, GetChannelsInfoResponse, GetMessageRequest, GetMessageResponse, GetMessagesBeforeCallRequest, GetMessagesBeforeCallResponse, GetMessagesBeforeIdRequest, GetMessagesBeforeIdResponse, GetMessagesByIdsRequest, GetMessagesByIdsResponse, GetMessagesInCallRequest, GetMessagesInCallResponse, GetMessagesRequest, GetMessagesResponse, GetMostRecentMessagesRequest, GetMostRecentMessagesResponse, ReadChannelSequenceNumbersRequest, ReadChannelSequenceNumbersResponse, RemoveUserFromChannelRequest, RemoveUserFromChannelResponse, SendCallMessageRequest, SendCallMessageResponse, SendChatMessageRequest, SendChatMessageResponse, SetAttachmentAltTextRequest, SetAttachmentAltTextResponse, SetAttachmentNotificationMessageRequest, SetAttachmentNotificationMessageResponse, SetUserReadSequenceNumberRequest, SetUserReadSequenceNumberResponse, SubMessageChannelRequest, SubMessageChannelResponse, SubUserMaxReadSeqNumsRequest, SubUserMaxReadSeqNumsResponse, UpdateAttachmentKnowledgeRequest, UpdateAttachmentKnowledgeResponse } from "./chats_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service chats.ChatService
 */
export const ChatService = {
  typeName: "chats.ChatService",
  methods: {
    /**
     * GetMessages returns all messages for a channel specified by the supplied channelId.
     *
     * @generated from rpc chats.ChatService.GetMessages
     */
    getMessages: {
      name: "GetMessages",
      I: GetMessagesRequest,
      O: GetMessagesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * FetchMessages returns first the <req.Limit <= 64> messages ordered by sequence number ascending from the specified channel with greater than or equal to sequence numbers to the one specified.
     *
     * @generated from rpc chats.ChatService.FetchMessages
     */
    fetchMessages: {
      name: "FetchMessages",
      I: FetchMessagesRequest,
      O: FetchMessagesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetMessagesByIds returns all messages with the requested IDs.
     *
     * @generated from rpc chats.ChatService.GetMessagesByIds
     */
    getMessagesByIds: {
      name: "GetMessagesByIds",
      I: GetMessagesByIdsRequest,
      O: GetMessagesByIdsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetMessagesBeforeId returns at most k messages with attachments up to and including the message with the given ID.
     *
     * @generated from rpc chats.ChatService.GetMessagesBeforeId
     */
    getMessagesBeforeId: {
      name: "GetMessagesBeforeId",
      I: GetMessagesBeforeIdRequest,
      O: GetMessagesBeforeIdResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetMessage returns the message with the requested message ID, along with fresh SAS URLs for its attachments.
     *
     * @generated from rpc chats.ChatService.GetMessage
     */
    getMessage: {
      name: "GetMessage",
      I: GetMessageRequest,
      O: GetMessageResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetMessageMostRecentMessages returns at most `limit` number of the most recent messages with attachments for a channel.
     *
     * @generated from rpc chats.ChatService.GetMostRecentMessages
     */
    getMostRecentMessages: {
      name: "GetMostRecentMessages",
      I: GetMostRecentMessagesRequest,
      O: GetMostRecentMessagesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetMessagesInCall returns the chat messages posted during a call
     *
     * @generated from rpc chats.ChatService.GetMessagesInCall
     */
    getMessagesInCall: {
      name: "GetMessagesInCall",
      I: GetMessagesInCallRequest,
      O: GetMessagesInCallResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetMessagesBeforeCall returns the chat messages posted before the call. 
     *
     * @generated from rpc chats.ChatService.GetMessagesBeforeCall
     */
    getMessagesBeforeCall: {
      name: "GetMessagesBeforeCall",
      I: GetMessagesBeforeCallRequest,
      O: GetMessagesBeforeCallResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetCallsFromChannel returns the call ids for all the calls on a given channel in the requested sequence number range
     *
     * @generated from rpc chats.ChatService.GetCallsFromChannel
     */
    getCallsFromChannel: {
      name: "GetCallsFromChannel",
      I: GetCallsFromChannelRequest,
      O: GetCallsFromChannelResponse,
      kind: MethodKind.Unary,
    },
    /**
     * SendChatMessage sends a chat message to a chosen channel.
     *
     * @generated from rpc chats.ChatService.SendChatMessage
     */
    sendChatMessage: {
      name: "SendChatMessage",
      I: SendChatMessageRequest,
      O: SendChatMessageResponse,
      kind: MethodKind.Unary,
    },
    /**
     * SendCallMessage sends a call message to a chosen channel.
     *
     * @generated from rpc chats.ChatService.SendCallMessage
     */
    sendCallMessage: {
      name: "SendCallMessage",
      I: SendCallMessageRequest,
      O: SendCallMessageResponse,
      kind: MethodKind.Unary,
    },
    /**
     * SubMessageChannel returns a stream of messages in a chosen chat channel.
     *
     * @generated from rpc chats.ChatService.SubMessageChannel
     */
    subMessageChannel: {
      name: "SubMessageChannel",
      I: SubMessageChannelRequest,
      O: SubMessageChannelResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * CreateChannel creates a new channel in a chosen squad with an initial message in it.
     *
     * @generated from rpc chats.ChatService.CreateChannel
     */
    createChannel: {
      name: "CreateChannel",
      I: CreateChannelRequest,
      O: CreateChannelResponse,
      kind: MethodKind.Unary,
    },
    /**
     * AddUsersToChannel adds the given users to a channel.
     *
     * @generated from rpc chats.ChatService.AddUsersToChannel
     */
    addUsersToChannel: {
      name: "AddUsersToChannel",
      I: AddUsersToChannelRequest,
      O: AddUsersToChannelResponse,
      kind: MethodKind.Unary,
    },
    /**
     * RemoveUserFromChannel removes the given users from a channel.
     *
     * @generated from rpc chats.ChatService.RemoveUserFromChannel
     */
    removeUserFromChannel: {
      name: "RemoveUserFromChannel",
      I: RemoveUserFromChannelRequest,
      O: RemoveUserFromChannelResponse,
      kind: MethodKind.Unary,
    },
    /**
     * SetUserReadSequenceNumber sets the sequence number of the last message that the user has read in a channel.
     *
     * @generated from rpc chats.ChatService.SetUserReadSequenceNumber
     */
    setUserReadSequenceNumber: {
      name: "SetUserReadSequenceNumber",
      I: SetUserReadSequenceNumberRequest,
      O: SetUserReadSequenceNumberResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ReadChannelSequenceNumbers returns the sequence numbers of the last messages that all given users have seen in a given channel.
     *
     * @generated from rpc chats.ChatService.ReadChannelSequenceNumbers
     */
    readChannelSequenceNumbers: {
      name: "ReadChannelSequenceNumbers",
      I: ReadChannelSequenceNumbersRequest,
      O: ReadChannelSequenceNumbersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetChannelsInfo returns a list of channels that the user is subscribed to and their last read sequence number.
     *
     * @generated from rpc chats.ChatService.GetChannelsInfo
     */
    getChannelsInfo: {
      name: "GetChannelsInfo",
      I: GetChannelsInfoRequest,
      O: GetChannelsInfoResponse,
      kind: MethodKind.Unary,
    },
    /**
     * SubUserMaxReadSeqNums returns a stream of the maximum read sequence number for the user's subscribed channels.
     *
     * @generated from rpc chats.ChatService.SubUserMaxReadSeqNums
     */
    subUserMaxReadSeqNums: {
      name: "SubUserMaxReadSeqNums",
      I: SubUserMaxReadSeqNumsRequest,
      O: SubUserMaxReadSeqNumsResponse,
      kind: MethodKind.BiDiStreaming,
    },
    /**
     * CreateAttachment returns the ability to upload a message attachment.
     *
     * @generated from rpc chats.ChatService.CreateAttachment
     */
    createAttachment: {
      name: "CreateAttachment",
      I: CreateAttachmentRequest,
      O: CreateAttachmentResponse,
      kind: MethodKind.Unary,
    },
    /**
     * CompleteAttachment informs the backend that a previously created attachment has been uploaded and should be served to interested parties.
     * It is safe to call this for an already completed attachment. Doing so will only update the underlying blob's upload_end timestamp.
     *
     * @generated from rpc chats.ChatService.CompleteAttachment
     */
    completeAttachment: {
      name: "CompleteAttachment",
      I: CompleteAttachmentRequest,
      O: CompleteAttachmentResponse,
      kind: MethodKind.Unary,
    },
    /**
     * CleanStaleAttachments finds and removes any stale (created for more than a day and not associated with a message) message attachments and subsequently tells the blobs service to do the same.
     *
     * @generated from rpc chats.ChatService.CleanStaleAttachments
     */
    cleanStaleAttachments: {
      name: "CleanStaleAttachments",
      I: CleanStaleAttachmentsRequest,
      O: CleanStaleAttachmentsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * UpdateAttachmentKnowledge updates attachment_knowledge for a given attachment.
     *
     * @generated from rpc chats.ChatService.UpdateAttachmentKnowledge
     */
    updateAttachmentKnowledge: {
      name: "UpdateAttachmentKnowledge",
      I: UpdateAttachmentKnowledgeRequest,
      O: UpdateAttachmentKnowledgeResponse,
      kind: MethodKind.Unary,
    },
    /**
     * SetAttachmentNotificationMessage sets the notification message for an attachment. 
     *
     * @generated from rpc chats.ChatService.SetAttachmentNotificationMessage
     */
    setAttachmentNotificationMessage: {
      name: "SetAttachmentNotificationMessage",
      I: SetAttachmentNotificationMessageRequest,
      O: SetAttachmentNotificationMessageResponse,
      kind: MethodKind.Unary,
    },
    /**
     * SetAttachmentAltText sets the alt text for an attachment.
     *
     * @generated from rpc chats.ChatService.SetAttachmentAltText
     */
    setAttachmentAltText: {
      name: "SetAttachmentAltText",
      I: SetAttachmentAltTextRequest,
      O: SetAttachmentAltTextResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetAttachmentKnowledges returns a list of attachment knowledges for each blob id that has a
     * corresponding attachment knowledge. Note: We do not generate attachment knowledges for all attachments.
     *
     * @generated from rpc chats.ChatService.GetAttachmentKnowledges
     */
    getAttachmentKnowledges: {
      name: "GetAttachmentKnowledges",
      I: GetAttachmentKnowledgesRequest,
      O: GetAttachmentKnowledgesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetAvatarBlobUrl returns a valid SAS URL for the attachment with the given blob ID.
     *
     * @generated from rpc chats.ChatService.GetAttachmentBlobUrl
     */
    getAttachmentBlobUrl: {
      name: "GetAttachmentBlobUrl",
      I: GetAttachmentBlobUrlRequest,
      O: GetAttachmentBlobUrlResponse,
      kind: MethodKind.Unary,
    },
  }
};

