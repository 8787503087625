import { getBackendInfo } from "../features/meta";
import { useAppDispatch } from "../store/redux";
import { useConnectedEffect } from "../hooks/useConnectedEffect";

export function RefreshBackendInfo(): React.JSX.Element {
    const dispatch = useAppDispatch();

    useConnectedEffect(() => {
        dispatch(getBackendInfo());
    }, [dispatch]);

    return <></>;
}
