import { ConnectError, Code } from "@connectrpc/connect";
import log from "../misc/log";

export async function* streamHandler<T, S>(
    resp: AsyncIterable<T>,
    f: (t: T) => S,
    logPrefix: string,
) {
    try {
        for await (const res of resp) {
            const parsed = f(res);
            if (parsed) {
                yield parsed;
            }
        }
    }
    catch (e) {
        if (e instanceof ConnectError) {
            if (e.code != Code.Canceled) {
                log.info(`${logPrefix} exception: ${JSON.stringify(e)}`, e);
                throw e;
            }
            else {
                // log.info(`${logPrefix} cancelled normally`)
            }
        }
        else if (e instanceof Error) {
            log.info(`${logPrefix} exception: ${JSON.stringify(e)}`, e);
            throw e;
        }
        else {
            log.info(`${logPrefix} non-Error thrown: ${JSON.stringify(e)}`, e);
            throw e;
        }
    }
    finally {
        // log.info(`${logPrefix} finished`);
    }
}
