import { createPromiseClient } from "@connectrpc/connect";
import { DeviceService } from "../../gen/proto/devices/devices_connect";
import * as devices_pb from "../../gen/proto/devices/devices_pb";
import { transport } from "./transport";
import { fromProtoUserId, fromProtoTimestamp } from "./util";
import { UserObservation } from "../domain/presence";

export const deviceService = createPromiseClient(DeviceService, transport);
export default deviceService;

export function observersParser<T>(
    parseUrn: (_: string) => T,
) {
    return (res: devices_pb.SubObserversResponse) => {
        if (!res.viewUrn) {
            return null;
        }
        return {
            viewId: parseUrn(res.viewUrn),
            observations: res.observers?.map((observation): UserObservation => {
                return {
                    userId: fromProtoUserId(observation.userId),
                    current: observation.current,
                    startedAt: observation.startedAt && fromProtoTimestamp(observation.startedAt),
                    endedAt: observation.endedAt && fromProtoTimestamp(observation.endedAt),
                };
            }) || [],
        };
    };
}
