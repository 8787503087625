import * as d from "../domain/domain";

export interface BackoffState {
    // The number of *failed* attempts.
    attempts: number;
    // When we can next try again. `undefined` means "now".
    nextAttempt?: d.Timestamp;
}

export interface WithBackoff {
    backoffState: BackoffState;
}

// Exponential backoff.
// Let f be the number of previous failures seen, and let
// g = min(f - 1, truncateAt).
// We will delay by `intervalMs * 2^g * (1 + r)`
// where r is a randomly chosen float in [0, 1]
export interface BackoffProps {
    intervalMs: number;
    truncateAt?: number;
}

export const defaultBackoffProps: BackoffProps = {
    // Initial delay is between 256ms and 512ms
    intervalMs: 256,
    // Maximum possible delay is (2 * 256 * 2^12) ms ~= 35 minutes
    truncateAt: 12,
};

export const delayCalculator =
    (intervalMs: number, truncateAt: number = 12) => (attempts: number) => {
        const t = truncateAt ?? 0;
        // Exponent: double the minimum delay for every failure,
        // up to `truncateAt` doublings.
        const p = t ? Math.min(attempts - 1, t) : attempts - 1;
        // Jitter multiplier: maximum delay is 2x minimum delay for a
        // given number of failed attempts.
        const r = 1 + Math.random();
        return intervalMs * r * (1 << p);
    };

export const backoffUpdater = (
    { intervalMs, truncateAt }: BackoffProps = defaultBackoffProps,
): (attempts: number) => BackoffState => {
    const calc = delayCalculator(intervalMs, truncateAt);
    return (attempts: number) => {
        attempts += 1;
        return { attempts, nextAttempt: Date.now() + calc(attempts) };
    };
};

export const defaultBackoffUpdater = backoffUpdater();

export const defaultBackoffState = (): BackoffState => ({ attempts: 0 });
