// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=js+ts"
// @generated from file bonds/bonds.proto (package bonds, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AddArchiversToBondRequest, AddArchiversToBondResponse, AddFollowersToBondRequest, AddFollowersToBondResponse, CreateBondFromCallRequest, CreateBondFromCallResponse, CreateBondFromChatMessageRequest, CreateBondFromChatMessageResponse, CreateFakedataCallForBondRequest, CreateFakedataCallForBondResponse, GetBondIdsForChannelsRequest, GetBondIdsForChannelsResponse, GetBondInfosRequest, GetBondInfosResponse, GetBondKnowledgeByTypeRequest, GetBondKnowledgeByTypeResponse, GetBondKnowledgePreviewRequest, GetBondKnowledgePreviewResponse, GetBondKnowledgesRequest, GetBondKnowledgesResponse, GetBondRoleHoldersRequest, GetBondRoleHoldersResponse, GetCallParentDetailsRequest, GetCallParentDetailsResponse, GetChannelIdFromCallRequest, GetChannelIdFromCallResponse, GetFollowerLaggardsRequest, GetFollowerLaggardsResponse, GetSearchableBondsRequest, GetSearchableBondsResponse, ListSquadBondsRequest, ListSquadBondsResponse, ListUserBondsRequest, ListUserBondsResponse, RemoveArchiversFromBondRequest, RemoveArchiversFromBondResponse, RemoveFollowersFromBondRequest, RemoveFollowersFromBondResponse, SendChatMessageViaBondRequest, SendChatMessageViaBondResponse, StartCallViaBondRequest, StartCallViaBondResponse, SubArchivedBondsListDiffRequest, SubArchivedBondsListDiffResponse, SubBondContemporariesRequest, SubBondContemporariesResponse, SubBondRequest, SubBondResponse, SubBondsPreviewsRequest, SubBondsPreviewsResponse, SubCatchupSummariesRequest, SubCatchupSummariesResponse, SubMsgSeqNumsRequest, SubMsgSeqNumsResponse, UpdateBondAiGeneratedTitleRequest, UpdateBondAiGeneratedTitleResponse, UpdateBondKnowledgeSummaryAllContentRequest, UpdateBondKnowledgeSummaryAllContentResponse, UpdateBondUserSpecifiedTitleRequest, UpdateBondUserSpecifiedTitleResponse, UpdateUserBondKnowledgeRequest, UpdateUserBondKnowledgeResponse } from "./bonds_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service bonds.BondService
 */
export const BondService = {
  typeName: "bonds.BondService",
  methods: {
    /**
     * GetBondInfos returns the overviews for a set of specified bonds.
     *
     * @generated from rpc bonds.BondService.GetBondInfos
     */
    getBondInfos: {
      name: "GetBondInfos",
      I: GetBondInfosRequest,
      O: GetBondInfosResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetBondIdsForChannels returns a list of bond Ids from a list of channel ids.
     *
     * @generated from rpc bonds.BondService.GetBondIdsForChannels
     */
    getBondIdsForChannels: {
      name: "GetBondIdsForChannels",
      I: GetBondIdsForChannelsRequest,
      O: GetBondIdsForChannelsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ListSquadBonds returns a list of bond IDs for the specified squad.
     *
     * @generated from rpc bonds.BondService.ListSquadBonds
     */
    listSquadBonds: {
      name: "ListSquadBonds",
      I: ListSquadBondsRequest,
      O: ListSquadBondsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetCallParentDetails returns a identifiers of the bond, squad, org and initiating user for a given call.
     *
     * @generated from rpc bonds.BondService.GetCallParentDetails
     */
    getCallParentDetails: {
      name: "GetCallParentDetails",
      I: GetCallParentDetailsRequest,
      O: GetCallParentDetailsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ListUserBonds returns the set of bonds that a user is in at the specified level of privacy.
     *
     * @generated from rpc bonds.BondService.ListUserBonds
     */
    listUserBonds: {
      name: "ListUserBonds",
      I: ListUserBondsRequest,
      O: ListUserBondsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * CreateBondFromChatMessage creates a new bond with an initial message in it.
     *
     * @generated from rpc bonds.BondService.CreateBondFromChatMessage
     */
    createBondFromChatMessage: {
      name: "CreateBondFromChatMessage",
      I: CreateBondFromChatMessageRequest,
      O: CreateBondFromChatMessageResponse,
      kind: MethodKind.Unary,
    },
    /**
     * CreateBondFromCall creates a new bond with a live call in it.
     *
     * @generated from rpc bonds.BondService.CreateBondFromCall
     */
    createBondFromCall: {
      name: "CreateBondFromCall",
      I: CreateBondFromCallRequest,
      O: CreateBondFromCallResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetBondKnowledgePreview returns the knowledge preview of a bond.
     *
     * @generated from rpc bonds.BondService.GetBondKnowledgePreview
     */
    getBondKnowledgePreview: {
      name: "GetBondKnowledgePreview",
      I: GetBondKnowledgePreviewRequest,
      O: GetBondKnowledgePreviewResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetBondKnowledgeByType returns a bond's bond knowledges of a given knowledge type.
     *
     * @generated from rpc bonds.BondService.GetBondKnowledgeByType
     */
    getBondKnowledgeByType: {
      name: "GetBondKnowledgeByType",
      I: GetBondKnowledgeByTypeRequest,
      O: GetBondKnowledgeByTypeResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetBondKnowledges returns a list of bond_knowledges for a given bondSet.
     *
     * @generated from rpc bonds.BondService.GetBondKnowledges
     */
    getBondKnowledges: {
      name: "GetBondKnowledges",
      I: GetBondKnowledgesRequest,
      O: GetBondKnowledgesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetChannelIdFromCall returns the channelId for a given call id.
     *
     * @generated from rpc bonds.BondService.GetChannelIdFromCall
     */
    getChannelIdFromCall: {
      name: "GetChannelIdFromCall",
      I: GetChannelIdFromCallRequest,
      O: GetChannelIdFromCallResponse,
      kind: MethodKind.Unary,
    },
    /**
     * AddFollowersToBond associates a set of users with a bond as followers - currently enough to allow them access.
     *
     * @generated from rpc bonds.BondService.AddFollowersToBond
     */
    addFollowersToBond: {
      name: "AddFollowersToBond",
      I: AddFollowersToBondRequest,
      O: AddFollowersToBondResponse,
      kind: MethodKind.Unary,
    },
    /**
     * RemoveFollowersFromBond disassociates a set of bond followers from the bond.
     *
     * @generated from rpc bonds.BondService.RemoveFollowersFromBond
     */
    removeFollowersFromBond: {
      name: "RemoveFollowersFromBond",
      I: RemoveFollowersFromBondRequest,
      O: RemoveFollowersFromBondResponse,
      kind: MethodKind.Unary,
    },
    /**
     * AddArchiversToBond associates a set of users with a bond as archivers, indicating they marked the bond as 'Done'.
     *
     * @generated from rpc bonds.BondService.AddArchiversToBond
     */
    addArchiversToBond: {
      name: "AddArchiversToBond",
      I: AddArchiversToBondRequest,
      O: AddArchiversToBondResponse,
      kind: MethodKind.Unary,
    },
    /**
     * RemoveArchiversFromBond disassociates a set of bond archivers from the bond, leaving them in the state from before they archived.
     *
     * @generated from rpc bonds.BondService.RemoveArchiversFromBond
     */
    removeArchiversFromBond: {
      name: "RemoveArchiversFromBond",
      I: RemoveArchiversFromBondRequest,
      O: RemoveArchiversFromBondResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetBondRoleHolders returns the users that have the specified role in the given bond.
     *
     * @generated from rpc bonds.BondService.GetBondRoleHolders
     */
    getBondRoleHolders: {
      name: "GetBondRoleHolders",
      I: GetBondRoleHoldersRequest,
      O: GetBondRoleHoldersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetFollowerLaggards returns the laggards of this channel, that are also followers of the related bond.
     *
     * @generated from rpc bonds.BondService.GetFollowerLaggards
     */
    getFollowerLaggards: {
      name: "GetFollowerLaggards",
      I: GetFollowerLaggardsRequest,
      O: GetFollowerLaggardsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * SendChatMessageViaBond sends a message to a chosen chat via the chats service and adds the sender to the bond contributors.
     *
     * @generated from rpc bonds.BondService.SendChatMessageViaBond
     */
    sendChatMessageViaBond: {
      name: "SendChatMessageViaBond",
      I: SendChatMessageViaBondRequest,
      O: SendChatMessageViaBondResponse,
      kind: MethodKind.Unary,
    },
    /**
     * StartCallViaBond starts a call via the calls service then attaches the call to the bond.
     *
     * @generated from rpc bonds.BondService.StartCallViaBond
     */
    startCallViaBond: {
      name: "StartCallViaBond",
      I: StartCallViaBondRequest,
      O: StartCallViaBondResponse,
      kind: MethodKind.Unary,
    },
    /**
     * UpdateBondUserSpecifiedTitle updates the user specified title of a bond.
     *
     * @generated from rpc bonds.BondService.UpdateBondUserSpecifiedTitle
     */
    updateBondUserSpecifiedTitle: {
      name: "UpdateBondUserSpecifiedTitle",
      I: UpdateBondUserSpecifiedTitleRequest,
      O: UpdateBondUserSpecifiedTitleResponse,
      kind: MethodKind.Unary,
    },
    /**
     * UpdateBondAiGeneratedTitle updates the AI generated title of a bond.
     *
     * @generated from rpc bonds.BondService.UpdateBondAiGeneratedTitle
     */
    updateBondAiGeneratedTitle: {
      name: "UpdateBondAiGeneratedTitle",
      I: UpdateBondAiGeneratedTitleRequest,
      O: UpdateBondAiGeneratedTitleResponse,
      kind: MethodKind.Unary,
    },
    /**
     * UpdateBondKnowledgeSummaryAllContent updates the content of the bond_knowledge with knowledge type 'summary-all-content'.
     *
     * @generated from rpc bonds.BondService.UpdateBondKnowledgeSummaryAllContent
     */
    updateBondKnowledgeSummaryAllContent: {
      name: "UpdateBondKnowledgeSummaryAllContent",
      I: UpdateBondKnowledgeSummaryAllContentRequest,
      O: UpdateBondKnowledgeSummaryAllContentResponse,
      kind: MethodKind.Unary,
    },
    /**
     * UpdateUserBondKnowledge updates the content of the bond_knowledge with knowledge type 'user-bond-summary'.
     *
     * @generated from rpc bonds.BondService.UpdateUserBondKnowledge
     */
    updateUserBondKnowledge: {
      name: "UpdateUserBondKnowledge",
      I: UpdateUserBondKnowledgeRequest,
      O: UpdateUserBondKnowledgeResponse,
      kind: MethodKind.Unary,
    },
    /**
     * CreateFakedataCallForBond creates a new call from fake data, then stores and handles a series of fake call events.
     *
     * @generated from rpc bonds.BondService.CreateFakedataCallForBond
     */
    createFakedataCallForBond: {
      name: "CreateFakedataCallForBond",
      I: CreateFakedataCallForBondRequest,
      O: CreateFakedataCallForBondResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetSearchableBonds returns the overviews of all bonds that the user has access to, given a list of constraint squads.
     *
     * @generated from rpc bonds.BondService.GetSearchableBonds
     */
    getSearchableBonds: {
      name: "GetSearchableBonds",
      I: GetSearchableBondsRequest,
      O: GetSearchableBondsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * SubBondsPreviews subscribes to previews for all available bonds.
     *
     * @generated from rpc bonds.BondService.SubBondsPreviews
     */
    subBondsPreviews: {
      name: "SubBondsPreviews",
      I: SubBondsPreviewsRequest,
      O: SubBondsPreviewsResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * SubBond subscribes to changes in a bond overview.
     * TODO: For internal consistency this should be bidirectional.
     *
     * @generated from rpc bonds.BondService.SubBond
     */
    subBond: {
      name: "SubBond",
      I: SubBondRequest,
      O: SubBondResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * SubMsgSeqNums subscribes to the user's max read sequence numbers for the their *primary* channels.
     *
     * @generated from rpc bonds.BondService.SubMsgSeqNums
     */
    subMsgSeqNums: {
      name: "SubMsgSeqNums",
      I: SubMsgSeqNumsRequest,
      O: SubMsgSeqNumsResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * SubArchivedBondsListDiff streams back the list of IDs of the bonds this user has archived and subsequently diffs
     *
     * @generated from rpc bonds.BondService.SubArchivedBondsListDiff
     */
    subArchivedBondsListDiff: {
      name: "SubArchivedBondsListDiff",
      I: SubArchivedBondsListDiffRequest,
      O: SubArchivedBondsListDiffResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * SubCatchupSummaries streams back personalised bond summaries for all bonds a user can see.
     *
     * @generated from rpc bonds.BondService.SubCatchupSummaries
     */
    subCatchupSummaries: {
      name: "SubCatchupSummaries",
      I: SubCatchupSummariesRequest,
      O: SubCatchupSummariesResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * SubBondContemporaries streams the list of users who are caught up to each bond's primary channel.
     *
     * @generated from rpc bonds.BondService.SubBondContemporaries
     */
    subBondContemporaries: {
      name: "SubBondContemporaries",
      I: SubBondContemporariesRequest,
      O: SubBondContemporariesResponse,
      kind: MethodKind.BiDiStreaming,
    },
  }
};

