import { useCallback, type MutableRefObject, type RefCallback } from "react";

type MutableRefList<T> = Array<RefCallback<T> | MutableRefObject<T> | undefined | null>;

function setRef<T>(val: T, ...refs: MutableRefList<T>): void {
    refs.forEach(ref => {
        if (typeof ref === "function") {
            ref(val);
        }
        else if (ref != null) {
            ref.current = val;
        }
    });
}

export default function useMergedRefs<T>(...refs: MutableRefList<T>): RefCallback<T> {
    return useCallback((val: T) => {
        setRef(val, ...refs);
    }, [refs]);
}
