import classNames from "classnames";

export interface SwitchState {
    titleText: string;
    buttonText: string;
    extraClassNames?: string;
}

interface ActionButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    switchValue: boolean;
    action: (newVal: boolean) => void | Promise<void>;
    showWhen?: boolean;
    falseState?: SwitchState;
    trueState?: SwitchState;
}

type MEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>;

const ActionButton = (
    props: ActionButtonProps,
): React.JSX.Element => {
    const {
        action: inputAction,
        switchValue,
        className,
        showWhen,
        trueState,
        falseState,
        ...passthroughProps
    } = props;

    const onClick = (e: MEvent) => {
        e.stopPropagation();
        inputAction(!switchValue);
    };

    const state = switchValue ? trueState : falseState;
    const doNotShow = showWhen != undefined && showWhen != switchValue;

    if (doNotShow || !state) {
        return <></>;
    }

    const classes = classNames(
        className,
        state.extraClassNames ?? "",
    );

    return (
        <button
            {...passthroughProps}
            className={classes}
            onClick={onClick}
            title={state.titleText}
            tabIndex={-1}
        >
            <span>{state.buttonText}</span>
        </button>
    );
};

export default ActionButton;
