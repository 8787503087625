import queue from "../ds/queue";
import { nanoid } from "@reduxjs/toolkit";

// These declarations are missing from typescript's type definitions
declare global {
    interface FileSystemDirectoryHandle extends FileSystemHandle {
        [Symbol.asyncIterator](): AsyncIterableIterator<[string, FileSystemHandle]>;
        entries(): AsyncIterableIterator<[string, FileSystemHandle]>;
        keys(): AsyncIterableIterator<string>;
        values(): AsyncIterableIterator<FileSystemHandle>;
    }

    interface FileSystemFileHandle extends FileSystemHandle {
        readonly kind: "file";
        getFile(): Promise<File>;
        createSyncAccessHandle(): Promise<FileSystemSyncAccessHandle>;
    }

    interface FileSystemSyncAccessHandle {
        read: (buffer: BufferSource, options: FilesystemReadWriteOptions) => number;
        write: (buffer: BufferSource, options: FilesystemReadWriteOptions) => number;

        truncate: (size: number) => Promise<undefined>;
        getSize: () => Promise<number>;
        flush: () => Promise<undefined>;
        close: () => Promise<undefined>;
    }

    type FilesystemReadWriteOptions = {
        at: number;
    };
}

export const logArchiveWorkerConfig = {
    directoryName: "logs-beyond",
    maxFileSizeBytes: 1 * 1024 * 1024,
    maxNumFiles: 5,
};

async function getLogDirectory(create: boolean) {
    const root = await navigator.storage.getDirectory();
    return await root.getDirectoryHandle(logArchiveWorkerConfig.directoryName, {
        create: create,
    });
}

async function getLogFiles() {
    interface FileModificationInfo {
        name: string;
        lastModified: number;
    }

    try {
        const logDirectory = await getLogDirectory(false);
        const logFiles: FileModificationInfo[] = [];
        const isFileHandle = (handle: FileSystemHandle): handle is FileSystemFileHandle =>
            handle.kind === "file";

        for await (const [name, handle] of logDirectory) {
            if (!isFileHandle(handle)) {
                continue;
            }
            const file = await handle.getFile();
            logFiles.push({ name: name, lastModified: file.lastModified });
        }

        logFiles.sort((a: FileModificationInfo, b: FileModificationInfo) => {
            return a.lastModified - b.lastModified;
        });

        return { files: logFiles.map(logFileInfo => logFileInfo.name), directory: logDirectory };
    }
    catch (e) {
        return { files: [], directory: null };
    }
}

export async function getArchivedLogLines(): Promise<string[]> {
    try {
        const { files, directory } = await getLogFiles();
        if (!files || !directory) {
            return [];
        }
        const getFileContents = async (name: string): Promise<string | undefined> => {
            const logFileHandle = await directory.getFileHandle(name);
            if (!logFileHandle) return;
            const file = await logFileHandle.getFile();
            return await file.text();
        };
        const contentsPromises = files.map(getFileContents);
        const contents = await Promise.all(contentsPromises);
        return contents.join("").split("\n").filter(l => l.length > 0);
    }
    catch (e) {
        console.error("Getting archived logs failed", e);
        return [];
    }
}

export class LogArchiveWorkerContext {
    public fileAccessHandle: FileSystemSyncAccessHandle | undefined;
    writtenBytes: number = 0;
    sessionId = nanoid(5);

    async removeOldFiles() {
        const { files, directory } = await getLogFiles();
        const numToDelete = files.length - logArchiveWorkerConfig.maxNumFiles + 1;
        if (numToDelete <= 0) {
            return;
        }
        const logFilesToDelete = files.slice(0, numToDelete);
        await Promise.allSettled(logFilesToDelete.map(name => directory?.removeEntry(name)));
    }

    async createNewLogFile() {
        if (this.fileAccessHandle) {
            await this.fileAccessHandle.close();
            this.fileAccessHandle = undefined;
            this.writtenBytes = 0;
        }

        try {
            await this.removeOldFiles();
        }
        catch (e) {
            console.error("Removing old log files failed", e);
        }

        const logDirectory = await getLogDirectory(true);
        const filename = `log_${new Date().valueOf()}_${this.sessionId}.txt`;
        const fileHandle = await logDirectory.getFileHandle(filename, { create: true });
        this.fileAccessHandle = await fileHandle.createSyncAccessHandle();
    }

    async write(logLine: any) {
        try {
            if (
                !this.fileAccessHandle ||
                this.writtenBytes >= logArchiveWorkerConfig.maxFileSizeBytes
            ) {
                await this.createNewLogFile();
            }
            const encoder = new TextEncoder();
            const encodedMessage = encoder.encode(logLine + "\n");
            const written = this.fileAccessHandle?.write(encodedMessage, { at: this.writtenBytes });
            this.fileAccessHandle?.flush();
            this.writtenBytes += written ?? 0;
        }
        catch (e) {
            console.error("Writing log line to file failed", e);
        }
    }
}

const logMessageQueue = queue(async logLine => {
    await context.write(logLine);
});

const context = new LogArchiveWorkerContext();

self.onmessage = (e: MessageEvent<{ source?: string; logLine?: string; }>) => {
    if (e.data.source !== "avos-beyond" || !e.data.logLine) {
        return;
    }
    logMessageQueue(e.data.logLine);
};
