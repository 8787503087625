import classNames from "classnames";
import { ForwardedRef, forwardRef, useImperativeHandle, useMemo, useRef } from "react";
import { DraftTarget } from "../domain/channels";
import * as d from "../domain/domain";
import { Mention, isSquadMention, isUserMention } from "../domain/mentions";
import { selectSquadIdsFromBondId } from "../features/bonds";
import { selectDraft } from "../features/channels";
import { selectKnownSquadNames, selectUserIdsBySquadIds } from "../features/squads";
import { selectUsers } from "../features/users";
import useSelectorArgs from "../hooks/useSelectorArgs";
import { useShallowEqualsMemo } from "../hooks/useShallowEquals";
import { Flashable } from "../misc/types";
import { formatNodeList } from "../misc/utils";
import styles from "../sass/export.module.scss";
import SensitiveText from "./gui/SensitiveText";

interface AudienceHintProps {
    mentions: Mention[];
}
const AudienceHint = forwardRef((
    props: AudienceHintProps,
    ref: ForwardedRef<Flashable>,
): React.JSX.Element => {
    const { mentions } = props;

    const squadIds = useShallowEqualsMemo(
        () =>
            mentions.filter(isSquadMention)
                .map(s => s.target)
                .removeDuplicates(),
        [mentions],
    );
    const userMentions = useShallowEqualsMemo(
        () => mentions.filter(isUserMention),
        [mentions],
    );

    const squadNames = useSelectorArgs(selectKnownSquadNames, squadIds);
    const sortedSquadNames = useShallowEqualsMemo(
        () => [...squadNames].sort((a, b) => a.localeCompare(b)),
        [squadNames],
    );

    // Extract groups of users from the mentions
    const indirectUserIds = useSelectorArgs(selectUserIdsBySquadIds, squadIds);
    const directUserIds = useShallowEqualsMemo(
        () =>
            userMentions.map(m => m.target)
                .removeDuplicates(),
        [userMentions],
    );
    const notifiableUserIds = useShallowEqualsMemo(
        () =>
            userMentions
                .filter(m => m.notify)
                .map(m => m.target)
                .removeDuplicates(),
        [userMentions],
    );

    const directUsers = useSelectorArgs(selectUsers, directUserIds);
    const notifiableUsers = useSelectorArgs(selectUsers, notifiableUserIds);

    const sortedDirectUsers = useShallowEqualsMemo(
        () => [...directUsers].sort((a, b) => a.nickname.localeCompare(b.nickname)),
        [directUsers],
    );
    const sortedNotifiableUsers = useShallowEqualsMemo(
        () => [...notifiableUsers].sort((a, b) => a.nickname.localeCompare(b.nickname)),
        [notifiableUsers],
    );

    // Flags which change the format of output
    const isPrivate = squadNames.length == 0;
    const showNotifies = notifiableUsers.length > 0;

    // Values used directly in the output
    const privateUserNodes = [
        "you",
        ...sortedDirectUsers.map(u => <em key={u.id}>{u.nickname}</em>),
    ];
    const canViewUserCount = useMemo(
        () => [...indirectUserIds, ...directUserIds].removeDuplicates().length,
        [indirectUserIds, directUserIds],
    );
    const squadNodes = sortedSquadNames.map(s => <em key={s}>{s}</em>);
    const notifiableUserNodes = sortedNotifiableUsers.map(u => <em key={u.id}>{u.nickname}</em>);

    // Flash the hint (e.g. when the user tries to submit an invalid message)
    const spanRef = useRef<HTMLSpanElement>(null);
    useImperativeHandle(ref, () => ({
        flash: () => {
            spanRef.current?.animate?.([
                {
                    transform: "initial",
                    color: styles.red,
                    fontWeight: styles.fw_bold,
                },
                { transform: "translateX(-10px)" },
                { transform: "translateX(4px)" },
                { transform: "translateX(-6px)" },
                { transform: "translateX(4px)" },
                { transform: "translateX(-4px)" },
                { transform: "translateX(4px)" },
                {
                    transform: "initial",
                    color: styles.white_80,
                    fontWeight: styles.fw_regular,
                },
            ], { duration: 250, iterations: 1 });
        },
    }), []);

    return (
        <span ref={spanRef} className="c-audience__hint">
            {(mentions.length == 0) ?
                "Use @ to specify your audience" :
                (
                    <>
                        {isPrivate ? (
                            <>
                                Private between {formatNodeList(privateUserNodes, 5)}
                            </>
                        ) : (
                            <>
                                {canViewUserCount}
                                &nbsp;in {formatNodeList(squadNodes)}
                                &nbsp;can view and engage
                            </>
                        )}
                        {showNotifies && (
                            <>
                                &nbsp;&bull; {formatNodeList(notifiableUserNodes, 5)}
                                &nbsp;will be pinged
                            </>
                        )}
                    </>
                )}
        </span>
    );
});

interface CurrentSquadsProps {
    squadIds: d.SquadId[];
}
const CurrentSquads = (props: CurrentSquadsProps): React.JSX.Element => {
    const { squadIds } = props;

    const isPrivate = squadIds.length == 0;
    const squadNames = useSelectorArgs(selectKnownSquadNames, squadIds);

    const squadNamesToRender = isPrivate ? ["Private"] : squadNames;
    const classes = classNames("c-audience__squad", {
        "c-audience__squad--private": isPrivate,
    });

    return (
        <span className="c-audience__squads">
            {squadNamesToRender.map(s => (
                <span key={s} className={classes}>
                    <SensitiveText>{s}</SensitiveText>
                </span>
            ))}
        </span>
    );
};

// NOTE: this component works based on the phase 2 mention assumptions:
//   - mentioned squads are never notified
//   - scoped bonds can only have a single squad
//   - scoped bonds can only have user mentions from within that squad
interface AudienceLineProps {
    bondId?: d.BondId;
    draftTarget: DraftTarget;
    bondComposer: boolean;
}
export const AudienceLine = forwardRef((
    props: AudienceLineProps,
    ref: ForwardedRef<Flashable>,
): React.JSX.Element => {
    const { bondId, draftTarget, bondComposer } = props;

    const squadIds = useSelectorArgs(selectSquadIdsFromBondId, bondId);
    const draft = useSelectorArgs(selectDraft, draftTarget);
    const mentions = draft?.content.mentions ?? [];

    const showHint = bondComposer;

    return (
        <div className="c-audience">
            {showHint ?
                <AudienceHint ref={ref} mentions={mentions} /> :
                <CurrentSquads squadIds={squadIds} />}
        </div>
    );
});

export default AudienceLine;
