import classNames from "classnames";
import Linkify from "linkify-react";
import { Opts as LinkifyOpts } from "linkifyjs";
import {
    AnyMessage,
    getAttachmentMsgId,
    getSenderId,
    getMsgTs,
    haveSameMessageType,
    haveSameSender,
    isOfficialChatMessage,
    isUnsentLocalMessage,
    getCallId,
} from "../../domain/chats";
import * as d from "../../domain/domain";
import { AnyMessageId, UserId } from "../../domain/domain";
import { ContentMention } from "../../domain/mentions";
import { selectCurrentUserId } from "../../features/auth";
import { selectMessage } from "../../features/chats";
import { selectSquadById } from "../../features/squads";
import { selectUser } from "../../features/users";
import { useAppSelector } from "../../store/redux";
import useSelectorArgs from "../../hooks/useSelectorArgs";
import { useShallowEqualsMemo } from "../../hooks/useShallowEquals";
import { partialComponent } from "../../misc/utils";
import { FeatureFlagged } from "../FeatureFlags";
import Avatar from "../gui/Avatar";
import SensitiveText from "../gui/SensitiveText";
import TextFields, { TextField } from "../gui/TextFields";
import TimeAgo from "../gui/TimeAgo";
import AttachmentView from "./AttachmentView";
import { useMemo } from "react";
import Graphemer from "graphemer";
import { Optional } from "../../misc/types";

// The context in which a message was sent
// e.g. this message was sent during a live call
enum MessageCallContext {
    // Not sent during a call
    None,
    // Sent during a call which has since ended
    EndedCall,
    // Sent during a call which is live
    LiveCall,
}

export const maxJumbomojiLength = 40;

// Get the status of the call the message was sent in, returning None if it was
// not sent in a call.
function getMessageCallStatus(msgCallId?: d.CallId, currentCallId?: d.CallId): MessageCallContext {
    if (!msgCallId) {
        return MessageCallContext.None;
    }
    if (currentCallId === msgCallId) {
        return MessageCallContext.LiveCall;
    }

    return MessageCallContext.EndedCall;
}

const whitespaceRegex = /\s/g;
const emojiRegex = new RegExp(
    "^(?:" +
        "(?:\\p{Extended_Pictographic}|\\p{Emoji_Component})+" +
        "(?:\\p{Emoji_Modifier}|\\p{Emoji_Modifier_Base}|\\p{Emoji_Presentation}|\\u200d)*" +
        ")+$",
    "u",
);

const alphaNumericRegex = /[\p{L}\p{N}]/u;

function isEmojiOnly(str: string): boolean {
    const noWhiteSpace = str.replace(whitespaceRegex, "");
    const splitter = new Graphemer();

    if (splitter.countGraphemes(noWhiteSpace) > maxJumbomojiLength) return false;

    return emojiRegex.test(noWhiteSpace) && !alphaNumericRegex.test(noWhiteSpace);
}

export interface ChatMessageViewProps {
    msg: AnyMessage;
    previousMsgId?: AnyMessageId;
    currentCallId?: d.CallId;
}

export const ChatMessageView = (
    { currentCallId, previousMsgId, msg }: ChatMessageViewProps,
): React.JSX.Element => {
    const invalidMessage = !msg || (!isOfficialChatMessage(msg) && !isUnsentLocalMessage(msg));

    const currentUserId = useAppSelector(selectCurrentUserId);
    const senderId = getSenderId(msg, currentUserId);

    const sender = useSelectorArgs(selectUser, senderId);
    const previousMsg = useSelectorArgs(selectMessage, previousMsgId);

    if (invalidMessage) {
        return <div className="c-message c-message--unknown"></div>;
    }

    const ts = getMsgTs(msg);
    const previousTs = getMsgTs(previousMsg);
    const callId = getCallId(msg);

    const callStatus = getMessageCallStatus(callId, currentCallId);

    const props: ChatMessageViewInternalProps = {
        userId: senderId,
        content: msg.content.message ?? "",
        mentions: msg.content.mentions ?? [],
        callStatus: callStatus,
        attachmentIds: msg.attachmentIds,
        messageId: getAttachmentMsgId(msg),
    };

    if (
        shouldShowMessageHeader(previousMsg, msg, currentUserId)
        || shouldShowTimestamp(ts, previousTs)
    ) {
        props.name = sender?.name;
        props.ts = ts;
    }

    return <ChatMessageViewInternal {...props} />;
};

interface ChatMessageViewInternalProps {
    userId?: UserId;
    name?: string;
    ts?: Date;
    content: string;
    mentions: ContentMention[];
    callStatus: MessageCallContext;
    attachmentIds: d.BlobId[] | d.LocalAttachmentId[];
    messageId: d.AttachmentMessageId;
}

const ChatMessageViewInternal = (props: ChatMessageViewInternalProps): React.JSX.Element => {
    const { content, mentions, attachmentIds } = props;

    const timestamp = props.ts && (
        <TimeAgo from={props.ts?.valueOf() || 0} live={true} precise={true} />
    );

    const linkifyOpts: LinkifyOpts = {
        target: "_blank",
    };

    const emojiOnly = useMemo(() => isEmojiOnly(content), [content]);

    const isEnded = props.callStatus === MessageCallContext.EndedCall;
    const isLive = props.callStatus === MessageCallContext.LiveCall;
    const containerClassNames = classNames("c-message", {
        "c-message--live": isLive,
        "c-message--ended": isEnded,
    });
    const postClassNames = classNames("c-message__post", {
        "c-message__post--live": isLive,
        "c-message__post--ended": isEnded,
        "c-message__post--emoji": emojiOnly,
    });

    const fields: TextField[] = useShallowEqualsMemo(
        () =>
            mentions.map(m => ({
                range: { start: m.startOffset, end: m.endOffset },
                Component: partialComponent(ContentMentionView, { mention: m }),
            })),
        [mentions],
    );

    return (
        <div className={containerClassNames}>
            {props.userId && <Avatar userId={props.userId} hidden={!props.name} />}
            <div className="c-message__content">
                {(props.name || props.ts) && (
                    <div className="c-message__meta">
                        {props.name &&
                            (
                                <span className="c-message__author">
                                    {props.name}
                                </span>
                            )}
                        {props.ts && (
                            <span className="c-message__timestamp">
                                {timestamp}
                            </span>
                        )}
                    </div>
                )}
                <div className={postClassNames}>
                    <p>
                        <TextFields
                            text={content}
                            fields={fields}
                            DefaultComponent={partialComponent(Linkify, { options: linkifyOpts })}
                        />
                    </p>
                </div>

                <FeatureFlagged flag="message-attachments-view" match={true}>
                    {attachmentIds.map((id, i) => (
                        <AttachmentView
                            attachmentId={id}
                            key={id}
                            msgId={props.messageId}
                            index={i}
                        />
                    ))}
                </FeatureFlagged>
            </div>
        </div>
    );
};

interface ContentMentionProps {
    text: string;
    mention: ContentMention;
}

const ContentMentionView = (props: ContentMentionProps): React.JSX.Element => {
    const { text, mention } = props;

    const currentUserId = useAppSelector(selectCurrentUserId);

    const userId = (mention.case == "user") ? mention.target : undefined;
    const user = useSelectorArgs(selectUser, userId);

    const squadId = (mention.case == "squad") ? mention.target : undefined;
    const squad = useSelectorArgs(selectSquadById, squadId);

    const title = user ? user.name : squad?.name;

    const classes = classNames("cp-mention", {
        "cp-mention--self": userId && userId == currentUserId,
    });

    return (
        <span className={classes} title={title}>
            <SensitiveText>{text}</SensitiveText>
        </span>
    );
};

const shouldShowMessageHeader = (
    previousMsg: Optional<AnyMessage>,
    msg: AnyMessage,
    currentUserId?: d.UserId,
) => {
    // `!haveSameSender` includes the case of 2 call messages.
    return !previousMsg || !haveSameMessageType(previousMsg, msg) ||
        !haveSameSender(previousMsg, msg, currentUserId);
};

const bigEnoughTimeDifference = (a: Date, b: Date) => a.getTime() - b.getTime() >= 5 * 60 * 1000;
const shouldShowTimestamp = (a: Optional<Date>, b: Optional<Date>) =>
    !a || !b || bigEnoughTimeDifference(a, b);
