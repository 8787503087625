import * as d from "../../domain/domain";
import { registerOrgInterest, unregisterOrgInterest } from "../../features/squads";
import useRegisterInterest, { OneNoneOrMany, oneNoneOrManyToArray } from "./useRegisterInterest";

export default function useInterestedOrgs(orgIds: OneNoneOrMany<d.OrgId>) {
    useRegisterInterest({
        ids: oneNoneOrManyToArray(orgIds),
        registerInterest: registerOrgInterest,
        unregisterInterest: unregisterOrgInterest,
    });
}
