import { useRef } from "react";

import asyncIterableQueue from "../../ds/asyncIterableQueue";
import { useAppSelector } from "../../store/redux";
import type { AppAsyncThunkConfig, RootState } from "../../store/types";
import useArrayDiff from "../../hooks/useArrayDiff";
import { useConnectedEffect, useDisconnectedEffect } from "../../hooks/useConnectedEffect";
import useStreamDispatch from "../../hooks/useStreamDispatch";
import { Diff } from "../../misc/types";
import { AsyncThunk } from "@reduxjs/toolkit";

interface StreamManagerProps<T> {
    idSelector: (state: RootState) => T[];
    streamThunk: AsyncThunk<
        void,
        AsyncIterableIterator<Diff<T>>,
        AppAsyncThunkConfig
    >;
}

/** @function Manage the objects requested in a streaming thunk by calculating
 * the diff in interest from the Redux store.
 *
 * @param idSelector a Redux selector which selects the objects to register interest in
 * @param streamThunk a Redux thunk which consumes the diff AsyncIterableIterator
 * @returns an empty `React.JSX.Element` which will manage interest in the stream.
 */
function StreamManager<T>(props: StreamManagerProps<T>): React.JSX.Element {
    const { idSelector, streamThunk } = props;

    const currentInterest = useAppSelector(idSelector);
    const diff = useArrayDiff(currentInterest);

    // Don't send diffs until we have sent the full list upon first connection
    const readyToSendDiffs = useRef<boolean>(false);
    const iterableRef = useRef(asyncIterableQueue<Diff<T>>());
    const { push, clear } = iterableRef.current;

    useStreamDispatch(() => streamThunk(iterableRef.current), [streamThunk]);

    useConnectedEffect(() => {
        if (readyToSendDiffs.current) {
            push(diff);
        }
        else {
            push({ added: currentInterest });
            readyToSendDiffs.current = true;
        }
    }, [diff, currentInterest, push]);

    useDisconnectedEffect(() => {
        readyToSendDiffs.current = false;
        clear();
    }, [clear]);

    return <></>;
}

export default StreamManager;
