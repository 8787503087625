import {
    ForwardedRef,
    RefObject,
    createRef,
    forwardRef,
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import useWindowSize from "../../hooks/useWindowSize";
import { indexNestedArray, modulo, range } from "../../misc/utils";
import { isMobileBrowser } from "../../misc/mobile";
import classNames from "classnames";
import { BoxSize } from "../../misc/types";
import { calcBorderBoxSize } from "../../misc/css";

export interface SuggestionContentProps {
    highlighted: boolean;
}

type SuggestionContent = (props: SuggestionContentProps) => React.JSX.Element;

interface SuggestionProps {
    className: string;
    Content: SuggestionContent;
    onAdd?: () => void;
    highlighted: boolean;
}
const Suggestion = forwardRef((
    props: SuggestionProps,
    ref: ForwardedRef<HTMLButtonElement>,
): React.JSX.Element => {
    const { className, Content, onAdd, highlighted } = props;

    const classes = classNames("c-suggestion", className, {
        "c-suggestion--highlighted": highlighted,
    });

    const preventBlur = (e: React.MouseEvent<HTMLButtonElement>) => e.preventDefault();

    return (
        <button ref={ref} className={classes} onClick={onAdd} onMouseDown={preventBlur}>
            <Content highlighted={highlighted} />
        </button>
    );
});

export interface AutoCompleteSuggestion {
    key: string;
    className: string;
    Content: SuggestionContent;
    onAdd?: () => void;
}

const coordCalc = (
    anchor: number,
    altAnchor: number,
    invertAnchor: boolean,
    clampBehaviour: ClampBehaviour,
    size: number,
    windowSize: number,
): number => {
    if (invertAnchor) {
        const regular = Math.min(anchor, windowSize) - size;
        const alt = Math.max(size, altAnchor);
        const altClamped = Math.min(alt, windowSize) - size;
        if (clampBehaviour == "clamp") {
            return Math.max(size, regular);
        }
        else {
            return (anchor - size > 0) ? regular : altClamped;
        }
    }
    else {
        const regular = Math.max(0, anchor);
        const alt = Math.min(altAnchor, windowSize) - size;
        const altClamped = Math.max(0, alt);
        if (clampBehaviour == "clamp") {
            return Math.min(regular, windowSize - size);
        }
        else {
            return (anchor + size < windowSize) ? regular : altClamped;
        }
    }
};

type ClampBehaviour = "flip-alt" | "clamp";
type HorizontalAnchor = "left" | "right";
type VerticalAnchor = "top" | "bottom";

interface AutoCompleteProps {
    anchorX: number;
    anchorY: number;
    altAnchorX?: number;
    altAnchorY?: number;
    horizontalAnchor?: HorizontalAnchor;
    verticalAnchor?: VerticalAnchor;
    horizontalClampBehaviour?: ClampBehaviour;
    verticalClampBehaviour?: ClampBehaviour;
    suggestionGroups: AutoCompleteSuggestion[][];
}

export function AutoComplete(props: AutoCompleteProps): React.JSX.Element {
    const { anchorX, anchorY, suggestionGroups } = props;
    const altAnchorX = props.altAnchorX ?? anchorX;
    const altAnchorY = props.altAnchorY ?? anchorY;
    const horizontalAnchor = props.horizontalAnchor ?? "left";
    const verticalAnchor = props.verticalAnchor ?? "top";
    const horizontalClampBehaviour = props.horizontalClampBehaviour ?? "flip-alt";
    const verticalClampBehaviour = props.verticalClampBehaviour ?? "flip-alt";

    const ref = useRef<HTMLDivElement>(null);

    const {
        width: windowWidth,
        height: windowHeight,
    } = useWindowSize();

    const suggestions = useMemo(() => suggestionGroups.flat(), [suggestionGroups]);
    const indexedSuggestionGroups = useMemo(
        () => indexNestedArray(suggestionGroups),
        [suggestionGroups],
    );

    // Track the size of the dialog
    const [size, setSize] = useState<BoxSize>({ width: 0, height: 0 });
    useLayoutEffect(() => {
        if (!ref.current) {
            return;
        }

        setSize(calcBorderBoxSize(ref.current));
    }, [suggestions, windowWidth, windowHeight]);

    // Position the dropdown on screen, moving to the other side of the anchor if necessary
    const x = useMemo(
        () =>
            coordCalc(
                anchorX,
                altAnchorX,
                horizontalAnchor == "right",
                horizontalClampBehaviour,
                size.width,
                windowWidth,
            ),
        [
            anchorX,
            altAnchorX,
            horizontalAnchor,
            horizontalClampBehaviour,
            size.width,
            windowWidth,
        ],
    );
    const y = useMemo(
        () =>
            coordCalc(
                anchorY,
                altAnchorY,
                verticalAnchor == "bottom",
                verticalClampBehaviour,
                size.height,
                windowHeight,
            ),
        [
            anchorY,
            altAnchorY,
            verticalAnchor,
            verticalClampBehaviour,
            size.height,
            windowHeight,
        ],
    );

    // Create refs for scrolling individual suggestion rows into view
    const scrollRefs = useRef<RefObject<HTMLButtonElement>[]>([]);
    // Increase the number of refs as needed to match the number of suggestions we have
    scrollRefs.current = range(suggestions.length).map(i => scrollRefs.current[i] ?? createRef());

    // Implement keyboard behaviour
    const [highlightedIndex, setHighlightedIndex] = useState(0);
    useEffect(
        () => setHighlightedIndex(0),
        [suggestions.length],
    );
    const offsetHighlightedIndex = useCallback((offset: number) => {
        const newIndex = modulo(highlightedIndex + offset, suggestions.length);
        setHighlightedIndex(newIndex);

        scrollRefs.current[newIndex].current?.scrollIntoView({ block: "nearest" });
    }, [highlightedIndex, suggestions.length]);
    useEffect(() => {
        const check = (e: KeyboardEvent) => {
            if (isMobileBrowser()) {
                return;
            }

            switch (e.key) {
                case "Enter": {
                    if (e.shiftKey) {
                        break;
                    }
                    e.preventDefault();
                    suggestions[highlightedIndex].onAdd?.();
                    break;
                }
                case "Tab": {
                    e.preventDefault();
                    offsetHighlightedIndex(e.shiftKey ? -1 : 1);
                    break;
                }
                case "ArrowDown": {
                    e.preventDefault();
                    offsetHighlightedIndex(1);
                    break;
                }
                case "ArrowUp": {
                    e.preventDefault();
                    offsetHighlightedIndex(-1);
                    break;
                }
            }
        };

        document.addEventListener("keydown", check);
        return () => document.removeEventListener("keydown", check);
    }, [highlightedIndex, offsetHighlightedIndex, suggestions]);

    const classes = classNames("c-autocomplete", {
        "c-autocomplete--hydrating": !size.width || !size.height,
    });

    const style = {
        left: (isNaN(x)) ? undefined : x,
        top: (isNaN(y)) ? undefined : y,
    };

    return (
        <div
            ref={ref}
            className={classes}
            style={style}
        >
            {indexedSuggestionGroups.map((group, i) => (
                <div key={i} className="c-autocomplete__group">
                    {group.map(([j, suggestion]) => (
                        <Suggestion
                            key={suggestion.key}
                            ref={scrollRefs.current[j]}
                            className={suggestion.className}
                            Content={suggestion.Content}
                            onAdd={suggestion.onAdd}
                            highlighted={!isMobileBrowser() && j === highlightedIndex}
                        />
                    ))}
                </div>
            ))}
        </div>
    );
}
