import { useCallback, useEffect, useRef } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";

import { SystemMessage } from "../components/SystemMessage";
import { AuthStatus, resetStore, updateAuthStatus } from "../features/auth";
import { useAppDispatch } from "../store/redux";
import log from "../misc/log";
import { purgeCache } from "../persist/persist";

const verySafeWrapper = async (t: Promise<unknown> | (() => Promise<unknown>)): Promise<void> => {
    try {
        const p = typeof t === "function" ? t() : t;
        await p;
    }
    catch (e) {
        log.warn(`Promise failed`, e);
    }
};

export default function LogoutView(): React.JSX.Element {
    const { removeUser } = useAuth();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const asyncCleanup = useCallback(async () => {
        log.info(`Resetting auth and purging cache`);

        try {
            await Promise.all([
                purgeCache,
                removeUser,
                new Promise(r => setTimeout(r, 2000)), // enforce minimum 2s total delay
            ].map(verySafeWrapper));
        }
        catch (err) {
            log.warn(`Auth cleanup failed`, err);
        }
    }, [removeUser]);

    const cleaningRef = useRef<boolean>(false);

    useEffect(() => {
        if (cleaningRef.current) return;

        cleaningRef.current = true;

        dispatch(updateAuthStatus(AuthStatus.ClearingAuthentication));

        asyncCleanup().finally(() => {
            dispatch(resetStore());

            navigate("/login", { replace: true });
        });
    }, [asyncCleanup, dispatch, navigate]);

    return <SystemMessage message="Logging you out" />;
}
