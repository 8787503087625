import { useCallback, useEffect, useState } from "react";

export type ClearConditionFunc = () => void;

/**
 * Creates an effect that checks a condition whenever a mousemove event is fired. The
 * condition will only update when a mousemove event is fired, even if the condition
 * itself has changed.
 *
 * @param cond The condition to run whenever the mouse position changes
 * @returns The result of the condition, along with a function to clear the condition
 */
export function useMousePosCondition(
    cond: (x: number, y: number) => boolean,
): [boolean, ClearConditionFunc] {
    const [value, setValue] = useState(false);

    useEffect(() => {
        const check = (e: MouseEvent) => setValue(cond(e.clientX, e.clientY));

        window.addEventListener("mousemove", check);
        return () => window.removeEventListener("mousemove", check);
    }, [cond, setValue]);

    const clear = useCallback(() => {
        setValue(false);
    }, [setValue]);

    return [value, clear];
}

export default useMousePosCondition;
