import { useCallback, useEffect, useRef, useState } from "react";
import useTimerTarget from "./useTimerTarget";
import { DateTime, Duration } from "luxon";
import useOnComponentUnmount from "./useOnComponentUnmount";

interface useDelayedActionProps {
    action: () => void | Promise<void>;
    delay?: number | Duration;
    fireOnUnmount?: boolean;
}

interface useDelayedActionReturnType {
    triggered: boolean;
    hold: () => void;
    resume: () => void;
    trigger: (args?: { hold?: boolean; }) => void;
    reset: () => void;
    refresh: () => void;
}

/** @function
 * Apply a delay to running an action, also allowing the possibility of pausing
 * or cancelling said action.
 *
 * @param action action to be fired after a delay
 * @param delay the delay to use before firing (default 3000ms)
 * @param fireOnUnmount if the action has been triggered, whether to fire the
 * action if the component unmounts before the delay elapses (default false)
 *
 * @returns an object with the following methods:
 * - `trigger` - trigger the action, arm the timer
 * - `hold` - pause the timer.  The action is still triggered. Elapsed time
 * still counts against the delay.
 * - `resume` - unpause the timer
 * - `refresh` - unpause the timer, resetting the delay
 * - `reset` - disarm the timer, reset held status
 * - `triggered` - whether the action has been triggered. A held timer does *not*
 * affect this.
 */
export default function useDelayedAction(
    { action, delay, fireOnUnmount }: useDelayedActionProps,
): useDelayedActionReturnType {
    const triggerDelay = delay ?? 3000;
    const shouldFireOnUnmount = fireOnUnmount ?? false;

    const { now, target, setDelay } = useTimerTarget();
    const [triggered, setTriggered] = useState<boolean>(false);
    const [held, setHeld] = useState<boolean>(false);

    const lastTargetRef = useRef<DateTime | undefined>();

    const canFire = triggered && !held;

    const reset = useCallback(() => {
        setHeld(false);
        setTriggered(false);
        lastTargetRef.current = undefined;
    }, [setHeld, setTriggered]);

    const refresh = useCallback(() => {
        if (held) {
            setHeld(false);
            lastTargetRef.current = undefined;
            setDelay(triggerDelay);
        }
    }, [setHeld, held, triggerDelay, setDelay]);

    const hold = useCallback(() => setHeld(true), [setHeld]);

    const resume = useCallback(() => setHeld(false), [setHeld]);

    const trigger = useCallback((args?: { hold?: boolean; }) => {
        setTriggered(true);
        if (args?.hold) {
            setHeld(true);
        }
    }, [setTriggered]);

    const runAction = useCallback((duringCleanup?: boolean) => {
        if (duringCleanup && !shouldFireOnUnmount) return;

        if (triggered) {
            action();
            reset();
        }
    }, [shouldFireOnUnmount, triggered, action, reset]);

    useOnComponentUnmount(runAction);

    useEffect(() => {
        if (!canFire || lastTargetRef.current === undefined) return;

        if (now < lastTargetRef.current) return;

        runAction(false);
    }, [canFire, target, now, runAction]);

    useEffect(() => {
        if (!canFire) return;

        if (lastTargetRef.current === undefined) {
            if (target) {
                lastTargetRef.current = target;
            }
            else {
                setDelay(triggerDelay);
            }
        }
    }, [triggerDelay, canFire, setDelay, target]);

    return {
        triggered,
        hold,
        resume,
        trigger,
        reset,
        refresh,
    };
}
