import classNames from "classnames";
import React, { memo, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { bondCreationDraftTarget } from "../domain/channels";
import * as d from "../domain/domain";
import { newUserMention } from "../domain/mentions";
import { selectCurrentUserId } from "../features/auth";
import { insertDraftMention, selectDraft } from "../features/channels";
import { selectPrivateOrSquadFilter, topFilterScope } from "../features/filterPanel";
import { selectCurrentSquadIds } from "../features/squads";
import { selectUser } from "../features/users";
import { useAppDispatch, useAppSelector } from "../store/redux";
import useFreshSquadObservers from "../hooks/useFreshSquadObservers";
import useSelectorArgs from "../hooks/useSelectorArgs";
import { userNameForMention } from "../misc/messageContent";
import Avatar from "./gui/Avatar";

function PresenceAvatarUnmemoed(
    props: { userId: d.UserId; },
): React.JSX.Element {
    const { userId } = props;

    const dispatch = useAppDispatch();

    const draftTarget = bondCreationDraftTarget;
    const draft = useSelectorArgs(selectDraft, draftTarget);
    const insertionOffset = draft?.content.draftMessage?.length ?? 0;
    const user = useSelectorArgs(selectUser, userId);

    const currentUserId = useAppSelector(selectCurrentUserId);
    const isCurrentUser = userId == currentUserId;

    // Set the new bond scope, pre-select this user, and navigate to the new bond view
    const pickUserForNewBond = useCallback(() => {
        if (!user) {
            return;
        }

        dispatch(insertDraftMention({
            draftTarget,
            mentionText: "@" + userNameForMention(user),
            mention: newUserMention(userId),
            range: {
                start: insertionOffset,
                end: insertionOffset,
            },
        }));
    }, [dispatch, draftTarget, insertionOffset, user, userId]);

    if (!userId) {
        return <></>;
    }

    const classes = classNames("c-btn", "c-btn--presence", {
        "c-btn--self": isCurrentUser,
    });

    return (
        <button
            className={classes}
            onClick={isCurrentUser ? undefined : pickUserForNewBond}
        >
            <Avatar
                userId={userId}
                context={{ showSquadPresence: true }}
                size="small"
            />
        </button>
    );
}

const PresenceAvatar = memo(PresenceAvatarUnmemoed);

function PresenceHeader(): React.JSX.Element {
    const pathSegments = useLocation().pathname.split("/");
    const viewLocation = pathSegments.length > 1 ? pathSegments[1] : "";

    const currentUserSquads = useAppSelector(selectCurrentSquadIds);
    const privateOrSquadFilter = useAppSelector(selectPrivateOrSquadFilter);
    const scope = useMemo(() => {
        return topFilterScope(privateOrSquadFilter);
    }, [privateOrSquadFilter]);

    const squadsToSubObservers = useMemo(() => {
        if (scope == "private" || scope == "unknown") {
            return currentUserSquads;
        }
        return [scope.squadId];
    }, [scope, currentUserSquads]);

    const sortedSquadObservers = useFreshSquadObservers(
        squadsToSubObservers,
        scope,
    );

    if (viewLocation != "bond") return <></>;

    return (
        <div className="c-presence">
            <div className="c-presence__humans">
                {sortedSquadObservers.map(userId => (
                    <PresenceAvatar
                        userId={userId}
                        key={userId}
                    />
                ))}
            </div>
        </div>
    );
}

export default PresenceHeader;
