import { useCallback, useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { Navigate } from "react-router-dom";
import { SystemMessage } from "../components/SystemMessage";
import { nativePlatformTargetKey, isNativePlatform, getNativeTarget } from "../misc/capacitor";
import { isDevEnv, nativeTargets } from "../misc/environment";
import usePrevious from "../hooks/usePrevious";
import { clearCurrentUserId } from "../features/auth";
import { useAppDispatch } from "../store/redux";
import log from "../misc/log";

// NativeTargetSwitcher renders only if the frontend is running natively using
// Capacitor, i.e. using our mobile client.
// It allows users to switch between bo-nd.dev and bondtest.uk.
function NativeTargetSwitcher(): React.JSX.Element {
    const initialTarget = getNativeTarget();
    const [nativeTarget, setNativeTarget] = useState(initialTarget);
    const previousNativeTarget = usePrevious(nativeTarget);

    const toggleNativeTarget = useCallback(() => {
        setNativeTarget(currentTarget =>
            currentTarget === nativeTargets.bondtestUk ? nativeTargets.bondDev
                : nativeTargets.bondtestUk
        );
    }, []);

    useEffect(() => {
        if (previousNativeTarget && previousNativeTarget !== nativeTarget) {
            localStorage.setItem(nativePlatformTargetKey, nativeTarget!);

            // Refresh the page to cause the AuthController
            // to be recreated with the new OIDC authority.
            log.info("Reloading page due to the native target changing");
            window.location.reload();
        }
    }, [nativeTarget, previousNativeTarget]);

    return (
        <>
            <div className="c-signin__element">
                <label className="c-signin__label">Current target</label>
                <div className="c-signin__value">{nativeTarget}</div>
            </div>
            <button
                className="cp-btn cp-btn-signin"
                title="Switch target"
                onClick={toggleNativeTarget}
            >
                Switch target
            </button>
        </>
    );
}

export default function LoginView(): React.JSX.Element {
    const auth = useAuth();
    const dispatch = useAppDispatch();

    const [previousUserId, setPreviousUserId] = useState<string>("");
    const [inputValue, setInputValue] = useState<string>("");

    useEffect(() => {
        const previousUser = localStorage.getItem("x-beyond-temp-userid");
        if (previousUser) {
            setPreviousUserId(previousUser);
            setInputValue(previousUser);
        }
    }, []);

    const saveDevUser = (newUserId: string) => {
        if (isDevEnv && newUserId) {
            localStorage.setItem("x-beyond-temp-userid", newUserId);
        }
    };

    const inputValid = !isDevEnv || !!inputValue;

    const signIn = useCallback(() => {
        if (!inputValid) return;

        dispatch(clearCurrentUserId());

        saveDevUser(inputValue.toLowerCase());
        auth.signinRedirect();
    }, [auth, inputValue, inputValid, dispatch]);

    useEffect(() => {
        const handleKeyPress = (e: KeyboardEvent) => {
            if (e.key === "Enter") {
                signIn();
            }
        };
        document.addEventListener("keypress", handleKeyPress);

        return () => {
            document.removeEventListener("keypress", handleKeyPress);
        };
    }, [signIn]);

    switch (auth.activeNavigator) {
        case "signinSilent":
            return <SystemMessage message="Signing in..." />;
        case "signoutRedirect":
            return <SystemMessage message="Signing out..." />;
    }

    if (auth.isLoading) {
        return <SystemMessage message="Loading..." />;
    }

    if (auth.isAuthenticated && auth.user) {
        // This could be better...
        return <Navigate to={"/"} />;
    }

    return (
        <div className="c-signin-wrapper">
            <fieldset className="c-signin">
                {auth.error && (
                    <div className="c-signin__error">
                        Authentication required: {auth.error.message}
                    </div>
                )}
                {isNativePlatform && <NativeTargetSwitcher />}
                {isDevEnv && (
                    <>
                        <div className="c-signin__element">
                            <label className="c-signin__label">Current user ID</label>
                            <div className="c-signin__value">{previousUserId}</div>
                        </div>
                        <div className="c-signin__element c-signin__element--active">
                            <label htmlFor="userId" className="c-signin__label">New user ID</label>
                            <div className="c-signin__value">
                                <input
                                    type="text"
                                    id="userId"
                                    name="signin-input"
                                    placeholder="Enter your user ID"
                                    value={inputValue}
                                    autoComplete="off"
                                    maxLength={100}
                                    className="c-signin__input"
                                    onChange={e => setInputValue(e.target.value)}
                                />
                            </div>
                        </div>
                    </>
                )}
                <button
                    className="cp-btn cp-btn-signin"
                    title="Sign in"
                    onClick={signIn}
                    disabled={!inputValid}
                >
                    Sign in
                </button>
            </fieldset>
        </div>
    );
}
