import { createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

import type { AppAsyncThunkConfig, AppDispatch, RootState } from "./types";

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();

export const createAppAsyncThunk = createAsyncThunk.withTypes<AppAsyncThunkConfig>();

export const createAppSelector = createSelector.withTypes<RootState>();
