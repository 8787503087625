import { selectUser } from "../../features/users";
import * as d from "../../domain/domain";
import Avatar from "./Avatar";
import useSelectorArgs from "../../hooks/useSelectorArgs";

export type UserRowProps = {
    userId: d.UserId;
    buttonText?: string;
    onButtonClick?: (userId: d.UserId) => void;
};

export default function UserRow(props: UserRowProps): React.JSX.Element {
    const userOverview = useSelectorArgs(selectUser, props.userId) ??
        { name: `Unknown User (id:${props.userId})` };

    return (
        <div className="c-follower">
            <Avatar
                userId={props.userId}
                size="xs-small"
            />
            <div className="u-truncate-auto">
                {userOverview.name}
            </div>
            {props.buttonText && props.onButtonClick && (
                <button
                    className="c-btn-add-user"
                    onClick={() => props.onButtonClick?.(props.userId)}
                    data-testid={`${props.userId}-btn`}
                >
                    {props.buttonText}
                </button>
            )}
        </div>
    );
}
