import { DateTime, DateTimeUnit, Duration, Interval } from "luxon";
import { useEffect, useMemo } from "react";

import useTimerTarget from "../../hooks/useTimerTarget";
import { modulo } from "../../misc/utils";

/*
    Live (session counter): mm:ss / h:mm:ss
    < 1 min ago: Just now
    1 min ago: 1 min
    n < 60 minutes ago: n mins
    > 60 minutes but same day: [hh:mm]
    Yesterday: Yesterday
    > 7 days: [Week day]
    < 7 days: [Month dd]
    Last year: [Month dd yyyy]

    Note if we specify an optional `to` parameter, we append `to`'s "-HH:mm"
    to the time string.
*/
// TODO: use locale aware formatting for the more complex formats

function getTimeString(preciseStr: string, impreciseStr: string, precise: boolean, to?: DateTime) {
    const fromStr = precise || to ? preciseStr : impreciseStr;
    const toStr = to ? `-${to.toFormat("HH:mm")}` : "";
    return fromStr + toStr;
}

interface IntervalDesc {
    desc: string;
    next: Duration;
}

function intervalDescription(
    i: Interval,
    precise: boolean,
    to?: DateTime,
): { desc: string; next: Duration | DateTimeUnit; } {
    if (!i.start || !i.end || !i.isValid) {
        return { desc: "Just now", next: Duration.fromObject({ seconds: 10 }) };
    }

    const minuteLength = i.length("minutes");

    if (!to && minuteLength < 1) {
        return {
            desc: "Just now",
            next: Duration.fromObject({ seconds: 60 - i.length("seconds") }),
        };
    }
    if (!to && minuteLength < 60) {
        const suffix = Math.floor(minuteLength) == 1 ? "" : "s";
        const secondsOnly = modulo(i.length("seconds"), 60);
        return {
            desc: `${minuteLength.toFixed(0)} min` + suffix,
            next: Duration.fromObject({ seconds: 60 - secondsOnly }),
        };
    }

    const dayCount = i.count("days");

    if (dayCount == 1) {
        return {
            desc: getTimeString(
                i.start.toFormat("'Today' HH:mm"),
                i.start.toFormat(`HH:mm`),
                precise,
                to,
            ),
            next: "day",
        };
    }
    if (dayCount == 2) {
        return {
            desc: getTimeString(
                i.start.toFormat("'Yesterday' HH:mm"),
                "Yesterday",
                precise,
                to,
            ),
            next: "day",
        };
    }
    if (dayCount <= 7) {
        return {
            desc: getTimeString(
                i.start.toFormat("cccc HH:mm"),
                i.start.toFormat("cccc"),
                precise,
                to,
            ),
            next: "day",
        };
    }

    const yearCount = i.count("years");

    if (yearCount == 1) {
        return {
            desc: getTimeString(
                i.start.toFormat("LLLL d HH:mm"),
                i.start.toFormat("LLLL d"),
                precise,
                to,
            ),
            next: "year",
        };
    }
    return {
        desc: getTimeString(
            i.start.toFormat("LLLL d yyyy HH:mm"),
            i.start.toFormat("LLLL d yyyy"),
            precise,
            to,
        ),
        next: "year",
    };
}

export interface TimeAgoProps {
    from: number;
    live: boolean;
    describeCallback?: () => void;
    precise?: boolean;
    to?: number;
}

function findDelay(end: DateTime | null, next: Duration | DateTimeUnit): Duration {
    if (typeof next !== "string") {
        // Duration
        return next;
    }

    // DateTimeUnit
    if (!end) {
        return Duration.fromObject({ seconds: 10 });
    }

    const nextTarget = end.endOf(next).plus({ milliseconds: 1 });
    return Interval.fromDateTimes(end, nextTarget).toDuration();
}

function describe(
    now: DateTime,
    fromNum: number,
    precise: boolean,
    toNum?: number,
): IntervalDesc {
    const from = DateTime.fromMillis(fromNum);
    const to = toNum !== undefined ? DateTime.fromMillis(toNum) : undefined;
    const interval = Interval.fromDateTimes(from, now);

    const v = intervalDescription(interval, precise, to);

    return { desc: v.desc, next: findDelay(interval.end, v.next) };
}

export default function TimeAgo(props: TimeAgoProps): React.JSX.Element {
    const { from, live, describeCallback, to, precise = false } = props;

    // `now` is suitably memoized, and changes to it mean there really is
    // work to do to recalculate the description.
    const { now, setDelay } = useTimerTarget();

    const described = useMemo(() => {
        describeCallback?.();
        return describe(now, from, precise, to);
    }, [describeCallback, now, from, precise, to]);

    useEffect(() => {
        if (live) {
            setDelay(described.next);
        }
    }, [live, described, setDelay]);

    return (
        <>
            {described.desc}
        </>
    );
}
