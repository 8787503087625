import { useEffect, useState } from "react";
import { sustainBrowserFocusReport, updateActiveStatus } from "../features/connection";
import useStreamDispatch from "../hooks/useStreamDispatch";
import useIdle from "../hooks/useIdle";
import { useAppDispatch } from "../store/redux";

export default function WindowFocusTracker(): React.JSX.Element {
    // If there are ordering problems, we coud init these in a useEffect after
    // the event listeners are set up.
    const [focused, setFocused] = useState<boolean>(document.hasFocus());
    const [visible, setVisible] = useState<boolean>(document.visibilityState == "visible");
    const awake = !useIdle({ minutes: 1 });

    useEffect(() => {
        const focus = () => {
            setFocused(true);
        };
        window.addEventListener("focus", focus);

        const blur = () => {
            setFocused(false);
        };
        window.addEventListener("blur", blur);

        const updateVisibility = () => {
            // It's possible we may want to separately report visibility for some uses
            setVisible(!document.hidden);
        };
        document.addEventListener("visibilitychange", updateVisibility);

        return () => {
            window.removeEventListener("focus", focus);
            window.removeEventListener("blur", blur);
            document.removeEventListener("visibilitychange", updateVisibility);
        };
    }, []);

    const isActive = visible && focused && awake;

    // Store active state in Redux store when user is totally active in window
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(updateActiveStatus(isActive));
    }, [dispatch, isActive]);

    // This stream should start sending when visibleAndFocused goes from false
    // to true, and stop sending when it goes from true to false.
    useStreamDispatch(() => isActive && sustainBrowserFocusReport(), [isActive]);

    return <></>;
}
