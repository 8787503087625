import type { PersistorMap } from "../store/types";

export const { getPersistorMap, setPersistorMap } = (() => {
    let m: PersistorMap<any>;
    return {
        getPersistorMap: <T>() => m as PersistorMap<T>,
        setPersistorMap: <T>(pm: PersistorMap<T>) => {
            m = pm;
        },
    };
})();
