import * as d from "./domain";

export interface CallOverview {
    id: d.CallId;
    sessionId: d.RtcSessionId;

    startedAt: d.Timestamp;
    endedAt: d.Timestamp;

    knowledge: CallKnowledgePreview;
    participants: CallParticipant[];
}

export interface CallKnowledgePreview {
    title: string;
    summary: string;
    detailedSummary: string;
}

export interface CallParticipant {
    userId: d.UserId;
    role: CallParticipationRole;
}

export interface CallParticipationRole {
    observing?: boolean;
    publishingMicrophone?: boolean;
    publishingCamera?: boolean;
    publishingScreenShare?: boolean;
}

export const isNullRole = (role: CallParticipationRole) => {
    return !role.observing && !role.publishingMicrophone && !role.publishingCamera &&
        !role.publishingScreenShare;
};

export enum CallLanguageCode {
    ChineseSimplified = "zh-CN",
    ChineseTraditional = "zh-TW",
    Danish = "da",
    Dutch = "nl",
    English = "en",
    French = "fr",
    German = "de",
    Hindi = "hi",
    Indonesian = "id",
    Italian = "it",
    Japanese = "ja",
    Korean = "ko",
    Norwegian = "no",
    Polish = "pl",
    Portuguese = "pt",
    Russian = "ru",
    Spanish = "es",
    Swedish = "sv",
    Tamil = "ta",
    Tamasheq = "taq",
    Turkish = "tr",
    Ukranian = "uk",
}

export const DefaultCallLanguage = CallLanguageCode.English;

export interface CallLanguageCodeName {
    id: CallLanguageCode;
    name: string;
}

export const CallLanguageNames: CallLanguageCodeName[] = [
    { id: CallLanguageCode.ChineseSimplified, name: "Chinese (simplified)" },
    { id: CallLanguageCode.ChineseTraditional, name: "Chinese (traditional)" },
    { id: CallLanguageCode.Danish, name: "Danish" },
    { id: CallLanguageCode.Dutch, name: "Dutch" },
    { id: CallLanguageCode.English, name: "English" },
    { id: CallLanguageCode.French, name: "French" },
    { id: CallLanguageCode.German, name: "German" },
    { id: CallLanguageCode.Hindi, name: "Hindi" },
    { id: CallLanguageCode.Indonesian, name: "Indonesian" },
    { id: CallLanguageCode.Italian, name: "Italian" },
    { id: CallLanguageCode.Japanese, name: "Japanese" },
    { id: CallLanguageCode.Korean, name: "Korean" },
    { id: CallLanguageCode.Norwegian, name: "Norwegian" },
    { id: CallLanguageCode.Polish, name: "Polish" },
    { id: CallLanguageCode.Portuguese, name: "Portuguese" },
    { id: CallLanguageCode.Russian, name: "Russian" },
    { id: CallLanguageCode.Spanish, name: "Spanish" },
    { id: CallLanguageCode.Swedish, name: "Swedish" },
    { id: CallLanguageCode.Tamil, name: "Tamil" },
    { id: CallLanguageCode.Tamasheq, name: "Tamasheq" },
    { id: CallLanguageCode.Turkish, name: "Turkish" },
    { id: CallLanguageCode.Ukranian, name: "Ukranian" },
];
