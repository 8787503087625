import { createListenerMiddleware } from "@reduxjs/toolkit";

import type { AppDispatch, RootState } from "./types";

/** Create a single instance of the middleware.
 * Listeners add themselves by calling `startAppListening()` with their callback.
 */
export const listenerMiddleware = createListenerMiddleware();

/**
 * Add a callback as a store listener.
 */
export const startAppListening = listenerMiddleware.startListening.withTypes<
    RootState,
    AppDispatch
>();

export type AppStartListening = typeof startAppListening;
