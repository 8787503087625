import { useCallback, useEffect, useMemo, useRef } from "react";
import WebSocket from "isomorphic-ws";

import transport, {
    WebsocketAuthMessage,
    WebsocketError,
    WebsocketErrorCode,
    WebsocketRpcs,
    WebsocketRpcsInterface,
    setBearerToken,
} from "../api/transport";
import { useAppDispatch, useAppSelector } from "../store/redux";
import {
    checkRefreshToken,
    selectAuthenticated,
    selectBearerToken,
    fetchCurrentUserId,
    selectCurrentOrgId,
    selectCurrentUserId,
} from "../features/auth";
import { selectConnectionIdentifiers } from "../features/meta";
import {
    ConnectionStatus,
    selectConnectionStatus,
    selectOnlineStatus,
    updateConnectionStatus,
} from "../features/connection";
import log from "../misc/log";
import useBackoff, { ActionBeginReturnType } from "../hooks/useBackoff";
import { getEnvironmentConfig } from "../misc/environment";
import { fromProtoUserId, pbUserId } from "../api/util";
import { useConnectedEffect } from "../hooks/useConnectedEffect";

// The number of milliseconds after running the auth callback that we should
// wait before allowing ourselves to mark a websocket connection as
// "successfully connected."
export const connectionSuccessfulDelay = 2048;

export const clientConnectionProtocolVersion = 2;

export interface ConnectionControllerProps {
    rpcs?: () => WebsocketRpcsInterface;
}

const targetConfig = getEnvironmentConfig();
const targetURL = new URL(targetConfig.apiBaseUrl + "/client");
const defaultFactory = () => new WebsocketRpcs();

export function ConnectionController(props: ConnectionControllerProps) {
    const dispatch = useAppDispatch();

    const factory = props.rpcs ?? defaultFactory;

    const token = useAppSelector(selectBearerToken);
    const authenticated = useAppSelector(selectAuthenticated);
    const status = useAppSelector(selectConnectionStatus);
    // This is: "does the browser think we're online?"
    const online = useAppSelector(selectOnlineStatus);

    const connectionIdentifiers = useAppSelector(selectConnectionIdentifiers);
    const currentUserId = useAppSelector(selectCurrentUserId);

    const authMessage = useMemo(() =>
        new WebsocketAuthMessage({
            ...connectionIdentifiers,
            userId: currentUserId && pbUserId(currentUserId),
            authToken: token ?? "",
            protocolVersion: clientConnectionProtocolVersion,
        }), [connectionIdentifiers, currentUserId, token]);

    const { actionBegin, attempts, actionIsPermitted } = useBackoff();

    const wsRef = useRef<WebsocketRpcsInterface>();
    const backoffRef = useRef<ActionBeginReturnType>();

    const transition = useCallback((status: ConnectionStatus) => {
        dispatch(updateConnectionStatus(status));
    }, [dispatch]);

    const authCallback = useCallback(
        (backoffCallback: ActionBeginReturnType) => async (ws: WebSocket) => {
            ws.send(authMessage.toBinary());

            log.info(
                "Sent pre-GOAT websocket authentication message",
                {
                    deviceTag: authMessage.deviceTag,
                    connTag: `${authMessage.connTagPrefix}::${authMessage.connTagNum}`,
                    userId: authMessage.userId && fromProtoUserId(authMessage.userId),
                },
            );

            // We do not want to consider ourselves "successfully connected" from
            // the perspective of the `useBackoff` hook here. It's possible that
            // we're about to be booted off by the server if, say, our token has
            // expired and we haven't noticed.
            // Instead, only successfully complete the action after a delay.
            setTimeout(() => {
                const isSuccess = ws.readyState === WebSocket.OPEN;

                // This is idempotent, so it doesn't matter if the
                // `websocketErrorTransition` pathway already called it.
                backoffCallback(isSuccess);
            }, connectionSuccessfulDelay);
            // We do, however, want to start sending any RPC requests we've been
            // storing up, so we should mark ourselves as connected in the redux
            // store.
            transition(ConnectionStatus.Connected);
        },
        [authMessage, transition],
    );

    const websocketErrorTransition = useCallback(
        (code: WebsocketErrorCode, backoffCallback: ActionBeginReturnType) => {
            backoffCallback(false);
            backoffRef.current = undefined;
            wsRef.current = undefined;

            if (code === WebsocketErrorCode.Unauthorised) {
                transition(ConnectionStatus.ClearAuth);
            }
            else if (code === WebsocketErrorCode.UnsupportedProtocol) {
                log.error(`Outdated websocket protocol; Refresh page to update`);
            }
            else {
                transition(ConnectionStatus.AwaitReconnect);
            }
        },
        [transition],
    );

    // Always keep the token for the interceptor up-to-date.
    useEffect(() => {
        if (token) {
            setBearerToken(token);
        }
    }, [token]);

    // Try to connect the websocket
    useEffect(() => {
        if (status !== ConnectionStatus.ShouldConnect) return;

        if (!online) return;

        if (!token || !authenticated) {
            log.error(`Want to connect but not authenticated`);
            transition(ConnectionStatus.ClearAuth);
            return;
        }

        const newWs = factory();
        wsRef.current = newWs;

        const backoffCallback = actionBegin();
        backoffRef.current = backoffCallback;

        log.info(`New websocket`);

        newWs.disconnected.wait().then((err: WebsocketError) => {
            // It's possible that by the time we're woken up here, we're no longer the active
            // websocket. In such a case, we need to ignore this event.
            if (newWs != wsRef.current) return;

            websocketErrorTransition(err.code, backoffCallback);
        });

        newWs.connected.wait().then((err: WebsocketError | null) => {
            if (newWs != wsRef.current) return;

            if (err) {
                websocketErrorTransition(err.code, backoffCallback);
            }
            else {
                // Don't transition to `Connected` here - wait for the auth callback
                // to complete.
            }
        });

        const authCallbackWithBackoffHandles = authCallback(backoffCallback);
        newWs.connect(targetURL, authCallbackWithBackoffHandles);

        transport.reset(newWs, new DOMException("reset", "AbortError"));
    }, [
        transition,
        factory,
        status,
        token,
        actionBegin,
        authenticated,
        authCallback,
        websocketErrorTransition,
        online,
    ]);

    // Reconnect after a delay.
    useEffect(() => {
        if (status !== ConnectionStatus.AwaitReconnect) return;

        if (!actionIsPermitted) return;

        transition(ConnectionStatus.ShouldConnect);
    }, [transition, status, actionIsPermitted, attempts]);

    // Close connection if we encounter an auth problem.
    useEffect(() => {
        if (status !== ConnectionStatus.ClearAuth) return;

        log.info(`Lost authentication, closing websocket`);
        wsRef.current?.done();
        wsRef.current = undefined;
        if (backoffRef.current) {
            // This is a "success" pathway, because to be here we are electing
            // to be good citizens and tear down the websocket pre-emptively.
            // In reality, I don't think it matters which method we call.
            backoffRef.current(true);
            backoffRef.current = undefined;
        }
        dispatch(checkRefreshToken());

        transition(ConnectionStatus.AwaitAuth);
    }, [dispatch, transition, status]);

    useEffect(() => {
        // If we're connected, but have no user ID, get it from the backend.
        if (status == ConnectionStatus.Connected && currentUserId == undefined) {
            dispatch(fetchCurrentUserId()).unwrap().then(resp => {
                // If we got a user ID, refresh the page to reload the IndexedDB
                if (resp?.userId) {
                    log.info("Got user ID, reloading page");
                    window.location.reload();
                }
            });
        }
    }, [currentUserId, status, dispatch]);

    const currentOrgId = useAppSelector(selectCurrentOrgId);
    useConnectedEffect(() => {
        if (currentOrgId == undefined) {
            dispatch(fetchCurrentUserId());
        }
    }, [currentOrgId, dispatch]);

    return <></>;
}
