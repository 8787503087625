import { useRef, useEffect } from "react";

interface usePreviousOptions<T> {
    filter?: (t: T) => boolean;
}

export default function usePrevious<T>(input: T, options?: usePreviousOptions<T>) {
    const { filter } = options ?? {};

    const ref = useRef<T>();

    useEffect(() => {
        if (filter?.(input) ?? true) {
            ref.current = input;
        }
    }, [filter, input]);

    return ref.current;
}
