import { useCallback, useEffect, useRef, useState } from "react";
import vuWorkletUrl from "../../misc/vuMeterWorkletProcessor.ts?worker&url";

const SAMPLE_INTERVAL_MILLIS = 50;

export interface useVolumeProps {
    enabled?: boolean;
    stream?: MediaStream;
}

export function useVolume(props: useVolumeProps) {
    const { stream } = props;
    const enabled = props.enabled ?? true;

    const audioContextRef = useRef<AudioContext | undefined>(undefined);
    const [volume, setVolume] = useState<number | undefined>(undefined);

    const startVuMeter = useCallback(async () => {
        if (!stream) {
            return;
        }
        if (!audioContextRef.current) {
            // Add module to audio context
            audioContextRef.current = new AudioContext();
            await audioContextRef.current.audioWorklet.addModule(vuWorkletUrl);
        }

        // Create analyser node and connect it to source
        const mediaStreamAudioSourceNode = audioContextRef.current.createMediaStreamSource(stream);
        const vuMeterNode = new AudioWorkletNode(audioContextRef.current, "vumeter", {
            numberOfInputs: 1,
            numberOfOutputs: 0,
            channelCount: 1,
            processorOptions: { updateIntervalMS: SAMPLE_INTERVAL_MILLIS },
        });
        mediaStreamAudioSourceNode.connect(vuMeterNode);

        // Handle new volume data
        vuMeterNode.port.onmessage = event => {
            if (event.data.volume) {
                setVolume(event.data.volume);
            }
        };

        // Start analysis
        vuMeterNode.port.start();

        return () => {
            vuMeterNode.port.close();
            vuMeterNode.disconnect();
            mediaStreamAudioSourceNode.disconnect();
        };
    }, [stream]);

    useEffect(() => {
        if (!enabled || !window.AudioContext) {
            return;
        }

        // Start analysis
        let cleanup: (() => void) | undefined;
        startVuMeter().then(c => cleanup = c);

        // Stop analysis
        return () => {
            cleanup?.();
            setVolume(undefined);
        };
    }, [enabled, setVolume, startVuMeter, stream]);

    return volume;
}
