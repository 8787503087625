import React, { useState, useEffect } from "react";

export interface EditTitleProps {
    userSpecifiedTitle: string;
    aiGeneratedTitle: string;
    updateTitle: (title: string) => void;
    setEditing: (editable: boolean) => void;
}

export function EditTitle(props: EditTitleProps): React.JSX.Element {
    const title = props.userSpecifiedTitle || props.aiGeneratedTitle;
    const [userTitle, setUserTitle] = useState(title);
    const setEditing = props.setEditing;

    // TODO - this is a side effect, should be moved to a custom hook
    // (the addEventListener and removeEventListener parts very much belong in a custom hook, I think, but my thoughts are not usually worth much)
    useEffect(() => {
        const handleEscape = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                e.preventDefault();
                setEditing(false);
            }
        };
        document.addEventListener("keydown", handleEscape);

        const handleFocusLost = (e: FocusEvent) => {
            if (!e.relatedTarget) {
                setEditing(false);
            }
        };
        document.addEventListener("focusout", handleFocusLost);

        return () => {
            document.removeEventListener("keydown", handleEscape);
            document.removeEventListener("focusout", handleFocusLost);
        };
    }, [setEditing]);

    return (
        <div className="c-edit-title">
            <div className="c-edit-title__item">
                <div className="c-form-element c-form-element--full">
                    <label
                        htmlFor="edit-bond-title"
                        className="c-label"
                    >
                        Edit current title:
                    </label>
                    <input
                        className="c-input-title"
                        name="edit-bond-title"
                        id="edit-bond-title"
                        type={"text"}
                        value={userTitle}
                        autoFocus
                        onChange={e => setUserTitle(e.target.value)}
                        onKeyDown={e => {
                            if (e.key === "Enter") {
                                props.updateTitle(userTitle);
                                setEditing(false);
                            }
                        }}
                        onKeyUp={e => {
                            if (e.key === "Escape") {
                                setEditing(false);
                            }
                        }}
                    />
                </div>
                <button
                    className="cp-btn cp-btn-edit-title"
                    disabled={userTitle === title}
                    onClick={() => props.updateTitle(userTitle)}
                >
                    Update title
                </button>
            </div>

            <div className="c-edit-title__item">
                <div className="c-form-element c-form-element--full">
                    <label className="c-label">
                        AI generated title:
                    </label>
                    <div
                        className="c-input-title"
                        data-testid="bond-ai-title"
                    >
                        {props.aiGeneratedTitle}
                    </div>
                </div>

                {(title != props.aiGeneratedTitle || props.userSpecifiedTitle != "") ? (
                    <button
                        className="cp-btn cp-btn-edit-title"
                        disabled={!props.aiGeneratedTitle}
                        onClick={() => {
                            setUserTitle(props.aiGeneratedTitle);
                            props.updateTitle("");
                        }}
                    >
                        Use generated title
                    </button>
                ) : (
                    <button
                        className="cp-btn cp-btn-edit-title"
                        disabled={!props.aiGeneratedTitle}
                        onClick={() => props.updateTitle(props.aiGeneratedTitle)}
                    >
                        Lock title
                    </button>
                )}
            </div>

            <button
                className="c-btn-close c-btn-close--small c-btn-close--right-top"
                title="Close"
                onClick={() => setEditing(false)}
            >
                Close
            </button>
        </div>
    );
}
