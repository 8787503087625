import { nanoid } from "@reduxjs/toolkit";
import { useContext, useCallback } from "react";
import { selectCurrentOrgId, selectCurrentUserId } from "../features/auth";
import { useAppDispatch, useAppSelector } from "../store/redux";
import log from "../misc/log";
import { BlockBlobClientFactory, FileStashContext } from "./managers/AttachmentManager";
import {
    clearUploadableAvatar,
    completeAvatarUploadThunk,
    getAvatarUploadCredentialsThunk,
    selectUploadableAvatar,
} from "../features/users";
import { useConnectedEffect } from "../hooks/useConnectedEffect";
import { BlockBlobClient, newPipeline } from "@azure/storage-blob";
import { UploadableAvatarImage } from "../domain/users";
import Avatar from "./gui/Avatar";
import { isValidAvatarMimeType } from "../api/users";
import { useSelfInterest } from "../hooks/interest/useInterestedUsers";
import { FeatureFlagged } from "./FeatureFlags";
import { fileNamePreparer } from "../misc/attachments";

const blockBlobClientGenerator = (url: string) => {
    return new BlockBlobClient(url, newPipeline());
};

export default function AvatarUploadControls(
    { clientFactory }: { clientFactory?: BlockBlobClientFactory; },
): React.JSX.Element {
    const dispatch = useAppDispatch();

    const blobClientFactory = clientFactory || blockBlobClientGenerator;
    const fileStash = useContext(FileStashContext);

    const userId = useAppSelector(selectCurrentUserId);
    // We should consider which org should really own a user's avatar image
    const orgId = useAppSelector(selectCurrentOrgId);

    useSelfInterest();

    const startAvatarUpload = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        log.info("Starting avatar image upload");

        if (!e.target.files || e.target.files.length != 1) {
            e.target.value = "";
            return;
        }

        if (!userId || !orgId) {
            log.error("Starting avatar upload: UserId or OrgId missing");
            return;
        }

        const file = e.target.files.item(0);
        if (!file) {
            log.warn("File missing: skipping");
            return;
        }
        if (!isValidAvatarMimeType(file.type)) {
            log.warn("Invalid mime type for an avatar");
            return;
        }

        const localId = nanoid();
        fileStash.set(localId, file);

        const receivedCredentials = dispatch(getAvatarUploadCredentialsThunk({
            uploadableAvatarId: localId,
            properties: {
                fileName: file.name,
                fileSize: file.size,
                mimeType: file.type,
            },
            ownership: {
                orgId: orgId,
                uploaderId: userId,
            },
        }));

        const getCredentialsSuccessHandler = () => {
            log.info("Got avatar image upload credentials");
        };
        const getCredentialsFailureHandler = (e: any) => {
            log.error("Problem when getting attachment upload credentials", e);
        };

        receivedCredentials.then(
            getCredentialsSuccessHandler,
            getCredentialsFailureHandler,
        );
    }, [dispatch, orgId, userId, fileStash]);

    const uploadableAvatar = useAppSelector(selectUploadableAvatar);
    const uploadableExists = !!uploadableAvatar;

    const finishUploadableAvatar = useCallback(() => {
        // This may become more complex in the future, with different states to
        // represent the upload completing, or failing at different stages.
        dispatch(clearUploadableAvatar());
    }, [dispatch]);

    const completeAvatarImageUpload = useCallback((uai: UploadableAvatarImage) => () => {
        const attachmentCompleted = dispatch(completeAvatarUploadThunk({
            uploadableAvatarId: uai.localId,
            blobId: uai.credentials!.blobId,
            completerId: uai.uploaderId,
        }));
        const successHandler = () => {
            log.info("Avatar image upload completed");
        };
        const failureHandler = (e: any) => {
            // No retry mechanism for now
            log.error("Completing avatar image upload failed", e);
        };

        attachmentCompleted
            .then(
                successHandler,
                failureHandler,
            ).finally(
                () => {
                    fileStash.delete(uai.localId);
                    finishUploadableAvatar();
                },
            );
    }, [dispatch, fileStash, finishUploadableAvatar]);

    useConnectedEffect(() => {
        if (!uploadableAvatar) {
            return;
        }

        const file = fileStash.get(uploadableAvatar.localId);
        if (!file) {
            log.error("File missing from stash");
            finishUploadableAvatar();
            return;
        }

        if (!isValidAvatarMimeType(file.type)) {
            log.error("Invalid mime type for an avatar");
            finishUploadableAvatar();
            return;
        }

        // Copied straight from AttachmentManager.tsx, should consider DRY
        // TODO: maybe AttachmentManager is really BlobUploadManager?
        const blockBlobClient = blobClientFactory(
            uploadableAvatar.credentials.url,
        );
        const dataUpload = blockBlobClient.uploadData(file, {
            maxSingleShotSize: 4 * 1024 * 1024,
            blobHTTPHeaders: {
                blobContentType: file.type,
                blobCacheControl: "max-age=31536000, immutable",
                blobContentDisposition: `attachment;filename="${fileNamePreparer(file.name)}"`,
            },
        });

        const uploadAvatarFailureHandler = (e: any) => {
            log.error("Failed to upload avatar image file", e);
            finishUploadableAvatar();
        };

        dataUpload
            .then(
                completeAvatarImageUpload(uploadableAvatar),
                uploadAvatarFailureHandler,
            );
    }, [
        uploadableAvatar,
        blobClientFactory,
        completeAvatarImageUpload,
        fileStash,
        finishUploadableAvatar,
    ]);

    if (!userId) {
        return <></>;
    }

    return (
        <FeatureFlagged flag={"avatar-image-upload"} match={true}>
            <div className="c-settings__user">
                <h3>Change profile picture</h3>
                <div className="c-settings-avatar">
                    <Avatar
                        userId={userId}
                        size="medium"
                    />
                    <input
                        type="file"
                        id="avatarImageUpload"
                        multiple={false}
                        onChange={startAvatarUpload}
                        className="cp-btn c-fileupload c-fileupload--profile"
                        title="Upload photo"
                    />
                    {uploadableExists && `Uploading image...`}
                </div>
            </div>
        </FeatureFlagged>
    );
}
