import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import {
    selectBondById,
    selectChannelIdByBondId,
    selectSelectedBondId,
    streamAndObserveBond,
    streamArchivedBondsList,
    streamCurrentUserCatchupSummaries,
    streamCurrentUserBondPreviews,
    updateSelectedBond,
} from "../features/bonds";
import { useAppDispatch, useAppSelector } from "../store/redux";
import { closeLiveView, JoinedCallView, selectJoinedCallView } from "../features/calls";
import useAddressParams from "../hooks/useAddressParams";
import useSelectorArgs from "../hooks/useSelectorArgs";
import useStreamDispatch from "../hooks/useStreamDispatch";
import ChatView from "./ChatView";

import { useHotkeys } from "react-hotkeys-hook";
import { isMobileBrowser } from "../misc/mobile";
import { Focusable, Optional } from "../misc/types";
import { streamPublishedSequenceNumbers } from "../features/channels";
import useObserveCurrentSquads from "../hooks/useObserveCurrentSquads";
import { bondCreationDraftTarget, newChannelDraftTarget } from "../domain/channels";
import { transferDraftThunk } from "../features/bondCreation";
import usePrevious from "../hooks/usePrevious";
import useInterestedCall from "../hooks/interest/useInterestedCall.ts";
import { MetaInterestCounterKey, selectInterestInKey } from "../features/meta";
import classNames from "classnames";
import { BondChildRouteContext } from "../misc/bondChildRouteContext";
import {
    PrivateOrSquadFilterOption,
    setPrivateOrSquadFilterThunk,
    setRelevanceLevelFilter,
} from "../features/filterPanel.ts";
import useInterestedSquads from "../hooks/interest/useInterestedSquads.ts";

export default BondView;

export function BondViewStreamSubscriptions(): React.JSX.Element {
    // This component is always mounted whenever these are needed.
    // So they are started and ended here to ensure that they are not
    // continuously stopped and started.
    useStreamDispatch(streamCurrentUserBondPreviews, []);
    useStreamDispatch(streamPublishedSequenceNumbers, []);
    useStreamDispatch(streamArchivedBondsList, []);
    useStreamDispatch(streamCurrentUserCatchupSummaries, []);

    // Always observe our squads while in either the card view or single bond view
    useObserveCurrentSquads();

    return <></>;
}

export function BondView(): React.JSX.Element {
    const { bondId, messageId } = useAddressParams();

    // TODO: remove this
    const selectedBondId = useAppSelector(selectSelectedBondId);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const prevBondId = usePrevious(bondId);
    const bond = useSelectorArgs(selectBondById, bondId);
    const joinedCallView = useAppSelector(selectJoinedCallView);
    const isLiveView = joinedCallView === JoinedCallView.Live;

    const chatRef = useRef<Focusable>(null);
    useEffect(() => {
        if (isMobileBrowser()) {
            return;
        }

        if (bondId != prevBondId) {
            chatRef.current?.focus({ goToEnd: true });
        }
    }, [bondId, prevBondId]);

    useEffect(() => {
        if (bondId !== selectedBondId) {
            dispatch(updateSelectedBond(bondId));
        }
    }, [dispatch, bondId, selectedBondId]);

    useStreamDispatch(() => bondId && streamAndObserveBond(bondId), [bondId]);
    useInterestedCall(bond?.liveCallIds[0]);
    useInterestedSquads(bond?.squadIds);

    const showBondInterestedAction = useCallback(() => {
        navigate("interested");
    }, [navigate]);

    const channelId = useAppSelector(selectChannelIdByBondId(bondId));

    // Escape key handler
    const navigateUp = useCallback(async () => {
        if (channelId) {
            await dispatch(transferDraftThunk({
                from: newChannelDraftTarget(channelId),
                to: bondCreationDraftTarget,
            }));
            navigate("/bond");
        }
        else {
            await dispatch(
                setPrivateOrSquadFilterThunk({
                    by: "option",
                    option: PrivateOrSquadFilterOption.ALL,
                }),
            );
            dispatch(setRelevanceLevelFilter({ by: "all" }));
        }
    }, [navigate, dispatch, channelId]);

    const minimiseGrid = useCallback(() => dispatch(closeLiveView()), [dispatch]);
    // NB: both callbacks are memoised above, so goBack is stable when passed to the hook.
    const goBack = isLiveView ? minimiseGrid : navigateUp;
    const hotkeyBlockingInterest = useSelectorArgs(
        selectInterestInKey,
        MetaInterestCounterKey.BlockHotkey,
    );
    const isEscHotkeyBlocked = useCallback(() => hotkeyBlockingInterest, [hotkeyBlockingInterest]);
    useHotkeys("esc", goBack, {
        ignoreEventWhen: isEscHotkeyBlocked,
        enableOnFormTags: ["textarea"],
    });

    useEffect(() => {
        if (bondId && !bond) {
            const t = setTimeout(() => {
                navigate("/bond");
            }, 2 * 1000);

            return () => {
                clearTimeout(t);
            };
        }
    }, [bond, bondId, navigate]);

    const raiseBondViewInterest = useSelectorArgs(
        selectInterestInKey,
        MetaInterestCounterKey.RaiseBondView,
    );

    // Memoise the outlet context to avoid unnecessary rerenders
    const outletCtx: Optional<BondChildRouteContext> = useMemo(
        () => bondId ? { bondId } : undefined,
        [bondId],
    );

    if (!bondId || !bond) {
        return <h1>No bond found. Redirecting...</h1>;
    }
    else {
        const classes = classNames(
            "l-main",
            isLiveView ? "l-main--call-view" : "l-main--bond-view",
            {
                "l-main--bond-view---raised": raiseBondViewInterest,
            },
        );

        return (
            <>
                <BondViewStreamSubscriptions />
                <main className={classes}>
                    <ChatView
                        ref={chatRef}
                        bondId={bondId}
                        scrollToMessageId={messageId}
                        showBondInterestedAction={showBondInterestedAction}
                    />
                    <Outlet context={outletCtx} />
                </main>
            </>
        );
    }
}
