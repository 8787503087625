import * as d from "../domain/domain";
import { createAppSelector } from "../store/redux";
import { selectSuggestedBonds } from "./bondCreation";
import { selectBondEntities, selectBonds } from "./bonds";
import { selectAllChannelMaps } from "./channels";
import { memoizeOptions } from "./selectors";

// Avoid circular dependency between bonds and bondCreation slices.
export const selectSuggestedChannelIds = createAppSelector(
    [selectSuggestedBonds, selectBondEntities],
    (suggestedBonds, bonds): Partial<Record<d.BondId, d.ChannelId>> => {
        const entries = suggestedBonds
            .filter(sb => bonds[sb.bondId]?.channelId)
            .map(sb => [sb.bondId, bonds[sb.bondId]!.channelId]);
        return Object.fromEntries(entries);
    },
    memoizeOptions.weakMapShallow,
);

// Avoid circular dependency between bonds and channels slices.
export const selectChannelsWithUnfetchedMessages = createAppSelector(
    [
        selectBonds,
        selectAllChannelMaps,
    ],
    (orderedBonds, cm) =>
        orderedBonds
            .filter(
                ({ channelId, maxSequenceNumber }) => {
                    const local = cm[channelId]?.localSequenceNumber;
                    return maxSequenceNumber !== 0 &&
                        (local === undefined || local < maxSequenceNumber);
                },
            ).map(bo => bo.channelId),
);
