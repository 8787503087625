import React, { ForwardedRef, forwardRef, useMemo, useRef } from "react";

import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { bondCreationDraftTarget, newChannelDraftTarget } from "../domain/channels";
import { DraftChatMessage } from "../domain/chats";
import * as d from "../domain/domain";
import { MediaToggleMap } from "../domain/mediaDevices";
import { transferDraftThunk } from "../features/bondCreation";
import {
    selectAllBondInvolvees,
    selectBondIdByChannelId,
    selectLiveCallIdByBondId,
} from "../features/bonds";
import { selectDraftMessageSendableStatus, stageMessageForChannel } from "../features/channels";
import { useAppDispatch, useAppSelector } from "../store/redux";
import useMergedRefs from "../hooks/useMergedRefs";
import useSelectorArgs from "../hooks/useSelectorArgs";
import { Focusable } from "../misc/types";
import MessageComposer, { ComposerStates } from "./MessageComposer";
import { OrderedParticipant } from "../domain/session";

export interface ChannelMessageComposerProps {
    channelId: d.ChannelId;
    mediaControls?: MediaToggleMap;
    showBondInterestedAction?: () => void;
    onStatesChange?: (current: ComposerStates) => void;
    liveParticipants?: OrderedParticipant[];
}

export const ChannelMessageComposer = forwardRef((
    props: ChannelMessageComposerProps,
    ref: ForwardedRef<Focusable>,
): React.JSX.Element => {
    const {
        channelId,
        mediaControls,
        showBondInterestedAction,
        onStatesChange,
        liveParticipants,
    } = props;

    const dispatch = useAppDispatch();

    const bondId = useSelectorArgs(selectBondIdByChannelId, channelId);
    const liveCallId = useAppSelector(selectLiveCallIdByBondId(bondId));

    const allBondInvolvees = useSelectorArgs(selectAllBondInvolvees, bondId);

    const draftTarget = useMemo(() => newChannelDraftTarget(channelId), [channelId]);

    const sendableStatus = useSelectorArgs(selectDraftMessageSendableStatus, draftTarget);

    const sendMessage = useCallback((dcm: DraftChatMessage | undefined) => {
        if (!dcm) return;

        dispatch(stageMessageForChannel({ ...dcm, liveCallId }));
    }, [dispatch, liveCallId]);

    const navigate = useNavigate();

    const escapeAction: React.KeyboardEventHandler = useCallback(e => {
        dispatch(transferDraftThunk({
            from: draftTarget,
            to: bondCreationDraftTarget,
        })).then(() => navigate("/bond"));
        e.stopPropagation();
    }, [dispatch, draftTarget, navigate]);

    const focusRef = useRef<Focusable>();

    const mergedRef = useMergedRefs(ref, focusRef);

    return (
        <MessageComposer
            id={`comms-input-${channelId}`}
            ref={mergedRef}
            key={`comms-input-bond-channel-${channelId}`}
            bondId={bondId}
            draftTarget={draftTarget}
            msgCompletionAction={sendMessage}
            completable={sendableStatus}
            escapeAction={escapeAction}
            numberOfParticipants={allBondInvolvees.length}
            showBondInterestedAction={showBondInterestedAction}
            mediaControls={mediaControls}
            onStatesChange={onStatesChange}
            liveParticipants={liveParticipants}
        />
    );
});
