import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
    DismissedWrapper,
    NotificationOrDismissed,
    NotificationWrapper,
    dismissNotification as dismissNotificationAPI,
    ackNotification as ackNotificationAPI,
    streamNotifications as streamNotificationsAPI,
} from "../api/notifications";
import * as d from "../domain/domain";
import {
    CallStartWebNotificationDetails,
    ChatMessageWebNotificationDetails,
    NotificationPriority,
    WebNotification,
    WebNotificationType,
} from "../domain/notifications";
import type { RootState } from "../store/types";
import { resetStore, selectCurrentUserId } from "./auth";
import { selectDisplayFilterForBond, setDisplayFilter } from "./filterPanel";
import { streamThunkHandlerBatched, unaryThunkHandler } from "./thunk";
import { Optional } from "../misc/types";
import { separateDiscriminatedUnion } from "../misc/utils";
import { createAppAsyncThunk } from "../store/redux";
import { checkPersistor } from "../persist/shared";
import { reportFirebaseToken } from "../api/client";

export const dismissNotification = createAsyncThunk(
    "notifications/dismiss",
    async (id: d.NotificationId, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const userId = selectCurrentUserId(state);
        if (!userId) {
            return;
        }

        if (!userId) {
            return thunkAPI.rejectWithValue("currentUserId undefined");
        }

        return await unaryThunkHandler(
            thunkAPI,
            dismissNotificationAPI(userId, id),
            "dismissNotification",
        );
    },
);

export const ackNotifications = createAppAsyncThunk(
    "notifications/ack",
    async (ids: d.NotificationId[], thunkAPI) => {
        if (ids.length === 0) return;

        const state = thunkAPI.getState();
        const userId = selectCurrentUserId(state);

        if (!userId) {
            return thunkAPI.rejectWithValue("currentUserId undefined");
        }

        for (let i = 0; i < ids.length; i++) {
            await unaryThunkHandler(
                thunkAPI,
                ackNotificationAPI(userId, ids[i]),
                "ackNotification",
            );
        }
    },
);

export const reportFirebaseTokenThunk = createAppAsyncThunk(
    "notifications/reportFirebaseToken",
    async (token: string, thunkAPI) => {
        if (!token) {
            return thunkAPI.rejectWithValue("Firebase token is empty");
        }

        await unaryThunkHandler(
            thunkAPI,
            reportFirebaseToken(token),
            "reportFirebaseToken",
        );
    },
);

export const streamNotifications = createAppAsyncThunk(
    "notifications/stream",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState();
        const userId = selectCurrentUserId(state);

        if (!userId) {
            return thunkAPI.rejectWithValue("currentUserId undefined");
        }

        const req = streamNotificationsAPI(userId, thunkAPI.signal);

        streamThunkHandlerBatched(
            thunkAPI,
            req,
            50,
            (msgs: NotificationOrDismissed[]) => {
                const [dismissed, notified] = separateDiscriminatedUnion<
                    DismissedWrapper,
                    NotificationWrapper
                >(msg => msg.case === "dismissed", msgs);

                if (dismissed.length) {
                    const ids = dismissed.map(msg => msg.id);
                    thunkAPI.dispatch(dismissNotifications(ids));
                }

                if (notified.length) {
                    const notifs = notified.map(msg => msg.notification);
                    thunkAPI.dispatch(streamedNotifications(notifs));
                    // TODO: It's not ideal to do 2 dispatches here.
                    // But rather than introduce complexity into
                    // `ackNotifications` (around dealing with possible
                    // failures), let's keep it simple and do the dispatches
                    // separately.
                    thunkAPI.dispatch(ackNotifications(notifs.map(n => n.id)));
                }
            },
            `notifications user:${userId}`,
        );
    },
);

const chatMessageNotificationThunk = createAsyncThunk(
    "notifications/action/chatMessage",
    async (details: ChatMessageWebNotificationDetails, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const filter = selectDisplayFilterForBond(state, details.bond.id);
        thunkAPI.dispatch(setDisplayFilter(filter));
        return `/bond/${d.extractUUID(details.bond.id)}/message/${
            d.extractUUID(details.messageId)
        }`;
    },
);

const callStartNotificationThunk = createAsyncThunk(
    "notifications/action/callStart",
    async (details: CallStartWebNotificationDetails, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const filter = selectDisplayFilterForBond(state, details.bond.id);
        thunkAPI.dispatch(setDisplayFilter(filter));
        return `/bond/${d.extractUUID(details.bond.id)}`;
    },
);

// Stores formatted notification data for presentation. The thunk should be an
// `AsyncThunkAction` which returns a `string | null` value describing where to
// navigate after completing.
export interface DisplayNotification {
    id: d.NotificationId;
    iconUserId?: d.UserId;
    heading: string;
    content: string;
    timestamp: number;
    sticky: boolean;
    thunk?: any;
}

export function toDisplayNotification(
    notification: WebNotification,
): DisplayNotification {
    const sticky = notification.priority == NotificationPriority.TimeSensitive;
    switch (notification.details.case) {
        case WebNotificationType.Text:
            return {
                id: notification.id,
                heading: "Bond",
                content: notification.details.value.text,
                timestamp: notification.createdTs,
                sticky,
            };
        case WebNotificationType.ChatMessage: {
            const details = notification.details.value;
            return {
                id: notification.id,
                iconUserId: details.senderId,
                heading: details.bond.name,
                content: details.previewText,
                timestamp: notification.eventTs,
                sticky,
                thunk: chatMessageNotificationThunk(details),
            };
        }
        case WebNotificationType.CallStart: {
            const details = notification.details.value;
            return {
                id: notification.id,
                iconUserId: details.initiator.id,
                heading: `${details.bond.name} is live`,
                content: `${details.initiator.name} is live in ${details.bond.name}`,
                timestamp: notification.eventTs,
                sticky,
                thunk: callStartNotificationThunk(details),
            };
        }
    }
}

interface NotificationChangeAdd {
    type: "add";
    notification: WebNotification;
}

interface NotificationChangeRemove {
    type: "remove";
    id: d.NotificationId;
}

type NotificationChange = NotificationChangeAdd | NotificationChangeRemove;

export interface NotificationsState {
    queue: NotificationChange[];
}

const getInitialState = (props?: {
    queue?: NotificationChange[];
}): NotificationsState => ({
    queue: props?.queue || [],
});

export const notificationsSlice = createSlice({
    name: "notifications",
    initialState: getInitialState(),
    reducers: {
        streamed: (state, action: PayloadAction<WebNotification>) => {
            state.queue.push({ type: "add", notification: action.payload });
        },
        streamedMany: (state, { payload: notifs }: PayloadAction<WebNotification[]>) => {
            state.queue.push(
                ...notifs.map(n => ({ type: "add", notification: n }) as NotificationChangeAdd),
            );
        },
        dismissed: (state, action: PayloadAction<d.NotificationId>) => {
            state.queue.push({ type: "remove", id: action.payload });
        },
        dismissMany: (state, { payload: ids }: PayloadAction<d.NotificationId[]>) => {
            state.queue.push(
                ...ids.map(id => ({ type: "remove", id: id }) as NotificationChangeRemove),
            );
        },
        consumeFromQueue: state => {
            state.queue.shift();
        },
    },
    extraReducers: builder => {
        builder.addCase(resetStore, _state => {
            return getInitialState();
        });
    },
});

export const selectNotificationQueue = (state: RootState): Optional<NotificationChange> =>
    state.notifications.queue[0];

export const consumeFromQueue = notificationsSlice.actions.consumeFromQueue;

const { streamedMany: streamedNotifications, dismissMany: dismissNotifications } =
    notificationsSlice.actions;

export default notificationsSlice.reducer;

export const reducer = notificationsSlice.reducer;

// Persistence.

export const persistor = {
    stores: {},
};
checkPersistor<NotificationsState>(persistor);
