import { useCallback, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../store/redux";
import {
    addUserToOrg,
    addUserToSquad,
    createNewSquad,
    selectCurrentOrg,
    selectSquadById,
    selectSquadIdsOrderedBySquadName,
} from "../features/squads";
import * as d from "../domain/domain";
import SensitiveText from "./gui/SensitiveText";
import useSelectorArgs from "../hooks/useSelectorArgs";
import { selectUser } from "../features/users";
import useInterestedUsers from "../hooks/interest/useInterestedUsers";
import Avatar from "./gui/Avatar";
import { FeatureFlagged } from "./FeatureFlags";
import { AutoCompleteQuery } from "../hooks/useAutoCompleteQuery";
import TextArea from "./gui/TextArea";
import { useOffsetScreenPos } from "../hooks/useOffsetScreenPos";
import { UserLookupAutoComplete } from "./UserLookupAutocomplete";
import classNames from "classnames";

function SquadListing(props: { id: d.SquadId; onClick?: () => void; selected?: boolean; }) {
    const { id, onClick, selected } = props;
    const squad = useSelectorArgs(selectSquadById, id);

    const thisSquadSelected = selected || false;
    const classes = classNames("cp-dropdown-squad__item", thisSquadSelected && "is-selected");

    return squad && (
        <a
            className={classes}
            onClick={onClick}
        >
            <SensitiveText>{squad.name}</SensitiveText>
        </a>
    );
}

function CreateSquadSetting(): React.JSX.Element {
    const dispatch = useAppDispatch();

    const selectedOrg = useAppSelector(selectCurrentOrg);
    const [newSquadName, setNewSquadName] = useState("");

    const createSquadCb = useCallback(() => {
        if (!selectedOrg || !newSquadName) return;

        const confirmed = confirm(
            `Create '${newSquadName}' in ${selectedOrg.name}?`,
        );
        if (!confirmed) return;

        dispatch(createNewSquad({ name: newSquadName, orgId: selectedOrg.id }));
        setNewSquadName("");
    }, [dispatch, newSquadName, selectedOrg]);

    if (!selectedOrg) return <></>;
    return (
        <>
            <h4>{`Create squad in ${selectedOrg.name}`}</h4>
            <TextArea
                value={newSquadName}
                className="c-textarea c-textarea--form"
                placeholder="Enter new squad name..."
                onValueChange={setNewSquadName}
            />
            <button
                className="c-btn c-btn--secondary"
                onClick={createSquadCb}
                disabled={!selectedOrg || !newSquadName}
            >
                Create Squad
            </button>
            <br /> <br />
        </>
    );
}

function AddSquadMemberSetting(): React.JSX.Element {
    const dispatch = useAppDispatch();

    const allSquadIds = useAppSelector(selectSquadIdsOrderedBySquadName);
    const [showSquadDropdown, setShowSquadDropdown] = useState(false);
    const [chosenSquadId, setChosenSquadId] = useState<d.SquadId | undefined>(undefined);
    const chosenSquad = useSelectorArgs(selectSquadById, chosenSquadId);

    const chooseSquad = useCallback((squadId: d.SquadId) => {
        setChosenSquadId(squadId);
        setShowSquadDropdown(false);
    }, [setChosenSquadId, setShowSquadDropdown]);

    const [chosenUserId, setChosenUserId] = useState<d.UserId | undefined>(undefined);
    const chosenUser = useSelectorArgs(selectUser, chosenUserId);
    useInterestedUsers(chosenUserId);

    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const [userLookupText, setUserLookupText] = useState("");

    const userLookupQuery: AutoCompleteQuery = useMemo(() => ({
        trigger: "",
        text: userLookupText,
        range: { start: 0, end: userLookupText.length },
    }), [userLookupText]);
    const textScreenPos = useOffsetScreenPos(textAreaRef, userLookupQuery?.range.start);

    const chooseUser = useCallback((userId: d.UserId) => {
        if (!userId) return;
        setChosenUserId(userId);
        setUserLookupText("");
    }, [setChosenUserId]);

    const addUserToSquadCb = useCallback(() => {
        if (!chosenSquad || !chosenUser) return;
        const confirmed = confirm(
            `Add ${chosenUser.name} to '${chosenSquad.name}'?`,
        );
        if (!confirmed) return;

        dispatch(addUserToSquad({ squadId: chosenSquad.id, userId: chosenUser.id }));
        setChosenUserId(undefined);
    }, [dispatch, chosenSquad, chosenUser]);

    return (
        <>
            <h4>Add user to squad</h4>

            {userLookupText &&
                (
                    <UserLookupAutoComplete
                        mentionX={(textScreenPos?.x || 0) - 840}
                        mentionY={(textScreenPos?.y || 0) + 340}
                        query={userLookupQuery}
                        chooseUser={chooseUser}
                    />
                )}
            <TextArea
                value={userLookupText}
                className="c-textarea c-textarea--form"
                placeholder="Lookup user..."
                onValueChange={setUserLookupText}
                ref={textAreaRef}
            />
            {chosenUser && (
                <>
                    Chosen user: {chosenUser.name}
                    <Avatar
                        userId={chosenUser.id}
                        size="small"
                    />
                    <br />
                </>
            )}

            <button
                className="c-btn c-textarea--form"
                onClick={() => setShowSquadDropdown(x => !x)}
            >
                {chosenSquad ? `Chosen squad: ${chosenSquad.name}` : "Choose squad"}
            </button>
            {showSquadDropdown && (
                <div className="cp-dropdown__wrapper cp-dropdown-squad">
                    {allSquadIds.map(squadId => (
                        <SquadListing
                            key={squadId}
                            id={squadId}
                            selected={chosenSquadId === squadId}
                            onClick={() => chooseSquad(squadId)}
                        />
                    ))}
                </div>
            )}
            <br />

            <button
                className="c-btn c-btn--secondary"
                onClick={addUserToSquadCb}
                disabled={!chosenSquad || !chosenUser}
            >
                Add User to Squad
            </button>
            <br /> <br />
        </>
    );
}

function CreateUserSetting(): React.JSX.Element {
    const dispatch = useAppDispatch();

    const selectedOrg = useAppSelector(selectCurrentOrg);

    const [chosenUserId, setChosenUserId] = useState<d.UserId | undefined>(undefined);
    const chosenUser = useSelectorArgs(selectUser, chosenUserId);
    useInterestedUsers(chosenUserId);

    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const [userLookupText, setUserLookupText] = useState("");

    const userLookupQuery: AutoCompleteQuery = useMemo(() => ({
        trigger: "",
        text: userLookupText,
        range: { start: 0, end: userLookupText.length },
    }), [userLookupText]);
    const textScreenPos = useOffsetScreenPos(textAreaRef, userLookupQuery?.range.start);

    const chooseUser = useCallback((userId: d.UserId) => {
        if (!userId) return;
        setChosenUserId(userId);
        setUserLookupText("");
    }, [setChosenUserId]);

    const addUserToOrgCb = useCallback(() => {
        if (!selectedOrg || !chosenUser) return;
        const confirmed = confirm(
            `Add ${chosenUser.name} to ${selectedOrg.name}?`,
        );
        if (!confirmed) return;

        dispatch(addUserToOrg({ orgId: selectedOrg.id, personId: chosenUser.personId }));
        setChosenUserId(undefined);
    }, [dispatch, selectedOrg, chosenUser]);

    if (!selectedOrg) return <></>;
    return (
        <>
            <h4>{`Add user to ${selectedOrg.name}`}</h4>

            {userLookupText &&
                (
                    <UserLookupAutoComplete
                        mentionX={(textScreenPos?.x || 0) - 840}
                        mentionY={(textScreenPos?.y || 0) + 340}
                        query={userLookupQuery}
                        chooseUser={chooseUser}
                    />
                )}
            <TextArea
                value={userLookupText}
                className="c-textarea c-textarea--form"
                placeholder="Lookup user..."
                onValueChange={setUserLookupText}
                ref={textAreaRef}
            />
            {chosenUser && (
                <>
                    Chosen user: {chosenUser.name}
                    <Avatar
                        userId={chosenUser.id}
                        size="small"
                    />
                    <br />
                </>
            )}
            <button
                className="c-btn c-btn--secondary"
                onClick={addUserToOrgCb}
                disabled={!selectedOrg || !chosenUser}
            >
                Add User to Organisation
            </button>
            <br /> <br />
        </>
    );
}

export default function SquadManagementSettings(): React.JSX.Element {
    return (
        <FeatureFlagged flag={"show-squads-management-frontend"} match={true}>
            <div className="c-settings__user">
                <h3>Manage Organisation</h3>

                <CreateUserSetting />

                <CreateSquadSetting />

                <AddSquadMemberSetting />
            </div>
        </FeatureFlagged>
    );
}
