import { PrivacyLevel } from "../api/bonds";
import { createAppSelector } from "../store/redux";
import { selectBondByDraftTarget } from "./bonds";
import { selectDraftSquadMentions, selectDraftUserMentions } from "./channels";
import * as d from "../domain/domain";
import { memoizeOptions } from "./selectors";
import {
    selectSquadIdsContainingUserIds,
    selectSquads,
    selectUserIdsBySquadIds,
    selectUserIdsKnownToCurrentUser,
} from "./squads";
import { selectCurrentUserId } from "./auth";
import type { RootState } from "../store/types";
import { DraftTarget } from "../domain/channels";
import { SquadOverview } from "../domain/squads";

const selectDraftSquadScopeForMention = createAppSelector(
    [selectDraftSquadMentions, selectBondByDraftTarget],
    (squadIds, bond) => [
        ...squadIds,
        ...(bond?.squadIds ?? []),
    ],
    memoizeOptions.weakMapShallow,
);

const selectDraftUserScopeForMention = createAppSelector(
    [selectDraftUserMentions, selectBondByDraftTarget],
    (userIds, bond) => [
        ...userIds,
        ...(bond?.followers ?? []),
    ],
    memoizeOptions.weakMapShallow,
);

const selectSquadIdsContainingUserScope = (state: RootState, dt: DraftTarget) => {
    const userIds = selectDraftUserScopeForMention(state, dt);
    return selectSquadIdsContainingUserIds(state, userIds);
};

const selectShouldIgnoreSquadsForMention = (state: RootState, dt: DraftTarget) => {
    const squadIds = selectDraftSquadScopeForMention(state, dt);
    if (squadIds.length > 0) return true;

    const bond = selectBondByDraftTarget(state, dt);
    if (bond?.privacy === PrivacyLevel.PRIVATE) return true;

    return false;
};

const selectValidSquadIdsForMention = createAppSelector(
    [
        selectShouldIgnoreSquadsForMention,
        selectSquadIdsContainingUserScope,
    ],
    (ignore, userIds) => ignore ? [] : userIds,
    memoizeOptions.weakMapShallow,
);

export const selectValidSquadsForMention = (state: RootState, dt: DraftTarget): SquadOverview[] => {
    const ids = selectValidSquadIdsForMention(state, dt);
    return selectSquads(state, ids);
};

const selectAllUserIdsForMention = (state: RootState, dt: DraftTarget): d.UserId[] => {
    const squadIds = selectDraftSquadScopeForMention(state, dt);

    if (squadIds.length === 0) return selectUserIdsKnownToCurrentUser(state);

    return selectUserIdsBySquadIds(state, squadIds);
};

export const selectValidUserIdsForMention = createAppSelector(
    [selectAllUserIdsForMention, selectCurrentUserId],
    (ids, currentUserId) => ids.filter(id => id !== currentUserId),
    memoizeOptions.weakMapShallow,
);
