import { combineReducers, configureStore } from "@reduxjs/toolkit";

import * as auth from "../features/auth";
import * as bondCreation from "../features/bondCreation";
import * as bonds from "../features/bonds";
import * as calls from "../features/calls";
import * as channels from "../features/channels";
import * as messages from "../features/chats";
import * as connection from "../features/connection";
import * as filterPanel from "../features/filterPanel";
import * as mediaDevices from "../features/mediaDevices";
import * as meta from "../features/meta";
import * as notifications from "../features/notifications";
import * as squads from "../features/squads";
import * as users from "../features/users";

import log from "../misc/log";
import { setPersistorMap } from "../persist/map";
import { startPersisting } from "../persist/persist";
import { indexedDbAvailable } from "../persist/shared";
import { listenerMiddleware, startAppListening } from "./middleware";
import type { PersistorMap, RootReducerMap, RootState } from "./types";
import { selectCurrentUserId } from "../features/auth";

const rootReducerMap: RootReducerMap = {
    auth: auth.reducer,
    bondCreation: bondCreation.reducer,
    bonds: bonds.reducer,
    calls: calls.reducer,
    channels: channels.reducer,
    connection: connection.reducer,
    filterPanel: filterPanel.reducer,
    mediaDevices: mediaDevices.reducer,
    messages: messages.reducer,
    meta: meta.reducer,
    notifications: notifications.reducer,
    squads: squads.reducer,
    users: users.reducer,
};

const combinedReducer = combineReducers({ ...rootReducerMap });

const rootPersistorMap: PersistorMap<RootState> = {
    auth: auth.persistor,
    bondCreation: bondCreation.persistor,
    bonds: bonds.persistor,
    calls: calls.persistor,
    channels: channels.persistor,
    connection: connection.persistor,
    filterPanel: filterPanel.persistor,
    mediaDevices: mediaDevices.persistor,
    messages: messages.persistor,
    meta: meta.persistor,
    notifications: notifications.persistor,
    squads: squads.persistor,
    users: users.persistor,
};

setPersistorMap(rootPersistorMap);

interface SetupStoreArgs {
    persist?: boolean;
}
export function setupStore(preloadedState?: Partial<RootState>, args?: SetupStoreArgs) {
    const persist = args?.persist ?? false;

    const store = configureStore({
        reducer: combinedReducer,
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware().prepend(listenerMiddleware.middleware),
        preloadedState,
    });

    const dbUpgradeBlockingAction = () => {
        store.dispatch(meta.idbUpgradeRequired());
    };

    if (persist) {
        if (indexedDbAvailable()) {
            store.dispatch(meta.updatePersisting(true));
            const userId = selectCurrentUserId(store.getState());
            startPersisting(userId, startAppListening, dbUpgradeBlockingAction);
        }
        else {
            log.info(`Persistence enabled but IndexedDB not available`);
        }
    }

    return store;
}
