import classNames from "classnames";
import { HTMLProps } from "react";

interface CloseButtonProps extends Omit<HTMLProps<HTMLButtonElement>, "type"> {
    side?: "left" | "right" | "inline" | "composer";
    title?: string;
}

const sideToClass = (side?: CloseButtonProps["side"]): string | undefined => {
    switch (side) {
        case "right":
            return "c-btn-close--right";
        case "inline":
            return "c-btn-close--inline";
        case "composer":
            return "c-btn-close--composer";
    }
};

export const CloseButton = (props: CloseButtonProps) => {
    const className = classNames("c-btn-close", props.className, sideToClass(props.side));

    const title = props.title || "Close";

    return (
        <button
            {...props}
            className={className}
            title={title}
        >
            {title}
        </button>
    );
};
