import { ExpandType } from "../misc/types";
import * as d from "./domain";

export interface ExpiringSasUrl {
    url: string;
    expiresAt: d.Timestamp;
}

export interface BlobOwnershipDetails {
    uploaderId: d.UserId;
    orgId: d.OrgId;
}

export interface BlobDimensions {
    width: number;
    height: number;
}

export interface BlobMetadata {
    fileName: string;
    mimeType: string;
    fileSize: number;
    dimensions?: BlobDimensions;
}

const mimeTypePreparer = (mimeType: string | undefined): string =>
    mimeType || "application/octet-stream";

export const fileToBlobMetadata = (file: File): BlobMetadata => ({
    fileName: file.name,
    fileSize: file.size,
    mimeType: mimeTypePreparer(file.type),
});

export type BlobCredentials = ExpandType<
    ExpiringSasUrl & {
        type: "upload" | "download";
        blobId: d.BlobId;
    }
>;

export function credentialsValidWithin(
    creds: BlobCredentials,
    seconds: number,
    now?: number,
): boolean {
    return !!creds.url && (now ?? Date.now()) + seconds * 1000 < creds.expiresAt;
}
