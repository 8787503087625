import { useCallback, useRef } from "react";

import { Peer, Session, RemoteTrack, JoinResponse } from "@avos-io/rtc-client";

import * as d from "../../domain/domain";
import { Participant, getDisabledTrack } from "../../domain/session";
import log from "../../misc/log";
import { Optional } from "../../misc/types";

interface RtcEventPayload<T> {
    detail: T;
}

type RtcEventHandler<T> = (payload: RtcEventPayload<T>) => void;

interface SessionLeaveEventPayload {
    canReconnect: boolean;
}

interface TrackEventPayload {
    track: RemoteTrack;
}

interface TrackRemoveEventPayload {
    trackId: string;
}

type RtcJoinEventHandler = RtcEventHandler<JoinResponse>;
type RtcLeaveEventHandler = RtcEventHandler<SessionLeaveEventPayload>;
type RtcPeerEventHandler = RtcEventHandler<Peer>;
type RtcTrackEventHandler = RtcEventHandler<TrackEventPayload>;
type RtcTrackRemoveEventHandler = RtcEventHandler<TrackRemoveEventPayload>;

interface PeerEventHandlers {
    onTrackAdd: RtcTrackEventHandler;
    onTrackMedia: RtcTrackEventHandler;
    onTrackUpdate: RtcTrackEventHandler;
    onTrackMute: RtcTrackEventHandler;
    onTrackRemove: RtcTrackRemoveEventHandler;
}

type PeerAndEventHandlers = {
    peer: Peer;
    eventHandlers: PeerEventHandlers;
};

function getPeerId(peer: Peer) {
    return d.fromRawRtcParticipantId(peer.getParticipant().id);
}

function getPeerUserId(peer: Peer) {
    return d.fromRawUserId(peer.getParticipant().identity);
}

export interface UseSessionEventListenersProps {
    sessionId: Optional<d.RtcSessionId>;
    onSessionJoin: () => void;
    onSessionLeave: (canReconnect: boolean) => void;
    addParticipant: (peerId: d.RtcParticipantId, participant: Participant) => void;
    removeParticipant: (peerId: d.RtcParticipantId) => void;
    updateParticipantTrack: (peerId: d.RtcParticipantId, track: RemoteTrack) => void;
    removeParticipantTrack: (peerId: d.RtcParticipantId, trackId: string) => void;
}

export function useSessionEventListeners(props: UseSessionEventListenersProps) {
    const {
        sessionId,
        onSessionJoin,
        onSessionLeave,
        addParticipant,
        removeParticipant,
        updateParticipantTrack,
        removeParticipantTrack,
    } = props;

    // Track peers in call, and their event handler functions so we can remove
    // those same functions from their event listeners.
    const peersRef = useRef<Map<d.RtcParticipantId, PeerAndEventHandlers>>(new Map());

    // Add event listeners to a peer
    const addPeerEventListeners = useCallback((peer: Peer): PeerEventHandlers => {
        const peerId = getPeerId(peer);
        const userId = getPeerUserId(peer);

        const onTrackAdd: RtcTrackEventHandler = ({ detail: { track } }) => {
            log.info(`User ${userId} as Peer ${peerId} added a track.`);
            updateParticipantTrack(peerId, track);
        };
        const onTrackMedia: RtcTrackEventHandler = ({ detail: { track } }) => {
            log.info(`User ${userId} as Peer ${peerId} got track media.`);
            updateParticipantTrack(peerId, track);
        };
        const onTrackUpdate: RtcTrackEventHandler = ({ detail: { track } }) => {
            log.info(`User ${userId} as Peer ${peerId} updated a track.`);
            updateParticipantTrack(peerId, track);
        };
        const onTrackMute: RtcTrackEventHandler = ({ detail: { track } }) => {
            log.info(
                `User ${userId} as Peer ${peerId} ${track.isMuted() ? "muted" : "umuted"} a track.`,
            );
            updateParticipantTrack(peerId, track);
        };
        const onTrackRemove: RtcTrackRemoveEventHandler = ({ detail: { trackId } }) => {
            log.info(`User ${userId} as Peer ${peerId} removed a track.`);
            removeParticipantTrack(peerId, trackId);
        };

        peer.addEventListener("trackadded", onTrackAdd);
        peer.addEventListener("trackmedia", onTrackMedia);
        peer.addEventListener("trackupdated", onTrackUpdate);
        peer.addEventListener("trackmuted", onTrackMute);
        peer.addEventListener("trackremoved", onTrackRemove);

        return {
            onTrackAdd,
            onTrackMedia,
            onTrackUpdate,
            onTrackMute,
            onTrackRemove,
        };
    }, [updateParticipantTrack, removeParticipantTrack]);

    // Remove event listeners from a peer, using the stored event handler functions
    const removePeerEventListeners = useCallback(
        (peerAndEventHandlers: Optional<PeerAndEventHandlers>) => {
            if (!peerAndEventHandlers) {
                return;
            }

            const {
                peer,
                eventHandlers: {
                    onTrackAdd,
                    onTrackMedia,
                    onTrackUpdate,
                    onTrackMute,
                    onTrackRemove,
                },
            } = peerAndEventHandlers;

            peer.removeEventListener("trackadded", onTrackAdd);
            peer.removeEventListener("trackmedia", onTrackMedia);
            peer.removeEventListener("trackupdated", onTrackUpdate);
            peer.removeEventListener("trackmuted", onTrackMute);
            peer.removeEventListener("trackremoved", onTrackRemove);
        },
        [],
    );

    const resetPeerEventListeners = useCallback(() => {
        peersRef.current.forEach(removePeerEventListeners);
        peersRef.current = new Map();
    }, [removePeerEventListeners]);

    // Listen to peers connecting to session
    const onParticipantConnected: RtcPeerEventHandler = useCallback(({ detail: peer }) => {
        const peerId = getPeerId(peer);
        const userId = getPeerUserId(peer);
        log.info(`User ${userId} as Peer ${peerId} connected to session.`);

        addParticipant(peerId, {
            id: peerId,
            userId,
            audioTrack: getDisabledTrack(),
            displayTrack: getDisabledTrack(),
            videoTrack: getDisabledTrack(),
        });

        // Store peer event listeners
        const eventHandlers = addPeerEventListeners(peer);
        peersRef.current.set(getPeerId(peer), { eventHandlers, peer });
    }, [addPeerEventListeners, addParticipant]);

    // Listen to peers disconnecting from session
    const onParticipantDisconnected: RtcPeerEventHandler = useCallback(({ detail: peer }) => {
        const peerId = getPeerId(peer);
        const userId = getPeerUserId(peer);
        log.info(`User ${userId} as Peer ${peerId} disconnected from session.`);

        removeParticipant(peerId);

        // Cleanup peer event listeners
        removePeerEventListeners(peersRef.current.get(peerId));
        peersRef.current.delete(peerId);
    }, [removeParticipant, removePeerEventListeners]);

    // Handle joining session
    const onSessionJoinHandler: RtcJoinEventHandler = useCallback(() => {
        log.info("Joined session", sessionId);

        onSessionJoin();
    }, [onSessionJoin, sessionId]);

    // Handle leaving session
    const onSessionLeaveHandler: RtcLeaveEventHandler = useCallback(
        ({ detail: { canReconnect } }) => {
            log.info(`Left session. Can reconnect: ${canReconnect}.`);
            onSessionLeave(canReconnect);

            resetPeerEventListeners();
        },
        [onSessionLeave, resetPeerEventListeners],
    );

    // Add event listeners to session
    const addSessionEventListeners = useCallback((session: Optional<Session>) => {
        if (!session) {
            return;
        }

        session.addEventListener("joined", onSessionJoinHandler);
        session.addEventListener("leave", onSessionLeaveHandler);
        session.addEventListener("participantconnected", onParticipantConnected);
        session.addEventListener("participantdisconnected", onParticipantDisconnected);
    }, [
        onParticipantConnected,
        onParticipantDisconnected,
        onSessionJoinHandler,
        onSessionLeaveHandler,
    ]);

    // Remove event listeners from session
    const removeSessionEventListeners = useCallback((session: Optional<Session>) => {
        if (!session) {
            return;
        }

        session.removeEventListener("joined", onSessionJoinHandler);
        session.removeEventListener("leave", onSessionLeaveHandler);
        session.removeEventListener("participantconnected", onParticipantConnected);
        session.removeEventListener("participantdisconnected", onParticipantDisconnected);

        resetPeerEventListeners();
    }, [
        onParticipantConnected,
        onParticipantDisconnected,
        onSessionJoinHandler,
        onSessionLeaveHandler,
        resetPeerEventListeners,
    ]);

    return { addSessionEventListeners, removeSessionEventListeners };
}
