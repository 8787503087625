import useTimerTarget from "./useTimerTarget";
import { useAppDispatch, useAppSelector } from "../store/redux";
import { addIdleSubscriber, removeIdleSubscriber, selectIdleSince } from "../features/meta";
import { useEffect, useState } from "react";
import { Duration, DurationLike } from "luxon";

/** Wrapper around useIdleTimer() such that we only have at most one instantiated
 * useIdleTimer(). It's quite a heavy-weight library so it seems worth managing it.
 *
 * Requires <IdleTracker /> to be rendered somewhere in the app.
 */
export default function useIdle(timeout: DurationLike) {
    const [isIdle, setIsIdle] = useState(false);
    const { nowMs, setTarget } = useTimerTarget();
    const idleSince = useAppSelector(selectIdleSince);
    const dispatch = useAppDispatch();

    const timeoutMs = Duration.fromDurationLike(timeout).valueOf();

    useEffect(() => {
        const { payload } = dispatch(addIdleSubscriber(timeoutMs));
        return () => {
            dispatch(removeIdleSubscriber(payload.id));
        };
    }, [dispatch, timeoutMs]);

    useEffect(() => {
        if (!idleSince) {
            setIsIdle(false);
            return;
        }

        const next = idleSince + timeoutMs;

        if (next <= nowMs) {
            setIsIdle(true);
        }
        else {
            setIsIdle(false);
            setTarget(next);
        }
    }, [idleSince, nowMs, timeoutMs, setTarget, setIsIdle]);

    return isIdle;
}
