import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import BondCreationBar from "../components/BondCreationBar";
import * as d from "../domain/domain";

import PresenceHeader from "../components/PresenceHeader";
import {
    selectCurrentBondIds,
    selectFollowedBondIds,
    selectRelevanceLevelFilter,
    selectUnfollowedBondIds,
} from "../features/filterPanel";
import {
    selectBondCreationBodyEmpty,
    selectShowSuggestedBonds,
    selectSuggestedBonds,
    transferDraftThunk,
} from "../features/bondCreation";
import { useAppDispatch, useAppSelector } from "../store/redux";
import BondPreviewView from "./BondPreviewView";
import useLoadMoreOnScroll from "../hooks/useLoadMoreOnScroll";
import log from "../misc/log";
import { bondCreationDraftTarget, newChannelDraftTarget } from "../domain/channels";
import { useShallowEquals } from "../hooks/useShallowEquals";
import { selectCardsViewScrollTop, setCardsViewScrollTop } from "../features/bonds";
import { selectSuggestedChannelIds } from "../features/bondsShared";
import { BondViewStreamSubscriptions } from "./BondView";

const initialNumberOfBondsShown = 36;
const incrementNumberOfBondsShown = 18;

function limitBondIds(
    followedInput: d.BondId[],
    unfollowedInput: d.BondId[],
    fullInput: d.BondId[],
    cardLimit: number,
) {
    const followedBondIds = followedInput.slice(0, cardLimit);
    const unfollowedBondIds = unfollowedInput.slice(0, cardLimit - followedBondIds.length);

    const allBondIds = fullInput.slice(0, cardLimit);

    const isLimited = (followedInput.length + unfollowedInput.length > cardLimit) ||
        (fullInput.length > cardLimit);
    return { followedBondIds, unfollowedBondIds, allBondIds, isLimited };
}

function BondsCardView(): React.JSX.Element {
    const navigate = useNavigate();
    const [cardLimit, setCardLimit] = useState(initialNumberOfBondsShown);
    const endDivRef = useRef<HTMLDivElement>(null);

    const dispatch = useAppDispatch();

    const unlimitedCurrentBondIds = useAppSelector(selectCurrentBondIds);
    const unlimitedFollowedBondIds = useAppSelector(selectFollowedBondIds);
    const unlimitedUnfollowedBondIds = useAppSelector(selectUnfollowedBondIds);

    const { followedBondIds, unfollowedBondIds, allBondIds, isLimited } = useMemo(
        () =>
            limitBondIds(
                unlimitedFollowedBondIds,
                unlimitedUnfollowedBondIds,
                unlimitedCurrentBondIds,
                cardLimit,
            ),
        [
            unlimitedFollowedBondIds,
            unlimitedUnfollowedBondIds,
            unlimitedCurrentBondIds,
            cardLimit,
        ],
    );
    const relevenceLevelFilter = useAppSelector(selectRelevanceLevelFilter);

    const loadMore = useCallback(() => {
        if (isLimited) {
            setCardLimit(x => x + incrementNumberOfBondsShown);
        }
    }, [isLimited, setCardLimit]);
    const loadMoreOnScroll = useLoadMoreOnScroll(endDivRef, loadMore);

    const savedScrollPosition = useAppSelector(selectCardsViewScrollTop);
    const scrollableAreaRef = useRef<HTMLElement>(null);
    useLayoutEffect(() => {
        const current = scrollableAreaRef.current;
        return () => {
            dispatch(setCardsViewScrollTop(current?.scrollTop ?? 0));
        };
    }, [dispatch]);
    useLayoutEffect(() => {
        if (scrollableAreaRef.current) {
            scrollableAreaRef.current?.scrollTo({
                top: savedScrollPosition,
                behavior: "instant",
            });
        }
    }, [savedScrollPosition]);

    const clickCallback = useCallback((id: d.BondId) => navigate(`/bond/${d.extractUUID(id)}`), [
        navigate,
    ]);

    const bondIdsToBondPreviews = (ids: d.BondId[]) =>
        ids.map(id => (
            <BondPreviewView
                key={id}
                id={id}
                onClick={clickCallback}
            />
        ));

    const viewingDoneBucket = relevenceLevelFilter.by === "archived";

    const showSuggestedBonds = useAppSelector(selectShowSuggestedBonds);
    const suggestedBonds = useShallowEquals(useAppSelector(selectSuggestedBonds));

    const suggestedChannelIds = useAppSelector(selectSuggestedChannelIds);

    const suggestedBondClick = useCallback((id: d.BondId) => () => {
        const channelId = suggestedChannelIds[id];
        if (!channelId) {
            log.warn(`Cannot transfer into bond ${id} with no channel`);
            return;
        }

        dispatch(transferDraftThunk({
            from: bondCreationDraftTarget,
            to: newChannelDraftTarget(channelId),
        })).then(() => navigate(`/bond/${d.extractUUID(id)}`));
    }, [navigate, suggestedChannelIds, dispatch]);

    const suggestedBondsAsCards = (
        <>
            {suggestedBonds.length === 0 ?
                <h2 className="c-cards-title">No bonds matched your message</h2> :
                <h2 className="c-cards-title c-cards-title--sticky">Suggested</h2>}
            <div className="cp-cards">
                {suggestedBonds.map(({ bondId, score }) => (
                    <BondPreviewView
                        id={bondId}
                        key={bondId}
                        score={score}
                        onClick={suggestedBondClick(bondId)}
                    />
                ))}
            </div>
        </>
    );

    const followingBonds = (
        <div className="cp-cards">
            {bondIdsToBondPreviews(followedBondIds)}
        </div>
    );

    const discoverBonds = (
        <>
            <h2 className="c-cards-title c-cards-title--sticky-discover">
                Discover
            </h2>
            <div className="cp-cards">
                {bondIdsToBondPreviews(unfollowedBondIds)}
            </div>
        </>
    );

    const followedAndUnfollowedBonds = (
        <div className="cp-cards">
            {bondIdsToBondPreviews(allBondIds)}
        </div>
    );

    const displayedBondsAsCards = viewingDoneBucket ? followedAndUnfollowedBonds : (
        <>
            {followingBonds}
            {unfollowedBondIds.length > 0 && discoverBonds}
        </>
    );

    const bondCards = showSuggestedBonds ? suggestedBondsAsCards : displayedBondsAsCards;

    const textInComposer = !useAppSelector(selectBondCreationBodyEmpty);
    const cardViewClass = textInComposer ? "l-main--card-view-composer" : "l-main--card-view";

    return (
        <>
            <BondViewStreamSubscriptions />
            <main
                className={`l-main ${cardViewClass}`}
                onScroll={loadMoreOnScroll}
                ref={scrollableAreaRef}
            >
                {!viewingDoneBucket && <PresenceHeader />}
                <div className="cp-cards-wrapper">
                    {bondCards}
                    <div ref={endDivRef} />
                </div>
            </main>
            <BondCreationBar />
        </>
    );
}

export default BondsCardView;
