import { selectBondIds, subBondContemporariesThunk } from "../../features/bonds";
import StreamManager from "./StreamManager";

function BondStreamsManager(): React.JSX.Element {
    return StreamManager({
        idSelector: selectBondIds,
        streamThunk: subBondContemporariesThunk,
    });
}

export default BondStreamsManager;
