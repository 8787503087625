import log from "./log";

export async function* translateAsyncIterable<T, S>(
    iterable: AsyncIterableIterator<T>,
    f: (t: T) => AsyncIterable<S>,
): AsyncIterableIterator<S> {
    try {
        for await (const i of iterable) {
            yield* f(i);
        }
    }
    catch (e) {
        if (e instanceof DOMException && e.name === "AbortError") return;

        log.error(`translateIterable failed`, e);
        throw e;
    }
}
