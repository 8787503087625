import { MessageType } from "../../api/chats";
import type { NumberRange, Optional } from "../../misc/types";
import type { ExpiringSasUrl } from "../blobs";
import * as d from "../domain";
import type { ContentMention, Mention } from "../mentions";

// Messages

interface BlobDimensions {
    width: number;
    height: number;
}

interface BlobProperties {
    fileName: string;
    mimeType: string;
    fileSize: number;
    dimensions?: BlobDimensions;
}

interface ChatMessageContent {
    id?: d.UnsentMessageId;
    message?: string;
    mentions?: ContentMention[];
}

interface EnrichedChatMessageContent {
    coreContent: ChatMessageContent;
    localAttachmentIds?: d.LocalAttachmentId[];
    attachmentIds?: d.BlobId[];
    attachmentsLength?: number;
    prefix: {
        length: number;
        padding: number;
    };
}

interface CallMessageContent {
    callId: d.CallId;
    participantIds?: d.UserId[];
}

interface CoreMessage {
    callId?: d.CallId;
    channelId: d.ChannelId;
    clientTxTs: d.Timestamp;
}

type CoreChatMessage = CoreMessage & {
    content: EnrichedChatMessageContent;
    messageType: MessageType.CHAT;
    senderId: d.UserId;
    attachments: Attachment[];
};

type CoreCallMessage = CoreMessage & {
    content: CallMessageContent;
    messageType: MessageType.CALL_START | MessageType.CALL_END;
    senderId?: d.UserId;
};

type DataFromSend = {
    id: d.MessageId;
    serverRxTs: d.Timestamp;
    sequenceNumber: number;
    editedOrDeleted: boolean;
};

interface BondSuggestionQueryParameters {
    triggers: string[];
    currentAutoCompleteRange?: NumberRange;
}

// Final exports

export interface Attachment extends BlobProperties {
    id: d.BlobId;
    initialSasUrl: ExpiringSasUrl;
}

export type SentMessage = (CoreChatMessage | CoreCallMessage) & DataFromSend;

// Helper for typing for translation.
export type SentChatMessage = CoreChatMessage & DataFromSend;

export const isCallStartMsg = (
    msg?: SentMessage,
): msg is SentMessage & { messageType: MessageType.CALL_START; } =>
    msg?.messageType === MessageType.CALL_START;
export const isCallEndedMsg = (
    msg?: SentMessage,
): msg is SentMessage & { messageType: MessageType.CALL_END; } =>
    msg?.messageType === MessageType.CALL_END;
export const isChatMsg = (
    msg?: SentMessage,
): msg is SentChatMessage => msg?.messageType === MessageType.CHAT;

export type UnsentChatMessage = CoreChatMessage & {
    localId: d.UnsentMessageId;
    confirmedId?: d.MessageId;
    lastSendAttempt?: d.Timestamp;
    permanentFailure?: boolean;
    mentions?: Mention[];
};

export interface DraftChatMessageContent extends EnrichedChatMessageContent {
    trimmedMessage: string;
    caretHint: Optional<number>;
    caretHintId: string;
}

export interface PerChannelData {
    curatedList: d.AnyMessageId[];
    unreadMessagesIndex: Record<number, SentMessage>;
    localSequenceNumber?: number;
    index: Record<d.AnyMessageId, number>;
    unsent: d.UnsentMessageId[];
    pending: Record<d.MessageId, d.UnsentMessageId>;
}

export interface PersistedBondCreationState {
    draft: UnsentChatMessage & { content: DraftChatMessageContent; };
    draftPassesBack: boolean;
    queryParameters?: BondSuggestionQueryParameters;
}

export type AvatarBlob = {
    blobId: d.BlobId;
    sasUrl: ExpiringSasUrl;
    properties: BlobProperties;
};
