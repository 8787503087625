import {
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice,
    EntityState,
    PayloadAction,
} from "@reduxjs/toolkit";
import log from "../misc/log";
import {
    createUser,
    CreateUserArgs,
    addSquadMember,
    AddSquadMemberArgs,
    createOrg,
    createSquad,
    listUserSquads,
    listUsersByPerson,
    SquadObservations,
    subSquadObservers,
    listPersonOrgs,
    subSquadOverviews,
    subOrgOverviews,
} from "../api/squads";
import { UserObservation } from "../domain/presence";
import * as d from "../domain/domain";
import { OrgOverview, SquadOverview } from "../domain/squads";
import { createAppAsyncThunk, createAppSelector } from "../store/redux";
import { setUnion } from "../misc/primatives";
import { Diff, Optional, optionalFriendly, partialRecord, TypedKeys } from "../misc/types";
import type {
    Connection,
    IdbStoreUpdate,
    IdbStoreUpdateWithClear,
    PersistenceUpdate,
    RWTransaction,
} from "../persist/types";
import {
    checkPersistor,
    clearKey,
    fetchIdbStore,
    idbStore,
    localStorageStore,
    simpleIdbTranslate,
} from "../persist/shared";
import type { RootState } from "../store/types";
import { resetStore, selectCurrentUserId, selectCurrentOrgId, selectCurrentPersonId } from "./auth";
import { memoizeOptions, shallowArrayEqualSelectorCreator } from "./selectors";
import { streamThunkHandlerBatched, unaryThunkHandler } from "./thunk";
import { CounterState, createCounter } from "../misc/counter";
import * as migrateV17 from "../domain/migrate/v17";

const squadsAdapter = createEntityAdapter({
    selectId: (squad: SquadOverview) => squad.id,
});

const orgsAdapter = createEntityAdapter({
    selectId: (org: OrgOverview) => org.id,
});

export const streamOrgOverviews = createAsyncThunk(
    "squads/streamOrgOverviews",
    async (orgDiffStream: AsyncIterableIterator<Diff<d.OrgId>>, thunkAPI) => {
        await streamThunkHandlerBatched(
            thunkAPI,
            subOrgOverviews(orgDiffStream, thunkAPI.signal),
            50,
            (orgs: OrgOverview[]) => {
                thunkAPI.dispatch(updateOrgs(orgs));
            },
            `streamOrgOverviews`,
        );
    },
);

export const streamSquadOverviews = createAsyncThunk(
    "squads/streamSquadOverviews",
    async (squadDiffStream: AsyncIterableIterator<Diff<d.SquadId>>, thunkAPI) => {
        await streamThunkHandlerBatched(
            thunkAPI,
            subSquadOverviews(squadDiffStream, thunkAPI.signal),
            50,
            (squads: SquadOverview[]) => {
                thunkAPI.dispatch(updateSquads(squads));
            },
            `streamSquadOverviews`,
        );
    },
);

export const fetchCurrentUserMemberships = createAppAsyncThunk(
    "squads/fetchCurrentUserMemberships",
    async (_, thunkAPI) => {
        const store = thunkAPI.getState();
        const currentUserId = selectCurrentUserId(store);
        if (!currentUserId) {
            return thunkAPI.rejectWithValue({
                error: `Cannot fetch current memberships with empty user id`,
            });
        }

        return await unaryThunkHandler(
            thunkAPI,
            listUserSquads(currentUserId),
            "fetchCurrentUserMemberships",
        );
    },
);

export const fetchCurrentPersonMemberships = createAppAsyncThunk(
    "squads/fetchCurrentPersonMemberships",
    async (_, thunkAPI) => {
        const store = thunkAPI.getState();
        const currentPersonId = selectCurrentPersonId(store);
        if (!currentPersonId) {
            return thunkAPI.rejectWithValue({
                error: `Cannot fetch current memberships with empty person id`,
            });
        }

        return await unaryThunkHandler(
            thunkAPI,
            listPersonOrgs(currentPersonId),
            "fetchCurrentPersonMemberships",
        );
    },
);

export const fetchUsersForPerson = createAppAsyncThunk(
    "squads/fetchUsersForPerson",
    async (personId: d.PersonId, thunkAPI) => {
        log.info(`Fetching users for person ${personId}`);

        return await unaryThunkHandler(
            thunkAPI,
            listUsersByPerson(personId),
            `fetchUsersForPerson ${personId}`,
        );
    },
);

interface StreamSquadObserverArgs {
    squadId: d.SquadId;
}

export const streamSquadObservers = createAsyncThunk(
    "bonds/streamObservers",
    async (args: StreamSquadObserverArgs, thunkAPI) => {
        const req = subSquadObservers(args.squadId, thunkAPI.signal);

        streamThunkHandlerBatched(
            thunkAPI,
            req,
            50,
            (msgs: SquadObservations[]) => {
                if (msgs.length === 0) return; // Should never happen.
                const latest = msgs[msgs.length - 1];
                thunkAPI.dispatch(streamedSquadObservers(latest));
            },
            `observers for bonds in squad:${args.squadId}`,
        );
    },
);

interface CreateOrgArgs {
    name: string;
}
export const createNewOrg = createAppAsyncThunk(
    "squads/createOrg",
    async (args: CreateOrgArgs, thunkAPI) => {
        const store = thunkAPI.getState();
        const personId = selectCurrentPersonId(store);

        if (args.name == "" || !personId) {
            throw thunkAPI.rejectWithValue({
                error: `Tried to create org without name or user ID`,
            });
        }

        log.info(`Creating org`);

        return await unaryThunkHandler(
            thunkAPI,
            createOrg({
                name: args.name,
                initalPerson: personId,
            }),
            `createOrg`,
        );
    },
);

export const addUserToOrg = createAsyncThunk(
    "squads/createUser",
    async (args: CreateUserArgs, thunkAPI) => {
        log.info(`Adding person ${args.personId} to org ${args.orgId}`);

        return await unaryThunkHandler(
            thunkAPI,
            createUser(args),
            `createUser ${args.personId} to org ${args.orgId}`,
        );
    },
);

interface CreateSquadArgs {
    name: string;
    orgId: d.OrgId;
}
export const createNewSquad = createAsyncThunk(
    "squads/createSquad",
    async (args: CreateSquadArgs, thunkAPI) => {
        const store = thunkAPI.getState() as RootState;
        const userId = selectCurrentUserId(store);

        if (args.name == "" || !userId) {
            throw thunkAPI.rejectWithValue({
                error: `Tried to create squad without name or user ID`,
            });
        }

        log.info(`Creating squad`);

        return await unaryThunkHandler(
            thunkAPI,
            createSquad({
                orgId: args.orgId,
                name: args.name,
                initalUser: userId,
            }),
            `createSquad`,
        );
    },
);

export const addUserToSquad = createAsyncThunk(
    "squads/addSqaudMember",
    async (args: AddSquadMemberArgs, thunkAPI) => {
        log.info(`Adding user ${args.userId} to squad ${args.squadId}`);

        return await unaryThunkHandler(
            thunkAPI,
            addSquadMember(args),
            `addSquadMember ${args.userId} to squad ${args.squadId}`,
        );
    },
);

export interface SquadState {
    orgs: EntityState<OrgOverview, d.OrgId>;
    squads: EntityState<SquadOverview, d.SquadId>;
    currentPersonOrgIds: Array<d.OrgId>;
    currentUserSquadIds: Array<d.SquadId>;
    orgInterested: CounterState<d.OrgId>;
    squadInterested: CounterState<d.SquadId>;
    squadSizes: Record<d.SquadId, number>;
    observers: Record<d.SquadId, Array<UserObservation>>;
    updates: SquadsUpdate[];
}

const orgInterestedCounter = createCounter<d.OrgId>();
const squadInterestedCounter = createCounter<d.SquadId>();

const getInitialState = (props?: {
    orgs?: Record<d.OrgId, OrgOverview>;
    squads?: Record<d.SquadId, SquadOverview>;
    userOrgs?: Array<d.OrgId>;
    userSquads?: Array<d.SquadId>;
}): SquadState => ({
    orgs: orgsAdapter.getInitialState({}, props?.orgs ?? {}),
    squads: squadsAdapter.getInitialState({}, props?.squads ?? {}),
    currentPersonOrgIds: props?.userOrgs ?? [],
    currentUserSquadIds: props?.userSquads ?? [],
    orgInterested: orgInterestedCounter.getInitialState(),
    squadInterested: squadInterestedCounter.getInitialState(),
    squadSizes: {},
    observers: {},
    updates: [],
});

export const squadSlice = createSlice({
    name: "squads",
    initialState: getInitialState(),
    selectors: {
        currentPersonOrgIds: state => state.currentPersonOrgIds,
        currentUserSquadIds: state => state.currentUserSquadIds,
        orgInterested: state => state.orgInterested,
        squadInterested: state => state.squadInterested,
        observers: (state, squadId?: d.SquadId): Optional<UserObservation[]> =>
            squadId && state.observers[squadId],
    },
    reducers: {
        updateOrgs: (state, { payload: orgs }: PayloadAction<Array<OrgOverview>>) => {
            state.orgs = orgsAdapter.upsertMany(state.orgs, orgs);

            state.updates = orgs.map(org => [stores.orgs, org.id, org]);
        },
        updateSquads: (state, { payload: squads }: PayloadAction<Array<SquadOverview>>) => {
            squadsAdapter.upsertMany(state.squads, squads);
            squads.forEach(so => {
                state.squadSizes[so.id] = so.userIds.length;
            });

            state.updates = squads.map(so => [stores.squads, so.id, so]);
        },
        // Useful for testing and for ladle stories.
        updateMemberships: (
            state,
            action: PayloadAction<{ orgIds: d.OrgId[]; squadIds: d.SquadId[]; }>,
        ) => {
            state.currentPersonOrgIds = action.payload.orgIds;
            state.currentUserSquadIds = action.payload.squadIds;

            state = updatePersistedSquadMembership(state);
            state = updatePersistedOrgMembership(state);
        },
        streamedSquadObservers: (
            state,
            { payload: observers }: PayloadAction<SquadObservations>,
        ) => {
            state.observers[observers.viewId] = observers.observations;
        },
        registerOrgInterest: (state, { payload: ids }: PayloadAction<d.OrgId[]>) => {
            state.orgInterested = orgInterestedCounter.add(state.orgInterested, ...ids);
        },
        unregisterOrgInterest: (state, { payload: ids }: PayloadAction<d.OrgId[]>) => {
            state.orgInterested = orgInterestedCounter.remove(state.orgInterested, ...ids);
        },
        registerSquadInterest: (state, { payload: ids }: PayloadAction<d.SquadId[]>) => {
            state.squadInterested = squadInterestedCounter.add(state.squadInterested, ...ids);
        },
        unregisterSquadInterest: (state, { payload: ids }: PayloadAction<d.SquadId[]>) => {
            state.squadInterested = squadInterestedCounter.remove(state.squadInterested, ...ids);
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchCurrentUserMemberships.fulfilled, (state, action) => {
            state.currentUserSquadIds = action.payload.squadIDs;

            state = updatePersistedSquadMembership(state);
        });
        builder.addCase(fetchCurrentPersonMemberships.fulfilled, (state, action) => {
            state.currentPersonOrgIds = action.payload.orgIds;

            state = updatePersistedOrgMembership(state);
        });
        builder.addCase(resetStore, _state => {
            return getInitialState();
        });
    },
});

function updatePersistedSquadMembership(state: SquadState): SquadState {
    state.updates = [[stores.squadMembership, clearKey]];
    state.currentUserSquadIds.forEach(squadId => {
        state.updates.push([stores.squadMembership, squadId, true]);
    });
    return state;
}

function updatePersistedOrgMembership(state: SquadState): SquadState {
    state.updates = [[stores.orgMembership, clearKey]];
    state.currentPersonOrgIds.forEach(orgId => {
        state.updates.push([stores.orgMembership, orgId, true]);
    });
    return state;
}

export const {
    updateOrgs,
    updateSquads,
    updateMemberships,
    streamedSquadObservers,
    registerOrgInterest,
    unregisterOrgInterest,
    registerSquadInterest,
    unregisterSquadInterest,
} = squadSlice.actions;

const selectors = squadSlice.getSelectors((state: RootState) => state.squads);
export const {
    currentPersonOrgIds: selectCurrentOrgIds,
    currentUserSquadIds: selectCurrentSquadIds,
    orgInterested: selectOrgIdsWithInterest, // Sufficiently memoized by counter
    squadInterested: selectSquadIdsWithInterest, // Sufficiently memoized by counter
} = selectors;

const squadSelectors = squadsAdapter.getSelectors<RootState>(
    state => state.squads.squads,
);
const orgSelectors = orgsAdapter.getSelectors<RootState>(
    state => state.squads.orgs,
);

export const selectSquadIds = squadSelectors.selectIds;
export const selectOrg = optionalFriendly(orgSelectors.selectById);

export const selectCurrentOrg = (state: RootState): Optional<OrgOverview> =>
    selectOrg(state, selectCurrentOrgId(state));

// Select our own orgs, and any additional orgs with interest
export const selectCurrentAndInterestedOrgIds = createAppSelector(
    [selectCurrentOrgIds, selectOrgIdsWithInterest],
    (currentIds, interestedIds) => currentIds.concat(interestedIds).removeDuplicates(),
    memoizeOptions.lruShallow,
);

// Select our own squads, and any additional squads with interest
export const selectCurrentAndInterestedSquadIds = createAppSelector(
    [selectCurrentSquadIds, selectSquadIdsWithInterest],
    (currentIds, interestedIds) => currentIds.concat(interestedIds).removeDuplicates(),
    memoizeOptions.lruShallow,
);

export const selectSquadIdsOrderedBySquadName = createSelector(
    [squadSelectors.selectAll],
    sos => sos.sort((a, b) => a.name.localeCompare(b.name)).map(bov => bov.id),
);

export const selectCurrentSquadIdsOrderedBySquadName = createSelector(
    [selectCurrentSquadIds, selectSquadIdsOrderedBySquadName],
    (currentSquadIds, orderedSquadIds) =>
        orderedSquadIds.filter(id => currentSquadIds.includes(id)),
);

export const selectAllSquads = squadSelectors.selectAll;

export const selectSquadById = optionalFriendly(squadSelectors.selectById);

export const selectSquadSize = (id: Optional<d.SquadId>) => (state: RootState): Optional<number> =>
    id && state.squads.squadSizes[id];

const selectSquadEntities = partialRecord(squadSelectors.selectEntities);
const isSquad = (so: Optional<SquadOverview>): so is SquadOverview => !!so;

export const selectUserIdsBySquadIds = createSelector(
    [selectSquadEntities, (_, ids: d.SquadId[]) => ids],
    (squadRecord, ids) =>
        ids.map(id => squadRecord[id])
            .filter(isSquad)
            .flatMap(so => so.userIds)
            .removeDuplicates(),
    memoizeOptions.lruShallow,
);

export const selectSquads = createAppSelector(
    [selectSquadEntities, (_, ids: d.SquadId[]) => ids],
    (squadMap, ids) => ids.map(id => squadMap[id]).filter(isSquad),
    memoizeOptions.lruShallow,
);

export const selectUserIdsKnownToCurrentUser = shallowArrayEqualSelectorCreator(
    [selectSquadEntities],
    squadMap =>
        Object.values(squadMap)
            .flatMap(so => so?.userIds ?? [])
            .removeDuplicates(),
);

export const selectSquadIdsContainingUserIds = createAppSelector(
    [selectAllSquads, (_, userIds: d.UserId[]) => userIds],
    (squads, userIds) =>
        squads.filter(so => userIds.every(userId => so.userIds.includes(userId)))
            .map(so => so.id),
    memoizeOptions.weakMapShallow,
);

const squadNames = shallowArrayEqualSelectorCreator(
    [selectSquadEntities, (_, ids: d.SquadId[]) => ids],
    (squadMap, ids) =>
        ids.map(id => squadMap[id])
            .filter(isSquad)
            .map(so => so.name),
);

export const selectKnownSquadNames = shallowArrayEqualSelectorCreator(
    [squadNames],
    (names: Optional<string>[]) => names.filter(n => n) as string[],
);

const observationSetSelector = createAppSelector(
    [selectors.observers],
    obs => obs && new Set(obs),
    memoizeOptions.weakMapShallow,
);

const setIsNotUndefined = <T>(s: Set<T> | undefined): s is Set<T> => !!s;

export const selectObservationsForSquadIds = createAppSelector(
    [state => state, (_, ids: d.SquadId[]) => ids],
    (state, ids) => {
        const sets = ids.map(id => observationSetSelector(state, id));
        return setUnion(...sets.filter(setIsNotUndefined));
    },
    memoizeOptions.weakMapShallow,
);

export const reducer = squadSlice.reducer;

// Persistence.

const stores = {
    orgs: idbStore<d.OrgId, OrgOverview>("orgs-2", 17),
    _orgs1: idbStore<d.OrgId, migrateV17.OrgOverview>("orgs", 1, 16),
    squads: idbStore<d.SquadId, SquadOverview>("squads", 1),
    orgMembership: idbStore<d.OrgId, true>("org-membership", 16),
    squadMembership: idbStore<d.SquadId, true>("squad-membership", 16),
    _squadMembership1: localStorageStore<d.SquadId[]>("memberships/squad", 1, 15),
};

type SquadsUpdate =
    | IdbStoreUpdate<typeof stores.orgs>
    | IdbStoreUpdate<typeof stores.squads>
    | IdbStoreUpdateWithClear<typeof stores.orgMembership>
    | IdbStoreUpdateWithClear<typeof stores.squadMembership>;

const persist = (
    previousState: SquadState,
    currentState: SquadState,
): PersistenceUpdate[] => {
    if (previousState.updates == currentState.updates) {
        return [];
    }

    return currentState.updates;
};

const hydrate = async (conn: Connection) => {
    const orgs = await fetchIdbStore(conn, stores.orgs);
    const squads = await fetchIdbStore(conn, stores.squads);

    const userOrgs = await fetchIdbStore(conn, stores.orgMembership);
    const userSquads = await fetchIdbStore(conn, stores.squadMembership);

    return getInitialState({
        orgs,
        squads,
        userOrgs: TypedKeys(userOrgs),
        userSquads: TypedKeys(userSquads),
    });
};

const migrate = async (tx: RWTransaction, oldVersion: number) => {
    if (oldVersion < 17) {
        await simpleIdbTranslate(
            tx,
            stores._orgs1,
            stores.orgs,
            migrateV17.translateOrgsOverview,
        );
    }
};

export const persistor = {
    stores,
    hydrate,
    persist,
    migrate,
};
checkPersistor<SquadState>(persistor);
