import { useEffect, useState } from "react";
import { selectWakeLockInterest } from "../../features/calls";
import { useAppSelector } from "../../store/redux";
import log from "../../misc/log";

const isScreenLockSupported = "wakeLock" in navigator;

const WakeLockManager = (): React.JSX.Element => {
    const [visible, setVisible] = useState(document.visibilityState === "visible");

    const wakeLockInterest = useAppSelector(selectWakeLockInterest);

    useEffect(() => {
        if (isScreenLockSupported && visible && wakeLockInterest > 0) {
            const promise = navigator.wakeLock.request("screen")
                .then(sentinel => {
                    log.info("Screen Wake Lock acquired");
                    return sentinel;
                }, e => log.warn(`Error acquiring wakeLock:`, e));

            return () => {
                promise.then(sentinel => {
                    sentinel?.release();
                    log.info("Screen Wake Lock released");
                });
            };
        }
    }, [visible, wakeLockInterest]);

    useEffect(() => {
        const handleVisibilityChange = () => {
            setVisible(document.visibilityState === "visible");
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, []);

    return <></>;
};

export default WakeLockManager;
