import React from "react";
import { PropsWithChildren } from "react";

interface StrictModeWrapperProps {
    strictMode?: boolean;
}

const StrictModeWrapper = (
    { strictMode, children }: PropsWithChildren<StrictModeWrapperProps>,
): React.JSX.Element => {
    if (strictMode ?? true) {
        return <React.StrictMode>{children}</React.StrictMode>;
    }
    else {
        return <>{children}</>;
    }
};

export default StrictModeWrapper;
