import { DateTime, Duration, Interval } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";

import { clamp } from "../misc/utils";
import log from "../misc/log";

/*
MDN says:
  # Maximum delay value
  > Browsers store the delay as a 32-bit signed integer internally. This
  > causes an integer overflow when using delays larger than 2,147,483,647 ms
  > (about 24.8 days), resulting in the timeout being executed immediately.

  Limit ourselves to 2^30 ~= 12 days. Gives us a bit of headroom.

  For the minimum, just choose some small number > 0 to ensure we are
  making forward progress. This would be straightforward to make configurable,
  if that were desired.
*/
export const maxSetTimeoutDelayMs = 1 << 30;
const delayClamper = clamp(1 << 3, maxSetTimeoutDelayMs);

const zeroSeconds = Duration.fromObject({});
const oneSecond = Duration.fromObject({ seconds: 1 });

interface useTimerTargetReturnType {
    now: DateTime;
    nowMs: number;
    target: DateTime | undefined;
    setDelay: (delay: number | Duration) => void;
    setTarget: (target: number | DateTime) => void;
    cancel: () => void;
}

/** @function
 * Arm and manage a timer.
 *
 * @returns an object containing the following:
 * - `now` - a `DateTime` of the latest firing of the timer
 * - `nowMs` - the epoch time in milliseconds of the latest firing of the timer
 * - `target?` - a `DateTime` for the current target, `undefined` if not armed
 * - `setDelay` - schedule the timer for a duration into the future
 * - `setTarget` - schedule the timer for a precise time in the future
 * - `cancel` - disarm the timer
 */
export default function useTimerTarget(): useTimerTargetReturnType {
    const [lastCheck, setLastCheck] = useState<DateTime>(DateTime.now());

    const [consumerNow, setConsumerNow] = useState<DateTime>(lastCheck);
    const [target, setTarget] = useState<DateTime | undefined>();

    const setDelay = useCallback((delay: number | Duration) => {
        const now = DateTime.now();
        if (delay < zeroSeconds) {
            log.warn(`Tried to set a negative delay:`, delay);
            setTarget(now);
        }
        else {
            setTarget(now.plus(delay));
        }
    }, [setTarget]);

    // `target: number` => unix epoch in milliseconds
    const setTargetInternal = useCallback((target: number | DateTime) => {
        const now = DateTime.now();

        const targetDateTime = typeof target === "number" ? DateTime.fromMillis(target) : target;

        if (targetDateTime < now) {
            if (Interval.fromDateTimes(targetDateTime, now).toDuration() > oneSecond) {
                log.warn(`Tried to set a target in the past:`, target);
            }
            setTarget(DateTime.now());
        }
        else {
            setTarget(targetDateTime);
        }
    }, [setTarget]);

    const cancel = useCallback(() => {
        setTarget(undefined);
    }, []);

    useEffect(() => {
        if (target === undefined || target <= lastCheck) return;

        // Don't use `lastCheck` for comparison here. `lastCheck` is used only
        // to force re-evaluation of the `useEffect`.
        const now = DateTime.now();
        const delayMs = target.diff(now).toMillis();

        if (delayMs <= 0) {
            setLastCheck(now);
            setConsumerNow(now);
            setTarget(undefined);
            return;
        }

        const clampedMs = delayClamper(Math.ceil(delayMs));

        const handle = setTimeout(() => {
            const newNow = DateTime.now();
            setLastCheck(newNow);

            if (consumerNow < target && target <= newNow) {
                setConsumerNow(newNow);
                setTarget(undefined);
            }
        }, clampedMs);

        return () => clearTimeout(handle);
    }, [target, lastCheck, consumerNow, setLastCheck, setTarget, setConsumerNow]);

    return useMemo(() => ({
        now: consumerNow,
        nowMs: consumerNow.toMillis(),
        target,
        setDelay,
        setTarget: setTargetInternal,
        cancel,
    }), [consumerNow, target, setDelay, setTargetInternal, cancel]);
}
