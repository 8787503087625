import { useCallback } from "react";
import { RouterProvider } from "react-router-dom";

import { ConnectionController } from "./ConnectionController";
import MessageFetcher from "./MessageFetcher";
import MessageReadUpdater from "./MessageReadUpdater";
import MessageSender from "./MessageSender";
import WindowTitleUpdater from "./WindowTitleUpdater";
import WindowFocusTracker from "./WindowFocusTracker";
import RefreshOnNewVersion from "./RefreshOnNewVersion";
import WakeLockManager from "./managers/WakeLockManager";
import { SystemMessage } from "./SystemMessage";
import { ErrorBoundary } from "@sentry/react";
import useWebLock from "../hooks/useWebLock";
import AttachmentManager, { FileStashContext, fileStashMap } from "./managers/AttachmentManager";
import { BlockBlobClient, newPipeline } from "@azure/storage-blob";
import OnlineTracker from "./OnlineTracker";
import { RefreshBackendInfo } from "./RefreshBackendInfo";
import IdleTracker from "./IdleTracker";
import StreamManagers from "./managers/StreamManagers";
import { getRouter } from "../misc/router";

const BeyondOpenElsewhere = () => (
    <SystemMessage message="Bond is already open in another window." />
);

export default function Session(): React.JSX.Element {
    const haveLock = useWebLock("beyond");

    const blockBlobClientGenerator = useCallback((url: string) => {
        return new BlockBlobClient(
            url,
            newPipeline(),
        );
    }, []);

    if (haveLock === undefined) {
        return <></>;
    }
    else if (haveLock === false) {
        return <BeyondOpenElsewhere />;
    }

    return (
        <ErrorBoundary>
            <ConnectionController />
            <OnlineTracker />
            <MessageSender />
            <MessageFetcher />
            <StreamManagers />
            <WakeLockManager />
            <MessageReadUpdater />
            <WindowTitleUpdater />
            <WindowFocusTracker />
            <RefreshOnNewVersion />
            <RefreshBackendInfo />
            <IdleTracker />
            <FileStashContext.Provider value={fileStashMap}>
                <AttachmentManager clientFactory={blockBlobClientGenerator} />
                <RouterProvider router={getRouter()} />
            </FileStashContext.Provider>
        </ErrorBoundary>
    );
}
