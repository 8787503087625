import useResizeObserver from "@react-hook/resize-observer";
import {
    ForwardedRef,
    forwardRef,
    LegacyRef,
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useInView } from "react-intersection-observer";

import {
    ChannelMessageComposer,
    ChannelMessageComposerProps,
} from "../components/ChannelMessageComposer";
import * as d from "../domain/domain";
import { selectChannelIdByBondId, selectLiveCallIdByBondId } from "../features/bonds";
import {
    JoinedCallView,
    selectCallById,
    selectJoinedCallView,
    setCurrentCallId,
} from "../features/calls";
import {
    selectLocalSeqNum,
    selectMessageIdsByChannelId,
    selectPublishedSequenceNumber,
} from "../features/channels";
import { streamMessagesByChannelId } from "../features/chats";
import { useAppDispatch, useAppSelector } from "../store/redux";
import useMergedRefs from "../hooks/useMergedRefs";
import useSessionWithMediaControls from "../hooks/session/useSessionWithMediaControls";
import useStreamDispatch from "../hooks/useStreamDispatch";
import { Focusable, Optional } from "../misc/types";
import MessageView from "./MessageView";
import { BondLiveInteraction } from "../components/BondLiveInteraction";
import { Participant, canOpenLiveView, orderParticipants } from "../domain/session";
import useSelectorArgs from "../hooks/useSelectorArgs";
import BondLiveGridView from "./BondLiveGridView";
import { selectCurrentUserId } from "../features/auth";
import useKeypressFocus from "../hooks/useKeypressFocus";
import { ComposerStates } from "../components/MessageComposer";
import { useShallowEqualsMemo } from "../hooks/useShallowEquals";

const DEFAULT_MSG_HEIGHT = 60;

export const testWarningRegex =
    /^Warning: `NaN` is an invalid value for the `%s` css style property/;

export function ChatMessagesViewInternal(props: ChatMessagesViewProps): React.JSX.Element {
    const {
        bondId,
        callParticipants,
        scrollToMessageId,
        publishedSequenceNumberForChannel,
        liveMessageParticipantsRef,
    } = props;

    const channelId = useAppSelector(selectChannelIdByBondId(bondId));
    const messageIds = useAppSelector(selectMessageIdsByChannelId(channelId));
    const liveCallId = useAppSelector(selectLiveCallIdByBondId(bondId));
    const listRef = useRef<HTMLDivElement>(null);
    const spanRef = useRef<HTMLSpanElement>(null);
    const previousScrollToMessageId = useRef<Optional<d.AnyMessageId>>(undefined);
    const endDivRef = useRef<HTMLDivElement>(null);
    const [currentlyScrolledToBottom, setCurrentlyScrolledToBottom] = useState(
        props.scrollToMessageId === undefined,
    );

    const { orderedParticipants, displayMediaParticipants } = useMemo(
        () => orderParticipants(callParticipants),
        [callParticipants],
    );

    const currentUserId = useAppSelector(selectCurrentUserId);

    // can open live view if any non-self orderedParticipant has an active video
    const canOpenLiveViewMemo = useMemo(
        () => canOpenLiveView(orderedParticipants, currentUserId),
        [orderedParticipants, currentUserId],
    );

    const scrollToBottom = useCallback(() => {
        endDivRef.current?.scrollIntoView({ behavior: "instant" });
    }, []);

    useLayoutEffect(() => {
        if (!scrollToMessageId) {
            scrollToBottom();
            return;
        }

        if (scrollToMessageId && scrollToMessageId != previousScrollToMessageId.current) {
            document.getElementById(scrollToMessageId)?.scrollIntoView({
                behavior: "instant",
                block: "center",
            });

            previousScrollToMessageId.current = scrollToMessageId;
        }
    }, [scrollToBottom, scrollToMessageId]);

    // Keep scroll up to date
    useResizeObserver(spanRef, () => {
        if (listRef.current) {
            setCurrentlyScrolledToBottom(isNearBottom(listRef.current));
        }

        if (currentlyScrolledToBottom) {
            scrollToBottom();
        }

        // This implicitly assumes that call summaries do not change for long-since-ended calls.
    });

    const isNearBottom = useCallback((d: HTMLDivElement) => {
        // Half a small message height is about right as a heuristic
        // for "can we see the message content at all".
        const bottomThresh = DEFAULT_MSG_HEIGHT / 2;
        return d.scrollHeight - d.scrollTop - d.clientHeight < bottomThresh;
    }, []);

    const handleScroll: React.UIEventHandler<HTMLDivElement> = useCallback(
        (ev: React.UIEvent<HTMLDivElement, UIEvent>) => {
            setCurrentlyScrolledToBottom(isNearBottom(ev.currentTarget));
        },
        [isNearBottom],
    );

    if (!channelId) {
        return <></>;
    }

    return (
        <div
            onScroll={handleScroll}
            ref={listRef}
            className="c-bond__content"
        >
            <span style={{ display: "block" }} ref={spanRef}>
                {messageIds.map((id, index) => (
                    <MessageView
                        key={id}
                        id={id}
                        currentCallId={liveCallId}
                        previousId={messageIds[index - 1]}
                        lastMessage={index == (messageIds.length - 1)}
                        publishedSequenceNumber={publishedSequenceNumberForChannel}
                    />
                ))}

                <BondLiveInteraction
                    callId={liveCallId}
                    displayParticipants={displayMediaParticipants}
                    expandable={canOpenLiveViewMemo}
                    participantsRef={liveMessageParticipantsRef}
                />
                <div ref={endDivRef} />
            </span>
        </div>
    );
}

interface ChatMessagesViewProps {
    bondId: d.BondId;
    callParticipants: Participant[];
    scrollToMessageId?: d.AnyMessageId;
    publishedSequenceNumberForChannel?: number;
    liveMessageParticipantsRef?: LegacyRef<HTMLDivElement>;
}

export function ChatMessagesView(props: ChatMessagesViewProps): React.JSX.Element {
    const { bondId } = props;

    const channelId = useAppSelector(selectChannelIdByBondId(bondId));
    const messageIds = useAppSelector(selectMessageIdsByChannelId(channelId));
    const publishedSequenceNumber = useSelectorArgs(selectPublishedSequenceNumber, channelId);

    // This is responsible for finding the ID of the message with the new messages marker on it.
    // If there is not one, the it returns undefined.
    //
    // If there is a specific message ID to scroll to supplied in the props to this component,
    // that ID is prioritised.
    const scrollToMessageId = useMemo(() => {
        if (props.scrollToMessageId !== undefined) {
            return props.scrollToMessageId;
        }

        // This is the index of the message that would have the new messages marker on it,
        // which is the message with sequence number 1 greater than the published sequence number.
        // Since the sequence numbers for messages start at 1, we have to subtract 1
        // from the published sequence number to use as an index into the array of messageIds.
        const desiredIndex = publishedSequenceNumber ?? 0;

        // The following index works since we have already fetched all messages
        // in the channel when we come to here.
        return messageIds[desiredIndex];
    }, [messageIds, props.scrollToMessageId, publishedSequenceNumber]);

    return (
        <ChatMessagesViewInternal
            {...props}
            scrollToMessageId={scrollToMessageId}
            publishedSequenceNumberForChannel={publishedSequenceNumber}
        />
    );
}

interface ChatViewProps {
    bondId: d.BondId;
    scrollToMessageId?: d.MessageId;
    showBondInterestedAction?: () => void;
}

export const ChatView = forwardRef((
    props: ChatViewProps,
    ref: ForwardedRef<Focusable>,
): React.JSX.Element => {
    const {
        bondId,
        scrollToMessageId,
        showBondInterestedAction,
    } = props;

    const dispatch = useAppDispatch();

    const channelId = useAppSelector(selectChannelIdByBondId(bondId));
    const liveCallId = useAppSelector(selectLiveCallIdByBondId(bondId));

    const liveCall = useSelectorArgs(selectCallById, liveCallId);
    const joinedCallView = useAppSelector(selectJoinedCallView);
    const isLiveView = joinedCallView === JoinedCallView.Live;

    const composerRef = useRef<Focusable>(null);
    const setComposerTextFocused = useKeypressFocus(composerRef);
    const mergedRefs = useMergedRefs(composerRef, ref);

    const onComposerActiveChange = useCallback((active: ComposerStates) => {
        setComposerTextFocused(active.textFocused);
    }, [setComposerTextFocused]);

    const { ref: liveMessageParticipantsRef, inView: liveMessageDisplaysInView } = useInView({
        threshold: 0.5,
    });
    // In BondView, if display tiles not visible in live message, show them in the bond presence.
    // In LiveView, let grid logic control this.
    const showDisplaysInBondPresence = isLiveView || !liveMessageDisplaysInView;

    // Mark the bond's call as the current call once we learn about it
    useEffect(() => {
        dispatch(setCurrentCallId(liveCallId));
    }, [dispatch, liveCallId]);

    const {
        allParticipants: callParticipants,
        mediaControls,
    } = useSessionWithMediaControls({
        bondId,
        callId: liveCallId,
    });

    const { orderedParticipants } = useShallowEqualsMemo(
        () => orderParticipants(callParticipants),
        [callParticipants],
    );
    const bondPresenceLiveParticipants = showDisplaysInBondPresence ? orderedParticipants
        : callParticipants;

    const localSequenceNumberForChannel = useSelectorArgs(selectLocalSeqNum, channelId);
    useStreamDispatch(
        () =>
            channelId &&
            streamMessagesByChannelId({
                channelId,
                startSequenceNumber: (localSequenceNumberForChannel ?? 0) + 1,
            }),
        [
            channelId,
            localSequenceNumberForChannel,
        ],
    );
    if (!channelId) {
        return <></>;
    }

    const liveGridView = liveCall && <BondLiveGridView participants={callParticipants} />;

    const chatOrLiveGrid = isLiveView ? liveGridView : (
        <ChatMessagesView
            bondId={bondId}
            callParticipants={callParticipants}
            scrollToMessageId={scrollToMessageId}
            liveMessageParticipantsRef={liveMessageParticipantsRef}
        />
    );

    const composerProps: ChannelMessageComposerProps = {
        channelId,
        showBondInterestedAction,
        mediaControls: mediaControls,
        onStatesChange: onComposerActiveChange,
        liveParticipants: bondPresenceLiveParticipants,
    };
    return (
        <>
            {chatOrLiveGrid}
            <div className="c-bond-composer c-bond-composer--visible">
                <ChannelMessageComposer ref={mergedRefs} {...composerProps} />
            </div>
        </>
    );
});

export default ChatView;
