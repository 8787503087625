import { memo } from "react";
import { MediaToggle, MediaToggleMap, toMediaGroup } from "../../domain/mediaDevices";
import MediaDeviceControl from "./MediaDeviceControl";
import { TypedEntries } from "../../misc/types";

export enum CallControlsLocation {
    Default,
    LiveBond,
}

export interface CallControlsProps {
    chatToggle?: MediaToggle;
    mediaToggles?: MediaToggleMap;
    onLeave?: () => void;
    location: CallControlsLocation;
}

function CallControls(
    props: CallControlsProps,
): React.JSX.Element {
    const { mediaToggles, chatToggle, location, onLeave } = props;

    const className = `c-call-controls ${
        location === CallControlsLocation.Default ? "c-call__controls--fixed" : ""
    }`;

    return (
        <div className={className}>
            {chatToggle && (
                // TODO: remove chat toggle
                <button
                    onClick={chatToggle?.toggle}
                    className="c-call-btn c-call-btn--chat"
                    title="Toggle Chat"
                >
                    Chat
                </button>
            )}
            {mediaToggles &&
                TypedEntries(mediaToggles).map(([kind, toggle]) => (
                    <MediaDeviceControl
                        control={toggle}
                        mediaGroup={toMediaGroup(kind)}
                        key={kind}
                    />
                ))}
            {onLeave && (
                <button
                    className="c-call-btn c-call-btn--leave"
                    onClick={onLeave}
                    title="Leave call"
                >
                    Leave
                </button>
            )}
        </div>
    );
}

// Explicitly memoise this component to stop unnecessarily re-rendering
const CallControlsMemoed = memo(CallControls);
export default CallControlsMemoed;
