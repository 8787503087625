import { CSSProperties } from "react";
import { BoxSize, Styleable } from "./types";

/**
 * Apply computed styles to a target styleable object
 */
export const applyStyle = (source: CSSStyleDeclaration, target: Styleable) => {
    Array.from(source).forEach(key =>
        target.setProperty(
            key,
            source.getPropertyValue(key),
            source.getPropertyPriority(key),
        )
    );
};

export const kebabStringToCSSPropertiesKey = (kebabKey: string): keyof CSSProperties =>
    kebabKey.replace(
        /-+([a-z])/g,
        (_, letter) => letter.toUpperCase(),
    ) as keyof CSSProperties;

/**
 * Convert a CSSStyleDeclaration (e.g. from getComputedStyle) to a CSSProperties that
 * can be applied to a JSX component. Input property priorities are discarded
 */
export const extractCSSProperties = (style: CSSStyleDeclaration): CSSProperties => {
    const properties: {
        [key: string]: string;
    } = {};

    Array.from(style).forEach(kebabKey => {
        const camelCaseKey = kebabStringToCSSPropertiesKey(kebabKey);
        properties[camelCaseKey] = style.getPropertyValue(kebabKey);
    });

    return properties;
};

export const calcBorderBoxSize = (element: HTMLElement): BoxSize => {
    const style = window.getComputedStyle(element);
    const width = parseInt(style.width) +
        parseInt(style.marginLeft) +
        parseInt(style.marginRight) +
        parseInt(style.borderLeftWidth) +
        parseInt(style.borderRightWidth);
    const height = parseInt(style.height) +
        parseInt(style.marginTop) +
        parseInt(style.marginBottom) +
        parseInt(style.borderTopWidth) +
        parseInt(style.borderBottomWidth);
    return { width, height };
};
