import { useCallback } from "react";

export function MobileMenuButton(): React.JSX.Element {
    const onClick = useCallback(() => {
        document.body.classList.toggle("m-aside");
    }, []);

    return (
        <button
            type="button"
            aria-label="Menu"
            aria-controls="navigation"
            className="c-menu-toggle"
            onClick={onClick}
        >
            <div className="c-menu-toggle__outer">
                <div className="c-menu-toggle__inner"></div>
            </div>
        </button>
    );
}
