import {
    selectCurrentAndInterestedOrgIds,
    selectCurrentAndInterestedSquadIds,
    streamOrgOverviews,
    streamSquadOverviews,
} from "../../features/squads";
import StreamManager from "./StreamManager";

function SquadStreamManager(): React.JSX.Element {
    return (
        <>
            <StreamManager
                idSelector={selectCurrentAndInterestedOrgIds}
                streamThunk={streamOrgOverviews}
            />
            <StreamManager
                idSelector={selectCurrentAndInterestedSquadIds}
                streamThunk={streamSquadOverviews}
            />
        </>
    );
}

export default SquadStreamManager;
