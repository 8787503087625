import { useCallback, useEffect, useState } from "react";
import { isNativePlatform } from "../misc/capacitor";
import { PushNotifications } from "@capacitor/push-notifications";
import log from "../misc/log";
import { reportFirebaseTokenThunk } from "../features/notifications";
import { useAppDispatch } from "../store/redux";
import { useConnectedEffect } from "../hooks/useConnectedEffect";
import useBackoff from "../hooks/useBackoff";

const createDefaultNotificationChannel = async () => {
    await PushNotifications.createChannel({
        id: "beyond_default_notification_channel", // Matches the default channel specified in AndroidManifest.xml.
        name: "Bond",
        description: "The primary notification channel for the Bond app.",
        sound: "helm_hammerhand.mp3", // The file name of a sound file should be specified relative to the android app `res/raw` directory.
        visibility: 0, // Private level: Show this notification on all lockscreens, but conceal sensitive or private information on secure lockscreens.
        lights: true,
        lightColor: "#6A87D0", // Matches the "blue" token in _color.scss.
        vibration: true,
    });
};

const registerNotifications = async () => {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === "prompt") {
        permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== "granted") {
        log.warn("User denied notification permissions!");
        return;
    }

    await PushNotifications.register();
};

function PermissionManager(): React.JSX.Element {
    const dispatch = useAppDispatch();

    const [firebaseToken, setFirebaseToken] = useState("");
    const [succeeded, setSucceeded] = useState(false);
    const { actionIsPermitted, actionBegin } = useBackoff();

    useConnectedEffect(() => {
        if (!firebaseToken || !actionIsPermitted || succeeded) return;

        const backoffCallback = actionBegin();

        dispatch(reportFirebaseTokenThunk(firebaseToken))
            .unwrap()
            .then(
                () => {
                    backoffCallback(true);
                    setSucceeded(true);
                },
                () => backoffCallback(false),
            );
    }, [actionBegin, actionIsPermitted, dispatch, firebaseToken, succeeded]);

    const addListeners = useCallback(async () => {
        await PushNotifications.addListener("registration", token => {
            setFirebaseToken(token.value);
        });

        await PushNotifications.addListener("registrationError", err => {
            log.error("Registration error: ", err.error);
        });

        await PushNotifications.addListener("pushNotificationReceived", () => {
            log.info("Push notification received");
        });

        await PushNotifications.addListener("pushNotificationActionPerformed", () => {
            log.info("Push notification action performed");
        });
    }, []);

    useEffect(() => {
        createDefaultNotificationChannel()
            .then(addListeners)
            .then(registerNotifications);
    }, [addListeners]);

    return <></>;
}

export default function NativeNotificationManager(): React.JSX.Element {
    return (
        <>
            {isNativePlatform && <PermissionManager />}
        </>
    );
}
