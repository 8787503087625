// import * as d from "../domain/domain";
import { IntelService } from "../../gen/proto/intel/intel_connect";
import { SuggestedBond } from "../domain/intel";
import { transport } from "./transport";
import { createPromiseClient } from "@connectrpc/connect";
import * as d from "../domain/domain";
import { SuggestBondsRequest } from "../../gen/proto/intel/intel_pb";
import { fromProtoBondId, pbSquadSet, pbUserId, pbUserSet } from "./util";

export const service = createPromiseClient(IntelService, transport);
export default service;

export interface GetSuggestedBondIdsArgs {
    userId: d.UserId;
    solicitingText: string;
    solicitingTitle?: string;
    targetUserIds: d.UserId[];
    limit: number;
    squadScope?: d.SquadId[];
}

export async function getSuggestedBondIds(
    args: GetSuggestedBondIdsArgs,
): Promise<Array<SuggestedBond>> {
    const resp = await service.suggestBonds(
        new SuggestBondsRequest({
            userId: pbUserId(args.userId),
            solicitingText: args.solicitingText,
            solicitingTitle: args.solicitingTitle,
            targetUserIds: pbUserSet(args.targetUserIds),
            squadScope: pbSquadSet(args.squadScope || []),
            limit: args.limit,
        }),
    );

    return resp?.suggestedBonds?.map(b => ({
        bondId: fromProtoBondId(b.bondId),
        score: b.score,
    })) || [];
}
