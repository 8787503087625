import React, { forwardRef, useEffect, useRef } from "react";

import useMergedRefs from "../../hooks/useMergedRefs";
import usePrevious from "../../hooks/usePrevious";
import { Optional, isString } from "../../misc/types";
import classNames from "classnames";

interface ITextareaProps extends React.HTMLProps<HTMLTextAreaElement> {
    caretHint?: number;
    caretHintId?: string;
    onValueChange?: (value: string) => void;
    onChangeEvent?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onEnter?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
    onEscape?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
}

export const TextArea = forwardRef(function TextArea(
    props: ITextareaProps,
    ref?: React.ForwardedRef<HTMLTextAreaElement>,
): React.JSX.Element {
    const inputRef = useRef<HTMLTextAreaElement>(null);

    // Remove props that don't match that of `HTMLTextAreaElement` to avoid
    // warnings. (It doesn't hurt to remove ones that will be overwritten too.)
    const {
        caretHint,
        caretHintId,
        onValueChange,
        onChangeEvent,
        onChange,
        onEnter,
        onEscape,
        onKeyDown,
        className: propClassName,
        ...passthroughProps
    } = props;

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (onKeyDown) {
            onKeyDown(e);
            if (e.isPropagationStopped()) {
                return;
            }
        }

        if (e.key === " " && inputRef.current === document.activeElement) {
            e.stopPropagation();
        }

        if (
            e.key === "Enter" &&
            !e.shiftKey &&
            inputRef.current?.id === document.activeElement?.id
        ) {
            onEnter?.(e);
            if (e.isPropagationStopped()) {
                return;
            }

            e.preventDefault();
        }

        if (e.key === "Escape") {
            onEscape?.(e);
            if (e.isPropagationStopped()) {
                return;
            }
        }
    };

    const mergedRefs = useMergedRefs(inputRef, ref);

    // Update the value and apply an outstanding caret hint if one exists
    const value = isString(props.value) ? props.value : "";
    const prevValue = usePrevious(value);
    const lastAppliedCaretHintIdRef = useRef<Optional<string>>(undefined);
    useEffect(() => {
        if (!inputRef.current) {
            return;
        }

        const applyCaretHint = (caretHint !== undefined) && caretHintId &&
            caretHintId != lastAppliedCaretHintIdRef.current;

        if (value != prevValue) {
            const caretOffset = inputRef.current.selectionEnd;
            inputRef.current.value = value;
            if (!applyCaretHint) {
                inputRef.current.setSelectionRange(caretOffset, caretOffset);
            }
        }

        if (applyCaretHint) {
            lastAppliedCaretHintIdRef.current = caretHintId;
            inputRef.current.setSelectionRange(caretHint, caretHint);
        }
    }, [value, prevValue, caretHint, caretHintId, onChange]);

    const classes = classNames("c-textarea", propClassName);

    return (
        <textarea
            {...passthroughProps}
            ref={mergedRefs}
            onChange={e => {
                onChangeEvent?.(e);
                onValueChange?.(e.target.value);
            }}
            onKeyDown={handleKeyDown}
            className={classes}
        />
    );
});

export default TextArea;
