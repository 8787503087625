import { RefObject, useCallback, useState } from "react";
import { NumberRange, Optional } from "../misc/types";
import { clamp } from "../misc/utils";

type UpdateSelectionFunc = () => void;

interface useSelectionReturnType {
    selection: Optional<NumberRange>;
    definedSelection: NumberRange;
    onSelectionChange: UpdateSelectionFunc;
}

export const useSelection = (
    ref: RefObject<HTMLTextAreaElement>,
): useSelectionReturnType => {
    const [selection, setSelection] = useState<Optional<NumberRange>>(undefined);
    const [definedSelection, setDefinedSelection] = useState<NumberRange>({ start: 0, end: 0 });

    const onSelectionChange = useCallback(() => {
        if (!ref.current || document.activeElement != ref.current) {
            setSelection(undefined);

            const selection = ref.current && {
                start: ref.current.selectionStart,
                end: ref.current.selectionEnd,
            };

            const selectionToUse = selection || definedSelection || { start: 0, end: 0 };
            const clampToValue = clamp(0, ref.current?.value.length ?? 0);
            selectionToUse.start = clampToValue(selectionToUse.start);
            selectionToUse.end = clampToValue(selectionToUse.end);
            setDefinedSelection(selectionToUse);
        }
        else {
            const selection = {
                start: ref.current.selectionStart,
                end: ref.current.selectionEnd,
            };
            setSelection(selection);
            setDefinedSelection(selection);
        }
    }, [ref, definedSelection]);

    return {
        selection,
        definedSelection,
        onSelectionChange,
    };
};
