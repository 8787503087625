export const BondNewDivider = (): React.JSX.Element => (
    <div className="c-message-divider">
        <span className="c-message-divider__title">New</span>
        <span className="c-message-divider__rule"></span>
    </div>
);

export const BondTimeDivider = (): React.JSX.Element => (
    <div className="c-message-divider">
        <span className="c-message-divider__rule--grey"></span>
    </div>
);
