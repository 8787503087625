import { App } from "@capacitor/app";
import { useState, useEffect } from "react";

import { selectBackendInfo } from "../features/meta";
import { useAppSelector } from "../store/redux";
import { isNativePlatform } from "../misc/capacitor";

export function VersionInfo(): React.JSX.Element {
    const backendInfo = useAppSelector(selectBackendInfo);

    const [nativeVersion, setNativeVersion] = useState<string>("");
    useEffect(() => {
        if (isNativePlatform) {
            App.getInfo().then(info => setNativeVersion(info.version));
        }
    }, []);

    return (
        <>
            <span>Backend:</span> {backendInfo?.version}
            <br />
            <span>Instance:</span> {backendInfo?.instance}

            {!isNativePlatform && (
                <>
                    <br />
                    <span>Frontend:</span> {__BEYOND_FRONTEND_VERSION__}
                </>
            )}

            {isNativePlatform && (
                <>
                    <br />
                    <span>App version:</span> {nativeVersion}
                </>
            )}
        </>
    );
}
