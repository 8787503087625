import classNames from "classnames";
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { bondCreationDraftTarget } from "../domain/channels";
import { DraftChatMessage } from "../domain/chats";
import * as d from "../domain/domain";
import {
    clearDraftThunk,
    createBondFromMessageThunk,
    fetchSuggestedBondIdsThunk,
    promotePrefix,
    selectBondCreationMessageQueryString,
} from "../features/bondCreation";
import { clearFreshBond } from "../features/bonds";
import {
    PrivateOrSquadFilterOption,
    setPrivateOrSquadFilterThunk,
    setRelevanceLevelFilter,
} from "../features/filterPanel";
import { useAppDispatch, useAppSelector } from "../store/redux";
import { useConnectedEffect } from "../hooks/useConnectedEffect";
import useDebounce from "../hooks/useDebounce";
import useKeypressFocus from "../hooks/useKeypressFocus";
import useMousePosCondition from "../hooks/useMousePosCondition";
import usePrevious from "../hooks/usePrevious";
import log from "../misc/log";
import { Focusable, Optional } from "../misc/types";
import { ComposerStates, MessageComposer } from "./MessageComposer";
import useSelectorArgs from "../hooks/useSelectorArgs";
import { selectDraftMentions, selectDraftMessageSendableStatus } from "../features/channels";
import { selectCurrentOrgId } from "../features/auth";

// The period after manually closing the bar before it can be manually opened again (either
// with the mouse or the handle). The bar will always open immediately when typing or tabbing
const barClosingGraceMillis = 500;

// Hysteresis rules for bond creation message triggering bond suggestions
const isStringLengthHysteresisMet =
    (triggerValue: number) => (oldValue: string, newValue: string) => {
        if (newValue.length < triggerValue) {
            return false;
        }
        const lastChar = newValue[newValue.length - 1];
        if (lastChar != " ") { // Triggers only on whole words.
            return false;
        }
        const lengthDelta = Math.abs(oldValue.trim().length - newValue.trim().length);
        return lengthDelta >= triggerValue;
    };

const messageTriggerValue = 10;
const isMessageHysteresisMet = isStringLengthHysteresisMet(messageTriggerValue);

const messageHysteresis = (oldValue: string, newValue: string) => {
    if (!newValue || newValue.length < messageTriggerValue) return false;

    return isMessageHysteresisMet(
        oldValue ?? "",
        newValue,
    );
};

interface BondCreationBarProps {}

export default function BondCreationBar(
    _props: BondCreationBarProps,
): React.JSX.Element {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const orgId = useAppSelector(selectCurrentOrgId);

    const messageSendableStatus = useSelectorArgs(
        selectDraftMessageSendableStatus,
        bondCreationDraftTarget,
    );

    const createBondFromMsg = useCallback(async (msg: Optional<DraftChatMessage>) => {
        if (!msg) return;

        // In future, we'll want this to be context dependent. Hence, don't
        // extract it from the store inside the thunk, do it here.
        if (!orgId) {
            log.warn(`No orgId for new bond`);
            return;
        }

        log.info("Creating bond with message");

        const newBond = await dispatch(createBondFromMessageThunk({
            orgId,
            msg,
        })).unwrap();

        dispatch(clearFreshBond({}));
        navigate("/bond/" + d.extractUUID(newBond.id));
    }, [
        dispatch,
        orgId,
        navigate,
    ]);

    const barRef = useRef<HTMLDivElement>(null);
    const composerRef = useRef<Focusable>(null);

    // Track composer text area focus, and the emoji input's visibility
    const setComposerTextFocused = useKeypressFocus(composerRef);
    const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);

    // If we have a non-empty draft, we want to show the bar immediately.
    const textInBody = !!useAppSelector(selectBondCreationMessageQueryString);
    const [immediate, setImmediate] = useState<boolean>(textInBody);

    const onComposerActiveChange = useCallback(
        (active: ComposerStates) => {
            setEmojiPickerOpen(active.emojiPickerOpen);
            setComposerTextFocused(active.textFocused);
            if (active.textFocused) {
                // Prevent the janky interaction between the css transition and focussing an off-screen element
                setImmediate(true);
            }
        },
        [setComposerTextFocused],
    );

    // Track whether the mouse position is within the trigger region for showing the bar
    const mouseCondition = useCallback((_: number, y: number) => {
        if (!barRef.current) {
            return false;
        }
        const barTop = barRef.current.getBoundingClientRect().top;
        return y > barTop - 10;
    }, []);
    const [mouseInRegion, clearMouseInRegion] = useMousePosCondition(mouseCondition);

    // Track whether the user has opened the bar using the handle---this is the only way
    // to open the bar on mobile
    const [pinned, setPinned] = useState(false);
    const handleClickCallback = useCallback(() => {
        // Pin the bar and focus the input when the user clicks the handle
        setPinned(true);
        setImmediate(true);
        composerRef.current?.focus();
    }, [setPinned]);
    useEffect(() => {
        const check = (e: MouseEvent | TouchEvent) => {
            if (
                barRef.current && e.target &&
                !barRef.current.contains(e.target as Node)
            ) {
                // Unpin the bar when the user clicks outside
                setPinned(false);
                clearMouseInRegion();
            }
        };

        document.addEventListener("click", check);
        document.addEventListener("touchstart", check);
        return () => {
            document.removeEventListener("click", check);
            document.removeEventListener("touchstart", check);
        };
    }, [setPinned, clearMouseInRegion]);

    // Condition where the bar should get the chance to fully close before opening again
    const [closing, setClosing] = useState(false);
    const closingTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
    const setClosingWithTimeout = useCallback(() => {
        setClosing(true);

        if (closingTimeout.current !== null) {
            clearTimeout(closingTimeout.current);
        }
        closingTimeout.current = setTimeout(() => {
            setClosing(false);
        }, barClosingGraceMillis);
    }, [setClosing]);

    const escapeAction = useCallback(() => {
        setClosingWithTimeout();
        setPinned(false);
        clearMouseInRegion();
    }, [setClosingWithTimeout, setPinned, clearMouseInRegion]);

    const discardAction = useCallback(() => {
        dispatch(clearDraftThunk(bondCreationDraftTarget))
            .unwrap()
            .finally(() => escapeAction());
    }, [dispatch, escapeAction]);

    // Clear the home/squad/done selection when the user starts a draft, and promote the prefix
    // mention to a regular part of the message
    useEffect(() => {
        if (textInBody) {
            dispatch(promotePrefix(bondCreationDraftTarget));
            dispatch(setRelevanceLevelFilter({ by: "all" }));
            dispatch(
                setPrivateOrSquadFilterThunk({
                    by: "option",
                    option: PrivateOrSquadFilterOption.ALL,
                }),
            );
        }
    }, [dispatch, textInBody]);

    // Always show the bar when the emoji picker is open or the composer contains text
    // Otherwise, show the bar when pinned or the mouse moves down, unless the manual close grace period is active
    const trigger = !closing && (pinned || mouseInRegion);
    const showBar = emojiPickerOpen || textInBody || trigger;
    const prevShowBar = usePrevious(showBar);

    // Reset the immediate flag when the bar is hidden, using a layout effect
    // so the CSS classes can be modified before the first redraw
    useLayoutEffect(() => {
        if (!showBar && showBar != prevShowBar) {
            setImmediate(false);
        }
    }, [showBar, prevShowBar]);

    // Update suggestions when the message changes
    const mentions = useSelectorArgs(selectDraftMentions, bondCreationDraftTarget);
    const suggestionQueryString = useAppSelector(selectBondCreationMessageQueryString);

    const debouncedSuggestionQueryString = useDebounce(
        suggestionQueryString,
        1000,
        undefined,
        messageHysteresis,
    );

    useConnectedEffect(() => {
        // Whitespace at the start of the message is removed by
        // the selector. We need whitespace at the end as part of our
        // determination of when to fetch new suggestions. But we don't
        // want to include that whitespace in the soliciting text.
        const solicitingText = debouncedSuggestionQueryString.trimEnd();

        // There's still a possible extra solicitation here that fires after
        // we hit send, due to the debounce updating on a subsequent render.
        // In non-test usage, I think it's okay, as we'll be unmounting
        // this component.

        if (!solicitingText && !mentions.length) return;

        dispatch(
            fetchSuggestedBondIdsThunk({
                solicitingText,
                mentions,
                // todo needs more thought for continuous loading, but making
                // `limit` large (50) seems OK for now..?
                limit: 50,
            }),
        );
    }, [
        dispatch,
        mentions,
        debouncedSuggestionQueryString,
    ]);

    // By default, the bar is shown
    const classes = classNames("c-bond-composer", {
        "c-bond-composer--immediate": immediate,
        "c-bond-composer--hidden": !immediate && !showBar,
    });

    return (
        <div className={classes} ref={barRef} data-testid="c-bond-composer">
            <button
                className="c-bond-composer__handle"
                onClick={handleClickCallback}
                aria-label="Compose"
                aria-hidden={showBar}
            >
            </button>
            <div aria-expanded={showBar}>
                <MessageComposer
                    id="comms-input-bond-creation"
                    key="comms-input-bond-creation"
                    ref={composerRef}
                    draftTarget={bondCreationDraftTarget}
                    msgCompletionAction={createBondFromMsg}
                    completable={messageSendableStatus}
                    discardAction={discardAction}
                    escapeAction={escapeAction}
                    numberOfParticipants={0}
                    bondComposer={true}
                    onStatesChange={onComposerActiveChange}
                />
            </div>
        </div>
    );
}
