import * as bonds_pb from "../../gen/proto/bonds/bonds_pb";
import * as clients_pb from "../../gen/proto/clients/clients_pb";
import * as devices_pb from "../../gen/proto/devices/devices_pb";
import { BondService } from "../../gen/proto/bonds/bonds_connect";

import deviceService, { observersParser } from "./devices";
import clientService from "./client";
import type { BondOverview, BondKnowledgePreview, PartialBondOverview } from "../domain/bonds";
import { transport } from "./transport";
import { createPromiseClient } from "@connectrpc/connect";
import {
    fromProtoBondId,
    fromProtoBondSet,
    fromProtoCallId,
    fromProtoChannelId,
    fromProtoOrgId,
    fromProtoSquadId,
    fromProtoUserId,
    fromProtoTimestamp,
    pbUserId,
    toProtoBondSet,
} from "./util";
import * as util from "./util";
import * as d from "../domain/domain";
import { UserObservation } from "../domain/presence";
import { streamHandler } from "./stream";
import { Diff } from "../misc/types";
import { translateAsyncIterable } from "../misc/iterable";

// Re-export proto types for use in the rest of the frontend
export { PrivacyLevel, BondSet } from "../../gen/proto/domain/domain_pb";

const service = createPromiseClient(BondService, transport);
export default service;

function translateBondKnowledgePreview(
    bk?: bonds_pb.BondKnowledgePreview,
): BondKnowledgePreview {
    if (!bk) {
        return {} as BondKnowledgePreview;
    }

    return {
        userSpecifiedTitle: bk.userSpecifiedTitle,
        aiGeneratedTitle: bk.aiGeneratedTitle,
        summary: bk.summary,
    };
}

function translateBondOverview(bo: bonds_pb.BondOverview): BondOverview {
    return {
        id: fromProtoBondId(bo.id),
        orgId: fromProtoOrgId(bo.orgId),
        channelId: fromProtoChannelId(bo.channelId),
        squadIds: bo.squadIds?.map(fromProtoSquadId) || [],
        privacy: bo.privacy,
        knowledge: translateBondKnowledgePreview(bo.knowledgePreview),
        contributors: bo.contributors?.ids?.map(fromProtoUserId) || [],
        followers: bo.followers?.ids?.map(fromProtoUserId) || [],
        externalUsers: bo.externalUsers?.ids?.map(fromProtoUserId) || [],
        lastActivityAt: fromProtoTimestamp(bo.lastActivityAt),
        maxSequenceNumber: util.bigintToNumber(bo.maxSequenceNumber),
        liveCallIds: bo.liveCalls?.ids?.map(fromProtoCallId) || [],
    };
}

function translateBondPreview(bo: bonds_pb.BondPreview): PartialBondOverview {
    return {
        id: fromProtoBondId(bo.id),
        orgId: fromProtoOrgId(bo.orgId),
        channelId: fromProtoChannelId(bo.channelId),
        squadIds: bo.squadIds?.map(fromProtoSquadId) || [],
        privacy: bo.privacy,
        knowledge: {
            userSpecifiedTitle: bo.userSpecifiedTitle,
            aiGeneratedTitle: bo.aiGeneratedTitle,
            summary: bo.summary,
        },
        followers: bo.followers?.ids?.map(fromProtoUserId) || [],
        lastActivityAt: fromProtoTimestamp(bo.lastActivityAt),
        maxSequenceNumber: util.bigintToNumber(bo.maxSequenceNumber),
        liveCallIds: bo.liveCalls?.ids?.map(fromProtoCallId) || [],
    };
}

interface FollowBondArgs {
    bondId: d.BondId;
    follow: boolean;
}

export async function setFollowBond(
    args: FollowBondArgs,
): Promise<void> {
    const req = new clients_pb.FollowBondRequest({
        bondId: util.pbBondId(args.bondId),
        follow: args.follow,
    });
    await clientService.followBond(req);
}

interface ArchiveBondArgs {
    bondId: d.BondId;
    archive: boolean;
}

export async function setArchiveBond(
    args: ArchiveBondArgs,
): Promise<void> {
    const req = new clients_pb.ArchiveBondRequest({
        bondId: util.pbBondId(args.bondId),
        archive: args.archive,
    });
    await clientService.archiveBond(req);
}

interface UpdateBondTitleArgs {
    bondId: d.BondId;
    title: string;
}

export async function updateBondTitle(
    args: UpdateBondTitleArgs,
): Promise<void> {
    const req = new bonds_pb.UpdateBondUserSpecifiedTitleRequest({
        bondId: util.pbBondId(args.bondId),
        content: args.title,
    });

    await service.updateBondUserSpecifiedTitle(req);
}

interface InviteUserToBondArgs {
    bondId: d.BondId;
    invitedUserId: d.UserId;
}

export async function inviteUserToBond(
    args: InviteUserToBondArgs,
): Promise<void> {
    const req = new clients_pb.InviteUserToBondRequest({
        bondId: util.pbBondId(args.bondId),
        invitedUserId: util.pbUserId(args.invitedUserId),
    });
    await clientService.inviteUserToBond(req);
}

export type BondOverviewDU = {
    case: "overview";
    overview: BondOverview;
};
export type BondPreviewDU = {
    case: "preview";
    partial: PartialBondOverview;
};
export type DeletedBondDU = {
    case: "deleted";
    deletedId: d.BondId;
};

export type BondOverviewOrDeleted = BondOverviewDU | DeletedBondDU;

function bondOverviewParser(
    res: clients_pb.ObserveBondResponse,
): BondOverviewOrDeleted | undefined {
    switch (res.bondOrDeleted?.case) {
        case "bondOverview": {
            const overview = translateBondOverview(res.bondOrDeleted.value);
            return { case: "overview", overview: overview };
        }
        case "deletedId": {
            const deletedId = fromProtoBondId(res.bondOrDeleted.value);
            return { case: "deleted", deletedId: deletedId };
        }
        default:
            throw new Error(`unexpected overviewOrDeleted case: ${res.bondOrDeleted?.case}`);
    }
}

export async function* observeBondOverview(
    bondId: d.BondId,
    signal: AbortSignal,
    observe: boolean = true,
): AsyncGenerator<BondOverviewOrDeleted, void, unknown> {
    const req = new clients_pb.ObserveBondRequest({
        bondId: util.pbBondId(bondId),
        dontObserve: !observe,
    });

    const logPrefix = `streamBondOverview`;

    const resp = clientService.observeBond(req, { signal });

    yield* streamHandler(resp, bondOverviewParser, logPrefix);
}

export type PartialOrDeleted = BondPreviewDU | DeletedBondDU;

function bondPreviewParser(
    res: bonds_pb.SubBondsPreviewsResponse,
): PartialOrDeleted | undefined {
    switch (res.previewOrDeleted?.case) {
        case "bondPreview": {
            const preview = translateBondPreview(res.previewOrDeleted.value);
            return { case: "preview", partial: preview };
        }
        case "deletedId": {
            const deletedId = fromProtoBondId(res.previewOrDeleted.value);
            return { case: "deleted", deletedId: deletedId };
        }
        default:
            throw new Error(`unexpected previewOrDeleted case: ${res.previewOrDeleted?.case}`);
    }
}

// A streamBondPreviews stream should always be accompanied by one or more
// observeBondPreviews streams for the relevant squads, to maintain the
// correct observations.
export async function* streamBondPreviews(
    userId: d.UserId,
    signal: AbortSignal,
): AsyncGenerator<PartialOrDeleted, void, unknown> {
    const req = new bonds_pb.SubBondsPreviewsRequest({
        userId: pbUserId(userId),
    });

    const logPrefix = `streamBondPreviews`;

    const resp = service.subBondsPreviews(req, { signal });

    yield* streamHandler(resp, bondPreviewParser, logPrefix);
}

export async function* observeBondPreviews(
    squadId: d.SquadId,
    userId: d.UserId,
    signal: AbortSignal,
): AsyncGenerator<NonNullable<void>, void, unknown> {
    const req = new clients_pb.ObserveBondsPreviewsRequest({
        squadId: util.pbSquadId(squadId),
        userId: util.pbUserId(userId),
    });

    const logPrefix = `observeBondPreviews`;

    const resp = clientService.observeBondsPreviews(req, { signal });

    yield* streamHandler(resp, () => {}, logPrefix);
}

export async function* subArchivedBonds(
    userId: d.UserId,
    signal: AbortSignal,
) {
    const req = new bonds_pb.SubArchivedBondsListDiffRequest({
        userId: pbUserId(userId),
    });

    const logPrefix = `streamArchivedBonds`;

    const resp = service.subArchivedBondsListDiff(req, { signal });

    let first = true;
    const parse = (pb: bonds_pb.SubArchivedBondsListDiffResponse) => {
        const overwrite = first;
        first = false;
        return ({
            overwrite: overwrite,
            added: fromProtoBondSet(pb.bondIdsAdded),
            removed: fromProtoBondSet(pb.bondIdsRemoved),
        });
    };

    yield* streamHandler(resp, parse, logPrefix);
}

export type BondObservations = {
    viewId: d.BondId;
    observations: Array<UserObservation>;
};

export async function* subBondObservers(
    bondId: d.BondId,
    signal: AbortSignal,
): AsyncGenerator<BondObservations, void, unknown> {
    const req = new devices_pb.SubObserversRequest({
        viewUrn: bondId as string,
    });

    const logPrefix = `streamBondObservers`;

    const resp = deviceService.subObservers(req, { signal });

    yield* streamHandler(resp, observersParser(d.parseBondUrn), logPrefix);
}

export type BondCatchupSummary = {
    bondId: d.BondId;
    summary: string;
};

export function isBondCatchupSummary(
    x: BondCatchupSummary | undefined,
): x is BondCatchupSummary {
    return x !== undefined;
}

export async function* subCatchupSummaries(
    userId: d.UserId,
    signal: AbortSignal,
) {
    const req = new bonds_pb.SubCatchupSummariesRequest({
        userId: pbUserId(userId),
    });

    const logPrefix = `streamCatchupSummaries`;

    const resp = service.subCatchupSummaries(req, { signal });
    const parser = (pb: bonds_pb.SubCatchupSummariesResponse) => {
        if (pb.summaryOrDeleted.case === "summary") {
            return {
                bondId: fromProtoBondId(pb.summaryOrDeleted.value.bondId),
                summary: pb.summaryOrDeleted.value.summary,
            };
        }

        // Deleted case
        // TODO handle this case, and have backend logic to delete catchup summaries
        return undefined;
    };

    yield* streamHandler(resp, parser, logPrefix);
}

export interface BondContemporaries {
    bondId: d.BondId;
    userIds: d.UserId[];
}

function bondContemporariesParser(res: bonds_pb.SubBondContemporariesResponse): BondContemporaries {
    return {
        bondId: fromProtoBondId(res.bondId),
        userIds: util.fromProtoUserSet(res.bondContemporaries),
    };
}

async function* translateBondContemporariesDiff(v: Diff<d.BondId>) {
    if (v.added?.length) {
        yield new bonds_pb.SubBondContemporariesRequest({
            bondIds: toProtoBondSet(v.added),
            addToSub: true,
        });
    }
    if (v.removed?.length) {
        yield new bonds_pb.SubBondContemporariesRequest({
            bondIds: toProtoBondSet(v.removed),
            addToSub: false,
        });
    }
}

export async function* subBondContemporaries(
    reqStream: AsyncIterableIterator<Diff<d.BondId>>,
    signal: AbortSignal,
) {
    const logPrefix = "subBondContemporaries";

    const translation = translateAsyncIterable(reqStream, translateBondContemporariesDiff);

    const stream = service.subBondContemporaries(translation, { signal });

    yield* streamHandler(stream, bondContemporariesParser, logPrefix);
}
