import { DependencyList, EffectCallback } from "react";
import { selectConnected } from "../features/connection";
import { useSelectorEffect } from "./useSelectorEffect";
import { selectCurrentUserId } from "../features/auth";
import type { RootState } from "../store/types";

const selectConnectedWithUserId = (state: RootState) => {
    return selectConnected(state) && selectCurrentUserId(state) !== undefined;
};
const selectNotConnectedWithUserId = (state: RootState) => {
    return !selectConnectedWithUserId(state);
};

/** @function Fire an effect only when: The client is connected to our backend
 * and the user is authenticated.
 */
export function useConnectedEffect(effect: EffectCallback, deps: DependencyList) {
    useSelectorEffect({ effect, deps, selector: selectConnectedWithUserId });
}

/** @function Fire an effect only when: The client is not connected to our backend
 * or the user is not authenticated.
 */
export function useDisconnectedEffect(effect: EffectCallback, deps: DependencyList) {
    useSelectorEffect({ effect, deps, selector: selectNotConnectedWithUserId });
}
