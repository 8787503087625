import { useEffect, useRef } from "react";

export default function useOutsideClick<ElementType extends HTMLElement>(
    callback: (target: EventTarget | null) => void,
) {
    const ref = useRef<ElementType>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent | TouchEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callback(event.target);
            }
        };

        document.addEventListener("click", handleClickOutside);
        document.addEventListener("touchstart", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
            document.removeEventListener("touchstart", handleClickOutside);
        };
    }, [callback]);

    return [ref] as [
        ref: React.RefObject<ElementType>,
    ];
}
