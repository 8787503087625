import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";

import { PrivacyLevel } from "../api/bonds";
import { BondOverview } from "../domain/bonds";
import * as d from "../domain/domain";
import { separate } from "../misc/utils";
import type { Connection, PersistenceUpdate } from "../persist/types";
import { checkPersistor, sessionStorageStore } from "../persist/shared";
import { storageRead, storageRemove } from "../persist/storage";
import type { RootState } from "../store/types";
import { resetStore, selectCurrentUserId } from "./auth";
import { selectArchivedBondsSet, selectBondById, selectBonds } from "./bonds";
import { selectAllStagedSequenceNumbers, unreadMessageCount } from "./channels";
import { memoizeOptions, shallowArrayEqualSelectorCreator } from "./selectors";
import { selectSquadById } from "./squads";
import { SquadOverview } from "../domain/squads";
import { Optional } from "../misc/types";
import log from "../misc/log";
import { createAppAsyncThunk } from "../store/redux";

// Two levels of filtering here today:
// 1. All / Private / Individual squad: PrivateOrSquadFilter*
// 2. All / Followed / New Activity / Archived: RelevanceLevelFilter*

export enum PrivateOrSquadFilterOption {
    ALL = "all",
    PRIVATE = "private",
}

export type PrivateOrSquadFilter = { by: "option"; option: PrivateOrSquadFilterOption; } | {
    by: "squad";
    squadId: d.SquadId;
};

export type RelevanceLevelFilter = {
    by: "all" | "followed" | "new-activity" | "archived";
};

export interface FilterPanelState {
    privateOrSquadFilter: PrivateOrSquadFilter;
    relevanceLevelFilter: RelevanceLevelFilter;
}

const defaultPrivateOrSquadFilter: PrivateOrSquadFilter = {
    by: "option",
    option: PrivateOrSquadFilterOption.ALL,
};
const defaultRelevanceLevelFilter: RelevanceLevelFilter = {
    by: "all",
};

export interface SetPrivateOrSquadFilterThunkResult {
    oldFilter: PrivateOrSquadFilter;
    newFilter: PrivateOrSquadFilter;
    newSquad: Optional<SquadOverview>;
}

export const setPrivateOrSquadFilterThunk = createAppAsyncThunk(
    "filterPanel/setPrivateOrSquadFilterThunk",
    async (
        args: PrivateOrSquadFilter | null,
        thunkAPI,
    ): Promise<SetPrivateOrSquadFilterThunkResult> => {
        const oldState = thunkAPI.getState();
        const oldFilter = selectPrivateOrSquadFilter(oldState);

        thunkAPI.dispatch(filterPanelSlice.actions.setPrivateOrSquadFilter(args));

        const newState = thunkAPI.getState();
        const newFilter = selectPrivateOrSquadFilter(newState);
        const newSquad = (newFilter.by == "squad") ?
            selectSquadById(newState, newFilter.squadId) :
            undefined;

        return { oldFilter, newFilter, newSquad };
    },
);

const getInitialState = (props?: Partial<FilterPanelState>): FilterPanelState => ({
    privateOrSquadFilter: props?.privateOrSquadFilter ?? defaultPrivateOrSquadFilter,
    relevanceLevelFilter: props?.relevanceLevelFilter ??
        defaultRelevanceLevelFilter,
});

function setPrivateOrSquadFilterInternal(
    state: FilterPanelState,
    filter: PrivateOrSquadFilter | null,
) {
    log.info(`Set private or squad filter: ${JSON.stringify(filter)}`);
    state.privateOrSquadFilter = filter ?? defaultPrivateOrSquadFilter;
}

function setRelevanceLevelFilterInternal(
    state: FilterPanelState,
    filter: RelevanceLevelFilter | null,
) {
    log.info(`Set relevance level filter: ${JSON.stringify(filter)}`);
    state.relevanceLevelFilter = filter ?? defaultRelevanceLevelFilter;
}

export const filterPanelSlice = createSlice({
    name: "filterPanel",
    initialState: getInitialState(),
    selectors: {
        privateOrSquadFilter: state => state.privateOrSquadFilter,
        relevanceLevelFilter: state => state.relevanceLevelFilter,
    },
    reducers: {
        setPrivateOrSquadFilter: (
            state,
            { payload }: PayloadAction<PrivateOrSquadFilter | null>,
        ) => {
            setPrivateOrSquadFilterInternal(state, payload);
        },
        setRelevanceLevelFilter: (
            state,
            action: PayloadAction<RelevanceLevelFilter | null>,
        ) => {
            setRelevanceLevelFilterInternal(state, action.payload);
        },
        setDisplayFilter: (
            state,
            { payload: [posFilter, aFilter] }: PayloadAction<
                [PrivateOrSquadFilter | null, RelevanceLevelFilter | null]
            >,
        ) => {
            setPrivateOrSquadFilterInternal(state, posFilter);
            setRelevanceLevelFilterInternal(state, aFilter);
        },
    },
    extraReducers: builder => {
        builder.addCase(resetStore, _state => {
            return getInitialState();
        });
    },
});

export const {
    setRelevanceLevelFilter,
    setDisplayFilter,
} = filterPanelSlice.actions;

const selectors = filterPanelSlice.getSelectors((state: RootState) => state.filterPanel);
export const {
    privateOrSquadFilter: selectPrivateOrSquadFilter,
    relevanceLevelFilter: selectRelevanceLevelFilter,
} = selectors;

// Sort and filter implementations:

const filterByOption = (bo: BondOverview, tfo: PrivateOrSquadFilterOption) => {
    switch (tfo) {
        case PrivateOrSquadFilterOption.ALL:
            return true;
        case PrivateOrSquadFilterOption.PRIVATE:
            return bo.privacy === PrivacyLevel.PRIVATE;
    }
    return false;
};

const overallFilter = createSelector(
    [
        selectPrivateOrSquadFilter,
        selectRelevanceLevelFilter,
        selectCurrentUserId,
        state => selectAllStagedSequenceNumbers(state),
        state => selectArchivedBondsSet(state),
    ],
    (
        privateOrSquadFilter,
        relevanceLevelFilter,
        currentUserId,
        stagedSequenceNumberMap,
        archivedBonds,
    ) =>
    (bo: BondOverview) => {
        if (!bo) {
            return false;
        }

        switch (privateOrSquadFilter.by) {
            case "option":
                if (!filterByOption(bo, privateOrSquadFilter.option)) return false;
                break;
            case "squad":
                if (!bo.squadIds?.includes(privateOrSquadFilter.squadId)) return false;
                break;
            default:
                break;
        }

        const archived = archivedBonds.has(bo.id);

        switch (relevanceLevelFilter.by) {
            // Archived bonds are filtered out by default

            case "all":
                if (archived) return false;
                break;
            case "followed":
                if (archived) return false;
                if (currentUserId && !bo.followers?.includes(currentUserId)) return false;
                break;
            case "new-activity":
                if (archived) return false;
                if (
                    unreadMessageCount(
                        stagedSequenceNumberMap[bo.channelId],
                        bo.maxSequenceNumber,
                    ) <= 0
                ) return false;
                break;
            case "archived":
                if (!archived) return false;
                break;
        }

        return true;
    },
);

const selectCurrentBonds = shallowArrayEqualSelectorCreator(
    [state => selectBonds(state), overallFilter],
    (previews, oFilter) =>
        previews
            .filter(bp => oFilter(bp)),
);

const selectBondsSplitByFollowing = createSelector(
    [selectCurrentBonds, selectCurrentUserId],
    (overviews, currentUserId) => {
        if (!currentUserId) {
            return [[], []];
        }

        return separate(
            bo => bo.followers?.includes(currentUserId) ?? false,
            overviews,
        );
    },
);

export const selectCurrentBondIds = createSelector(
    [selectCurrentBonds],
    bonds => bonds.map(bo => bo.id),
    memoizeOptions.weakMapShallow,
);

export const selectFollowedBondIds = createSelector(
    [selectBondsSplitByFollowing],
    ([followed]) => followed.map(bo => bo.id),
    memoizeOptions.lruShallow,
);

export const selectUnfollowedBondIds = createSelector(
    [selectBondsSplitByFollowing],
    ([_, unfollowed]) => unfollowed.map(bo => bo.id),
    memoizeOptions.lruShallow,
);

const selectBondsWithNewActivity = createSelector(
    [state => selectBonds(state), state => selectAllStagedSequenceNumbers(state)],
    (overviews, stagedSequenceNumberMap) =>
        overviews.filter(
            bo =>
                unreadMessageCount(stagedSequenceNumberMap[bo.channelId], bo.maxSequenceNumber) > 0,
        ).map(bo => bo.id).toSet(),
    memoizeOptions.lruShallow,
);

// Here, 'interested' = a followed bond that has not been dismissed.
export const selectInterestedUnreadBondCount = createSelector(
    [selectFollowedBondIds, selectBondsWithNewActivity, state => selectArchivedBondsSet(state)],
    (followed, unread, archivedSet) =>
        followed.filter(id => !archivedSet.has(id) && unread.has(id)).length,
    memoizeOptions.lruShallow,
);

// Return a filter state in which the given bond is visible, which contains the least possible
// change from the current squad filter state
export const selectDisplayFilterForBond = createSelector(
    [
        selectPrivateOrSquadFilter,
        selectRelevanceLevelFilter,
        (state, bondId) => selectBondById(state, bondId),
        state => selectArchivedBondsSet(state),
    ],
    (
        posFilter,
        aFilter,
        bo,
        archivedBonds,
    ): [PrivateOrSquadFilter, RelevanceLevelFilter] => {
        let posFilterOut: PrivateOrSquadFilter;
        let aFilterOut: RelevanceLevelFilter;

        if (bo === undefined) {
            posFilterOut = posFilter;
        }
        else if (
            posFilter.by === "option" && posFilter.option === PrivateOrSquadFilterOption.ALL
        ) {
            posFilterOut = posFilter;
        }
        else if (
            posFilter.by === "squad" &&
            bo.squadIds !== undefined &&
            bo.squadIds.includes(posFilter.squadId)
        ) {
            posFilterOut = posFilter;
        }
        else {
            const squadId = bo.squadIds[0];
            posFilterOut = squadId ? { by: "squad", squadId }
                : { by: "option", option: PrivateOrSquadFilterOption.PRIVATE };
        }

        if (bo === undefined) {
            aFilterOut = aFilter;
        }
        else if (archivedBonds.has(bo.id)) {
            aFilterOut = { by: "archived" };
        }
        else {
            aFilterOut = { by: "all" };
        }

        return [posFilterOut, aFilterOut];
    },
);

export type FilterScope = "private" | "unknown" | { squadId: d.SquadId; };

export const topFilterScope = (topFilter: PrivateOrSquadFilter): FilterScope => {
    if (topFilter.by === "squad") {
        return { squadId: topFilter.squadId };
    }
    else if (topFilter.option === PrivateOrSquadFilterOption.PRIVATE) {
        return "private";
    }
    else {
        return "unknown";
    }
};

export const reducer = filterPanelSlice.reducer;

// Persistence.

const stores = {
    filters: sessionStorageStore<Partial<FilterPanelState>>("filter-panel/filters", 1),
};

const persist = (
    previousState: FilterPanelState,
    currentState: FilterPanelState,
): PersistenceUpdate[] => {
    if (previousState == currentState) {
        return [];
    }

    return [[stores.filters, currentState]];
};

const hydrate = async (_conn: Connection) => {
    const filters = storageRead(stores.filters);
    return getInitialState(filters);
};

const purge = () => {
    storageRemove(stores.filters);
};

export const persistor = {
    stores,
    hydrate,
    persist,
    purge,
};
checkPersistor<FilterPanelState>(persistor);
