import { useCallback, useEffect, useRef, useState } from "react";
import { Track, getDisabledTrack, isTrackActive } from "../../domain/session";
import { useMediaDevice } from "./useMediaDevice";
import {
    MediaInputKind,
    MediaInputKind_Type,
    MediaKind,
    MediaPublisher,
    MediaPublisherMap,
} from "../../domain/mediaDevices";
import log from "../../misc/log";

// Get an enabled track, with the stream as its source
const getEnabledTrack = (stream?: MediaStream): Track => {
    return { enabled: true, muted: false, source: stream };
};

export interface useMediaTrackProps {
    endOnToggle?: boolean;
    mediaKind: MediaInputKind_Type;
    muteTrack?: (isMuted: boolean) => void;
    onNoDevice?: () => void;
    publisher: MediaPublisher;
}

/** @function
 * Exposes a media track object wrapping the MediaStream for the requested media kind.
 * This hook exposes a callback to toggle the track. This will disable the
 * display media track, but will instead mute audio and video media.
 * This hook also publishes media on becoming enabled, and unpublishes media upon
 * the MediaStream ending. In particular, audio and video media is only unpublished
 * when ended externally (e.g. permissions revoked), so we can instead replace
 * the media tracks when switching devices. Conversely, display media is always
 * unpublished on ending the stream as we cannot switch screenshare in-place.
 *
 * This hook also guards us from publishing media into a session until we've joined it.
 *
 * @param endOnToggle whether to disable the media instead of muting when
 * toggling the track off
 * @param mediaKind the kind of media to wrap inside the track
 * @param muteTrack a function to send a muted signal
 * @param onNoDevice a callback to run when no devices for the mediaKind are enumerated
 * @param publisher the functions to publish/unpublish media
 *
 * @returns an object with the following fields:
 * - `track` - the wrapped around the MediaStream, with flags indicated whether
 * it's enabled and muted.
 * - `resetInactiveTrack` - a function to reset the track if inactive,
 * used when leaving the session.
 * - `toggleTrack` - a function to toggle the track, disabling display media,
 * and (un)muting audio/video media.
 */
export function useMediaTrack(props: useMediaTrackProps) {
    const { endOnToggle, mediaKind, muteTrack, onNoDevice, publisher } = props;
    const { publish, unpublish } = publisher;

    const [track, setTrack] = useState<Track>(getDisabledTrack());

    // A callback to disable the track
    const disableTrack = useCallback(() => setTrack(getDisabledTrack()), []);

    // Return a copy of the track, toggling its `muted` field
    const toggle = useCallback((t: Track) => {
        if (!t.enabled) {
            log.info("Enabling media track", mediaKind);
        }

        // Disable if muting track with no source
        if (t.enabled && !t.source) {
            log.warn("Disabling track as it had no source when toggling.", mediaKind);
            return getDisabledTrack();
        }

        // Display media cannot be muted, only disabled
        if (mediaKind === MediaKind.Display || endOnToggle) {
            const enabled = !t.enabled;
            if (!enabled) {
                log.debug("Disabling display media track.");
            }

            // Set the muted field to mirror enabled so we can render the
            // display as a video tile.
            return { enabled: enabled, muted: !enabled, source: enabled ? t.source : undefined };
        }

        // Toggle mute on non-display track
        const isMuted = !t.muted;
        muteTrack?.(isMuted);

        return { enabled: true, muted: isMuted, source: t.source };
    }, [endOnToggle, mediaKind, muteTrack]);

    // Handle media device updates in track
    const onCleanup = useCallback(() => {
        setTrack(t => isTrackActive(t) ? getEnabledTrack() : getDisabledTrack());

        // Unpublish display media on ending stream
        if (mediaKind === MediaInputKind.Display) {
            unpublish?.();
        }
    }, [mediaKind, setTrack, unpublish]);
    const onGetMedia = useCallback((stream: MediaStream) => {
        setTrack(t => {
            // Don't assign source if track disabled since getting media
            if (!t.enabled) {
                return t;
            }

            // Send unmute track signal if track still enabled upon getting media
            const isMuted = false;
            muteTrack?.(isMuted);

            return getEnabledTrack(stream);
        });
    }, [muteTrack, setTrack]);
    const onEndedExternally = useCallback(() => {
        log.info("Track ended externally", mediaKind);
        disableTrack();

        // Display will be unpublished in onCleanup when the stream ends
        if (mediaKind !== MediaInputKind.Display) {
            unpublish?.();
        }
    }, [disableTrack, mediaKind, unpublish]);
    useMediaDevice({
        kind: mediaKind,
        enabled: track.enabled,
        useFallbackConstraints: !onNoDevice,
        onCleanup,
        onEndedExternally,
        onNoDevice,
        onGetMedia,
    });

    // Track muted state, so we can guard against publishing a muted track
    const isMutedRef = useRef(false);
    isMutedRef.current = track.muted;

    // Handle publishing track
    useEffect(() => {
        if (!track?.enabled || !track.source) {
            return;
        }

        if (isMutedRef.current) {
            log.warn(`Tried to publish muted track ${mediaKind}. Disabling track.`);
            disableTrack();
            return;
        }

        publish(track.source).catch(e => {
            log.error("Failed to publish track", e);
            disableTrack();
        });
    }, [disableTrack, mediaKind, publish, track.enabled, track.source]);

    // Expose callback to toggle track
    const toggleTrack = useCallback(() => {
        setTrack(t => toggle(t));
    }, [setTrack, toggle]);

    // Expose callback to disable the track if inactive
    const resetInactiveTrack = useCallback(() => {
        setTrack(t => isTrackActive(t) ? t : getDisabledTrack());
    }, [setTrack]);

    return {
        track,
        resetInactiveTrack,
        toggleTrack,
    };
}

export interface UseMediaTracksProps {
    endOnToggle?: boolean;
    muteTrack: (isMuted: boolean, mediaKind: MediaInputKind_Type) => void;
    publishers: MediaPublisherMap;
}
export function useMediaTracks(props: UseMediaTracksProps) {
    const { endOnToggle, muteTrack, publishers } = props;

    // eslint doesn't want a map here, so do it manually...
    const muteAudio = useCallback(
        (isMuted: boolean) => muteTrack(isMuted, MediaInputKind.AudioInput),
        [muteTrack],
    );
    const audio = useMediaTrack({
        endOnToggle,
        mediaKind: MediaInputKind.AudioInput,
        muteTrack: muteAudio,
        publisher: publishers[MediaInputKind.AudioInput],
    });
    const muteVideo = useCallback(
        (isMuted: boolean) => muteTrack(isMuted, MediaInputKind.VideoInput),
        [muteTrack],
    );
    const video = useMediaTrack({
        endOnToggle,
        mediaKind: MediaInputKind.VideoInput,
        muteTrack: muteVideo,
        publisher: publishers[MediaInputKind.VideoInput],
    });
    const display = useMediaTrack({
        endOnToggle,
        mediaKind: MediaInputKind.Display,
        publisher: publishers[MediaInputKind.Display],
    });

    const trackMap = useCallback((input: MediaInputKind_Type) => {
        switch (input) {
            case MediaInputKind.AudioInput:
                return audio;
            case MediaInputKind.VideoInput:
                return video;
            case MediaInputKind.Display:
                return display;
        }
    }, [audio, video, display]);

    return trackMap;
}
