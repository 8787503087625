// @generated by protoc-gen-es v1.4.2 with parameter "target=js+ts"
// @generated from file bonds/bonds.proto (package bonds, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { BondId, BondKnowledgeId, BondKnowledgeType, BondSet, CallId, CallSet, ChannelId, ChannelSet, InvolvementRole, MentionSet, OrgId, PrivacyLevel, RtcSessionId, SquadId, UserId, UserSet } from "../domain/domain_pb.js";
import { SendChatMessageDetails, SendChatMessageRequest, SendChatMessageResponse, SubUserMaxReadSeqNumsResponse } from "../chats/chats_pb.js";
import { CallParameters, RtcSessionEvent, StartCallResponse } from "../calls/calls_pb.js";

/**
 * @generated from message bonds.BondOverview
 */
export const BondOverview = proto3.makeMessageType(
  "bonds.BondOverview",
  () => [
    { no: 1, name: "id", kind: "message", T: BondId },
    { no: 2, name: "org_id", kind: "message", T: OrgId },
    { no: 3, name: "squad_ids", kind: "message", T: SquadId, repeated: true },
    { no: 4, name: "privacy", kind: "enum", T: proto3.getEnumType(PrivacyLevel) },
    { no: 14, name: "knowledge_preview", kind: "message", T: BondKnowledgePreview },
    { no: 6, name: "contributors", kind: "message", T: UserSet },
    { no: 7, name: "followers", kind: "message", T: UserSet },
    { no: 8, name: "external_users", kind: "message", T: UserSet },
    { no: 9, name: "channel_id", kind: "message", T: ChannelId },
    { no: 10, name: "max_sequence_number", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 11, name: "last_activity_at", kind: "message", T: Timestamp },
    { no: 12, name: "live_calls", kind: "message", T: CallSet },
  ],
);

/**
 * @generated from message bonds.BondKnowledge
 */
export const BondKnowledge = proto3.makeMessageType(
  "bonds.BondKnowledge",
  () => [
    { no: 1, name: "generated", kind: "message", T: Timestamp },
    { no: 11, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "knowledge_type", kind: "enum", T: proto3.getEnumType(BondKnowledgeType) },
    { no: 12, name: "first_summarised_seq", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "last_summarised_seq", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "id", kind: "message", T: BondKnowledgeId },
    { no: 10, name: "bond_id", kind: "message", T: BondId },
  ],
);

/**
 * @generated from message bonds.BondKnowledgePreview
 */
export const BondKnowledgePreview = proto3.makeMessageType(
  "bonds.BondKnowledgePreview",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "user_specified_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "ai_generated_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "summary", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message bonds.BondPreview
 */
export const BondPreview = proto3.makeMessageType(
  "bonds.BondPreview",
  () => [
    { no: 1, name: "id", kind: "message", T: BondId },
    { no: 2, name: "org_id", kind: "message", T: OrgId },
    { no: 3, name: "squad_ids", kind: "message", T: SquadId, repeated: true },
    { no: 4, name: "privacy", kind: "enum", T: proto3.getEnumType(PrivacyLevel) },
    { no: 5, name: "user_specified_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "ai_generated_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "summary", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "followers", kind: "message", T: UserSet },
    { no: 8, name: "channel_id", kind: "message", T: ChannelId },
    { no: 9, name: "max_sequence_number", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "last_activity_at", kind: "message", T: Timestamp },
    { no: 12, name: "live_calls", kind: "message", T: CallSet },
  ],
);

/**
 * @generated from message bonds.UserCatchupSummary
 */
export const UserCatchupSummary = proto3.makeMessageType(
  "bonds.UserCatchupSummary",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "summary", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message bonds.GetAllBondsRequest
 */
export const GetAllBondsRequest = proto3.makeMessageType(
  "bonds.GetAllBondsRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message bonds.GetAllBondsResponse
 */
export const GetAllBondsResponse = proto3.makeMessageType(
  "bonds.GetAllBondsResponse",
  () => [
    { no: 1, name: "bonds", kind: "message", T: BondOverview, repeated: true },
  ],
);

/**
 * @generated from message bonds.GetBondInfosRequest
 */
export const GetBondInfosRequest = proto3.makeMessageType(
  "bonds.GetBondInfosRequest",
  () => [
    { no: 1, name: "bond_ids", kind: "message", T: BondSet },
  ],
);

/**
 * @generated from message bonds.GetBondInfosResponse
 */
export const GetBondInfosResponse = proto3.makeMessageType(
  "bonds.GetBondInfosResponse",
  () => [
    { no: 1, name: "bonds", kind: "message", T: BondOverview, repeated: true },
  ],
);

/**
 * @generated from message bonds.GetBondIdsForChannelsRequest
 */
export const GetBondIdsForChannelsRequest = proto3.makeMessageType(
  "bonds.GetBondIdsForChannelsRequest",
  () => [
    { no: 2, name: "channel_ids", kind: "message", T: ChannelSet },
  ],
);

/**
 * @generated from message bonds.GetBondIdsForChannelsResponse
 */
export const GetBondIdsForChannelsResponse = proto3.makeMessageType(
  "bonds.GetBondIdsForChannelsResponse",
  () => [
    { no: 2, name: "bond_ids", kind: "message", T: BondSet },
  ],
);

/**
 * @generated from message bonds.ListSquadBondsRequest
 */
export const ListSquadBondsRequest = proto3.makeMessageType(
  "bonds.ListSquadBondsRequest",
  () => [
    { no: 1, name: "squad_id", kind: "message", T: SquadId },
  ],
);

/**
 * @generated from message bonds.ListSquadBondsResponse
 */
export const ListSquadBondsResponse = proto3.makeMessageType(
  "bonds.ListSquadBondsResponse",
  () => [
    { no: 1, name: "bond_ids", kind: "message", T: BondSet },
  ],
);

/**
 * @generated from message bonds.GetCallParentDetailsRequest
 */
export const GetCallParentDetailsRequest = proto3.makeMessageType(
  "bonds.GetCallParentDetailsRequest",
  () => [
    { no: 1, name: "call_id", kind: "message", T: CallId },
  ],
);

/**
 * @generated from message bonds.GetCallParentDetailsResponse
 */
export const GetCallParentDetailsResponse = proto3.makeMessageType(
  "bonds.GetCallParentDetailsResponse",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "squad_ids", kind: "message", T: SquadId, repeated: true },
    { no: 3, name: "org_ids", kind: "message", T: OrgId, repeated: true },
    { no: 4, name: "initiator_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message bonds.ListUserBondsRequest
 */
export const ListUserBondsRequest = proto3.makeMessageType(
  "bonds.ListUserBondsRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 2, name: "privacy", kind: "enum", T: proto3.getEnumType(PrivacyLevel) },
  ],
);

/**
 * @generated from message bonds.ListUserBondsResponse
 */
export const ListUserBondsResponse = proto3.makeMessageType(
  "bonds.ListUserBondsResponse",
  () => [
    { no: 1, name: "bond_ids", kind: "message", T: BondSet },
  ],
);

/**
 * @generated from message bonds.CreateBondRequest
 */
export const CreateBondRequest = proto3.makeMessageType(
  "bonds.CreateBondRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 2, name: "org_id", kind: "message", T: OrgId },
    { no: 3, name: "squad_ids", kind: "message", T: SquadId, repeated: true },
    { no: 4, name: "privacy", kind: "enum", T: proto3.getEnumType(PrivacyLevel) },
    { no: 5, name: "user_specified_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "followers", kind: "message", T: UserSet },
  ],
);

/**
 * @generated from message bonds.CreateBondResponse
 */
export const CreateBondResponse = proto3.makeMessageType(
  "bonds.CreateBondResponse",
  () => [
    { no: 1, name: "new_bond_id", kind: "message", T: BondId },
    { no: 2, name: "new_channel_id", kind: "message", T: ChannelId },
  ],
);

/**
 * @generated from message bonds.CreateBondFromChatMessageRequest
 */
export const CreateBondFromChatMessageRequest = proto3.makeMessageType(
  "bonds.CreateBondFromChatMessageRequest",
  () => [
    { no: 1, name: "bond", kind: "message", T: CreateBondRequest },
    { no: 2, name: "message", kind: "message", T: SendChatMessageDetails },
    { no: 3, name: "mentions", kind: "message", T: MentionSet },
  ],
);

/**
 * @generated from message bonds.CreateBondFromChatMessageResponse
 */
export const CreateBondFromChatMessageResponse = proto3.makeMessageType(
  "bonds.CreateBondFromChatMessageResponse",
  () => [
    { no: 1, name: "response", kind: "message", T: CreateBondResponse },
  ],
);

/**
 * @generated from message bonds.CreateBondFromCallRequest
 */
export const CreateBondFromCallRequest = proto3.makeMessageType(
  "bonds.CreateBondFromCallRequest",
  () => [
    { no: 1, name: "bond", kind: "message", T: CreateBondRequest },
    { no: 2, name: "mentions", kind: "message", T: MentionSet },
  ],
);

/**
 * @generated from message bonds.CreateBondFromCallResponse
 */
export const CreateBondFromCallResponse = proto3.makeMessageType(
  "bonds.CreateBondFromCallResponse",
  () => [
    { no: 1, name: "new_bond", kind: "message", T: CreateBondResponse },
  ],
);

/**
 * @generated from message bonds.GetBondKnowledgePreviewRequest
 */
export const GetBondKnowledgePreviewRequest = proto3.makeMessageType(
  "bonds.GetBondKnowledgePreviewRequest",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
  ],
);

/**
 * @generated from message bonds.GetBondKnowledgePreviewResponse
 */
export const GetBondKnowledgePreviewResponse = proto3.makeMessageType(
  "bonds.GetBondKnowledgePreviewResponse",
  () => [
    { no: 1, name: "preview", kind: "message", T: BondKnowledgePreview },
  ],
);

/**
 * @generated from message bonds.GetBondKnowledgeByTypeRequest
 */
export const GetBondKnowledgeByTypeRequest = proto3.makeMessageType(
  "bonds.GetBondKnowledgeByTypeRequest",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "knowledge_type", kind: "enum", T: proto3.getEnumType(BondKnowledgeType) },
  ],
);

/**
 * @generated from message bonds.GetBondKnowledgeByTypeResponse
 */
export const GetBondKnowledgeByTypeResponse = proto3.makeMessageType(
  "bonds.GetBondKnowledgeByTypeResponse",
  () => [
    { no: 1, name: "bond_knowledges", kind: "message", T: BondKnowledge, repeated: true },
  ],
);

/**
 * @generated from message bonds.GetBondKnowledgesRequest
 */
export const GetBondKnowledgesRequest = proto3.makeMessageType(
  "bonds.GetBondKnowledgesRequest",
  () => [
    { no: 1, name: "bond_set", kind: "message", T: BondSet },
  ],
);

/**
 * @generated from message bonds.GetBondKnowledgesResponse
 */
export const GetBondKnowledgesResponse = proto3.makeMessageType(
  "bonds.GetBondKnowledgesResponse",
  () => [
    { no: 1, name: "bond_knowledges", kind: "message", T: BondKnowledge, repeated: true },
  ],
);

/**
 * @generated from message bonds.GetChannelIdFromCallRequest
 */
export const GetChannelIdFromCallRequest = proto3.makeMessageType(
  "bonds.GetChannelIdFromCallRequest",
  () => [
    { no: 1, name: "call_id", kind: "message", T: CallId },
  ],
);

/**
 * @generated from message bonds.GetChannelIdFromCallResponse
 */
export const GetChannelIdFromCallResponse = proto3.makeMessageType(
  "bonds.GetChannelIdFromCallResponse",
  () => [
    { no: 1, name: "channel_id", kind: "message", T: ChannelId },
  ],
);

/**
 * @generated from message bonds.AddFollowersToBondRequest
 */
export const AddFollowersToBondRequest = proto3.makeMessageType(
  "bonds.AddFollowersToBondRequest",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "users", kind: "message", T: UserSet },
  ],
);

/**
 * @generated from message bonds.AddFollowersToBondResponse
 */
export const AddFollowersToBondResponse = proto3.makeMessageType(
  "bonds.AddFollowersToBondResponse",
  [],
);

/**
 * @generated from message bonds.RemoveFollowersFromBondRequest
 */
export const RemoveFollowersFromBondRequest = proto3.makeMessageType(
  "bonds.RemoveFollowersFromBondRequest",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "users", kind: "message", T: UserSet },
  ],
);

/**
 * @generated from message bonds.RemoveFollowersFromBondResponse
 */
export const RemoveFollowersFromBondResponse = proto3.makeMessageType(
  "bonds.RemoveFollowersFromBondResponse",
  [],
);

/**
 * @generated from message bonds.AddArchiversToBondRequest
 */
export const AddArchiversToBondRequest = proto3.makeMessageType(
  "bonds.AddArchiversToBondRequest",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "users", kind: "message", T: UserSet },
  ],
);

/**
 * @generated from message bonds.AddArchiversToBondResponse
 */
export const AddArchiversToBondResponse = proto3.makeMessageType(
  "bonds.AddArchiversToBondResponse",
  [],
);

/**
 * @generated from message bonds.RemoveArchiversFromBondRequest
 */
export const RemoveArchiversFromBondRequest = proto3.makeMessageType(
  "bonds.RemoveArchiversFromBondRequest",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "users", kind: "message", T: UserSet },
  ],
);

/**
 * @generated from message bonds.RemoveArchiversFromBondResponse
 */
export const RemoveArchiversFromBondResponse = proto3.makeMessageType(
  "bonds.RemoveArchiversFromBondResponse",
  [],
);

/**
 * @generated from message bonds.GetBondRoleHoldersRequest
 */
export const GetBondRoleHoldersRequest = proto3.makeMessageType(
  "bonds.GetBondRoleHoldersRequest",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "role", kind: "enum", T: proto3.getEnumType(InvolvementRole) },
  ],
);

/**
 * @generated from message bonds.GetBondRoleHoldersResponse
 */
export const GetBondRoleHoldersResponse = proto3.makeMessageType(
  "bonds.GetBondRoleHoldersResponse",
  () => [
    { no: 1, name: "user_ids", kind: "message", T: UserSet },
  ],
);

/**
 * @generated from message bonds.GetFollowerLaggardsRequest
 */
export const GetFollowerLaggardsRequest = proto3.makeMessageType(
  "bonds.GetFollowerLaggardsRequest",
  () => [
    { no: 1, name: "primary_channel_id", kind: "message", T: ChannelId },
    { no: 2, name: "sequence_number", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message bonds.GetFollowerLaggardsResponse
 */
export const GetFollowerLaggardsResponse = proto3.makeMessageType(
  "bonds.GetFollowerLaggardsResponse",
  () => [
    { no: 1, name: "user_ids", kind: "message", T: UserSet },
  ],
);

/**
 * @generated from message bonds.SendChatMessageViaBondRequest
 */
export const SendChatMessageViaBondRequest = proto3.makeMessageType(
  "bonds.SendChatMessageViaBondRequest",
  () => [
    { no: 1, name: "request", kind: "message", T: SendChatMessageRequest },
    { no: 2, name: "bond_id", kind: "message", T: BondId },
    { no: 3, name: "mentions", kind: "message", T: MentionSet },
  ],
);

/**
 * @generated from message bonds.SendChatMessageViaBondResponse
 */
export const SendChatMessageViaBondResponse = proto3.makeMessageType(
  "bonds.SendChatMessageViaBondResponse",
  () => [
    { no: 1, name: "response", kind: "message", T: SendChatMessageResponse },
  ],
);

/**
 * @generated from message bonds.StartCallViaBondRequest
 */
export const StartCallViaBondRequest = proto3.makeMessageType(
  "bonds.StartCallViaBondRequest",
  () => [
    { no: 2, name: "bond_id", kind: "message", T: BondId },
    { no: 3, name: "call_parameters", kind: "message", T: CallParameters },
  ],
);

/**
 * @generated from message bonds.StartCallViaBondResponse
 */
export const StartCallViaBondResponse = proto3.makeMessageType(
  "bonds.StartCallViaBondResponse",
  () => [
    { no: 1, name: "response", kind: "message", T: StartCallResponse },
    { no: 2, name: "did_redirect", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message bonds.UpdateBondUserSpecifiedTitleRequest
 */
export const UpdateBondUserSpecifiedTitleRequest = proto3.makeMessageType(
  "bonds.UpdateBondUserSpecifiedTitleRequest",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message bonds.UpdateBondUserSpecifiedTitleResponse
 */
export const UpdateBondUserSpecifiedTitleResponse = proto3.makeMessageType(
  "bonds.UpdateBondUserSpecifiedTitleResponse",
  [],
);

/**
 * @generated from message bonds.UpdateBondAiGeneratedTitleRequest
 */
export const UpdateBondAiGeneratedTitleRequest = proto3.makeMessageType(
  "bonds.UpdateBondAiGeneratedTitleRequest",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "first_summarised_seq", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "last_summarised_seq", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message bonds.UpdateBondAiGeneratedTitleResponse
 */
export const UpdateBondAiGeneratedTitleResponse = proto3.makeMessageType(
  "bonds.UpdateBondAiGeneratedTitleResponse",
  [],
);

/**
 * @generated from message bonds.UpdateBondKnowledgeSummaryAllContentRequest
 */
export const UpdateBondKnowledgeSummaryAllContentRequest = proto3.makeMessageType(
  "bonds.UpdateBondKnowledgeSummaryAllContentRequest",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "first_summarised_seq", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "last_summarised_seq", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message bonds.UpdateBondKnowledgeSummaryAllContentResponse
 */
export const UpdateBondKnowledgeSummaryAllContentResponse = proto3.makeMessageType(
  "bonds.UpdateBondKnowledgeSummaryAllContentResponse",
  [],
);

/**
 * @generated from message bonds.UpdateUserBondKnowledgeRequest
 */
export const UpdateUserBondKnowledgeRequest = proto3.makeMessageType(
  "bonds.UpdateUserBondKnowledgeRequest",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "user_id", kind: "message", T: UserId },
    { no: 3, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "last_summarised_seq", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "bond_done_seq", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "last_call_event_seq", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message bonds.UpdateUserBondKnowledgeResponse
 */
export const UpdateUserBondKnowledgeResponse = proto3.makeMessageType(
  "bonds.UpdateUserBondKnowledgeResponse",
  [],
);

/**
 * @generated from message bonds.SubBondsPreviewsRequest
 */
export const SubBondsPreviewsRequest = proto3.makeMessageType(
  "bonds.SubBondsPreviewsRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message bonds.SubBondsPreviewsResponse
 */
export const SubBondsPreviewsResponse = proto3.makeMessageType(
  "bonds.SubBondsPreviewsResponse",
  () => [
    { no: 1, name: "bond_preview", kind: "message", T: BondPreview, oneof: "preview_or_deleted" },
    { no: 2, name: "deleted_id", kind: "message", T: BondId, oneof: "preview_or_deleted" },
  ],
);

/**
 * @generated from message bonds.SubBondRequest
 */
export const SubBondRequest = proto3.makeMessageType(
  "bonds.SubBondRequest",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
  ],
);

/**
 * @generated from message bonds.SubBondResponse
 */
export const SubBondResponse = proto3.makeMessageType(
  "bonds.SubBondResponse",
  () => [
    { no: 1, name: "bond", kind: "message", T: BondOverview, oneof: "bond_or_deleted" },
    { no: 2, name: "deleted_id", kind: "message", T: BondId, oneof: "bond_or_deleted" },
  ],
);

/**
 * @generated from message bonds.SubMsgSeqNumsRequest
 */
export const SubMsgSeqNumsRequest = proto3.makeMessageType(
  "bonds.SubMsgSeqNumsRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message bonds.SubMsgSeqNumsResponse
 */
export const SubMsgSeqNumsResponse = proto3.makeMessageType(
  "bonds.SubMsgSeqNumsResponse",
  () => [
    { no: 1, name: "response", kind: "message", T: SubUserMaxReadSeqNumsResponse },
  ],
);

/**
 * @generated from message bonds.SubArchivedBondsListDiffRequest
 */
export const SubArchivedBondsListDiffRequest = proto3.makeMessageType(
  "bonds.SubArchivedBondsListDiffRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message bonds.SubArchivedBondsListDiffResponse
 */
export const SubArchivedBondsListDiffResponse = proto3.makeMessageType(
  "bonds.SubArchivedBondsListDiffResponse",
  () => [
    { no: 1, name: "bond_ids_added", kind: "message", T: BondSet },
    { no: 2, name: "bond_ids_removed", kind: "message", T: BondSet },
  ],
);

/**
 * @generated from message bonds.SubCatchupSummariesRequest
 */
export const SubCatchupSummariesRequest = proto3.makeMessageType(
  "bonds.SubCatchupSummariesRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message bonds.SubCatchupSummariesResponse
 */
export const SubCatchupSummariesResponse = proto3.makeMessageType(
  "bonds.SubCatchupSummariesResponse",
  () => [
    { no: 1, name: "summary", kind: "message", T: UserCatchupSummary, oneof: "summary_or_deleted" },
    { no: 2, name: "deleted_id", kind: "message", T: BondId, oneof: "summary_or_deleted" },
  ],
);

/**
 * @generated from message bonds.SubBondContemporariesRequest
 */
export const SubBondContemporariesRequest = proto3.makeMessageType(
  "bonds.SubBondContemporariesRequest",
  () => [
    { no: 1, name: "add_to_sub", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "bond_ids", kind: "message", T: BondSet },
  ],
);

/**
 * @generated from message bonds.SubBondContemporariesResponse
 */
export const SubBondContemporariesResponse = proto3.makeMessageType(
  "bonds.SubBondContemporariesResponse",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "bond_contemporaries", kind: "message", T: UserSet },
  ],
);

/**
 * @generated from message bonds.CreateFakedataCallForBondRequest
 */
export const CreateFakedataCallForBondRequest = proto3.makeMessageType(
  "bonds.CreateFakedataCallForBondRequest",
  () => [
    { no: 1, name: "call_events", kind: "message", T: RtcSessionEvent, repeated: true },
    { no: 2, name: "session_id", kind: "message", T: RtcSessionId },
    { no: 3, name: "start_time", kind: "message", T: Timestamp },
    { no: 5, name: "initiator_id", kind: "message", T: UserId },
    { no: 6, name: "bond_id", kind: "message", T: BondId },
  ],
);

/**
 * @generated from message bonds.CreateFakedataCallForBondResponse
 */
export const CreateFakedataCallForBondResponse = proto3.makeMessageType(
  "bonds.CreateFakedataCallForBondResponse",
  () => [
    { no: 1, name: "call_id", kind: "message", T: CallId },
  ],
);

/**
 * @generated from message bonds.GetSearchableBondsRequest
 */
export const GetSearchableBondsRequest = proto3.makeMessageType(
  "bonds.GetSearchableBondsRequest",
  () => [
    { no: 1, name: "squad_ids", kind: "message", T: SquadId, repeated: true },
    { no: 2, name: "user_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message bonds.GetSearchableBondsResponse
 */
export const GetSearchableBondsResponse = proto3.makeMessageType(
  "bonds.GetSearchableBondsResponse",
  () => [
    { no: 1, name: "bonds", kind: "message", T: BondOverview, repeated: true },
  ],
);

